{
  "common" : {
    "save" : "Salva",
    "saving" : "Salvataggio",
    "integrate" : "Integra",
    "preview" : "Anteprima",
    "back" : "Indietro",
    "alert" : "Avviso",
    "proceed" : "Procedi",
    "cancel" : "Annulla",
    "delete" : "Elimina",
    "embed" : "Incorpora",
    "link" : "Link",
    "iframeEmbed" : "Incorporamento Iframe",
    "styles" : "Stili",
    "options" : "Opzioni",
    "quickAdd" : "Aggiunta rapida",
    "customFields" : "Campi personalizzati",
    "personalInfo" : "Informazioni personali",
    "contactInfo" : "Informazioni di contatto",
    "submit" : "Invia",
    "address" : "Indirizzo",
    "customized" : "Personalizzato",
    "layout" : "Layout",
    "showLabel" : "Mostra etichetta",
    "background" : "Sfondo",
    "border" : "Bordo",
    "borderType" : "Tipo di bordo",
    "cornerRadius" : "Raggio d'angolo",
    "width" : "Larghezza",
    "padding" : "Spaziatura interna",
    "top" : "Superiore",
    "bottom" : "Inferiore",
    "left" : "Sinistra",
    "right" : "Destra",
    "shadow" : "Ombra",
    "color" : "Colore",
    "horizontal" : "Orizzontale",
    "vertical" : "Verticale",
    "blur" : "Sfocatura",
    "spread" : "Diffondi",
    "fieldStyle" : "Stile del campo",
    "fontColor" : "Colore del carattere",
    "label" : "Etichetta",
    "fontFamily" : "Famiglia di caratteri",
    "size" : "Dimensione",
    "weight" : "Peso",
    "placeholder" : "Segnaposto",
    "customCSS" : "CSS personalizzato",
    "backgroundImage" : "Immagine di sfondo",
    "headerImage" : "Immagine dell'intestazione",
    "miscellaneous" : "Varie",
    "agencyBranding" : "Branding dell'agenzia",
    "imageUploadMessage" : "Fai clic su un file immagine o trascinalo in quest'area per caricarlo",
    "backgroundImageTooltipMessage" : "L'immagine di sfondo deve coprire l'intera pagina, fornendo uno sfondo visivamente accattivante per il modulo. Per visualizzare l'immagine di sfondo, fai clic sull'opzione Anteprima.",
    "headerImageTooltipMessage" : "L'immagine dell'intestazione è progettata per coprire l'intera larghezza del modulo e rimane posizionata nella parte superiore del modulo. Per visualizzare l'immagine dell'intestazione, fai clic sull'opzione Anteprima.",
    "onSubmit" : "All'invio",
    "facebookPixelID" : "ID pixel di Facebook",
    "facebookPixelIDMessage" : "Ignora questo campo se prevedi di utilizzare questo modulo/sondaggio all'interno di un funnel",
    "facebookPixelEvents" : "Eventi del pixel di Facebook",
    "onpageView" : "Visualizzazione sulla pagina",
    "stickyContact" : "Contatto frequente",
    "stickyContactMessage" : "La funzione Contatto frequente compilerà automaticamente le informazioni di contatto inserite in precedenza, così non dovrai inserirle due volte.",
    "fieldDescription" : "Descrizione del campo",
    "fieldTitle" : "Titolo del campo",
    "queryKey" : "Chiave della query",
    "querykeyTooltipMessage" : "La chiave della query che può essere usata come parametro dell'URL per popolare questo campo",
    "required" : "Obbligatorio",
    "hidden" : "Nascosto",
    "hiddenValue" : "Valore nascosto",
    "enableCountryPicker" : "Abilita selettore di paese",
    "altText" : "Testo alternativo",
    "alignment" : "Allineamento",
    "imageWidth" : "Larghezza dell'immagine",
    "imageHeight" : "Altezza dell'immagine",
    "logic" : "Logica",
    "pleaseInput" : "Inserisci",
    "editHTML" : "Modifica HTML",
    "textStyle" : "Stile del testo",
    "subText" : "Testo secondario",
    "text" : "Testo",
    "buttonStyle" : "Stile del pulsante",
    "fullWidth" : "Larghezza completa",
    "htmlMessage" : "L'HTML personalizzato va qui",
    "htmlEditorHeader" : "JAVASCRIPT / HTML PERSONALIZZATO",
    "htmlEditorBodyMessage" : "Aggiungi qui il tuo codice",
    "yesSave" : "Sì, salva",
    "cancelKeep" : "Annulla, mantieni",
    "done" : "Fatto",
    "addElement" : "Aggiungi elemento",
    "addUrlLike" : "Aggiungi URL validi come",
    "doNotAddRelativePath" : "Non aggiungere percorsi relativi come",
    "or" : "o",
    "addCustomFields" : "Aggiungi campi personalizzati",
    "customFieldSearch" : "Cerca per nome",
    "enterSourceValue" : "Inserisci il valore della fonte",
    "shortLabel" : "Etichetta breve",
    "shortLabelPlaceholder" : "Type a short label",
    "fileUploadPlaceholder" : "Upload a file",
    "customCSSTooltip" : "Il CSS personalizzato ha la precedenza sullo stile dei moduli e sui temi e può avere un impatto sullo stile del tema.",
    "fontFamilyTooltip" : "Builder supporta tutti i tipi di font, se non lo vedi nell'elenco. Cercalo nel menu a discesa",
    "useGDPRCompliantFont" : "Usa carattere conforme al GDPR",
    "enableTimezone" : "Abilita fuso orario",
    "inputStyle" : "Stile di inserimento",
    "conditionalLogicAlertOnSubmit" : "La logica condizionale avrà la precedenza sul messaggio/reindirizzamento",
    "conditionalLogicAlertOnShowHide" : "La logica condizionale avrà la precedenza sulle impostazioni nascoste",
    "placeholderFacebookPixelIdInput" : "Inserisci l'ID pixel di Facebook",
    "doNotShowMessage" : "Non mostrare più",
    "themes" : {
      "themes" : "Temi",
      "alertThemeMessage" : "Il cambio di tema comporterà la perdita di tutte le modifiche di stile attuali.",
      "useTheme" : "Utilizza il tema",
      "removeTheme" : "Rimuovi il tema"
    },
    "box" : "Casella",
    "line" : "Linea",
    "message" : "Messaggio",
    "openUrl" : "Apri URL",
    "borderTypeList" : {
      "dotted" : "Punteggiato",
      "dashed" : "Tratteggiato",
      "solid" : "Uniforme",
      "none" : "Nessuno"
    },
    "button" : "Pulsante",
    "emailNotification" : "Notifica email",
    "emailNotificationSubheader" : "Ricevi un'e-mail quando qualcuno compila il tuo modulo",
    "subject" : "Oggetto",
    "emailTo" : "Email (a)",
    "senderName" : "Nome del mittente",
    "senderNameWarning" : "Il tuo (nome del sottoaccount) verrà aggiunto se lasciato vuoto",
    "emailError" : "È richiesto almeno un indirizzo email",
    "emailTooltip" : "Il primo indirizzo email è designato per il campo \"A\". Tutti gli indirizzi email successivi verranno inclusi nel campo \"Cc\".",
    "clear" : "Rimuovi",
    "showNotificationsSettings" : "Mostra impostazioni delle notifiche",
    "emailServicePaid" : "Questa funzionalità è un servizio a pagamento e comporterà costi in base all'utilizzo.",
    "integration" : "Integrazione",
    "payment" : {
      "payments" : "Pagamenti",
      "connected" : "Collegato",
      "connect" : "Collega",
      "liveModeOn" : "Modalità live",
      "status" : "Stato",
      "test" : "Test",
      "live" : "Live",
      "paymentFields" : "Campi di pagamento",
      "suggestAnAmount" : "Suggerisci un importo",
      "selectCurrency" : "Seleziona la valuta",
      "tagActiveBGColor" : "Colore etichetta attiva",
      "includeOtherAmount" : "Includi altro importo",
      "footerInfo" : "Info piè di pagina",
      "paymentType" : "Tipo di pagamento",
      "sellProducts" : "Vendi prodotti",
      "customAmount" : "Importo personalizzato",
      "collectPayment" : "Raccogli pagamento",
      "generalSettings" : "Campi generali",
      "product" : {
        "addProduct" : "Aggiungi prodotto",
        "productSettings" : "Impostazioni prodotto",
        "selectProduct" : "Seleziona prodotto",
        "editThisProduct" : "Per modificare questo prodotto",
        "clickHere" : "Fai clic qui",
        "show" : "Mostra",
        "description" : "Descrizione",
        "image" : "Immagine",
        "layoutSettings" : "Impostazioni di layout",
        "singleColumn" : "Colonna singola",
        "twoColumn" : "Due colonne",
        "threeColumn" : "Tre colonne",
        "maxProductError" : "In un modulo sono consentiti al massimo 20 prodotti",
        "productSelectPlaceholder" : "Seleziona un prodotto",
        "orderConfirmation" : "Conferma dell'ordine",
        "orderConfirmationWarning" : "Una volta che il pagamento è andato a buon fine, verrà visualizzata una pagina di conferma dell'ordine",
        "quantity" : "Quantità",
        "createProduct" : "Per creare un prodotto"
      },
      "coupons" : {
        "coupons" : "Coupon",
        "enableCoupon" : "Abilita coupon",
        "couponAlignment" : "Allineamento del coupon",
        "left" : "Sinistra",
        "right" : "Destra",
        "fullWidth" : "Larghezza completa",
        "tooltipMessage" : "Imposta la posizione di inserimento del coupon dai tipi sottostanti"
      }
    },
    "addressSettings" : {
      "autoComplete" : "Indirizzo a completamento automatico",
      "addressfields" : "Campi indirizzo",
      "placeholderSearch" : "Cerca un indirizzo",
      "autoCompleteTooltip" : "Fornisce la possibilità di cercare e popolare automaticamente l'indirizzo. Questa funzione è a pagamento.",
      "mandataorySearch" : {
        "title" : "Obbligatorio Cerca Bar",
        "primaryText" : "Cos'è un bar obbligatorio cerca?",
        "secondaryText" : "Quando Abilitato, quando un utente interagisce con l'Indirizzo Campo, Poi deve selezionare un Indirizzo valido per Procedi.",
        "secondaryText1" : "Quando Disabilitato Poi possono Procedi senza selezionare un Indirizzo in barra Cerca."
      }
    },
    "autoResponder" : "Risponditore automatico",
    "autoResponderSubheader" : "Invia un'email automatica alla persona che invia il modulo (l'email include una copia delle informazioni inserite nel modulo)",
    "replyToEmail" : "Rispondi all'email",
    "open" : "Aperta",
    "won" : "Vinta",
    "lost" : "Persa",
    "abandon" : "Abbandona",
    "selectAPipeline" : "Seleziona una sequenza",
    "stage" : "Fase",
    "status" : "Stato",
    "opportunityValue" : "Valore dell'opportunità",
    "createNewPipeline" : "Crea una nuova sequenza",
    "editOpportunitySettings" : "Modifica le impostazioni delle opportunità",
    "contact" : "Contatto",
    "opportunity" : "Opportunità",
    "add" : "Aggiungi",
    "more" : "Più",
    "opportunityHeading" : "Dove vuoi che la tua opportunità venga aggiornata?",
    "date" : {
      "dateSettings" : "Impostazioni data",
      "dateFormat" : "Formato data",
      "dateSeparator" : "Separatore di data",
      "dateFormatTooltipMessage" : "Seleziona un formato di data. D sta per giorno, M per mese e Y per anno",
      "dateSeparatorTooltipMessage" : "Seleziona un separatore che separerà giorno, mese e anno nel formato selezionato in Formato data",
      "disablePicker" : "Disabilita selettore"
    },
    "restoreVersion" : "Ripristina versione",
    "versionHistory" : "Cronologia delle versioni",
    "errorMessage" : "Errore, riprova di nuovo",
    "version" : "Versione:",
    "currentVersion" : "Versione attuale",
    "messageStyling" : "Stile dei messaggi",
    "validateEmailHeader" : "Convalida email",
    "validateEmailDescription" : "Il pulsante diventerà visibile una volta che l'utente avrà inserito la propria email. Questa funzione è progettata per aiutarti a generare lead di qualità.",
    "validatePhoneHeader" : "Convalida telefono",
    "validatePhoneDescription" : "Il pulsante diventerà visibile una volta che l'utente avrà inserito il proprio numero di telefono. Questa funzione è progettata per aiutarti a generare lead di qualità.",
    "validatePhoneWarning" : "Per abilitare la verifica OTP via SMS, assicurati che il tuo numero di telefono sia configurato nelle tue impostazioni di luogo",
    "addAnotherTnc" : "Add another T & C",
    "tnc1" : "T & C 1",
    "tnc2" : "T & C 2",
    "deleteCaps" : "DELETE",
    "deleteConfirmation" : "Type DELETE to confirm",
    "style" : {
      "formLayoutType" : "Tipo di modulo",
      "oneColumn" : "Una colonna",
      "twoColumn" : "Due colonne",
      "singleLine" : "Linea singola",
      "fieldWidth" : "Larghezza del campo",
      "labelAlignment" : {
        "labelAlignment" : "Allineamento etichetta",
        "labelWidth" : "Larghezza etichetta"
      },
      "fieldSpacing" : "Spaziatura dei campi",
      "color" : "colore",
      "fontColor" : "Colore del carattere",
      "inputBackground" : "Sfondo di input",
      "layout" : "Layout",
      "colorsAndBackground" : "Colori e sfondo",
      "advanceSettings" : "Advanced Settings",
      "inputField" : "Campo di inserimento",
      "borderWidth" : "Larghezza del bordo",
      "borderColor" : "Colore del bordo",
      "borderStyle" : "Stile del bordo",
      "fontSize" : "Dimensione del carattere",
      "fontWeight" : "Peso del carattere",
      "labelColor" : "Colore etichetta",
      "placeholderColor" : "Colore segnaposto",
      "shortLabelColor" : "Colore dell'etichetta breve"
    },
    "replyToTooltip" : "Personalizza l'indirizzo email di risposta predefinito utilizzando {'{'}{'{'}contact.email{'}'}{'}'} per abilitare le risposte al contatto che invia il modulo.",
    "content" : "Contenuti",
    "calculations" : "Calcoli",
    "uploadOptions" : "Opzioni di caricamento",
    "opportunitySettingsWarning" : "Per configurare le impostazioni dell'opportunità, fai clic sul pulsante Modifica impostazioni opportunità.",
    "question" : "Domanda",
    "createContactOnPartialSubmission" : "Crea contatto con invio parziale",
    "howItWorks" : "Come funziona?",
    "createContactOnPartialSubmissionDescription" : "Termina la diapositiva e fai clic su \"Successivo\". Un nuovo contatto verrà creato e memorizzato automaticamente.",
    "swapWarningMessageSurvey" : "La modifica o lo scambio delle opzioni influirà su questo campo personalizzato in tutti i luoghi in cui viene utilizzato il campo. Può anche resettare la logica condizionale. ",
    "swapWarningMessageForm" : "La modifica o lo scambio delle opzioni influirà su questo campo personalizzato in tutti i luoghi in cui viene utilizzato il campo. ",
    "optionLabel" : "Etichetta opzione",
    "calculationValue" : "Valore di calcolo",
    "addOption" : "Aggiungi opzione",
    "enableCalculations" : "Abilita calcoli",
    "allowOtherOption" : "Allow Other Option",
    "productSelectionSubtext" : "Abilitazione individuale o selezione di Prodotti multipli",
    "multi" : "Multi",
    "single" : "Individuo",
    "productSelectionHeading" : "Selezione Prodotto",
    "spreadToColumns" : "Diffondi in colonne",
    "columns" : "Colonne",
    "desktopMode" : "Modalità desktop",
    "mobileMode" : "Modalità mobile",
    "versions" : "Varianti"
  },
  "survey" : {
    "typeOfBuilder" : "Sondaggio",
    "survyeStyle" : "Stile del sondaggio",
    "surveyName" : "Nome del sondaggio",
    "surveyElement" : "Elemento del sondaggio",
    "addSurveyElement" : "Aggiungi elemento del sondaggio",
    "unsavedAlertMessage" : "Il sondaggio contiene lavoro non salvato. Vuoi davvero uscire ed eliminare tutto il lavoro non salvato?",
    "thirdPartyScriptAlertMessage" : "Gli script di terze parti all'interno del blocco HTML possono fornire potenti funzionalità, ma comportano anche rischi per la privacy, la sicurezza, le prestazioni e il comportamento della pagina. Pertanto, ti consigliamo di rivedere il contenuto di questo script prima di salvarlo nel sondaggio.",
    "integrateHeader" : "Integra sondaggio",
    "integrateSubHeader" : "Usa le seguenti opzioni per integrare il tuo sondaggio personalizzato nel tuo sito web",
    "integrateCopyLinkMessage" : "Copia il link qui sotto e condividilo facilmente ovunque desideri.",
    "onSurveySubmission" : "All'invio del sondaggio",
    "surveySettings" : "Impostazioni sondaggio",
    "oneQuestionAtTime" : "Una domanda alla volta",
    "backButton" : "Pulsante Indietro",
    "backButtonTooltipMessage" : "Il pulsante Indietro aiuterà l'utente a spostare la diapositiva precedente",
    "disableAutoNavigation" : "Disabilita navigazione automatica",
    "disableAutoNavigationTooltipMessage" : "Se l'opzione è attivata, il sondaggio non passerà automaticamente alla diapositiva successiva",
    "autoNavigation" : "Navigazione Automatico",
    "autoNavigationTooltipMessage" : "Se girato Disabilitato, Il Sondaggio non andrà automaticamente al Successivo Scorri",
    "progressBar" : "Barra di avanzamento",
    "progressBarTooltipMessage" : "Disattivando questa opzione verrà rimossa la barra di avanzamento dal sondaggio",
    "disableAnimation" : "Disabilita animazione",
    "disableAnimationTooltipMessage" : "Attivando questa opzione verrà disabilitata l'animazione di transizione della diapositiva",
    "scrollToTop" : "Scorri verso l'alto",
    "scrollToTopTooltipMessage" : "Se l'opzione è attivata, il sondaggio scorrerà verso l'alto nella diapositiva successiva",
    "footerHTML" : "Piè di pagina HTML",
    "footerHTMLMessage" : "Puoi scrivere CSS personalizzati (usando l'etichetta di stile) e HTML all'interno della stessa casella",
    "editFooter" : "Modifica piè di pagina",
    "disqualifyImmediately" : "Squalifica immediatamente",
    "disqualifyAfterSubmit" : "Squalifica dopo l'invio",
    "slideConflictAlertMessage" : "Questa diapositiva contiene più domande con una logica impostata. Ciò può causare alcuni comportamenti indesiderati",
    "addSlide" : "Aggiungi diapositiva",
    "settings" : "Impostazioni",
    "delete" : "Elimina",
    "slideDeleteAlertMessage" : "Vuoi davvero eliminare questa diapositiva?",
    "slideDeleteBetweenAlertMessage" : "Questo riorganizzerà le altre diapositive. Vuoi davvero eliminare questa diapositiva?",
    "slideName" : "Nome diapositiva",
    "slidePosition" : "Posizione della diapositiva",
    "jumpTo" : "Salta a",
    "enableTimezoneSurvey" : "L'attivazione di questa opzione acquisirà le informazioni sul fuso orario della persona che invia il sondaggio",
    "footer" : {
      "footer" : "Piè di pagina",
      "enableNewFooter" : "Abilita nuovo piè di pagina",
      "stickToSurvey" : " Fissa al sondaggio",
      "backgroundFill" : "Riempimento sfondo",
      "footerHeight" : "Altezza del piè di pagina",
      "enableProgressBar" : "Abilita barra di avanzamento",
      "buttons" : "Pulsanti",
      "buttonType" : "Tipo di pulsante",
      "fillNext" : "Riempi (successivo)",
      "fillPrevious" : "Riempi (precedente)",
      "fillSubmit" : "Compila (Invia)",
      "textColor" : "Testo del pulsante",
      "position" : "Posizione",
      "previousText" : "Precedente (testo)",
      "nextText" : "Successivo (testo)",
      "submitText" : "Invia (testo)",
      "fillComplete" : "Riempimento completo",
      "fillInactive" : "Riempimento inattivo",
      "footerType" : "Tipo di piè di pagina",
      "stickToCard" : "Fissa alla scheda",
      "stickToPage" : "Fissa alla pagina"
    },
    "surveyNotfication" : "Notifica del sondaggio",
    "style" : {
      "surveyPadding" : "Spaziatura del sondaggio"
    },
    "autoResponderSubheader" : "Invia un'email automatica alla persona che invia il sondaggio (l'email include una copia delle informazioni inserite nel sondaggio)"
  },
  "form" : {
    "typeOfBuilder" : "Modulo",
    "formStyle" : "Stile del modulo",
    "formElement" : "Elemento del modulo",
    "addFormElement" : "Aggiungi elemento modulo",
    "addConditionalLogic" : "Aggiungi logica condizionale",
    "inlineForm" : "Modulo in linea",
    "formName" : "Nome del modulo",
    "fullScreenMode" : "Modalità a schermo intero",
    "fullScreenModetooltip" : "L'attivazione di questa opzione farà sì che il modulo si espanda su tutta la larghezza del contenitore, estendendosi fino al bordo dell'area di incorporamento e aderendo alla larghezza del modulo.",
    "enableTimezoneForm" : "Attivando questa opzione si acquisiscono le informazioni sul fuso orario della persona che invia il modulo",
    "formSettings" : "Impostazioni del modulo",
    "unsavedAlertMessage" : "Il modulo contiene lavoro non salvato. Vuoi davvero uscire ed eliminare tutto il lavoro non salvato?",
    "thirdPartyScriptAlertMessage" : "Gli script di terze parti all'interno del blocco HTML possono fornire potenti funzionalità, ma comportano anche rischi per la privacy, la sicurezza, le prestazioni e il comportamento della pagina. Pertanto, ti consigliamo di rivedere il contenuto di questo script prima di salvarlo nel modulo.",
    "onFormSubmission" : "All'invio del modulo",
    "integrateForm" : "Integra modulo",
    "embedlayoutType" : "Tipo di layout incorporato",
    "embedlayoutTypeSubMessage" : "Seleziona un layout da incorporare in un funnel o in un sito web esterno",
    "triggerType" : "Tipo di trigger",
    "showOnScrolling" : "Mostra su scorrimento",
    "showAfter" : "Mostra dopo",
    "seconds" : "secondi",
    "alwaysShow" : "Mostra sempre",
    "activationOptions" : "Opzioni di attivazione",
    "activateOn" : "Attiva il",
    "visit" : "visita",
    "alwaysActivated" : "Sempre attivato",
    "deactivationOptions" : "Opzioni di disattivazione",
    "deactivateAfterShowing" : "Disattiva dopo la visualizzazione",
    "times" : "volte",
    "deactiveOnceLeadCollected" : "Disattiva una volta raccolto il lead",
    "neverDeactivate" : "Non disattivare mai",
    "copyEmbedCode" : "Copia codice di incorporamento",
    "copyFormLink" : "Copia link del modulo",
    "openFormLink" : "Apri il link del modulo",
    "inline" : "In linea",
    "inlineDescription" : "Un modulo in linea viene visualizzato insieme al contenuto della pagina come elemento nativo. Non si sovrappone e non può essere chiuso o ridotto a icona.",
    "inlineHoverDescription" : "Un modulo in linea viene visualizzato insieme al contenuto della pagina come elemento nativo. Non si sovrappone e non può essere chiuso o ridotto a icona.",
    "popup" : "Popup",
    "popupDescription" : "Un popup mostra il modulo come una sovrapposizione all'interno di una light-box. Non può essere ridotto a icona, ma può essere chiuso.",
    "popupHoverDescription" : "Un popup mostra il modulo come una sovrapposizione all'interno di una light-box. Non può essere ridotto a icona, ma può essere chiuso.",
    "politeSlideIn" : "Pannello di cortesia",
    "politeSlideInDescription" : "Un pannello di cortesia appare nella parte inferiore dello schermo e scompare una volta chiuso",
    "politeSlideInHoverDescription" : "Appare nella parte inferiore dello schermo e scompare una volta chiuso",
    "stickySidebar" : "Barra laterale fissa",
    "stickySidebarDescription" : "Una barra laterale fissa apparirà a lato dello schermo e può essere ridotta a icona in una scheda che resta fissa al lato della finestra",
    "stickySidebarHoverDescription" : "Appaiono a lato dello schermo e possono essere ridotti a icona in una scheda che resta fissa al lato della finestra",
    "allowMinimize" : "Consenti riduzione a icona",
    "conditonalLogic" : {
      "conditions" : "Condizioni",
      "noExistingCondition" : "Ancora nessuna condizione esistente",
      "freeRoamMessage" : "Puoi girare liberamente senza regole, almeno per ora",
      "addNewCondition" : "Aggiungi nuova condizione",
      "redirectToUrl" : "Reindirizza all'URL",
      "displayCustomMessage" : "Visualizza messaggio personalizzato",
      "disqualifyLead" : "Squalifica il lead",
      "showHideFields" : "Mostra/Nascondi campi",
      "redirectToUrlSubText" : "Reindirizza l'utente dopo l'invio",
      "displayCustomMessageSubText" : "Mostra messaggi basati sugli input di Attivo Utente",
      "disqualifyLeadSubText" : "Filtra le voci in condizioni specifiche",
      "showHideFieldsSubText" : "Modifica campo Visibilità in modo dinamico",
      "openBelowUrl" : "Apri l'URL sottostante",
      "showCustomMessage" : "Mostra messaggio personalizzato",
      "alertConditionDelete" : "Vuoi davvero eliminare questa condizione?",
      "deleteCondition" : "Elimina condizione",
      "conditionExecutedMessage" : "Le condizioni verranno eseguite dall'alto verso il basso.",
      "allOperationOptions" : {
        "isEqualTo" : "È uguale a",
        "isNotEqualTo" : "Non è uguale a",
        "contains" : "Contiene",
        "startsWith" : "Inizia con",
        "endsWith" : "Termina con",
        "isEmpty" : "È vuoto",
        "isFilled" : "È riempito",
        "before" : "Prima",
        "after" : "Dopo",
        "greaterThan" : "Maggiore di",
        "lessThan" : "Meno di"
      },
      "logic" : {
        "if" : "Se",
        "then" : "Allora",
        "and" : "E",
        "or" : "O"
      },
      "phoneAlertMessage" : "Per cercare numeri di telefono con un prefisso internazionale, devi includere esplicitamente il simbolo \"+\" nella query di ricerca.",
      "deleteAlertMessage" : "Vuoi davvero eliminare questo campo? Verrà rimossa anche la condizione associata a questo campo.",
      "deleteField" : "Elimina campo"
    },
    "minimizedTitlePlaceholder" : "Titolo ridotto",
    "dragYourElementFromLeft" : "Trascina i tuoi elementi qui da sinistra",
    "formNotification" : "Notifica modulo",
    "style" : {
      "formPadding" : "Spaziatura modulo"
    }
  },
  "quiz" : {
    "quizElement" : "Elemento del quiz",
    "quizNotification" : "Notifica quiz",
    "viewAndCustomise" : "Visualizza e personalizza",
    "categoryAndScore" : "Categoria e punteggio",
    "provideFeedback" : "Fornisci un feedback",
    "addQuizElement" : "Aggiungi elemento quiz",
    "typeOfBuilder" : "Quiz",
    "catgory" : {
      "manageCategories" : "Gestisci categorie"
    },
    "quizSettings" : "Impostazioni del quiz",
    "onQuizSubmission" : "All'invio del quiz",
    "settings" : "Impostazioni",
    "deletePage" : "Elimina pagina",
    "quizResult" : {
      "resultTemplate" : "Modello di risultato",
      "quizResultAlertMessage" : "La pagina dei risultati scelti verrà mostrata una volta completato il quiz"
    },
    "autoResponderSubheader" : "Invia un'e-mail automatica alla persona che invia il quiz (l'e-mail include una copia delle informazioni inserite nel quiz)",
    "integrateHeader" : "Integra quiz",
    "integrateSubHeader" : "Usa le seguenti opzioni per integrare il tuo quiz personalizzato nel tuo sito web",
    "integrateCopyLinkMessage" : "Copia il link qui sotto e condividilo facilmente ovunque desideri.",
    "pageName" : "Nome della pagina",
    "pagePosition" : "Posizione della pagina"
  }
}
