<script lang="ts" setup>
import { useAppStore } from "@/store/app";
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import AddressChildCardPreview from "./AddressChildCardPreview.vue";
const store = useAppStore();
const { t } = useI18n();
const props = defineProps({
  groupedElement: {
    type: Object,
    default: () => ({}),
  },
  inputClass: {
    type: Array,
    default: () => [],
  },
  formLabelVisible: {
    type: Boolean,
    default: true,
  },
  selectedIndex: {
    type: Number,
    required: true,
  },
  selectedSlideIndex: {
    type: Number,
    required: false,
    default: undefined,
  },
});

// Elements to show in the desired order
const elementOrder = ["city", "state", "country", "postal_code"];

const elementsToShow = computed(() => {
  return elementOrder
    .map((tag) => props.groupedElement?.children.find((element) => element.tag === tag))
    .filter(Boolean);
});

const addressElement = computed(() => {
  return props.groupedElement?.children.find((element) => element?.tag === "address");
});

const getInputCSSList = (): Array<String> => {
  return store.builderConfig.inputStyleType === "line" ? ["line-form-style"] : [];
};

const applyInlineStyle = computed(() => {
  return {
    marginBottom: store.formStyle.fieldSpacing + "px",
  };
});

const totalElements = computed(() => {
  return (
    props.groupedElement?.children?.filter((element) => element?.tag !== "address")?.length || 0
  );
});

const getSearchTextStyle = computed(() => {
  const paddingLeft = (parseInt(store.fieldStyle?.padding?.left) || 0) + 30;
  return {
    "padding-left": `${paddingLeft}px !important`,
    fontSize: store.fieldStyle.placeholderFontSize + "px",
  };
});

const getSearchIconStyle = computed(() => {
  return {
    left: `${parseInt(store.fieldStyle?.padding?.left) || 0}px !important`,
    top: `${parseInt(store.fieldStyle?.padding?.top) || 0}px !important`,
  };
});

const isRequired = computed(() => {
  if (props.selectedSlideIndex !== undefined) {
    return store.slides[props.selectedSlideIndex]?.slideData?.[props.selectedIndex].required;
  }
  return store.elements?.[props.selectedIndex].required;
});
</script>

<template>
  <div class="items-startself-s tretch flex flex-col">
    <!-- Address Input with Search Icon -->
    <div
      v-if="store.addressSettings.autoCompleteEnabled"
      class="relative flex flex-col items-start gap-1 self-stretch"
      :style="applyInlineStyle"
    >
      <div v-if="formLabelVisible" style="display: inline-flex">
        <label v-if="formLabelVisible">
          {{ store.addressSettings.label || "Address" }}
          <span v-if="isRequired">*</span>
        </label>
      </div>
      <div class="w-100 relative">
        <input
          :placeholder="store.addressSettings.placeholder || 'Search address'"
          class="form-control"
          :class="getInputCSSList()"
          :style="getSearchTextStyle"
          :readonly="true"
        />
        <!-- Search Icon positioned inside the input field -->
        <div :style="getSearchIconStyle" class="absolute flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            class=""
          >
            <path
              d="M17.5 18L14.5834 15.0833M16.6667 10.0833C16.6667 13.9954 13.4954 17.1667 9.58333 17.1667C5.67132 17.1667 2.5 13.9954 2.5 10.0833C2.5 6.17132 5.67132 3 9.58333 3C13.4954 3 16.6667 6.17132 16.6667 10.0833Z"
              stroke="#667085"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>

    <!-- Address preview card for "address" -->
    <template v-if="addressElement">
      <div class="flex flex-col items-start gap-1 self-stretch" :style="applyInlineStyle">
        <AddressChildCardPreview
          :element="addressElement"
          :form-label-visible="formLabelVisible"
          @update:label="addressElement.label = $event"
          @update:placeholder="addressElement.placeholder = $event"
        />
      </div>
    </template>

    <!-- Render other elements in a grid -->
    <template v-if="elementsToShow.length > 0">
      <div class="grid grid-cols-2 gap-x-4 self-stretch">
        <div
          v-for="(element, index) in elementsToShow"
          :key="element?.tag"
          :style="index == totalElements - 1 || index == totalElements - 2 ? '' : applyInlineStyle"
        >
          <div class="flex flex-col items-start gap-1 self-stretch">
            <AddressChildCardPreview
              :element="element"
              :form-label-visible="formLabelVisible"
              @update:label="element.label = $event"
              @update:placeholder="element.placeholder = $event"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
