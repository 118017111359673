<template>
  <div v-if="type === 'input'" class="flex flex-col">
    <input
      :value="inputValue"
      :placeholder="inputPlaceholder"
      class="form-placeholder form-control shadow-none focus:outline-transparent focus:ring-0"
      :class="classList"
      :style="styleObject"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
    />
    <template v-if="isActive">
      <input
        v-model="shortLabel"
        :placeholder="$t('common.shortLabelPlaceholder')"
        class="short-label border-0 p-0 focus:outline-none"
        @input="updateShortLabel"
      />
    </template>
    <template v-else>
      <span v-if="element.shortLabel" class="short-label">
        {{ element.shortLabel }}
      </span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { ref, watch } from "vue";

const props = defineProps({
  element: {
    type: Object,
    default: () => {
      return {};
    },
  },
  type: {
    type: String,
    default: "input",
  },
  placeholder: { type: String, default: "" },
  classList: {
    type: Array,
    default: () => {
      return [];
    },
  },
  styleObject: {
    type: Object,
    default: () => {
      return {
        fontSize: "14px",
      };
    },
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

const store = useAppStore();

const emit = defineEmits(["update:placeholder", "update:shortLabel"]);
const inputPlaceholder = ref(props.placeholder);
const inputValue = ref("");
const shortLabel = ref(props.element.shortLabel || "");

watch(
  () => props.placeholder,
  (newVal) => {
    inputPlaceholder.value = newVal;
  }
);

watch(
  () => props.element.shortLabel,
  (newVal) => {
    shortLabel.value = newVal || "";
  }
);

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  inputValue.value = target.value;
  inputPlaceholder.value = target.value;
  emit("update:placeholder", inputPlaceholder.value);
};

const handleFocus = () => {
  inputValue.value = inputPlaceholder.value;
};

const handleBlur = (event: Event) => {
  const target = event.target as HTMLInputElement;
  inputValue.value = "";
  emit("update:placeholder", inputPlaceholder.value);
  store.addToHistory();
};

const updateShortLabel = (event: Event) => {
  const target = event.target as HTMLInputElement;
  shortLabel.value = target.value;
  emit("update:shortLabel", shortLabel.value);
};
</script>

<style scoped>
.short-label {
  background-color: transparent;
  margin-top: 4px;
  font-size: 14px;
  color: #4b5563;
}
</style>
