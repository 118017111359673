<template>
  <div class="flex flex-col items-start gap-7 self-stretch">
    <ProductPreview v-if="store.payment?.paymentType === 'product'"></ProductPreview>
    <div class="flex flex-col items-start gap-8 self-stretch">
      <DonationPreview
        v-if="store.payment.paymentType !== 'product'"
        :payment="payment"
        :input-class="inputClass"
      ></DonationPreview>
      <CouponPreview
        v-if="store.payment.paymentType === 'product' && store.payment?.coupon?.enableCoupon"
      ></CouponPreview>
      <UIAlert
        v-if="store.payment.showInfoAboutPayment"
        id="warning-message"
        type="default"
        :closable="false"
      >
        <template #content
          >Payment will be processed as soon as you proceed to the next step</template
        >
      </UIAlert>
      <PaymentCardPreview></PaymentCardPreview>
    </div>
    <div v-if="!store.payment.footer.hidden" class="self-stretch text-center text-xs text-gray-400">
      <input
        v-model="store.payment.footer.message"
        class="w-full border-0 text-center text-xs text-gray-400 outline-none"
        @blur="store.addToHistory"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UIAlert } from "@gohighlevel/ghl-ui";
import DonationPreview from "../Donation/DonationPreview.vue";
import ProductPreview from "../Products/ProductPreview.vue";
import CouponPreview from "./CouponPreview.vue";
import PaymentCardPreview from "./PaymentCardPreview.vue";
const store = useAppStore();
defineProps({
  payment: {
    type: Object,
    default: () => {
      return {};
    },
  },
  inputClass: {
    type: Array,
    default: () => {
      return [];
    },
  },
});
</script>
