<template>
  <div class="flex flex-col">
    <div class="signature-button" :style="styleObject"></div>
    <template v-if="isActive">
      <input
        v-model="shortLabel"
        :placeholder="t('common.shortLabelPlaceholder')"
        class="short-label border-0 p-0 focus:outline-none"
        @input="updateShortLabel"
      />
    </template>
    <template v-else>
      <span v-if="element.shortLabel" class="short-label">{{ element.shortLabel }}</span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  element: {
    type: Object,
    default: () => {
      return {};
    },
  },
  styleObject: {
    type: Object,
    default: () => {
      return {};
    },
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:shortLabel"]);
const shortLabel = ref(props.element.shortLabel || "");
const { t } = useI18n();

watch(
  () => props.element.shortLabel,
  (newVal) => {
    shortLabel.value = newVal || "";
  }
);

const updateShortLabel = (event: Event) => {
  const target = event.target as HTMLInputElement;
  shortLabel.value = target.value;
  emit("update:shortLabel", shortLabel.value);
};
</script>
<style scoped>
.signature-button {
  border: 1px solid #ccc;
  height: 160px;
  width: 100%;
}
.short-label {
  margin-top: 8px;
  font-size: 14px;
  color: #4b5563;
}
</style>
