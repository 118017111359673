<template>
  <table class="flex flex-col">
    <tbody :style="styleObject">
      <VueDraggableNext v-model="localPicklistOptions" handle=".drag-handle" class="w-full">
        <tr v-for="(pickerOption, index) in localPicklistOptions" :key="pickerOption.id">
          <td v-if="isActive" class="flex items-center justify-center">
            <div class="mr-2 mt-1.5 h-8">
              <Menu05Icon class="drag-handle ml-4 mt-1 h-4 w-4 cursor-move text-gray-500" />
            </div>
          </td>
          <td>
            <label
              :for="pickerOption.id"
              contenteditable="true"
              class="inline-block w-auto cursor-text border-0 shadow-none outline-none"
              @input="updateOptionLabel($event, index)"
              @focus="handleFocus"
              @keyup="handleEditing($event, index)"
              >{{ pickerOption.label }}</label
            >
          </td>
          <td>
            <div class="option col-12 mb-2 pl-2">
              <input
                v-if="type === 'input'"
                :id="pickerOption.id"
                v-model="pickerOption.prefillValue"
                :class="classList"
                :style="styleObject"
                type="text"
                class="form-control"
                placeholder="Fill values"
              />
            </div>
          </td>
          <td>
            <Trash01Icon
              v-if="isActive"
              class="ml-2 mt-[-0.5rem] h-4 w-4 cursor-pointer text-gray-500"
              @click="prepareToRemoveOption(index)"
            />
          </td>
        </tr>
      </VueDraggableNext>
    </tbody>
    <div v-if="isActive" class="mt-[-1rem] flex items-center">
      <UIButton
        id="textbox-list-add-option"
        text
        class="mt-4"
        :disabled="isLastOptionEmpty"
        @click="addOption"
      >
        <PlusIcon class="mr-1.5 h-5 w-5" />
        {{ t("common.addOption") }}
      </UIButton>
    </div>
    <template v-if="isActive">
      <input
        v-model="shortLabel"
        :placeholder="t('common.shortLabelPlaceholder')"
        class="short-label border-0 p-0 focus:outline-none"
        @blur="updateShortLabel"
      />
    </template>
    <template v-else>
      <span v-if="element.shortLabel" class="short-label">
        {{ element.shortLabel }}
      </span>
    </template>
  </table>
  <DeleteConfirmationModal
    :show="showDeleteModal"
    @confirm="removeOption"
    @cancel="cancelRemoveOption"
  />
</template>

<script setup lang="ts">
import { Menu05Icon, PlusIcon, Trash01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton } from "@gohighlevel/ghl-ui";
import { computed, PropType, ref, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import { useI18n } from "vue-i18n";
import DeleteConfirmationModal from "./DeleteConfirmationModal.vue";

const { t } = useI18n();

const props = defineProps({
  type: {
    type: String,
    default: "input",
  },
  name: {
    type: String,
    default: "",
  },
  value: {
    type: [Array, String],
    default: "",
  },
  currentLocationId: { type: String, default: "" },
  placeholder: { type: String, default: "" },
  styleObject: {
    type: Object,
    default: () => {
      return {
        fontSize: "14px",
      };
    },
  },
  customClass: {
    type: String,
    default: "",
  },
  picklistOptions: {
    type: Array as PropType<Array<any>>,
    default() {
      return [{ id: "test", label: "Santosh", prefillValue: "hgdsjhf" }];
    },
  },
  classList: {
    type: Array,
    default: () => {
      return [];
    },
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  isTW: {
    type: Boolean,
    default: false,
  },
  element: {
    type: Object,
    default: () => {
      return {};
    },
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:shortLabel", "update:picklistOptions"]);

const shortLabel = ref(props.element.shortLabel || "");
const indexToRemove = ref<number | null>(null);
const showDeleteModal = ref(false);

const localPicklistOptions = ref([...props.picklistOptions]);

const isInternalUpdate = ref(false);

watch(
  () => props.picklistOptions,
  (newVal) => {
    if (!isInternalUpdate.value) {
      localPicklistOptions.value = JSON.parse(JSON.stringify(newVal));
    }
    isInternalUpdate.value = false;
  },
  { deep: true }
);

watch(
  () => localPicklistOptions.value,
  (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(props.picklistOptions)) {
      isInternalUpdate.value = true;
      emit("update:picklistOptions", JSON.parse(JSON.stringify(newVal)));
    }
  },
  { deep: true }
);

watch(
  () => props.element.shortLabel,
  (newVal) => {
    shortLabel.value = newVal || "";
  }
);

const updateOptionLabel = (event: Event, index: number) => {
  const target = event.target as HTMLElement;
  const text = target.innerText.replace(/\u200B/g, "");

  localPicklistOptions.value[index].label = text;
};

const prepareToRemoveOption = (index: number) => {
  if (props.picklistOptions.length > 1) {
    indexToRemove.value = index;
    showDeleteModal.value = true;
  }
};

const removeOption = () => {
  if (indexToRemove.value !== null) {
    const newOptions = [...localPicklistOptions.value];
    newOptions.splice(indexToRemove.value, 1);
    localPicklistOptions.value = newOptions;
  }
  showDeleteModal.value = false;
};

const cancelRemoveOption = () => {
  indexToRemove.value = null;
  showDeleteModal.value = false;
};

const isLastOptionEmpty = computed(() => {
  const lastOption = props.picklistOptions[props.picklistOptions.length - 1];
  return lastOption && typeof lastOption.label === "string" && lastOption.label.trim() === "";
});

const addOption = () => {
  const newOptions = [...localPicklistOptions.value];
  newOptions.push({ id: `option-${Date.now()}`, label: "Type an Option", prefillValue: "" });
  localPicklistOptions.value = newOptions;
};

const handleFocus = (event: FocusEvent) => {
  const target = event.target as HTMLElement;

  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
  }

  if (!target.textContent || target.textContent.trim() === "") {
    if (target.childNodes.length === 0) {
      const textNode = document.createTextNode("\u200B");
      target.appendChild(textNode);

      const range = document.createRange();
      range.setStart(textNode, 0);
      range.collapse(true);

      if (selection) {
        selection.addRange(range);
      }
    } else {
      const range = document.createRange();
      range.setStart(target, 0);
      range.collapse(true);

      if (selection) {
        selection.addRange(range);
      }
    }
  } else {
    const range = document.createRange();
    range.selectNodeContents(target);

    if (selection) {
      selection.addRange(range);
    }
  }
};

const handleEditing = (event: KeyboardEvent, index: number) => {
  const target = event.target as HTMLElement;

  if (target.textContent?.trim() === "") {
    if (target.textContent) {
      target.textContent = "";

      const range = document.createRange();
      range.setStart(target, 0);
      range.collapse(true);

      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }
};

const updateShortLabel = () => emit("update:shortLabel", shortLabel.value);
</script>

<style>
table.table.table-borderless th {
  border: 0;
}
table.table.table-borderless tr th:first-child {
  padding-right: 0;
  text-align: right;
  vertical-align: middle;
  max-width: 150px;
}
.textbox-list .option {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0;
}
.textbox-list label {
  width: 65px;
}
</style>
