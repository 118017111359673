<template>
  <div class="flex items-start gap-2 self-stretch">
    <div class="flex w-full items-center justify-between">
      <UITextSmMedium>{{ t("common.style.placeholderColor") }}</UITextSmMedium>
    </div>
  </div>
  <ColorInput
    :label="t('common.style.placeholderColor')"
    :color="
      store.deviceMode === 'mobile'
        ? store.fieldStyle?.mobilePlaceholderColor || store.fieldStyle?.placeholderColor
        : store.fieldStyle?.placeholderColor
    "
    @update:color="handlePlaceholderColor"
  />
  <FontFamilyInput
    :label="t('common.fontFamily')"
    class="w-full"
    :value="
      store.deviceMode === 'mobile'
        ? store.fieldStyle?.mobilePlaceholderFontFamily || store.fieldStyle?.placeholderFontFamily
        : store.fieldStyle?.placeholderFontFamily
    "
    :show-device-mode="true"
    @update:value="handlePlaceholderFontFamily"
  />
  <div class="flex items-start gap-3">
    <PixelNumber
      :label="t('common.style.fontSize')"
      :value="
        parseFloat(
          store.deviceMode === 'mobile'
            ? store.fieldStyle?.mobilePlaceholderFontSize || store.fieldStyle?.placeholderFontSize
            : store.fieldStyle?.placeholderFontSize
        )
      "
      :show-device-mode="true"
      @update:value="handlePlaceholderFontSize"
    />
    <PixelNumber
      :label="t('common.style.fontWeight')"
      :value="
        parseFloat(
          store.deviceMode === 'mobile'
            ? store.fieldStyle?.mobilePlaceholderFontWeight ||
                store.fieldStyle?.placeholderFontWeight
            : store.fieldStyle?.placeholderFontWeight
        )
      "
      :max="1800"
      :show-device-mode="true"
      @update:value="handlePlaceholderFontWeight"
    />
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UITextSmMedium } from "@gohighlevel/ghl-ui";
import { useI18n } from "vue-i18n";
import ColorInput from "../Elements/ColorInput.vue";
import FontFamilyInput from "../Elements/FontFamilyInput.vue";
import PixelNumber from "../Elements/PixelNumber.vue";

const { t } = useI18n();
const store = useAppStore();

const handlePlaceholderColor = (val: string) => {
  if (store.deviceMode === "mobile") {
    if (!store.fieldStyle.mobilePlaceholderColor) {
      store.fieldStyle.mobilePlaceholderColor = store.fieldStyle.placeholderColor;
    }
    store.fieldStyle.mobilePlaceholderColor = val;
  } else {
    store.fieldStyle.placeholderColor = val;
    if (!store.fieldStyle.mobilePlaceholderColor) {
      store.fieldStyle.mobilePlaceholderColor = val;
    }
  }
  store.anyUnsavedChanges = true;
};

const handlePlaceholderFontFamily = (val: string) => {
  if (!store.fontCollection.includes(val)) {
    store.fontCollection.push(val);
  }

  if (store.deviceMode === "mobile") {
    if (!store.fieldStyle.mobilePlaceholderFontFamily) {
      store.fieldStyle.mobilePlaceholderFontFamily = store.fieldStyle.placeholderFontFamily;
    }
    store.fieldStyle.mobilePlaceholderFontFamily = val;
  } else {
    store.fieldStyle.placeholderFontFamily = val;
    if (!store.fieldStyle.mobilePlaceholderFontFamily) {
      store.fieldStyle.mobilePlaceholderFontFamily = val;
    }
  }
  store.anyUnsavedChanges = true;
};

const handlePlaceholderFontSize = (val: number) => {
  if (store.deviceMode === "mobile") {
    if (!store.fieldStyle.mobilePlaceholderFontSize) {
      store.fieldStyle.mobilePlaceholderFontSize = store.fieldStyle.placeholderFontSize;
    }
    store.fieldStyle.mobilePlaceholderFontSize = val;
  } else {
    store.fieldStyle.placeholderFontSize = val;
  }
  store.anyUnsavedChanges = true;
};

const handlePlaceholderFontWeight = (val: number) => {
  if (store.deviceMode === "mobile") {
    if (!store.fieldStyle.mobilePlaceholderFontWeight) {
      store.fieldStyle.mobilePlaceholderFontWeight = store.fieldStyle.placeholderFontWeight;
    }
    store.fieldStyle.mobilePlaceholderFontWeight = val;
  } else {
    store.fieldStyle.placeholderFontWeight = val;
  }
  store.anyUnsavedChanges = true;
};
</script>
