<template>
  <UISpin v-if="!store.getProductListAPIProcessed" class="loader-container" size="large" />
  <div
    v-if="store.getProductListAPIProcessed && store.addedProductList.length < 1"
    class="product-container"
  >
    <ProductIcon class="product-icon"></ProductIcon>
    <div class="noproduct-text-container">
      <UITextMdMedium>No products added yet</UITextMdMedium>Once you add products, they'll show up
      right here <UITextSmRegular></UITextSmRegular>
    </div>
  </div>
  <LayoutOnePreview v-if="store.payment.layout === 1"></LayoutOnePreview>
  <LayoutMultiplePreview v-else></LayoutMultiplePreview>
</template>

<script setup lang="ts">
import ProductIcon from "@/assets/icons/product-icon.svg";
import { MinusIcon, PlusIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UITextMdMedium, UITextSmRegular, UITextLgMedium, UISpin } from "@gohighlevel/ghl-ui";
import { useAppStore } from "@/store/app";
import { computed } from "vue";
import LayoutOnePreview from "./LayoutOnePreview.vue";
import LayoutMultiplePreview from "./LayoutMultiplePreview.vue";
const store = useAppStore();
</script>

<style scoped>
.product-container {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background: #fff;
}

.product-icon {
  width: 136px;
  height: 102px;
  flex-shrink: 0;
}
.noproduct-text-container {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}
</style>
