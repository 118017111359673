<template>
  <section class="product-layout">
    <div v-for="(product, index) in store.addedProductList" :key="index" class="product-container">
      <div class="product-checkbox">
        <input
          v-if="
            store.payment.selectionType === 'checkbox' || store.payment.selectionType === undefined
          "
          :id="product.productId"
          :name="product._id"
          :value="product._id"
          type="checkbox"
        />
        <input
          v-else-if="store.payment.selectionType === 'radio'"
          :id="product.productId"
          :name="product._id"
          :value="product._id"
          type="radio"
        />
      </div>
      <div class="product-inner-container">
        <div
          v-if="product.showImage"
          class="image-container"
          :style="{
            backgroundImage: product.image ? `url(${product.image})` : '',
          }"
        ></div>
        <div class="product-text-container">
          <div class="product-stretch" :class="!product.showImage ? 'product-no-image-gap' : ''">
            <div class="product-text-divider">
              <label class="product-label">{{ product.name }}</label>
              <div
                v-if="product.showDescription"
                class="max-width"
                v-html="product.description"
              ></div>
              <div v-if="product.prices[0].type === 'recurring'">
                <div class="flex flex-wrap items-start gap-2 self-stretch">
                  <div class="flex items-center rounded-[16px] bg-primary-50 p-[2px_10px]">
                    <UITextXsMedium class="text-primary-700">Subscription</UITextXsMedium>
                  </div>
                  <div
                    v-if="product.prices[0].trialPeriod"
                    class="flex items-center rounded-[16px] bg-pink-50 p-[2px_10px]"
                  >
                    <UITextXsMedium class="text-pink-700"
                      >{{ product.prices[0].trialPeriod }} Day Free Trial</UITextXsMedium
                    >
                  </div>
                </div>
                <UITextXsRegular
                  v-if="product.prices[0].type === 'recurring' && product.prices[0].setupFee > 0"
                  class="setup-fee"
                >
                  {{
                    (store.currencyList[product.prices[0].currency]?.symbol || "$") +
                    product.prices[0]?.setupFee
                  }}
                  One Time Setup fee</UITextXsRegular
                >
              </div>
            </div>
            <div
              v-if="
                store.payment.layout === 1 && !product.showImage && product.showQuantity !== false
              "
              class="container-stretch"
            >
              <div class="product-selection-row">
                <label style="margin-bottom: 0px">Quantity</label>
                <div class="quantity-container-counter" :style="quantityStyle">
                  <MinusIcon class="plus-minus-icon"></MinusIcon>
                  <div :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }">1</div>
                  <PlusIcon class="plus-minus-icon"></PlusIcon>
                </div>
              </div>
            </div>
            <div class="product-price-container">
              <div
                v-if="product.prices[0]?.compareAtPrice"
                class="product-amount crossed-amount overflow-hidden text-ellipsis line-through"
              >
                {{
                  (store.currencyList[product.prices[0].currency]?.symbol || "$") +
                  product.prices[0]?.compareAtPrice
                }}
              </div>
              <div class="product-amount overflow-hidden text-ellipsis">
                {{
                  (store.currencyList[product.prices[0].currency]?.symbol || "$") +
                  product.amount +
                  (product.prices[0].type === "recurring"
                    ? `/${product.prices[0].recurring.interval}`
                    : "")
                }}
              </div>
            </div>
          </div>
          <div class="container-stretch product-column">
            <div
              v-if="product.showImage && product.showQuantity !== false"
              class="product-selection-row"
            >
              <label style="margin-bottom: 0px">Quantity</label>
              <div class="quantity-container-counter" :style="quantityStyle">
                <MinusIcon class="plus-minus-icon"></MinusIcon>
                <div :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }">1</div>
                <PlusIcon class="plus-minus-icon"></PlusIcon>
              </div>
            </div>
            <div
              v-for="(variant, variantIndex) in product.variants"
              :key="variantIndex"
              class="product-selection-row"
            >
              <label style="margin-bottom: 0px">{{ variant.name }}</label>
              <div class="container-stretch">
                <div
                  v-for="(option, optionIndex) in variant.options"
                  :key="optionIndex"
                  class="variant-tag payment-tag"
                >
                  <div>
                    {{ option.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { MinusIcon, PlusIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UITextXsMedium, UITextXsRegular } from "@gohighlevel/ghl-ui";
import { computed } from "vue";
const store = useAppStore();

const quantityStyle = computed(() => {
  return {
    paddingTop: store.fieldStyle.padding.top - 2 + "px",
    paddingBottom: store.fieldStyle.padding.bottom - 2 + "px",
  };
});
</script>

<style scoped>
.product-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}
.product-container {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  padding: 5px;
  border-radius: 8px;
  align-self: stretch;
}

.product-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-inner-container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;

  align-self: stretch;
}

.product-column {
  flex-direction: column;
}
.product-price-container {
  display: flex;
  align-items: start;
  gap: 10px;
  align-self: stretch;
  width: 110px;
  justify-content: end;
  flex-wrap: wrap;
  max-width: 100%;
}
.max-width {
  max-width: 90%;
}
.container-stretch {
  display: flex;
  align-items: stretch;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}
.product-stretch {
  display: flex;
  align-items: stretch;
  gap: 8px;
  align-self: stretch;
  justify-content: space-between;
}
.product-no-image-gap {
  gap: 6%;
}

.image-container {
  width: 140px;
  height: 149px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background-color: lightgray;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.product-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}
.product-text-divider {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  max-width: 60%;
}

.product-selection-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.quantity-container-counter {
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
}

.variant-tag {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #d0d5dd;
  background: #fff;
}

.plus-minus-icon {
  height: 16px;
  width: 16px;
  cursor: pointer;
}
.variant-tag {
  cursor: pointer;
}

.product-amount {
  max-width: 100%;
  word-break: break-word;
}
</style>
