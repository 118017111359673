{
  "common" : {
    "save" : "Salvar",
    "saving" : "Salvando",
    "integrate" : "Integrar",
    "preview" : "Pré-visualizar",
    "back" : "Voltar",
    "alert" : "Alerta",
    "proceed" : "Prosseguir",
    "cancel" : "Cancelar",
    "delete" : "Excluir",
    "embed" : "Incorporar",
    "link" : "Link",
    "iframeEmbed" : "Incorporação de iframe",
    "styles" : "Estilos",
    "options" : "Opções",
    "quickAdd" : "Adição rápida",
    "customFields" : "Campos personalizados",
    "personalInfo" : "Informações pessoais",
    "contactInfo" : "Informações de contato",
    "submit" : "Enviar",
    "address" : "Endereço",
    "customized" : "Personalizado",
    "layout" : "Layout",
    "showLabel" : "Mostrar rótulo",
    "background" : "Plano de fundo",
    "border" : "Borda",
    "borderType" : "Tipo de borda",
    "cornerRadius" : "Raio do canto",
    "width" : "Largura",
    "padding" : "Espaçamento",
    "top" : "Principal",
    "bottom" : "Parte inferior",
    "left" : "Esquerda",
    "right" : "Direita",
    "shadow" : "Sombra",
    "color" : "Cor",
    "horizontal" : "Horizontal",
    "vertical" : "Vertical",
    "blur" : "Desfoque",
    "spread" : "Difusão",
    "fieldStyle" : "Estilo do campo",
    "fontColor" : "Cor da fonte",
    "label" : "Rótulo",
    "fontFamily" : "Família de fontes",
    "size" : "Tamanho",
    "weight" : "Peso",
    "placeholder" : "Placeholder",
    "customCSS" : "CSS personalizado",
    "backgroundImage" : "Imagem de fundo",
    "headerImage" : "Imagem do cabeçalho",
    "miscellaneous" : "Diversos",
    "agencyBranding" : "Branding da agência",
    "imageUploadMessage" : "Clique ou arraste um arquivo de imagem para esta área para carregar",
    "backgroundImageTooltipMessage" : "A imagem de fundo deve cobrir toda a página, criando um pano de fundo visualmente atraente para o formulário. Para ver a imagem de fundo, clique na opção Pré-visualizar.",
    "headerImageTooltipMessage" : "A imagem do cabeçalho foi projetada para cobrir toda a largura do formulário e permanece posicionada na parte superior do formulário. Para ver a imagem do cabeçalho, clique na opção Pré-visualizar.",
    "onSubmit" : "Ao enviar",
    "facebookPixelID" : "ID do pixel do Facebook",
    "facebookPixelIDMessage" : "Ignore este campo se você planeja usar este formulário/pesquisa dentro de um funil",
    "facebookPixelEvents" : "Eventos do pixel do Facebook",
    "onpageView" : "Exibição na página",
    "stickyContact" : "Contato importante",
    "stickyContactMessage" : "O contato importante preencherá automaticamente as informações de contato inseridas anteriormente para que você não precise digitá-las duas vezes.",
    "fieldDescription" : "Descrição do campo",
    "fieldTitle" : "Título do campo",
    "queryKey" : "Chave de consulta",
    "querykeyTooltipMessage" : "A chave de consulta que pode ser usada como um parâmetro de URL para preencher este campo",
    "required" : "Obrigatório",
    "hidden" : "Oculto",
    "hiddenValue" : "Valor oculto",
    "enableCountryPicker" : "Ativar seletor de países",
    "altText" : "Texto alternativo",
    "alignment" : "Alinhamento",
    "imageWidth" : "Largura da imagem",
    "imageHeight" : "Altura da imagem",
    "logic" : "Lógica",
    "pleaseInput" : "Insira",
    "editHTML" : "Editar HTML",
    "textStyle" : "Estilo de texto",
    "subText" : "Subtítulo",
    "text" : "Texto",
    "buttonStyle" : "Estilo do botão",
    "fullWidth" : "Largura total",
    "htmlMessage" : "O HTML personalizado vai aqui",
    "htmlEditorHeader" : "JAVASCRIPT / HTML PERSONALIZADO",
    "htmlEditorBodyMessage" : "Adicione seu código aqui",
    "yesSave" : "Sim, salvar",
    "cancelKeep" : "Cancelar, Manter",
    "done" : "Concluído",
    "addElement" : "Adicionar elemento",
    "addUrlLike" : "Adicione URLs válidos como",
    "doNotAddRelativePath" : "Não adicione caminhos relativos como",
    "or" : "ou",
    "addCustomFields" : "Adicionar campos personalizados",
    "customFieldSearch" : "Pesquisar por nome",
    "enterSourceValue" : "Insira o valor de origem",
    "shortLabel" : "Rótulo curto",
    "shortLabelPlaceholder" : "Type a short label",
    "fileUploadPlaceholder" : "Upload a file",
    "customCSSTooltip" : "O CSS personalizado tem precedência sobre o estilo e os temas do formulário e pode afetar o estilo do tema.",
    "fontFamilyTooltip" : "O construtor é compatível com todos os tipos de fontes, caso você não consiga ver isso na lista. Pesquise no menu suspenso",
    "useGDPRCompliantFont" : "Use uma fonte compatível com GDPR",
    "enableTimezone" : "Ativar fuso horário",
    "inputStyle" : "Estilo de entrada",
    "conditionalLogicAlertOnSubmit" : "A lógica condicional terá precedência sobre a mensagem/ redirecionamento aqui",
    "conditionalLogicAlertOnShowHide" : "A lógica condicional terá precedência sobre as configurações ocultas aqui",
    "placeholderFacebookPixelIdInput" : "Insira o ID do pixel do Facebook",
    "doNotShowMessage" : "Não exibir novamente",
    "themes" : {
      "themes" : "Temas",
      "alertThemeMessage" : "A troca de temas resultará na perda de todas as modificações de estilo atuais.",
      "useTheme" : "Usar tema",
      "removeTheme" : "Remover tema"
    },
    "box" : "Caixa",
    "line" : "Linha",
    "message" : "Mensagem",
    "openUrl" : "Abrir URL",
    "borderTypeList" : {
      "dotted" : "Pontilhado",
      "dashed" : "Tracejado",
      "solid" : "Sólido",
      "none" : "Nenhum"
    },
    "button" : "Botão",
    "emailNotification" : "Notificação por e-mail",
    "emailNotificationSubheader" : "Receba um e-mail quando alguém preencher seu formulário",
    "subject" : "Assunto",
    "emailTo" : "E-mail (Para)",
    "senderName" : "Nome do remetente",
    "senderNameWarning" : "Seu (nome da subconta) será adicionado se deixado em branco",
    "emailError" : "Pelo menos um endereço de e-mail é necessário",
    "emailTooltip" : "O primeiro endereço de e-mail é designado para o campo \"Para\". Todos os endereços de e-mail subsequentes serão incluídos no campo \"Cc\".",
    "clear" : "Limpar",
    "showNotificationsSettings" : "Exibir configurações de notificações",
    "emailServicePaid" : "Este recurso é um serviço pago e será cobrado com base em seu uso.",
    "integration" : "Integração",
    "payment" : {
      "payments" : "Pagamentos",
      "connected" : "Conectado",
      "connect" : "Conectar",
      "liveModeOn" : "Modo ao vivo",
      "status" : "Status",
      "test" : "Testar",
      "live" : "Ao vivo",
      "paymentFields" : "Campos de pagamento",
      "suggestAnAmount" : "Sugira uma quantidade",
      "selectCurrency" : "Selecione a moeda",
      "tagActiveBGColor" : "Cor da etiqueta ativa",
      "includeOtherAmount" : "Incluir outro valor",
      "footerInfo" : "Informações do rodapé",
      "paymentType" : "Tipo de pagamento",
      "sellProducts" : "Vender produtos",
      "customAmount" : "Valor personalizado",
      "collectPayment" : "Cobrar pagamento",
      "generalSettings" : "Campos gerais",
      "product" : {
        "addProduct" : "Adicionar produto",
        "productSettings" : "Configurações do produto",
        "selectProduct" : "Selecione o produto",
        "editThisProduct" : "Para editar este produto",
        "clickHere" : "Clique aqui",
        "show" : "Exibir",
        "description" : "Descrição",
        "image" : "Imagem",
        "layoutSettings" : "Configurações de layout",
        "singleColumn" : "Coluna única",
        "twoColumn" : "Duas colunas",
        "threeColumn" : "Três colunas",
        "maxProductError" : "São permitidos no máximo 20 produtos em um formulário",
        "productSelectPlaceholder" : "Selecione um produto",
        "orderConfirmation" : "Confirmação do pedido",
        "orderConfirmationWarning" : "Uma página de confirmação do pedido será exibida assim que o pagamento for bem-sucedido",
        "quantity" : "Quantidade",
        "createProduct" : "Para criar um produto"
      },
      "coupons" : {
        "coupons" : "Cupons",
        "enableCoupon" : "Ativar cupom",
        "couponAlignment" : "Alinhamento de cupom",
        "left" : "Esquerda",
        "right" : "Direita",
        "fullWidth" : "Largura total",
        "tooltipMessage" : "Defina a posição da entrada do cupom a partir dos tipos abaixo"
      }
    },
    "addressSettings" : {
      "autoComplete" : "Endereço de preenchimento automático",
      "addressfields" : "Campos de endereço",
      "placeholderSearch" : "Pesquisar endereço",
      "autoCompleteTooltip" : "Oferece a capacidade de pesquisar e preencher endereços automaticamente. Este recurso é cobrado.",
      "mandataorySearch" : {
        "title" : "Barra do Obrigatório Pesquisar",
        "primaryText" : "O que é um barra do Obrigatório Pesquisar?",
        "secondaryText" : "Quando Habilitado, Quando um usuário interagem com o Endereço Campo, Então eles devem selecionar um Endereço válido para Prosseguir.",
        "secondaryText1" : "Quando desabilitado Então, eles podem Prosseguir sem selecionar um endereço in barra Pesquisar."
      }
    },
    "autoResponder" : "Resposta automática",
    "autoResponderSubheader" : "Envie um e-mail automático para a pessoa que enviar o formulário (o e-mail inclui uma cópia das informações inseridas no formulário)",
    "replyToEmail" : "Responder ao e-mail",
    "open" : "Aberto(a)",
    "won" : "Ganho(a)",
    "lost" : "Perdido(a)",
    "abandon" : "Abandonar",
    "selectAPipeline" : "Selecione um pipeline",
    "stage" : "Fase",
    "status" : "Status",
    "opportunityValue" : "Valor da oportunidade",
    "createNewPipeline" : "Crie um novo pipeline",
    "editOpportunitySettings" : "Editar configurações de oportunidade",
    "contact" : "Contato",
    "opportunity" : "Oportunidade",
    "add" : "Adicionar",
    "more" : "Mais",
    "opportunityHeading" : "Onde você quer que sua oportunidade seja atualizada?",
    "date" : {
      "dateSettings" : "Configurações de data",
      "dateFormat" : "Formato de data",
      "dateSeparator" : "Separador de data",
      "dateFormatTooltipMessage" : "Selecione um formato de data. D significa dia, M significa meses e Y significa ano",
      "dateSeparatorTooltipMessage" : "Selecione um separador que separará o dia, o mês e o ano no formato selecionado em Formato da data",
      "disablePicker" : "Desativar seletor"
    },
    "restoreVersion" : "Restaurar versão",
    "versionHistory" : "Histórico da versão",
    "errorMessage" : "Erro, tente novamente",
    "version" : "Versão:",
    "currentVersion" : "Versão atual",
    "messageStyling" : "Estilo de Mensagens",
    "validateEmailHeader" : "Validar e-mail",
    "validateEmailDescription" : "O botão ficará visível quando o usuário inserir o e-mail. Este recurso foi criado para ajudar você a gerar leads de qualidade.",
    "validatePhoneHeader" : "Validar telefone",
    "validatePhoneDescription" : "O botão ficará visível quando o usuário inserir o telefone. Este recurso foi criado para ajudar você a gerar leads de qualidade.",
    "validatePhoneWarning" : "Para ativar a verificação OTP por SMS, certifique-se de que seu número de telefone esteja configurado nas configurações de localização",
    "addAnotherTnc" : "Add another T & C",
    "tnc1" : "T & C 1",
    "tnc2" : "T & C 2",
    "deleteCaps" : "DELETE",
    "deleteConfirmation" : "Type DELETE to confirm",
    "style" : {
      "formLayoutType" : "Tipo de formulário",
      "oneColumn" : "Uma coluna",
      "twoColumn" : "Duas colunas",
      "singleLine" : "Linha única",
      "fieldWidth" : "Largura do campo",
      "labelAlignment" : {
        "labelAlignment" : "Alinhamento do rótulo",
        "labelWidth" : "Largura do rótulo"
      },
      "fieldSpacing" : "Espaçamento de campo",
      "color" : "cor",
      "fontColor" : "Cor da fonte",
      "inputBackground" : "Plano de fundo da entrada",
      "layout" : "Layout",
      "colorsAndBackground" : "Cores & Plano de fundo",
      "advanceSettings" : "Advanced Settings",
      "inputField" : "Campo de entrada",
      "borderWidth" : "Largura da borda",
      "borderColor" : "Cor da borda",
      "borderStyle" : "Estilo de borda",
      "fontSize" : "Tamanho da fonte",
      "fontWeight" : "Espessura da fonte",
      "labelColor" : "Cor da etiqueta",
      "placeholderColor" : "Cor do placeholder",
      "shortLabelColor" : "Cor do rótulo curto"
    },
    "replyToTooltip" : "Personalize o endereço de e-mail padrão para resposta usando {'{'}{'{'}contact.email{'}'}{'}'} para ativar respostas ao contato que envia o formulário.",
    "content" : "Conteúdo",
    "calculations" : "Cálculos",
    "uploadOptions" : "Opções de upload",
    "opportunitySettingsWarning" : "Defina as configurações de oportunidade clicando no botão Editar configurações de oportunidade.",
    "question" : "Pergunta",
    "createContactOnPartialSubmission" : "Criar contato em envio parcial",
    "howItWorks" : "Como funciona?",
    "createContactOnPartialSubmissionDescription" : "Finalize o slide e clique em \"Avançar\". Um novo contato será criado e armazenado automaticamente.",
    "swapWarningMessageSurvey" : "A edição ou troca de opções afetará este campo personalizado em todos os locais em que o campo for usado. Também pode redefinir a lógica condicional. ",
    "swapWarningMessageForm" : "A edição ou troca de opções afetará este campo personalizado em todos os locais em que o campo for usado. ",
    "optionLabel" : "Rótulo de opção",
    "calculationValue" : "Valor de cálculo",
    "addOption" : "Adicionar opção",
    "enableCalculations" : "Ativar cálculos",
    "allowOtherOption" : "Allow Other Option",
    "productSelectionSubtext" : "Habilitar Indivíduo ou seleção de vários produtos",
    "multi" : "Multi",
    "single" : "Individual",
    "productSelectionHeading" : "Seleção de Produto",
    "spreadToColumns" : "Espalhar em colunas",
    "columns" : "Colunas",
    "desktopMode" : "Modo de desktop",
    "mobileMode" : "Modo móvel",
    "versions" : "Variantes"
  },
  "survey" : {
    "typeOfBuilder" : "Pesquisa",
    "survyeStyle" : "Estilo da pesquisa",
    "surveyName" : "Nome da pesquisa",
    "surveyElement" : "Elemento da pesquisa",
    "addSurveyElement" : "Adicionar elemento de pesquisa",
    "unsavedAlertMessage" : "A pesquisa tem trabalho não salvo. Tem certeza de que deseja sair e descartar todo o trabalho não salvo?",
    "thirdPartyScriptAlertMessage" : "Scripts de terceiros dentro do bloco HTML podem fornecer uma funcionalidade poderosa, mas também trazem riscos à privacidade, segurança, desempenho e comportamento da página. Portanto, recomendamos que você revise o conteúdo desse script antes de salvá-lo no questionário.",
    "integrateHeader" : "Integrar pesquisa",
    "integrateSubHeader" : "Use as seguintes opções para integrar o questionário personalizado ao seu site",
    "integrateCopyLinkMessage" : "Copie o link abaixo e compartilhe-o facilmente em qualquer lugar.",
    "onSurveySubmission" : "No envio da pesquisa",
    "surveySettings" : "Configurações da pesquisa",
    "oneQuestionAtTime" : "Uma pergunta de cada vez",
    "backButton" : "Botão Voltar",
    "backButtonTooltipMessage" : "O botão Voltar ajuda o usuário a retornar ao slide anterior",
    "disableAutoNavigation" : "Desativar navegação automática",
    "disableAutoNavigationTooltipMessage" : "Se ativada, o questionário não passará automaticamente para o próximo slide",
    "autoNavigation" : "Navegação Automático",
    "autoNavigationTooltipMessage" : "Se estiver desabilitado, a pesquisa não passará automaticamente para o Próximo Deslizar",
    "progressBar" : "Barra de progresso",
    "progressBarTooltipMessage" : "Desativar essa opção removerá a barra de progresso da pesquisa.",
    "disableAnimation" : "Desativar animação",
    "disableAnimationTooltipMessage" : "Ativar essa opção desativará a animação da transição de slides",
    "scrollToTop" : "Rolar até o topo",
    "scrollToTopTooltipMessage" : "Se ativada, a pesquisa será rolada para o topo no próximo slide",
    "footerHTML" : "Rodapé HTML",
    "footerHTMLMessage" : "Você pode escrever CSS personalizado (usando tag de estilo) e HTML dentro da mesma caixa",
    "editFooter" : "Editar rodapé",
    "disqualifyImmediately" : "Desqualificar imediatamente",
    "disqualifyAfterSubmit" : "Desqualificar após o envio",
    "slideConflictAlertMessage" : "Este slide contém várias perguntas com uma lógica definida. Isso pode causar comportamento não intencional",
    "addSlide" : "Adicionar slide",
    "settings" : "Configurações",
    "delete" : "Excluir",
    "slideDeleteAlertMessage" : "Tem certeza de que deseja excluir este slide?",
    "slideDeleteBetweenAlertMessage" : "Isso reorganizará os outros slides. Tem certeza de que deseja excluir este slide?",
    "slideName" : "Nome do slide",
    "slidePosition" : "Posição do slide",
    "jumpTo" : "Pular para",
    "enableTimezoneSurvey" : "A ativação dessa opção capturará as informações de fuso horário da pessoa que está enviando a pesquisa",
    "footer" : {
      "footer" : "Rodapé",
      "enableNewFooter" : "Ativar novo rodapé",
      "stickToSurvey" : " Participe da pesquisa",
      "backgroundFill" : "Preenchimento do plano de fundo",
      "footerHeight" : "Altura do rodapé",
      "enableProgressBar" : "Ativar barra de progresso",
      "buttons" : "Botões",
      "buttonType" : "Tipo de botão",
      "fillNext" : "Preencher (próximo)",
      "fillPrevious" : "Preenchimento (anterior)",
      "fillSubmit" : "Preencher (Enviar)",
      "textColor" : "Texto do botão",
      "position" : "Posição",
      "previousText" : "Anterior (texto)",
      "nextText" : "Próximo (texto)",
      "submitText" : "Enviar (texto)",
      "fillComplete" : "Preenchimento concluído",
      "fillInactive" : "Preenchimento inativo",
      "footerType" : "Tipo de rodapé",
      "stickToCard" : "Ficar no cartão",
      "stickToPage" : "Ficar na página"
    },
    "surveyNotfication" : "Notificação de pesquisa",
    "style" : {
      "surveyPadding" : "Preenchimento de pesquisa"
    },
    "autoResponderSubheader" : "Envie um e-mail automático para a pessoa que enviar o questionário (o e-mail inclui uma cópia das informações inseridas no questionário)"
  },
  "form" : {
    "typeOfBuilder" : "Formulário",
    "formStyle" : "Estilo do formulário",
    "formElement" : "Elemento do formulário",
    "addFormElement" : "Adicionar elemento do formulário",
    "addConditionalLogic" : "Adicionar lógica condicional",
    "inlineForm" : "Formulário em linha",
    "formName" : "Nome do formulário",
    "fullScreenMode" : "Modo de tela cheia",
    "fullScreenModetooltip" : "Ativá-lo fará com que o formulário se expanda por toda a largura do contêiner, estendendo-se até a borda da área de incorporação e aderindo à largura do formulário.",
    "enableTimezoneForm" : "A ativação dessa opção capturará as informações de fuso horário da pessoa que está enviando o formulário",
    "formSettings" : "Configurações do formulário",
    "unsavedAlertMessage" : "O formulário tem trabalho não salvo. Tem certeza de que deseja sair e descartar todo o trabalho não salvo?",
    "thirdPartyScriptAlertMessage" : "Scripts de terceiros dentro do bloco HTML podem fornecer uma funcionalidade poderosa, mas também trazem riscos à privacidade, segurança, desempenho e comportamento da página. Portanto, recomendamos que você revise o conteúdo desse script antes de salvá-lo no formulário.",
    "onFormSubmission" : "No envio do formulário",
    "integrateForm" : "Formulário de integração",
    "embedlayoutType" : "Incorporar tipo de layout",
    "embedlayoutTypeSubMessage" : "Selecione um layout para incorporar em um funil ou site externo",
    "triggerType" : "Tipo de gatilho",
    "showOnScrolling" : "Mostrar na rolagem",
    "showAfter" : "Exibir após",
    "seconds" : "segundos",
    "alwaysShow" : "Sempre mostrar",
    "activationOptions" : "Opções de ativação",
    "activateOn" : "Ativar em",
    "visit" : "visitar",
    "alwaysActivated" : "Sempre ativado",
    "deactivationOptions" : "Opções de desativação",
    "deactivateAfterShowing" : "Desativar após mostrar",
    "times" : "vezes",
    "deactiveOnceLeadCollected" : "Desativar assim que o lead for coletado",
    "neverDeactivate" : "Nunca desativar",
    "copyEmbedCode" : "Copiar código de incorporação",
    "copyFormLink" : "Copiar link do formulário",
    "openFormLink" : "Abrir link do formulário",
    "inline" : "Em linha",
    "inlineDescription" : "Um formulário em linha aparece com o conteúdo da página como um elemento nativo. Ele não se sobrepõe e não pode ser fechado ou minimizado.",
    "inlineHoverDescription" : "Um formulário em linha aparece com o conteúdo da página como um elemento nativo. Ele não se sobrepõe e não pode ser fechado ou minimizado.",
    "popup" : "Pop-up",
    "popupDescription" : "Um pop-up mostra o formulário como uma sobreposição dentro de uma caixa iluminada. Pode ser fechado, mas não minimizado.",
    "popupHoverDescription" : "Um pop-up mostra o formulário como uma sobreposição dentro de uma caixa iluminada. Pode ser fechado, mas não minimizado.",
    "politeSlideIn" : "Formulário deslizante",
    "politeSlideInDescription" : "Um slide discreto aparece na parte inferior da tela e desaparece quando fechado",
    "politeSlideInHoverDescription" : "Aparece na parte inferior da tela e desaparece quando fechada",
    "stickySidebar" : "Barra lateral fixa",
    "stickySidebarDescription" : "Uma barra lateral fixa será exibida na lateral da tela e poderá ser minimizada em uma aba que fica na lateral da janela",
    "stickySidebarHoverDescription" : "Aparece na lateral da tela e pode ser minimizada em uma aba que fica na lateral da janela",
    "allowMinimize" : "Permitir minimizar",
    "conditonalLogic" : {
      "conditions" : "Condições",
      "noExistingCondition" : "Ainda não há condições existentes",
      "freeRoamMessage" : "Você está livre para se movimentar sem regras, pelo menos por enquanto.",
      "addNewCondition" : "Adicionar nova condição",
      "redirectToUrl" : "Redirecionar para URL",
      "displayCustomMessage" : "Exibir mensagem personalizada",
      "disqualifyLead" : "Desqualificar lead",
      "showHideFields" : "Mostrar/Ocultar Campos",
      "redirectToUrlSubText" : "Redirecionar usuário após o envio",
      "displayCustomMessageSubText" : "Exibir mensagens com base em entradas de usuário ativado",
      "disqualifyLeadSubText" : "Filtrar entradas sob condições específicas",
      "showHideFieldsSubText" : "Modificar o campo Visibilidade dinammente",
      "openBelowUrl" : "Abrir a URL abaixo",
      "showCustomMessage" : "Mostrar mensagem personalizada",
      "alertConditionDelete" : "Tem certeza de que deseja excluir esta condição?",
      "deleteCondition" : "Excluir condição",
      "conditionExecutedMessage" : "As condições serão executadas de cima para baixo.",
      "allOperationOptions" : {
        "isEqualTo" : "É igual a",
        "isNotEqualTo" : "Não é igual a",
        "contains" : "Contém",
        "startsWith" : "Começa com",
        "endsWith" : "Termina com",
        "isEmpty" : "Está vazio",
        "isFilled" : "É preenchido",
        "before" : "Antes",
        "after" : "Depois",
        "greaterThan" : "Maior que",
        "lessThan" : "Menor que"
      },
      "logic" : {
        "if" : "Se",
        "then" : "Então",
        "and" : "E",
        "or" : "Ou"
      },
      "phoneAlertMessage" : "Para pesquisar números de telefone com um código de país, você precisa incluir o símbolo \"+\" em sua consulta de pesquisa.",
      "deleteAlertMessage" : "Tem certeza de que deseja excluir este campo? A condição associada a este campo também será removida.",
      "deleteField" : "Excluir campo"
    },
    "minimizedTitlePlaceholder" : "Título minimizado",
    "dragYourElementFromLeft" : "Arraste seus elementos aqui a partir da esquerda",
    "formNotification" : "Notificação de formulário",
    "style" : {
      "formPadding" : "Preenchimento de formulário"
    }
  },
  "quiz" : {
    "quizElement" : "Elemento do questionário",
    "quizNotification" : "Notificação de questionário",
    "viewAndCustomise" : "Ver e personalizar",
    "categoryAndScore" : "Categoria e Pontuação",
    "provideFeedback" : "Dar feedback",
    "addQuizElement" : "Adicionar elemento de teste",
    "typeOfBuilder" : "Questionário",
    "catgory" : {
      "manageCategories" : "Gerenciar categorias"
    },
    "quizSettings" : "Configurações do Quiz",
    "onQuizSubmission" : "No envio do questionário",
    "settings" : "Configurações",
    "deletePage" : "Excluir página",
    "quizResult" : {
      "resultTemplate" : "Modelo de resultado",
      "quizResultAlertMessage" : "A página de resultados escolhida será exibida quando o questionário for concluído"
    },
    "autoResponderSubheader" : "Envie um e-mail automático para a pessoa que enviar o questionário (o e-mail inclui uma cópia das informações inseridas no questionário)",
    "integrateHeader" : "Integrar questionário",
    "integrateSubHeader" : "Use as seguintes opções para integrar o questionário personalizado ao seu site",
    "integrateCopyLinkMessage" : "Copie o link abaixo e compartilhe-o facilmente em qualquer lugar.",
    "pageName" : "Nome da página",
    "pagePosition" : "Posição da página"
  }
}
