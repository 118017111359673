<template>
  <div class="flex items-start gap-2 self-stretch">
    <div class="flex w-full items-center justify-between">
      <UITextSmMedium>{{ t("common.style.labelColor") }}</UITextSmMedium>
    </div>
  </div>
  <ColorInput
    :label="t('common.style.labelColor')"
    :color="
      store.deviceMode === 'mobile'
        ? store.fieldStyle?.mobileLabelColor || store.fieldStyle?.labelColor
        : store.fieldStyle?.labelColor
    "
    @update:color="handleLabelColor"
  />
  <FontFamilyInput
    class="w-full"
    :label="t('common.fontFamily')"
    :value="
      store.deviceMode === 'mobile'
        ? store.fieldStyle?.mobileLabelFontFamily || store.fieldStyle?.labelFontFamily
        : store.fieldStyle?.labelFontFamily
    "
    :show-device-mode="true"
    @update:value="handleLabelFontFamily"
  />
  <div class="flex items-start gap-3">
    <PixelNumber
      :label="t('common.style.fontSize')"
      :value="
        parseFloat(
          store.deviceMode === 'mobile'
            ? store.fieldStyle?.mobileLabelFontSize || store.fieldStyle?.labelFontSize
            : store.fieldStyle?.labelFontSize
        )
      "
      :show-device-mode="true"
      @update:value="handleLabelFontSize"
    />
    <PixelNumber
      :label="t('common.style.fontWeight')"
      :value="
        parseFloat(
          store.deviceMode === 'mobile'
            ? store.fieldStyle?.mobileLabelFontWeight || store.fieldStyle?.labelFontWeight
            : store.fieldStyle?.labelFontWeight
        )
      "
      :max="1800"
      :show-device-mode="true"
      @update:value="handleLabelFontWeight"
    />
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UITextSmMedium } from "@gohighlevel/ghl-ui";
import { useI18n } from "vue-i18n";
import ColorInput from "../Elements/ColorInput.vue";
import FontFamilyInput from "../Elements/FontFamilyInput.vue";
import PixelNumber from "../Elements/PixelNumber.vue";

const { t } = useI18n();
const store = useAppStore();

const handleLabelColor = (val: string) => {
  if (store.deviceMode === "mobile") {
    if (!store.fieldStyle.mobileLabelColor) {
      store.fieldStyle.mobileLabelColor = store.fieldStyle.labelColor;
    }
    store.fieldStyle.mobileLabelColor = val;
  } else {
    store.fieldStyle.labelColor = val;
    if (!store.fieldStyle.mobileLabelColor) {
      store.fieldStyle.mobileLabelColor = val;
    }
  }
  store.anyUnsavedChanges = true;
};

const handleLabelFontFamily = (val: string) => {
  if (!store.fontCollection.includes(val)) {
    store.fontCollection.push(val);
  }

  if (store.deviceMode === "mobile") {
    if (!store.fieldStyle.mobileLabelFontFamily) {
      store.fieldStyle.mobileLabelFontFamily = store.fieldStyle.labelFontFamily;
    }
    store.fieldStyle.mobileLabelFontFamily = val;
  } else {
    store.fieldStyle.labelFontFamily = val;
    if (!store.fieldStyle.mobileLabelFontFamily) {
      store.fieldStyle.mobileLabelFontFamily = val;
    }
  }
  store.anyUnsavedChanges = true;
};

const handleLabelFontSize = (val: number) => {
  if (store.deviceMode === "mobile") {
    if (!store.fieldStyle.mobileLabelFontSize) {
      store.fieldStyle.mobileLabelFontSize = store.fieldStyle.labelFontSize;
    }
    store.fieldStyle.mobileLabelFontSize = val;
  } else {
    store.fieldStyle.labelFontSize = val;
  }
  store.anyUnsavedChanges = true;
};

const handleLabelFontWeight = (val: number) => {
  if (store.deviceMode === "mobile") {
    if (!store.fieldStyle.mobileLabelFontWeight) {
      store.fieldStyle.mobileLabelFontWeight = store.fieldStyle.labelFontWeight;
    }
    store.fieldStyle.mobileLabelFontWeight = val;
  } else {
    store.fieldStyle.labelFontWeight = val;
  }
  store.anyUnsavedChanges = true;
};
</script>
