<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { deviceOptions } from "@/util/constants";
import { ChevronDownIcon, Monitor04Icon, Phone01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIDropdown, UIInputNumber, UITextXsMedium } from "@gohighlevel/ghl-ui";

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  value: {
    type: Number || String,
    default: 0,
  },
  isLabelSmall: {
    type: Boolean,
    default: false,
  },
  max: {
    type: Number,
    default: 1800,
  },
  min: {
    type: Number,
    default: 0,
  },
  icon: {
    type: Function,
    required: false,
  },
  showDeviceMode: {
    type: Boolean,
    default: false,
  },
});

const store = useAppStore();
const emit = defineEmits(["update:value"]);

const handleSetCount = (val: string) => emit("update:value", parseFloat(val) || 0);

const switchDeviceMode = (mode: string) => {
  store.deviceMode = mode;
};
</script>

<template>
  <div id="pixelNumber" class="flex flex-col items-start gap-2 self-stretch">
    <div class="flex items-center gap-2">
      <UITextXsMedium class="flex">{{ label }}</UITextXsMedium>
      <UIDropdown
        v-if="showDeviceMode"
        id="desktop-mobile-toggle"
        :options="deviceOptions"
        @select="switchDeviceMode"
      >
        <template #default>
          <div class="flex items-center gap-0.5">
            <Phone01Icon v-if="store.deviceMode === 'mobile'" class="h-4 w-4 cursor-pointer" />
            <Monitor04Icon v-else class="h-4 w-4 cursor-pointer" />
            <ChevronDownIcon class="h-3 w-3" />
          </div>
        </template>
      </UIDropdown>
    </div>
    <UIInputNumber
      id="text-input"
      class="w-100"
      :show-button="false"
      :min="min"
      :max="max"
      size="large"
      :value="value"
      @change="handleSetCount"
      @blur="store.addToHistory"
    >
      <template #prefix>
        <component :is="icon" class="h-4 w-4"></component>
      </template>
      <template #suffix>
        <div class="pixel-text flex h-4 w-4 items-center justify-center">PX</div>
      </template>
    </UIInputNumber>
  </div>
</template>

<style lang="css" scoped>
.pixel-text {
  color: #98a2b3;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
</style>
