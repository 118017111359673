<script setup lang="ts">
import { useEmitter } from "@/composition/useEmitter";
import { useAppStore } from "@/store/app";
import { CONSTANTS } from "@/util/constants";
import { fieldFullWidth } from "@/util/methods";
import {
  Bell03Icon,
  ClockRewindIcon,
  Dataflow03Icon,
  Grid01Icon,
  Monitor04Icon,
  Phone01Icon,
  PlusIcon,
  ReverseLeftIcon,
  ReverseRightIcon,
  Settings04Icon,
} from "@gohighlevel/ghl-icons/24/outline";
import { UIButton, UIToolbar, UIToolbarGroup, UITooltip } from "@gohighlevel/ghl-ui";
import { nextTick, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import ManageCategoryModal from "./../quizzes/ManageCategoryModal.vue";
import ConditionalLogicModal from "./ConditionalLogicModal.vue";

const showConditionalLogicModal = ref(false);
const showManageCategories = ref(false);
const store = useAppStore();
const emitter = useEmitter();
const { t } = useI18n();

const goToVersions = () => (store.showVersions = true);

const renderIcon = (icon) => icon;

const deviceOptions = ref([
  {
    key: "desktop",
    icon: renderIcon(Monitor04Icon),
    tooltip: t("common.desktopMode"),
    click: () => {
      switchDeviceMode("desktop");
    },
  },
  {
    key: "mobile",
    icon: renderIcon(Phone01Icon),
    tooltip: t("common.mobileMode"),
    click: () => {
      switchDeviceMode("mobile");
    },
  },
]);

const applyLayoutToElements = (layoutIndex: number) => {
  const validFieldsForWidth = store.elements.filter(
    (field) => !fieldFullWidth(field.type, true)
  ).length;

  store.elements.forEach((field) => {
    if (!fieldFullWidth(field.type, true)) {
      if (layoutIndex === CONSTANTS.ONE_COLUMN) {
        field.fieldWidthPercentage = 100;
      } else if (layoutIndex === CONSTANTS.TWO_COLUMN) {
        field.fieldWidthPercentage = 50;
      } else {
        field.fieldWidthPercentage = Math.max(
          parseFloat((100 / validFieldsForWidth).toFixed(2)),
          20
        );
        if (field.type === "submit") {
          field.fullwidth = false;
          field.padding.left = 10;
          field.padding.right = 10;
        }
      }
    }
  });

  store.addToHistory();
  store.anyUnsavedChanges = true;
};

const switchDeviceMode = (mode) => {
  store.deviceMode = mode;

  const currentLayout =
    store.deviceMode === "mobile"
      ? store.builderConfig.mobileLayout || store.builderConfig.layout
      : store.builderConfig.layout;

  if (store.deviceMode === "mobile") {
    store.builderConfig.mobileLayout = currentLayout;
  } else {
    store.builderConfig.layout = currentLayout;

    if (!store.builderConfig.mobileLayout) {
      store.builderConfig.mobileLayout = currentLayout;
    }
  }

  applyLayoutToElements(currentLayout);
};

onMounted(() => {
  const currentLayout =
    store.deviceMode === "mobile"
      ? store.builderConfig.mobileLayout || store.builderConfig.layout
      : store.builderConfig.layout;

  applyLayoutToElements(currentLayout);

  nextTick(() => {
    setTimeout(() => {
      const layoutToApply =
        store.deviceMode === "mobile"
          ? store.builderConfig.mobileLayout || store.builderConfig.layout
          : store.builderConfig.layout;

      applyLayoutToElements(layoutToApply);
    }, 500);
  });
});

watch(
  () => [store.builderConfig.layout, store.builderConfig.mobileLayout],
  () => {
    const currentLayout =
      store.deviceMode === "mobile"
        ? store.builderConfig.mobileLayout || store.builderConfig.layout
        : store.builderConfig.layout;

    applyLayoutToElements(currentLayout);
  }
);

watch(
  () => store.elements.length,
  () => {
    if (store.elements.length > 0) {
      const layoutToApply =
        store.deviceMode === "mobile"
          ? store.builderConfig.mobileLayout || store.builderConfig.layout
          : store.builderConfig.layout;

      applyLayoutToElements(layoutToApply);
    }
  }
);
</script>

<template>
  <UIToolbar class="secondary-header-toolbar mt-[50px] border-b bg-white p-0">
    <UIToolbarGroup class="ml-8 w-20 justify-between">
      <div class="flex justify-center">
        <div class="border-right flex gap-x-5 px-4">
          <UITooltip trigger="hover" placement="top">
            <template #trigger>
              <UIButton
                id="open-left-bar"
                text
                size="small"
                class="text-gray-800"
                @click="emitter.emit('toggle-left-side-bar')"
              >
                <PlusIcon class="h-4 w-4" />
              </UIButton>
            </template>
            {{
              store.formId
                ? t("form.addFormElement")
                : store.surveyId
                ? t("survey.addSurveyElement")
                : t("quiz.addQuizElement")
            }}
          </UITooltip>
          <UITooltip v-if="store.formId" trigger="hover" placement="top">
            <template #trigger>
              <UIButton
                id="open-conditional-logic"
                text
                size="small"
                class="text-gray-800"
                @click="showConditionalLogicModal = true"
              >
                <Dataflow03Icon class="h-4 w-4" />
              </UIButton>
            </template>
            {{ t("form.addConditionalLogic") }}
          </UITooltip>
          <UITooltip trigger="hover" placement="top">
            <template #trigger>
              <UIButton
                id="open-email-settings"
                text
                size="small"
                class="text-gray-800"
                @click="emitter.emit('toggle-email-side-bar')"
              >
                <Bell03Icon class="h-4 w-4" />
              </UIButton>
            </template>
            {{ t("common.showNotificationsSettings") }}</UITooltip
          >
          <UITooltip v-if="store.quizId" trigger="hover" placement="top">
            <template #trigger>
              <UIButton
                id="open-email-settings"
                text
                size="small"
                class="text-gray-800"
                @click="showManageCategories = true"
              >
                <Grid01Icon class="h-4 w-4" />
              </UIButton>
            </template>
            {{ t("quiz.catgory.manageCategories") }}</UITooltip
          >
        </div>
      </div>
    </UIToolbarGroup>
    <UIToolbarGroup class="ml-16 justify-center">
      <div class="flex items-center rounded-lg border border-gray-300">
        <UITooltip v-for="(item, index) in deviceOptions" :key="index">
          <template #trigger>
            <UIButton
              :id="`form-builder__btn--${item.key}`"
              class="hl-builder-action-buttons"
              :active="store.deviceMode === item.key"
              text
              @click="item.click"
            >
              <component :is="item.icon" class="h-5 w-5" />
            </UIButton>
          </template>
          <span>{{ item.tooltip }}</span>
        </UITooltip>
      </div>
    </UIToolbarGroup>
    <UIToolbarGroup class="justify-center">
      <div class="flex items-center justify-center">
        <div class="border-right">
          <UITooltip placement="top">
            <template #trigger>
              <UIButton
                :id="`builder__btn--version`"
                class="py-4 text-gray-800"
                quaternary
                :disabled="!store.builder?.versionHistory?.length"
                @click="goToVersions"
              >
                <ClockRewindIcon class="h-5 w-5"
              /></UIButton>
            </template>
            <span>{{ t("common.versions") }}</span>
          </UITooltip>
        </div>

        <div class="flex items-center justify-around">
          <UITooltip trigger="hover" placement="top">
            <template #trigger>
              <UIButton
                id="btn--undo"
                text
                size="small"
                class="pl-4 pr-2 text-gray-800"
                :disabled="!(store.historyIndex > 0)"
                @click="store.undo"
              >
                <ReverseLeftIcon class="h-5 w-5" />
              </UIButton>
            </template>
            Undo
          </UITooltip>
          <UITooltip trigger="hover" placement="top">
            <template #trigger>
              <UIButton
                id="btn--redo"
                text
                class="pl-2 pr-4 text-gray-800"
                :disabled="!(store.historyIndex < store.history.length - 1)"
                @click="store.redo"
              >
                <ReverseRightIcon class="h-5 w-5" />
              </UIButton>
            </template>
            Redo
          </UITooltip>
        </div>
        <UITooltip trigger="hover" placement="top">
          <template #trigger>
            <UIButton
              id="open-right-bar"
              text
              size="small"
              class="border-left p-3 text-gray-800"
              @click="emitter.emit('toggle-right-side-bar')"
            >
              <Settings04Icon class="h-4 w-4" />
            </UIButton>
          </template>
          {{ t("common.styles") }} & {{ t("common.options") }}
        </UITooltip>
      </div>
    </UIToolbarGroup>
  </UIToolbar>
  <ConditionalLogicModal
    :show="showConditionalLogicModal"
    @update:close-modal="showConditionalLogicModal = false"
  />
  <ManageCategoryModal
    v-if="showManageCategories"
    :show="showManageCategories"
    @update:close-modal="showManageCategories = false"
  ></ManageCategoryModal>
</template>

<style scoped>
.secondary-header-toolbar {
  border-color: #e2e2e2 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
  height: 49px;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.hl-toolbar-group {
  padding: 0px !important;
}

.hl-builder-action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  padding: 0 8px;
}
</style>
