<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { XCircleIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton } from "@gohighlevel/ghl-ui";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { CONSTANTS } from "../../util/constants";
import AlertModal from "./AlertModal.vue";
import CheckboxField from "./CheckboxField.vue";
import FileUploadField from "./FileUploadField.vue";
import LargeTextField from "./LargeTextField.vue";
import MonetaryField from "./MonetaryField.vue";
import NumericalField from "./NumericalField.vue";
import RadioField from "./RadioField.vue";
import SignatureField from "./SignatureField.vue";
import SingleOptionsField from "./SingleOptionsField.vue";
import TextField from "./TextField.vue";
import TextboxListField from "./TextboxListField.vue";

const { t } = useI18n();

const store = useAppStore();

const currentLayout = computed(() => {
  return store.deviceMode === "mobile"
    ? store.builderConfig.mobileLayout || store.builderConfig.layout
    : store.builderConfig.layout;
});

const props = defineProps({
  element: {
    type: Object,
    default() {
      return {};
    },
  },
  fieldStyle: Object,
  index: Number,
  slideIndex: {
    type: Number,
    default: undefined,
  },
  currentElement: Object,
  isForm: {
    type: Boolean,
    default: true,
  },
  formLabelVisible: {
    type: Boolean,
    default: true,
  },
  slide: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const showDeleteModal = ref(false);
const deleteIndex = ref<number | null>(null);
const emit = defineEmits(["update:label", "update:placeholder", "update:shortLabel"]);
const editableLabel = ref<HTMLElement | null>(null);
const inputElement = ref<HTMLInputElement | null>(null);
const localLabel = ref(props.element.label || "");
const inputClicked = ref(false);

watch(
  () => props.element.label,
  (newLabel) => {
    localLabel.value = newLabel || "";
  }
);

const localPlaceholder = ref(props.element.placeholder || "");
const localShortLabel = ref(props.element.shortLabel || "");
watch(
  () => props.element.placeholder,
  (newVal) => {
    localPlaceholder.value = newVal || "";
  }
);
watch(
  () => props.element.shortLabel,
  (newVal) => {
    localShortLabel.value = newVal || "";
  }
);
const updateLocalShortLabel = () => {
  onUpdateShortLabel(localShortLabel.value);
};
const updateLocalPlaceholder = () => {
  onUpdatePlaceholder(localPlaceholder.value);
};
const onLabelBlur = () => {
  store.addToHistory();
  inputClicked.value = false;
};
const onLabelInput = () => {
  emit("update:label", localLabel.value);
  resizeInput();
};
const onUpdatePlaceholder = (newPlaceholder: string) => {
  emit("update:placeholder", newPlaceholder);
  store.addToHistory();
};
const onUpdateShortLabel = (newShortLabel: string) => {
  emit("update:shortLabel", newShortLabel);
  store.addToHistory();
};

const handleFocus = (event: FocusEvent) => {
  const target = event.target as HTMLElement;
  const range = document.createRange();
  range.selectNodeContents(target);
  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

const successCallbackDeleteUpdate = () => {
  store.builderConfig.conditionalLogic = store.builderConfig.conditionalLogic.filter((item) => {
    item.conditions = item?.conditions?.filter(
      (condition) =>
        condition.selectedField !== store.elements[deleteIndex.value].hiddenFieldQueryKey
    );
    return item.conditions?.length > 0;
  });
  store.elements.splice(deleteIndex.value, 1);
  showDeleteModal.value = false;
};

const cancelCallbackDeleteUpdate = () => {
  showDeleteModal.value = false;
};

const removeField = (tag: string, index: number, slideIndex?: number) => {
  store.anyUnsavedChanges = true;
  let opportunityCustomFields = [];
  if (store.surveyId || store.quizId) {
    if (slideIndex !== undefined) {
      store.slides[slideIndex].slideData.splice(index, 1);
      store.isAnyElementActive = false;
      store.currentActiveIndex = [undefined, undefined];
      opportunityCustomFields = store.slides.reduce((acc, slide) => {
        const filteredFields = slide.slideData.filter((field) => field.model === "opportunity");
        return acc.concat(filteredFields);
      }, []);
    }
  } else {
    if (
      store.builderConfig.conditionalLogic?.some((item) =>
        item.conditions?.some(
          (condition) => condition.selectedField === store.elements[index].hiddenFieldQueryKey
        )
      )
    ) {
      showDeleteModal.value = true;
      deleteIndex.value = index;
    } else {
      store.elements.splice(index, 1);
      if (store.currentCustomSidebarPreview === index) {
        store.currentCustomSidebarPreview = undefined;
      } else if (
        typeof store.currentCustomSidebarPreview === "number" &&
        store.currentCustomSidebarPreview > index
      ) {
        store.currentCustomSidebarPreview--;
      }
    }
    opportunityCustomFields = store.elements.filter((field) => field.model === "opportunity");
  }
  if (opportunityCustomFields?.length === 0) {
    store.opportunitySettings = null;
  } else {
    store.opportunitySettings.payload.customFields = opportunityCustomFields.map(
      ({ Id, fieldKey }) => ({
        id: Id,
        key: fieldKey.replace("opportunity.", ""),
      })
    );
  }
  store.addToHistory();
};

const isActiveElement = (slideIndex, elementIndex, elementId, slideId) => {
  if (store.formId) {
    return store.currentCustomSidebarPreview === elementIndex;
  }
  if (store.quizId) {
    return store.currentActiveIndex[0] === slideId && store.currentActiveIndex[1] === elementId;
  }
  return store.currentActiveIndex[0] === slideIndex && store.currentActiveIndex[1] === elementIndex;
};

const labelAlignment = () => {
  if (currentLayout.value === CONSTANTS.ONE_COLUMN) {
    const validAlign = ["left", "right"].includes(store.fieldStyle.labelAlignment);
    return {
      width: validAlign ? store.fieldStyle.labelWidth + "px" : "",
      display: validAlign ? "inline-block" : "block",
      cssFloat: validAlign ? "left" : "none",
      textAlign: validAlign ? store.fieldStyle.labelAlignment : "",
      padding: validAlign ? "0 10px 0 0" : "",
    };
  }
  return {};
};

const getInputCSSList = (): string[] => {
  return store.builderConfig.inputStyleType === "line" ? ["line-form-style"] : [];
};

const getStyle = () => {
  return {
    fontSize: store.fieldStyle.placeholderFontSize + "px",
    width: "100%",
  };
};
const getEmailPhoneValidateClass = (fieldWidthPercentage: number) => {
  return [CONSTANTS.TWO_COLUMN, CONSTANTS.SINGLE_LINE].includes(currentLayout.value) &&
    fieldWidthPercentage <= 50
    ? "block space-y-3"
    : "flex";
};

const updatePicklistOptions = (newOptions: Array<string>, element: any) => {
  if (store.formId) {
    // For forms
    const elementIndex = store.elements.findIndex((el) => el === element);
    if (elementIndex !== -1) {
      store.elements[elementIndex].picklistOptions = [...newOptions];
      if (element.enableCalculations) {
        store.elements[elementIndex].calculatedOptions = [...newOptions];
      }
      store.anyUnsavedChanges = true;
      store.addToHistory();
    }
  } else if (store.surveyId || store.quizId) {
    // For surveys and quizzes
    const slideIndex = store.slides.findIndex((slide) =>
      slide.slideData.some((el) => el === element)
    );
    if (slideIndex !== -1) {
      const elementIndex = store.slides[slideIndex].slideData.findIndex((el) => el === element);
      if (elementIndex !== -1) {
        store.slides[slideIndex].slideData[elementIndex].picklistOptions = [...newOptions];
        if (element.enableCalculations) {
          store.slides[slideIndex].slideData[elementIndex].calculatedOptions = [...newOptions];
        }
        store.anyUnsavedChanges = true;
        store.addToHistory();
      }
    }
  }
};

const resizeInput = () => {
  if (!inputElement.value) {
    return;
  }

  if (!localLabel.value.trim()) {
    inputElement.value.style.width = "5px";
    return;
  }

  inputElement.value.style.width = "5px";
  const scrollWidth = inputElement.value.scrollWidth;
  const width = scrollWidth + 10;
  inputElement.value.style.width = `${width}px`;
};

onMounted(() => {
  nextTick(resizeInput);
});

watch(
  [() => localLabel.value, () => store.deviceMode],

  () => {
    nextTick(resizeInput);
  }
);

const handleInputClick = (event: MouseEvent) => {
  const target = event.target as HTMLInputElement;
  if (!inputClicked.value) {
    target.select();
    inputClicked.value = true;
  }
  // For subsequent clicks, the default behavior will position the cursor
};
</script>

<template>
  <div class="menu-item">
    <div class="form-builder--item">
      <label
        v-if="!isForm && element?.description !== undefined"
        style="display: block; overflow-wrap: break-word"
        v-html="element.description"
      ></label>
      <div style="display: inline-flex" :style="labelAlignment()">
        <div v-show="formLabelVisible" class="label-container flex items-center">
          <input
            ref="inputElement"
            v-model="localLabel"
            class="label-input font-inter cursor-text border-0 bg-transparent p-0 text-sm shadow-none focus:border-gray-300 focus:ring-0"
            placeholder=""
            @blur="onLabelBlur"
            @input="onLabelInput"
            @click="handleInputClick"
          />
          <span v-if="element.required" class="required-asterisk">*</span>
        </div>
        <div v-if="element.hidden">
          <small class="ml-[5px] rounded-sm bg-[#e1e1e1] px-[5px] py-[2px]">
            {{ t("common.hidden") }}
          </small>
        </div>
      </div>
      <TextField
        v-if="element.dataType === 'TEXT' || element.dataType === 'SCORE'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :readonly="true"
        :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
        @update:placeholder="onUpdatePlaceholder"
        @update:short-label="onUpdateShortLabel"
      />
      <LargeTextField
        v-if="element.dataType === 'LARGE_TEXT'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :readonly="true"
        :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
        @update:placeholder="onUpdatePlaceholder"
        @update:short-label="onUpdateShortLabel"
      />
      <TextboxListField
        v-if="element.dataType === 'TEXTBOX_LIST'"
        :placeholder="element.placeholder"
        :picklist-options="element.picklistOptions"
        :picklist-options-image="element.picklistOptionsImages"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
        :readonly="true"
        :element="element"
        @update:short-label="onUpdateShortLabel"
        @update:picklist-options="updatePicklistOptions($event, element)"
      />
      <CheckboxField
        v-if="element.dataType === 'CHECKBOX'"
        :element="element"
        :placeholder="element.placeholder"
        :picklist-options="element.picklistOptions"
        :layout="store?.builderConfig?.layout"
        :style-object="getStyle()"
        :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
        :readonly="true"
        :field="element"
        @update:short-label="onUpdateShortLabel"
        @update:picklist-options="updatePicklistOptions($event, element)"
      />
      <RadioField
        v-if="element.dataType === 'RADIO'"
        :field="element"
        :placeholder="element.placeholder"
        :picklist-options="element.picklistOptions"
        :picklist-options-image="element.picklistOptionsImage"
        :style-object="getStyle()"
        :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
        :layout="store?.builderConfig?.layout"
        :disabled="true"
        @update:short-label="onUpdateShortLabel"
        @update:picklist-options="updatePicklistOptions($event, element)"
      />
      <NumericalField
        v-if="element.dataType === 'NUMERICAL'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :readonly="true"
        :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
        @update:placeholder="onUpdatePlaceholder"
        @update:short-label="onUpdateShortLabel"
      />
      <div v-if="element.dataType === 'PHONE'" class="flex flex-col">
        <div
          class="gap-x-3"
          :class="[getEmailPhoneValidateClass(element.fieldWidthPercentage)]"
          :style="getStyle()"
        >
          <input
            v-model="localPlaceholder"
            :placeholder="localPlaceholder"
            class="form-control shadow-none focus:outline-transparent focus:ring-0"
            :class="getInputCSSList()"
            :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
            @input="(event) => { 
              const target = event.target as HTMLInputElement;
              localPlaceholder = target.value;
              emit('update:placeholder', localPlaceholder); 
            }"
          />
          <UIButton
            v-if="element?.enableValidatePhone && !element.hidden"
            id="verify-phone"
            class="pointer-events-none h-auto"
            :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
            >Verify Phone</UIButton
          >
        </div>
        <template v-if="isActiveElement(slideIndex, index, element.uuid, slide.id)">
          <input
            v-model="localShortLabel"
            :placeholder="t('common.shortLabelPlaceholder')"
            class="short-label border-0 p-0 focus:outline-none"
            @input="updateLocalShortLabel"
          />
        </template>
        <template v-else>
          <span v-if="element.shortLabel" class="short-label">
            {{ element.shortLabel }}
          </span>
        </template>
      </div>

      <MonetaryField
        v-if="element.dataType === 'MONETORY'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :style-input="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
        :readonly="true"
        :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
        @update:placeholder="onUpdatePlaceholder"
        @update:short-label="onUpdateShortLabel"
      />
      <SingleOptionsField
        v-if="element.dataType === 'SINGLE_OPTIONS'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :picklist-options="element.picklistOptions"
        :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
        @update:placeholder="onUpdatePlaceholder"
        @update:short-label="onUpdateShortLabel"
        @update:picklist-options="updatePicklistOptions($event, element)"
      />
      <SingleOptionsField
        v-if="element.dataType === 'MULTIPLE_OPTIONS'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :picklist-options="element.picklistOptions"
        :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
        @update:placeholder="onUpdatePlaceholder"
        @update:short-label="onUpdateShortLabel"
        @update:picklist-options="updatePicklistOptions($event, element)"
      />
      <TextField
        v-if="element.dataType === 'DATE'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :readonly="true"
        :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
        @update:placeholder="onUpdatePlaceholder"
        @update:short-label="onUpdateShortLabel"
      />
      <FileUploadField
        v-if="element.dataType === 'FILE_UPLOAD'"
        :placeholder="element.placeholder"
        :picklist-options="element.picklistOptions"
        :field="element"
        :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
        @update:placeholder="onUpdatePlaceholder"
        @update:short-label="onUpdateShortLabel"
      />
      <SignatureField
        v-if="element.dataType === 'SIGNATURE'"
        :element="element"
        :style-object="getStyle()"
        :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
        @update:short-label="onUpdateShortLabel"
      />
    </div>
    <a
      v-if="isActiveElement(slideIndex, index, element.uuid, slide.id)"
      class="close-icon"
      @mousedown="removeField(element.tag, index, slideIndex)"
    >
      <XCircleIcon class="h-5 w-5"></XCircleIcon>
    </a>
  </div>
  <AlertModal
    v-if="showDeleteModal"
    :description="'Are you sure you want to delete this custom field? The condition associated with this custom field will also be removed.'"
    :title="'Delete Field'"
    :positive-text="'Delete'"
    :negative-text="'Cancel'"
    :show-modal-popup="showDeleteModal"
    header-type="error"
    footer-type="error"
    @pop:change="cancelCallbackDeleteUpdate"
    @pop:positive="successCallbackDeleteUpdate"
    @pop:negative="cancelCallbackDeleteUpdate"
  />
</template>

<style scoped>
.form-control {
  border-radius: 0.3125rem;
  padding: 15px 20px;
  font-size: 0.875rem;
}
.form-control:disabled,
.form-control [readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}
.vs__search,
.vs__search:focus {
  padding: 12px 18px !important;
}
.form-builder--item .v-select {
  width: 100%;
  background: #f3f8fb;
  border-radius: 4px !important;
  height: auto;
  border: transparent;
}
.form-builder--item .v-select .vs__dropdown-toggle {
  height: 50px;
}
.add-custom-opt {
  padding: 3px;
  font-size: 14px;
}

.close-icon {
  position: absolute;
  right: -7px;
  top: -10px;
  opacity: 1;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  font-size: 10px;
  background: #f5f5f5;
  color: #2a3135;
  border-radius: 50%;
  height: 20px;
  cursor: pointer;
}
.form-builder--item {
  border-radius: 4px;
  border: 1px solid transparent;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  position: relative;
}
/* .form-builder--item:hover {
  border: 2px dashed rgba(175, 184, 188, 0.5);
}
.form-builder--item:hover .form-builder--actions {
  opacity: 1;
  visibility: visible;
} */
.form-builder--item h1,
.form-builder--item h2,
.form-builder--item h3,
.form-builder--item h4,
.form-builder--item h5,
.form-builder--item h6 {
  margin-bottom: 0;
}
.form-builder--item label {
  font-size: 0.875rem;
  color: #2a3135;
  margin-bottom: 0.5;
}
.label-container {
  display: flex;
  align-items: baseline;
  width: auto;
  overflow: visible;
  margin-bottom: 6px;
  position: relative;
}

.label-input {
  display: inline-block;
  min-width: 0;
  outline: none;
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  margin-bottom: 2px;
}

.label-input:focus {
  background-color: rgba(200, 200, 200, 0.1);
  border-radius: 2px;
  outline: none;
}

.required-asterisk {
  font-weight: bold;
  padding-left: 0;
  font-size: 14px;
  flex-shrink: 0;
  display: inline-flex;
  margin-left: -1px;
  margin-top: -2px;
}
</style>
