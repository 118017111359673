<template>
  <div class="flex flex-col">
    <div class="dropdown show form-dropdown" :class="classList" :style="styleObject">
      <div v-if="isActive" class="flex justify-between">
        <input
          :value="inputValue"
          :placeholder="inputPlaceholder"
          class="form-placeholder dropdown-toggle dropdown-input w-full border-0 bg-transparent p-0 shadow-none outline-none ring-0 focus:outline-transparent focus:ring-0"
          @input="handleInput"
          @focus="handleInputFocus"
          @blur="handleBlur"
        />
        <div class="dropdown-icon">
          <div class="css-arrow"></div>
        </div>
      </div>
      <a
        v-else
        id="dropdownMenuLink"
        class="btn dropdown-toggle text-truncate"
        href="#"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ inputPlaceholder }}
      </a>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink"></div>
    </div>
    <UICard
      v-if="picklistOptions !== undefined && isActive"
      id="options-card"
      :style="styleObject"
      class="mt-4"
    >
      <VueDraggableNext v-model="localPicklistOptions" handle=".drag-handle" class="w-full">
        <div v-for="(pickerOption, index) in localPicklistOptions" :key="index" class="option">
          <div v-if="pickerOption != ''" class="align-together flex">
            <Menu05Icon
              v-if="isActive"
              class="drag-handle mr-3 mt-1 h-4 w-4 cursor-move text-gray-500"
            />
            <label
              :for="field.id + '_' + index"
              contenteditable="true"
              class="inline-block w-auto cursor-text border-0 shadow-none outline-none"
              @input="updateOptionLabel($event, index)"
              @focus="handleFocus"
              @keyup="handleEditing($event, index)"
            >
              {{ pickerOption }}
            </label>
            <Trash01Icon
              v-if="isActive"
              class="ml-2 mt-1 h-4 w-4 cursor-pointer text-gray-500"
              @click="prepareToRemoveOption(index)"
            />
          </div>
        </div>
      </VueDraggableNext>
    </UICard>
    <div v-if="isActive" class="mt-[-1rem] flex items-center">
      <UIButton id="add-option" text class="mt-4" :disabled="isLastOptionEmpty" @click="addOption">
        <PlusIcon class="mr-1.5 h-5 w-5" />
        {{ t("common.addOption") }}
      </UIButton>
    </div>
    <template v-if="isActive">
      <input
        v-model="shortLabel"
        :placeholder="t('common.shortLabelPlaceholder')"
        class="short-label border-0 p-0 focus:outline-none"
        @input="updateShortLabel"
      />
    </template>
    <template v-else>
      <span v-if="element.shortLabel" class="short-label">
        {{ element.shortLabel }}
      </span>
    </template>
  </div>
  <DeleteConfirmationModal
    :show="showDeleteModal"
    @confirm="removeOption"
    @cancel="cancelRemoveOption"
  />
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { Menu05Icon, PlusIcon, Trash01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton, UICard } from "@gohighlevel/ghl-ui";
import { cloneDeep, isEqual } from "lodash";
import { computed, ref, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import { useI18n } from "vue-i18n";
import DeleteConfirmationModal from "./DeleteConfirmationModal.vue";

const { t } = useI18n();

interface OptionType {
  [key: string]: any;
}

const props = defineProps({
  type: {
    type: String,
    default: "input",
  },
  placeholder: {
    type: String,
    default: "",
  },
  picklistOptions: {
    type: Array as () => (string | OptionType)[],
    default() {
      return [];
    },
  },
  field: {
    type: Object,
    default() {
      return {};
    },
  },
  styleObject: {
    type: Object,
    default() {
      return {};
    },
  },
  classList: {
    type: Array,
    default: () => {
      return [];
    },
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  element: {
    type: Object,
    default: () => {
      return {
        fontSize: "14px",
      };
    },
  },
});

const emit = defineEmits(["update:placeholder", "update:shortLabel", "update:picklist-options"]);

const inputPlaceholder = ref(props.placeholder);
const inputValue = ref("");
const shortLabel = ref(props.element.shortLabel || "");

// Add local state for the draggable component
const localPicklistOptions = ref([...props.picklistOptions]);

// Add bidirectional watchers with flag to prevent recursive updates
const isInternalUpdate = ref(false);

watch(
  () => props.picklistOptions,
  (newVal) => {
    if (!isInternalUpdate.value) {
      localPicklistOptions.value = cloneDeep(newVal);
    }
    isInternalUpdate.value = false;
  },
  { deep: true }
);

watch(
  () => localPicklistOptions.value,
  (newVal) => {
    if (!isEqual(newVal, props.picklistOptions)) {
      isInternalUpdate.value = true;
      emit("update:picklist-options", cloneDeep(newVal));
    }
  },
  { deep: true }
);

watch(
  () => props.placeholder,
  (newVal) => {
    inputPlaceholder.value = newVal;
  }
);

watch(
  () => props.element.shortLabel,
  (newVal) => {
    shortLabel.value = newVal || "";
  }
);

const updateOptionLabel = (event: Event, index: number) => {
  const target = event.target as HTMLElement;
  const text = target.innerText.replace(/\u200B/g, "");

  const newOptions = [...localPicklistOptions.value];
  newOptions[index] = text;
  localPicklistOptions.value = newOptions;
};

const indexToRemove = ref<number | null>(null);
const showDeleteModal = ref(false);

const prepareToRemoveOption = (index: number) => {
  if (props.picklistOptions.length > 1) {
    indexToRemove.value = index;
    showDeleteModal.value = true;
  }
};

const removeOption = () => {
  if (indexToRemove.value !== null) {
    const newOptions = [...localPicklistOptions.value];
    newOptions.splice(indexToRemove.value, 1);
    localPicklistOptions.value = newOptions;
  }
  showDeleteModal.value = false;
};

const cancelRemoveOption = () => {
  indexToRemove.value = null;
  showDeleteModal.value = false;
};

const isLastOptionEmpty = computed(() => {
  const lastOption = props.picklistOptions[props.picklistOptions.length - 1];
  return typeof lastOption === "string" && lastOption.trim() === "";
});

const addOption = () => {
  const newOptions = [...localPicklistOptions.value];
  newOptions.push("Type an Option");
  localPicklistOptions.value = newOptions;
};

const handleFocus = (event: FocusEvent) => {
  const target = event.target as HTMLElement;

  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
  }

  if (!target.textContent || target.textContent.trim() === "") {
    if (target.childNodes.length === 0) {
      const textNode = document.createTextNode("\u200B");
      target.appendChild(textNode);

      const range = document.createRange();
      range.setStart(textNode, 0);
      range.collapse(true);

      if (selection) {
        selection.addRange(range);
      }
    } else {
      const range = document.createRange();
      range.setStart(target, 0);
      range.collapse(true);

      if (selection) {
        selection.addRange(range);
      }
    }
  } else {
    const range = document.createRange();
    range.selectNodeContents(target);

    if (selection) {
      selection.addRange(range);
    }
  }
};

const updateShortLabel = (event?: Event) => {
  if (event) {
    const target = event.target as HTMLInputElement;
    shortLabel.value = target.value;
  }
  emit("update:shortLabel", shortLabel.value);
};

const handleEditing = (event: KeyboardEvent, index: number) => {
  const target = event.target as HTMLElement;

  if (target.textContent?.trim() === "") {
    if (target.textContent) {
      target.textContent = "";

      const range = document.createRange();
      range.setStart(target, 0);
      range.collapse(true);

      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }
};

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  inputValue.value = target.value;
  inputPlaceholder.value = target.value;
  emit("update:placeholder", inputPlaceholder.value);
};

const handleInputFocus = () => {
  inputValue.value = inputPlaceholder.value;
};

const handleBlur = (event: Event) => {
  const target = event.target as HTMLInputElement;
  inputValue.value = "";
  emit("update:placeholder", inputPlaceholder.value);
  const store = useAppStore();
  store.addToHistory();
};
</script>

<style>
.form-dropdown {
  padding: 15px 20px;
  border-radius: 5px;
}
.form-dropdown a {
  padding: 0;
}
.show {
  display: block;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}

@font-face {
  font-family: "Magicons";
  src: url("./../../assets/icons/Magicons.eot?g0nnmd");
  src: url("./../../assets/icons/Magicons.eot?g0nnmd#iefix") format("embedded-opentype"),
    url("./../../assets/icons/Magicons.ttf?g0nnmd") format("truetype"),
    url("./../../assets/icons/Magicons.woff?g0nnmd") format("woff");
  font-weight: normal;
  font-style: normal;
}

.btn.dropdown-toggle:after {
  content: "\25BC";
  display: block;
  margin: 0;
  position: absolute;
  top: 50%;
  right: 14px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%) scaleY(0.5);
  font-family: "Magicons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 11px;
  height: 10px;
  font-size: 12px;
  border: none !important;
}

#options-card > div {
  padding: 0.8rem;
}

.dropdown-icon {
  display: inline-flex;
  align-items: center;
  margin-right: -5px;
  margin-top: 3px;
}

.css-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #8c8c8c;
}

.dropdown-icon:active .css-arrow,
.dropdown-icon:focus .css-arrow,
.dropdown-icon:hover .css-arrow,
.active .css-arrow {
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  border-top: 5px solid #8c8c8c !important;
}

.short-label {
  background-color: transparent;
  margin-top: 4px;
  font-size: 14px;
  color: #4b5563;
}
</style>
