<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { CONSTANTS } from "@/util/constants";
import { fieldFullWidth, getAllFontsImport } from "@/util/methods";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import CustomFieldsPreview from "../common/CustomFieldsPreview.vue";
import HLImage from "../common/HLImage.vue";
import StandardFieldPreview from "../common/StandardFieldPreview.vue";
import FormbuilderBranding from "./../common/BuilderBranding.vue";
const store = useAppStore();

const { elements } = storeToRefs(store);

const currentElement = ref({ label: "" });
const globalScriptCSSStyle = ref();
const globalScriptCSSLabelHolder = ref();
const globalScriptCSSPlaceHolder = ref();
const globalScriptCSSFieldBGColor = ref();
const globalScriptCSSFieldBorder = ref();
const globalScriptCSSFieldShadow = ref();
const globalScriptCSSFieldWidth = ref();
const globalScriptCSSFieldPadding = ref();
const globalScriptCSSFontHolder = ref();
const globalScriptCSSShortLabelHolder = ref();

const formWidth = computed(() => {
  return store.deviceMode === "mobile" ? 400 : store.formStyle.width;
});

const formStyle = computed(() => {
  const style = store.formStyle;
  const padding = store.deviceMode === "mobile" ? style?.mobilePadding : style?.padding;

  return {
    padding: `${padding?.top}px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
    backgroundColor: `#${style?.background}`,
    border: `${style?.border?.border}px ${style?.border?.style} #${style?.border?.color}`,
    borderRadius: `${style?.border?.radius}px`,
    boxShadow: `${style?.shadow?.horizontal}px ${style?.shadow?.vertical}px ${style?.shadow?.blur}px ${style?.shadow?.spread}px #${style?.shadow?.color}`,
    width: `${formWidth.value}px`,
  };
});

const currentLayout = computed(() => {
  return store.deviceMode === "mobile"
    ? store.builderConfig.mobileLayout || store.builderConfig.layout
    : store.builderConfig.layout;
});

const clickedElement = (e, element, index) => {
  e.stopPropagation();
  store.currentCustomSidebarPreview = index;
  store.isAnyElementActive = true;
  currentElement.value = element;
};

const fieldStyle = computed(() => {
  const fieldStyleStore = store.fieldStyle;
  const style = {
    padding: `${fieldStyleStore?.padding?.top}px ${fieldStyleStore?.padding?.right}px ${fieldStyleStore?.padding?.bottom}px ${fieldStyleStore?.padding?.left}px`,
    backgroundColor: "#" + fieldStyleStore?.bgColor,
    border: `${fieldStyleStore?.border?.border}px ${
      fieldStyleStore?.border?.type === "none" ? "" : fieldStyleStore?.border?.type
    } #${fieldStyleStore?.border?.color}`,
    borderRadius: fieldStyleStore?.border?.radius + "px",
    boxShadow: `${fieldStyleStore?.shadow?.horizontal}px ${fieldStyleStore?.shadow?.vertical}px ${fieldStyleStore?.shadow?.blur}px ${fieldStyleStore?.shadow?.spread}px #${fieldStyleStore?.shadow?.color}`,
    maxWidth: (fieldStyleStore?.width || 516) + "px",
    color: "#" + fieldStyleStore?.labelColor,
    font: `${fieldStyleStore.labelFontSize}px  ${fieldStyleStore.labelFontWeight} ${fieldStyleStore.labelFontFamily}`,
  };
  return style;
});

onMounted(() => {
  const globalScriptCSS = document.createElement("style");
  globalScriptCSS.type = "text/css";

  // Google and bunny font css script
  const globalScriptFontFamily = document.createElement("style");
  globalScriptFontFamily.type = "text/css";
  globalScriptFontFamily.appendChild(document.createTextNode(""));
  globalScriptCSSFontHolder.value = globalScriptFontFamily.childNodes[0];
  document.head.appendChild(globalScriptFontFamily);

  for (let i = 0; i < 9; i++) {
    globalScriptCSS.appendChild(document.createTextNode(""));
  }
  globalScriptCSSStyle.value = globalScriptCSS.childNodes[0];
  //For Label CSS Change
  globalScriptCSSLabelHolder.value = globalScriptCSS.childNodes[1];
  //For Placeholder CSS Change
  globalScriptCSSPlaceHolder.value = globalScriptCSS.childNodes[2];
  globalScriptCSSFieldBGColor.value = globalScriptCSS.childNodes[3];
  globalScriptCSSFieldBorder.value = globalScriptCSS.childNodes[4];
  globalScriptCSSFieldShadow.value = globalScriptCSS.childNodes[5];
  globalScriptCSSFieldWidth.value = globalScriptCSS.childNodes[6];
  globalScriptCSSFieldPadding.value = globalScriptCSS.childNodes[7];
  globalScriptCSSShortLabelHolder.value = globalScriptCSS.childNodes[8];

  document.head.appendChild(globalScriptCSS);
  globalScriptCSSFontHolder.value.textContent = getAllFontsImport(
    store.fontCollection,
    store.builderConfig.isGDPRCompliant
  );
  setGlobalStyleInHTMl();
});

const setGlobalStyleInHTMl = () => {
  const fieldStyle = store.fieldStyle;
  const formStyle = store.formStyle;
  const isMobile = store.deviceMode === "mobile";

  const getMobileOrDesktop = (mobileVal, desktopVal) =>
    isMobile ? mobileVal || desktopVal : desktopVal;

  const labelColor = getMobileOrDesktop(fieldStyle.mobileLabelColor, fieldStyle.labelColor);
  const labelFontSize = getMobileOrDesktop(
    fieldStyle.mobileLabelFontSize,
    fieldStyle.labelFontSize
  );
  const labelFontWeight = getMobileOrDesktop(
    fieldStyle.mobileLabelFontWeight,
    fieldStyle.labelFontWeight
  );
  const labelFontFamily = getMobileOrDesktop(
    fieldStyle.mobileLabelFontFamily,
    fieldStyle.labelFontFamily
  );

  const placeholderColor = getMobileOrDesktop(
    fieldStyle.mobilePlaceholderColor,
    fieldStyle.placeholderColor
  );
  const placeholderFontFamily = getMobileOrDesktop(
    fieldStyle.mobilePlaceholderFontFamily,
    fieldStyle.placeholderFontFamily
  );
  const placeholderFontSize = getMobileOrDesktop(
    fieldStyle.mobilePlaceholderFontSize,
    fieldStyle.placeholderFontSize
  );
  const placeholderFontWeight = getMobileOrDesktop(
    fieldStyle.mobilePlaceholderFontWeight,
    fieldStyle.placeholderFontWeight
  );

  const shortLabelColor = getMobileOrDesktop(
    fieldStyle.shortLabel?.mobileColor,
    fieldStyle.shortLabel?.color
  );
  const shortLabelFontSize = getMobileOrDesktop(
    fieldStyle.shortLabel?.mobileFontSize,
    fieldStyle.shortLabel?.fontSize
  );
  const shortLabelFontWeight = getMobileOrDesktop(
    fieldStyle.shortLabel?.mobileFontWeight,
    fieldStyle.shortLabel?.fontWeight
  );
  const shortLabelFontFamily = getMobileOrDesktop(
    fieldStyle.shortLabel?.mobileFontFamily,
    fieldStyle.shortLabel?.fontFamily
  );

  globalScriptCSSLabelHolder.value.textContent = `#_builder-form label, #_builder-form .label-input {
      color: #${labelColor};
      font-size: ${labelFontSize}px;
      font-weight: ${labelFontWeight};
      font-family: '${labelFontFamily}';
      margin-bottom: 0.5rem;
      line-height: 1.8;
    }
    #_builder-form .tnc {
      font-family: '${labelFontFamily}';
    }
    #_builder-form .product-amount {
      color: #${labelColor};
      font-size: ${parseFloat(labelFontSize) + 4}px;
      font-weight: ${parseFloat(labelFontWeight) + 200};
      font-family: ${labelFontFamily};
      line-height: 1.5rem;
    }
    #_builder-form .crossed-amount {
      color: #${labelColor};
      font-size: ${parseFloat(labelFontSize) + 2}px;
      font-weight: ${parseFloat(labelFontWeight) + 100};
      font-family: ${labelFontFamily};
      line-height: 1.5rem;
    }
    #_builder-form .product-label {
      color: #${labelColor};
      font-size: ${parseFloat(labelFontSize) + 2}px;
      font-weight: ${parseFloat(labelFontWeight) + 100};
      font-family: ${labelFontFamily};
      line-height: 1.575rem;
    }
    #_builder-form .setup-fee {
      color: #${labelColor};
      font-size: ${parseFloat(labelFontSize) - 2}px;
      font-weight: ${parseFloat(labelFontWeight)};
      font-family: ${labelFontFamily};
      line-height: 1.575rem;
    }
    #_builder-form .variant-tag {
      color: #${labelColor};
      font-size: ${parseFloat(labelFontSize) - 1}px;
      font-weight: ${parseFloat(labelFontWeight)};
      font-family: ${labelFontFamily};
      line-height: 1.5rem;
    }
    `;

  globalScriptCSSPlaceHolder.value.textContent = `#_builder-form ::placeholder, #_builder-form .form-dropdown a, 
    #_builder-form .form-dropdown .dropdown-toggle , #_builder-form .currency-code, #_builder-form .form-placeholder { 
      color : #${placeholderColor}; 
      font-family:'${placeholderFontFamily}'; 
      font-size:${placeholderFontSize}px; 
      font-weight: ${placeholderFontWeight};
    }`;
  globalScriptCSSFieldBGColor.value.textContent = `#_builder-form .form-control, .form-control:disabled, .form-control[readonly], #_builder-form .form-dropdown, #_builder-form .payment-tag, #_builder-form .quantity-container-counter { background-color : #${fieldStyle.bgColor};}`;
  globalScriptCSSFieldBorder.value.textContent =
    "  #_builder-form .form-control, .form-control:disabled, .form-control[readonly], #_builder-form .form-dropdown{ border:" +
    fieldStyle.border.border +
    "px " +
    fieldStyle.border.type +
    " #" +
    fieldStyle.border.color +
    ";border-radius:" +
    fieldStyle.border.radius +
    "px ; }" +
    ` .line-form-style { border-bottom: ${fieldStyle.border.border}px ${fieldStyle.border.type} #${fieldStyle.border.color} !important; background-color: #${fieldStyle.bgColor} !important;}`;
  globalScriptCSSFieldPadding.value.textContent =
    "  #_builder-form .form-control, .form-control:disabled, .form-control[readonly], #_builder-form .form-dropdown { padding:" +
    fieldStyle.padding.top +
    "px " +
    fieldStyle.padding.right +
    "px " +
    fieldStyle.padding.bottom +
    "px " +
    fieldStyle.padding.left +
    "px}";
  globalScriptCSSFieldShadow.value.textContent =
    "  #_builder-form .form-control, .form-control:disabled, .form-control[readonly], #_builder-form .form-dropdown, #_builder-form .payment-tag, #_builder-form .quantity-container-counter { box-shadow:" +
    fieldStyle.shadow.horizontal +
    "px " +
    fieldStyle.shadow.vertical +
    "px " +
    fieldStyle.shadow.blur +
    "px " +
    fieldStyle.shadow.spread +
    "px #" +
    fieldStyle.shadow.color +
    "}";
  globalScriptCSSFieldWidth.value.textContent =
    "  #_builder-form .form-control, .form-control:disabled, .form-control[readonly], #_builder-form .form-dropdown {max-width:" +
    fieldStyle.width +
    "px}";
  globalScriptCSSShortLabelHolder.value.textContent = `#_builder-form .short-label, #_builder-form .short-label::placeholder {
      color: #${shortLabelColor} !important;
      font-size: ${shortLabelFontSize}px !important;
      font-weight: ${shortLabelFontWeight};
      font-family: '${shortLabelFontFamily}';
  }`;
  const regex = /\n/g;
  const styleStore = formStyle.customStyle.replace(regex, "").split("}");
  let styleString = "";
  styleStore.map(function (value: any, key: any) {
    if (value !== "" && value.length > 0) {
      styleString += "#_builder-form " + value + "} ";
    }
  });
  globalScriptCSSStyle.value.textContent = styleString;
};

//Store Subscription to watch on store value change using patch
store.$subscribe((mutation, state) => {
  setGlobalStyleInHTMl();
});

watch([store.fontCollection, () => store.builderConfig.isGDPRCompliant], () => {
  globalScriptCSSFontHolder.value.textContent = getAllFontsImport(
    store.fontCollection,
    store.builderConfig.isGDPRCompliant
  );
});
const isActiveElement = (index) => {
  return store.currentCustomSidebarPreview === index;
};
const changeLog = (e) => {
  clickedOutSideForm();
  if (e.added) {
    store.handleDropAndMove(e.added.element, e.added.newIndex);
  } else {
    store.handleDropAndMove(e.moved.element, e.moved.newIndex, e.moved.oldIndex);
  }
};

const clickedOutSideForm = () => {
  store.currentCustomSidebarPreview = undefined;
  store.isAnyElementActive = false;
};

const applyInlineStyle = (element) => {
  if (currentLayout.value === CONSTANTS.SINGLE_LINE && !fieldFullWidth(element.type, true)) {
    return {
      width: element.fieldWidthPercentage + "%",
      marginBottom: store.formStyle.fieldSpacing + "px",
    };
  }
  if (currentLayout.value !== CONSTANTS.SINGLE_LINE && !fieldFullWidth(element.type, false)) {
    return {
      width: element.fieldWidthPercentage + "%",
      marginBottom: store.formStyle.fieldSpacing + "px",
    };
  }
  return { marginBottom: store.formStyle.fieldSpacing + "px" };
};

const updateLabel = (newLabel: string, index: number) => {
  store.elements[index].label = newLabel;
  store.anyUnsavedChanges = true;
};

const updatePlaceholder = (newPlaceholder: string, index: number) => {
  store.elements[index].placeholder = newPlaceholder;
  store.anyUnsavedChanges = true;
};

const updateShortLabel = (newShortLabel: string, index: number) => {
  store.elements[index].shortLabel = newShortLabel;
  store.anyUnsavedChanges = true;
};
</script>

<template>
  <section
    id="form-builder"
    class="hl_wrapper--inner form-builder"
    :style="{
      backgroundImage: `url(${
        store.deviceMode === 'mobile'
          ? store.formStyle?.mobileBgImage || store.formStyle?.bgImage
          : store.formStyle?.bgImage
      })`,
      backgroundSize: 'cover',
    }"
    @click.prevent="clickedOutSideForm"
  >
    <section class="hl_form-builder--main">
      <form id="_builder-form" name="builder-form" class="builder-preview">
        <div
          v-if="
            store.formAction?.headerImageSrc?.trim().length > 0 ||
            store.formAction?.mobileHeaderImageSrc?.trim().length > 0
          "
        >
          <div
            class="ghl-header-banner w-full max-w-full"
            :style="{
              maxWidth: formWidth + 'px',
            }"
          >
            <HLImage
              :url="
                store.deviceMode === 'mobile'
                  ? store.formAction?.mobileHeaderImageSrc || store.formAction?.headerImageSrc
                  : store.formAction?.headerImageSrc
              "
              :width="store.formStyle.width"
              alt="banner"
            ></HLImage>
          </div>
        </div>
        <div id="the-form-builder" class="form-element form-builder--wrap" :style="formStyle">
          <div
            :class="
              currentLayout !== CONSTANTS.TWO_COLUMN
                ? 'drop form-builder-drop builder-padding-remove'
                : 'drop form-builder-drop builder-inline'
            "
          >
            <VueDraggableNext
              id="form-builder-container"
              :model-value="store.elements"
              :class="
                [CONSTANTS.TWO_COLUMN, CONSTANTS.SINGLE_LINE].includes(currentLayout)
                  ? 'smooth-dnd-container vertical row'
                  : 'smooth-dnd-container vertical'
              "
              group="elements"
              :sort="true"
              @change="changeLog"
            >
              <div
                v-for="(element, index) in elements"
                :key="`form-element-${element.hiddenFieldQueryKey || element.fieldKey || index}`"
                :style="applyInlineStyle(element)"
                class="smooth-dnd-draggable-wrapper element-box menu-field-wrap form-field-wrapper"
                @click="(e) => clickedElement(e, element, index)"
              >
                <div :class="{ active: isActiveElement(index) }">
                  <StandardFieldPreview
                    v-if="!(element.id !== undefined || element?.custom)"
                    :key="`std-field-${element.hiddenFieldQueryKey || element.fieldKey || index}`"
                    :element="element"
                    :index="index"
                    :current-element="currentElement"
                    :field-style="fieldStyle"
                    :form-label-visible="store.builderConfig.formLabelVisible"
                    @update:label="(label) => updateLabel(label, index)"
                    @update:placeholder="(placeholder) => updatePlaceholder(placeholder, index)"
                    @update:short-label="(shortLabel) => updateShortLabel(shortLabel, index)"
                  ></StandardFieldPreview>
                  <CustomFieldsPreview
                    v-if="element.id !== undefined || element?.custom"
                    :key="`custom-field-${
                      element.hiddenFieldQueryKey || element.fieldKey || index
                    }`"
                    :element="element"
                    :index="index"
                    :current-element="currentElement"
                    :field-style="fieldStyle"
                    :form-label-visible="store.builderConfig.formLabelVisible"
                    :is-form="true"
                    @update:label="(label) => updateLabel(label, index)"
                    @update:placeholder="(placeholder) => updatePlaceholder(placeholder, index)"
                    @update:short-label="(shortLabel) => updateShortLabel(shortLabel, index)"
                  ></CustomFieldsPreview>
                </div>
              </div>
            </VueDraggableNext>
          </div>
          <FormbuilderBranding
            :is-branding-active="store.formStyle.acBranding"
            :company="store.company"
          />
        </div>
      </form>
    </section>
  </section>
</template>

<style scoped>
.builder-preview {
  min-height: 450px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

#form-builder {
  background-color: #f3f4f6;
  margin-bottom: 0px;
  overflow: auto;
  height: calc(100vh - 52px);
}

.builder-item {
  cursor: pointer;
  width: 100%;
  background: grey;
  margin: 0.5rem 0;
  min-height: 3rem;
}

#_builder-form .active {
  outline: 1px solid #86b7fe;
  cursor: move;
}

.form-builder--item {
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid transparent;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  position: relative;
}
.builder-inline .smooth-dnd-draggable-wrapper.col-md-6 .f-even .form-builder--item {
  padding-left: 4px !important;
}

.builder-inline .smooth-dnd-draggable-wrapper.col-md-6 .f-odd .form-builder--item {
  padding-left: 10px;
  padding-right: 4px;
}

.builder-inline .smooth-dnd-draggable-wrapper.col-12 .form-builder--item {
  padding-left: 10px;
  padding-right: 4px;
}
.form-builder--item h1,
.form-builder--item h2,
.form-builder--item h3,
.form-builder--item h4,
.form-builder--item h5,
.form-builder--item h6 {
  margin-bottom: 0;
}
.form-builder--item label {
  font-size: 0.875rem;
  color: #2a3135;
}
.captcha-wrap-img {
  width: 335px;
  display: block;
}

.button-element {
  min-width: 85px;
}

.menu-item {
  position: relative;
}
.menu-field-wrap {
  position: relative;
}
#the-form-builder .menu-field-wrap .close-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  opacity: 1;
  transition: all 0.5s ease-in;
  font-size: 10px;
  background: #f5f5f5;
  padding: 5px 7px;
  border-radius: 50%;
  height: 20px;
  cursor: pointer;
}

#the-form-builder {
  margin: 0 auto;
  margin-bottom: 70px;
}

.form-builder-drop {
  min-height: 400px;
  padding: 30px 12px;
}
.builder-padding-remove {
  padding: 30px 0;
}
.add-dropzone-builder {
  background: transparent;
  width: 100%;
  height: 86px;
  border-width: 1;
  border-style: solid;
  border-color: #e5e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6c757d !important;
}
.add-dropzone-builder p {
  margin-left: 5px !important;
}
#form-builder-container {
  height: 100%;
  min-height: 340px;
}
.ghl-header-banner {
  margin: 0 auto;
}
:deep(.form-control) {
  font-size: 0.875rem;
}

.element-box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.smooth-dnd-draggable-wrapper {
  cursor: move;
}
</style>
