import axios, { InternalAxiosRequestConfig } from "axios";

import config from "@/config";
import { useAppStore } from "@/store/app";
const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

const getHeader = () => {
  return {
    channel: "APP",
    source: "WEB_USER",
    version: "2021-07-28",
  };
};

export const requests = (host?: string) => {
  const baseUrl = host || config.REST_API_URLS;
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: getHeader(),
  });

  axiosInstance.interceptors.request.use(async (requestConfig: InternalAxiosRequestConfig) => {
    const appStore = useAppStore();
    try {
      if (requestConfig?.headers && appStore?.tokenId) {
        requestConfig.headers["token-id"] = appStore.tokenId;
      }
    } catch (e) {}
    return requestConfig;
  });

  axiosInstance.interceptors.response.use(
    function (res) {
      console.info(`Method=${res.config.method} URL=${res.config.url} Code=${res.status}`);
      return res;
    },
    async function axiosRetryInterceptor(err) {
      const appStore = useAppStore();

      if (err.config && err.response && err.response.status === 401) {
        const retryCount = appStore.requestRetryCount;

        if (appStore.appHandshake) {
          appStore.appHandshake.then((parent) => {
            parent.emit("refreshToken");
          });
        }
        if (retryCount >= 5) {
          appStore.updateRequestRetryCount(0);
          return Promise.reject(err);
        }
        await wait(2 ** retryCount * 10); // exponential backoff time. -> wait for token
        appStore.updateRequestRetryCount(retryCount + 1);
        return axiosInstance.request(err.config);
      }
      return Promise.reject(err);
    }
  );
  return axiosInstance;
};
