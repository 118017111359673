{
  "common" : {
    "save" : "Lagre",
    "saving" : "Lagrer",
    "integrate" : "Integrere",
    "preview" : "Forhåndsvisning",
    "back" : "Tilbake",
    "alert" : "Varsel",
    "proceed" : "Fortsett",
    "cancel" : "Kanseller",
    "delete" : "Slett",
    "embed" : "Sett inn",
    "link" : "Sammenkoble",
    "iframeEmbed" : "Iframe innebygd",
    "styles" : "Stiler",
    "options" : "Alternativer",
    "quickAdd" : "Hurtigtillegg",
    "customFields" : "Egendefinerte felt",
    "personalInfo" : "Personlig informasjon",
    "contactInfo" : "Kontaktinformasjon",
    "submit" : "Send inn",
    "address" : "Adresse",
    "customized" : "Tilpasset",
    "layout" : "Oppsett",
    "showLabel" : "Vis etikett",
    "background" : "Bakgrunn",
    "border" : "Kant",
    "borderType" : "Kanttype",
    "cornerRadius" : "Hjørneradius",
    "width" : "Bredde",
    "padding" : "Padding",
    "top" : "Topp",
    "bottom" : "Bunn",
    "left" : "Venstre",
    "right" : "Høyre",
    "shadow" : "Skygge",
    "color" : "Farge",
    "horizontal" : "Horisontal",
    "vertical" : "Vertikal",
    "blur" : "Uskarphet",
    "spread" : "Spredning",
    "fieldStyle" : "Feltstil",
    "fontColor" : "Skriftfarge",
    "label" : "Merkelapp",
    "fontFamily" : "Fontfamilie",
    "size" : "Størrelse",
    "weight" : "Vekt",
    "placeholder" : "Plassholder",
    "customCSS" : "Tilpasset CSS",
    "backgroundImage" : "Bakgrunnsbilde",
    "headerImage" : "Overskriftsbilde",
    "miscellaneous" : "Diverse",
    "agencyBranding" : "Byråmerkevarebygging",
    "imageUploadMessage" : "Klikk eller dra et bilde til dette området for å laste opp",
    "backgroundImageTooltipMessage" : "Bakgrunnsbildet er ment å dekke hele siden, og gir et visuelt tiltalende bakteppe for skjemaet. For å se bakgrunnsbildet, klikk på forhåndsning.",
    "headerImageTooltipMessage" : "Topptekstbildet er utformet slik at det dekker hele bredden på skjemaet og forblir plassert øverst i skjemaet. For å se topptekstbildet, klikk på forhåndsvisning.",
    "onSubmit" : "Ved innsending",
    "facebookPixelID" : "Facebook-Pixel-ID",
    "facebookPixelIDMessage" : "Ignorer dette feltet hvis du planlegger å bruke dette skjemaet/undersøkelsen inne i trakt",
    "facebookPixelEvents" : "Facebook Pixel Events",
    "onpageView" : "Ved sidevisning",
    "stickyContact" : "Klistret kontakt",
    "stickyContactMessage" : "Klistret kontakt vil autofylle kontaktinformasjon som er angitt tidligere, slik at du ikke trenger å angi den to ganger.",
    "fieldDescription" : "Beskrivelse av felt",
    "fieldTitle" : "Tittel på felt",
    "queryKey" : "Spørsmålsnøkkel",
    "querykeyTooltipMessage" : "Spørsmålsnøkkelen som kan brukes som URL-parameter for å fylle ut dette feltet",
    "required" : "Obligatorisk",
    "hidden" : "Skjult",
    "hiddenValue" : "Skjult verdi",
    "enableCountryPicker" : "Aktiver landvelger",
    "altText" : "Alt. tekst",
    "alignment" : "Justering",
    "imageWidth" : "Bildebredde",
    "imageHeight" : "Bildehøyde",
    "logic" : "Logikk",
    "pleaseInput" : "Skriv inn",
    "editHTML" : "Rediger HTML",
    "textStyle" : "Tekststil",
    "subText" : "Undertekst",
    "text" : "Tekst",
    "buttonStyle" : "Knappestil",
    "fullWidth" : "Full bredde",
    "htmlMessage" : "Tilpasset HTML skal være her",
    "htmlEditorHeader" : "TILPASSET JAVASCRIPT/HTML",
    "htmlEditorBodyMessage" : "Legg til koden din her",
    "yesSave" : "Ja, lagre",
    "cancelKeep" : "Avbryt, behold",
    "done" : "Fullført",
    "addElement" : "Legg til element",
    "addUrlLike" : "Legg til gyldige nettadresser som",
    "doNotAddRelativePath" : "Ikke legg til relative baner som",
    "or" : "eller",
    "addCustomFields" : "Legg til egendefinerte felt",
    "customFieldSearch" : "Søk etter navn",
    "enterSourceValue" : "Angi kildeverdi",
    "shortLabel" : "Kort etikett",
    "shortLabelPlaceholder" : "Type a short label",
    "fileUploadPlaceholder" : "Upload a file",
    "customCSSTooltip" : "Tilpasset CSS har forrang fremfor skjemastyling og temaer og kan ha innvirkning på temaets styling.",
    "fontFamilyTooltip" : "Builder støtter alle typer skrifter hvis du ikke kan se det i listen. Søk i rullegardinmenyen",
    "useGDPRCompliantFont" : "Bruk GDPR-kompatibel skrift",
    "enableTimezone" : "Aktiver tidssone",
    "inputStyle" : "Inndatastil",
    "conditionalLogicAlertOnSubmit" : "Betinget logikk vil ha forrang over meldingen/omdirigeringen her",
    "conditionalLogicAlertOnShowHide" : "Betinget logikk vil ha forrang over de skjulte innstillingene her",
    "placeholderFacebookPixelIdInput" : "Skriv inn Facebook Pixel-ID",
    "doNotShowMessage" : "Ikke vis igjen",
    "themes" : {
      "themes" : "Temaer",
      "alertThemeMessage" : "Hvis du bytter tema, vil du miste alle gjeldende stilendringer.",
      "useTheme" : "Bruk tema",
      "removeTheme" : "Fjern tema"
    },
    "box" : "Boks",
    "line" : "Linje",
    "message" : "Melding",
    "openUrl" : "Åpne URL",
    "borderTypeList" : {
      "dotted" : "Stiplet",
      "dashed" : "Stiplet",
      "solid" : "Fast",
      "none" : "Ingen"
    },
    "button" : "Knapp",
    "emailNotification" : "E-postvarsling",
    "emailNotificationSubheader" : "Motta en e-post når noen fyller ut skjemaet ditt",
    "subject" : "Tema",
    "emailTo" : "E-post (til)",
    "senderName" : "Navn på avsender",
    "senderNameWarning" : "Ditt (underkontonavn) vil bli lagt til hvis det står tomt",
    "emailError" : "Minst én e-postadresse er påkrevd",
    "emailTooltip" : "Den første e-postadressen er angitt for «Til»-feltet. Eventuelle påfølgende e-postadresser vil bli inkludert i «Cc»-feltet.",
    "clear" : "Fjern",
    "showNotificationsSettings" : "Vis varslingsinnstillinger",
    "emailServicePaid" : "Denne funksjonen er en betalt tjeneste og vil medføre gebyrer basert på bruken din.",
    "integration" : "Integrering",
    "payment" : {
      "payments" : "Betalinger",
      "connected" : "Tilkoblet",
      "connect" : "Koble til",
      "liveModeOn" : "Live-modus",
      "status" : "Status",
      "test" : "Test",
      "live" : "Direkte",
      "paymentFields" : "Betalingsfelt",
      "suggestAnAmount" : "Foreslå et beløp",
      "selectCurrency" : "Velg valuta",
      "tagActiveBGColor" : "Aktiv taggfarge",
      "includeOtherAmount" : "Inkluder annet beløp",
      "footerInfo" : "Bunntekstinfo",
      "paymentType" : "Betalingstype",
      "sellProducts" : "Selg produkter",
      "customAmount" : "Tilpasset beløp",
      "collectPayment" : "Innkreve betaling",
      "generalSettings" : "Generelle felt",
      "product" : {
        "addProduct" : "Legg til produkt",
        "productSettings" : "Produktinnstillinger",
        "selectProduct" : "Velg produkt",
        "editThisProduct" : "For å redigere dette produktet",
        "clickHere" : "Klikk her",
        "show" : "Vis",
        "description" : "Beskrivelse",
        "image" : "Bilde",
        "layoutSettings" : "Layoutinnstillinger",
        "singleColumn" : "En kolonne",
        "twoColumn" : "To kolonner",
        "threeColumn" : "Tre kolonner",
        "maxProductError" : "Maksimalt 20 produkter er tillatt i et skjema",
        "productSelectPlaceholder" : "Velg et produkt",
        "orderConfirmation" : "Ordrebekreftelse",
        "orderConfirmationWarning" : "En ordrebekreftelse vil vises når betalingen er gjennomført",
        "quantity" : "Mengde",
        "createProduct" : "For å oppretter et produkt"
      },
      "coupons" : {
        "coupons" : "Kuponger",
        "enableCoupon" : "Aktiver kupong",
        "couponAlignment" : "Kupongjustering",
        "left" : "Venstre",
        "right" : "Høyre",
        "fullWidth" : "Full bredde",
        "tooltipMessage" : "Angi plasseringen av kuponginndata fra typene nedenfor"
      }
    },
    "addressSettings" : {
      "autoComplete" : "Automatisk utfylling av adresse",
      "addressfields" : "Adressefelt",
      "placeholderSearch" : "Søk etter en adresse",
      "autoCompleteTooltip" : "Gir mulighet til å søke etter og automatisk fylle ut adresse. Denne funksjonen er avgiftsbelagt.",
      "mandataorySearch" : {
        "title" : "Obligatorisk Søke",
        "primaryText" : "Hva er en Obligatorisk Søke Bar?",
        "secondaryText" : "Når Aktivert, Når en Bruker samhandler med Adressefilten, Neste må de velge en gyldig adresse til Fortsette.",
        "secondaryText1" : "Når deaktivert Neste kan de Fortsette uten å velge adresse in Søke-linjen."
      }
    },
    "autoResponder" : "Autosvar",
    "autoResponderSubheader" : "Send en automatisk e-post til personen som sender inn skjemaet (e-post inneholder en kopi av informasjonen de skrev inn i skjemaet)",
    "replyToEmail" : "Svar på e-post",
    "open" : "Åpen",
    "won" : "Vunnet",
    "lost" : "Tapt",
    "abandon" : "Forlatt",
    "selectAPipeline" : "Velg en pipeline",
    "stage" : "Etappe",
    "status" : "Status",
    "opportunityValue" : "Mulighetsverdi",
    "createNewPipeline" : "Opprett ny pipeline",
    "editOpportunitySettings" : "Rediger mulighetsinnstillinger",
    "contact" : "Kontakt",
    "opportunity" : "Mulighet",
    "add" : "Legg til",
    "more" : "Flere",
    "opportunityHeading" : "Hvor vil du at muligheten din skal bli oppdatert?",
    "date" : {
      "dateSettings" : "Datoinnstillinger",
      "dateFormat" : "Datoformat",
      "dateSeparator" : "Datoseparator",
      "dateFormatTooltipMessage" : "Velg et datoformat. D står for dag, M for måned og Å for år",
      "dateSeparatorTooltipMessage" : "Velg en separator som vil skille dag, måned og år i format valgt under datoformat",
      "disablePicker" : "Deaktiver velger"
    },
    "restoreVersion" : "Gjenopprett versjon",
    "versionHistory" : "Versjonshistorikk",
    "errorMessage" : "Feil, prøv på nytt",
    "version" : "Versjon:",
    "currentVersion" : "Nåværende versjon",
    "messageStyling" : "Meldingsstil",
    "validateEmailHeader" : "Bekreft e-post",
    "validateEmailDescription" : "Knappen blir synlig når brukeren skriver inn e-posten sin. Denne funksjonen er utviklet for å hjelpe deg med å generere kvalitetsleads.",
    "validatePhoneHeader" : "Valider telefon",
    "validatePhoneDescription" : "Knappen blir synlig når brukeren går inn på telefonen sin. Denne funksjonen er utviklet for å hjelpe deg med å generere kvalitetsleads.",
    "validatePhoneWarning" : "For å aktivere SMS OTP-verifisering, må du sørge for at telefonnummeret ditt er konfigurert i stedsinnstillingene dine",
    "addAnotherTnc" : "Add another T & C",
    "tnc1" : "T & C 1",
    "tnc2" : "T & C 2",
    "deleteCaps" : "DELETE",
    "deleteConfirmation" : "Type DELETE to confirm",
    "style" : {
      "formLayoutType" : "Skjematype",
      "oneColumn" : "Én kolonne",
      "twoColumn" : "To kolonner",
      "singleLine" : "Én linje",
      "fieldWidth" : "Feltbredde",
      "labelAlignment" : {
        "labelAlignment" : "Etikettjustering",
        "labelWidth" : "Etikettbredde"
      },
      "fieldSpacing" : "Avstand mellom feltene",
      "color" : "farge",
      "fontColor" : "Skriftfarge",
      "inputBackground" : "Inndatabakgrunn",
      "layout" : "Oppsett",
      "colorsAndBackground" : "Farger og bakgrunn",
      "advanceSettings" : "Advanced Settings",
      "inputField" : "Inngangsfelt",
      "borderWidth" : "Kantvidde",
      "borderColor" : "Kantfarge",
      "borderStyle" : "Kantlinjestil",
      "fontSize" : "Skriftstørrelse",
      "fontWeight" : "Skriftvekt",
      "labelColor" : "Etikettfarge",
      "placeholderColor" : "Plassholderfarge",
      "shortLabelColor" : "Kort etikettfarge"
    },
    "replyToTooltip" : "Tilpass standard svar-til-e-postadresse ved å bruke {'{'}{'{'}contact.email{'}'}{'}'} for å aktivere svar til kontakten som sender inn skjemaet.",
    "content" : "Innhold",
    "calculations" : "Beregninger",
    "uploadOptions" : "Opplastingsalternativer",
    "opportunitySettingsWarning" : "Vennligst sett opp mulighetsinnstillinger ved å klikke på knappen Edit Opportunity Settings.",
    "question" : "Spørsmål",
    "createContactOnPartialSubmission" : "Opprett kontakt ved delvis innsending",
    "howItWorks" : "Hvordan fungerer det?",
    "createContactOnPartialSubmissionDescription" : "Fullfør lysbildet og klikk «Neste». En ny kontakt opprettes og lagres automatisk.",
    "swapWarningMessageSurvey" : "Hvis du redigerer eller bytter alternativer, vil det påvirke dette egendefinerte feltet på alle steder der feltet brukes. Det kan også tilbakestille betinget logikk. ",
    "swapWarningMessageForm" : "Hvis du redigerer eller bytter alternativer, vil det påvirke dette egendefinerte feltet på alle steder der feltet brukes. ",
    "optionLabel" : "Alternativ-etikett",
    "calculationValue" : "Beregningsverdi",
    "addOption" : "Legg til alternativ",
    "enableCalculations" : "Aktiver beregninger",
    "allowOtherOption" : "Allow Other Option",
    "productSelectionSubtext" : "Aktivere Individ Eller flere produktvalg",
    "multi" : "Flere",
    "single" : "Individ",
    "productSelectionHeading" : "Produkt",
    "spreadToColumns" : "Spred til kolonner",
    "columns" : "Kolonner",
    "desktopMode" : "Skrivebordmodus",
    "mobileMode" : "Mobilmodus",
    "versions" : "Varianter"
  },
  "survey" : {
    "typeOfBuilder" : "Spørreundersøkelse",
    "survyeStyle" : "Stil for spørreundersøkelse",
    "surveyName" : "Navn på spørreundersøkelse",
    "surveyElement" : "Undersøkelseselement",
    "addSurveyElement" : "Legg til undersøkelseselement",
    "unsavedAlertMessage" : "Undersøkelsen har arbeid som ikke er lagret. Er du sikker på at du vil forlate og forkaste alt arbeid som ikke er lagret?",
    "thirdPartyScriptAlertMessage" : "Tredjepartsskript inne i HTML-blokken kan gi sterk funksjonalitet, men de medfører også risiko for personvern, sikkerhet, ytelse og sideoppførsel. Vi anbefaler derfor at du leser gjennom innholdet i dette skriptet før du lagrer det i undersøkelsen.",
    "integrateHeader" : "Integrer undersøkelsen",
    "integrateSubHeader" : "Bruk følgende alternativer for å integrere den egendefinerte spørreundersøkelsen på nettstedet ditt",
    "integrateCopyLinkMessage" : "Kopier lenken nedenfor og del den enkelt hvor du måtte ønske.",
    "onSurveySubmission" : "Ved innsending av undersøkelse",
    "surveySettings" : "Innstillinger for undersøkelse",
    "oneQuestionAtTime" : "Ett spørsmål om gangen",
    "backButton" : "Tilbakeknapp",
    "backButtonTooltipMessage" : "Tilbakeknappen vil hjelpe brukeren til å flytte forrige lysbilde",
    "disableAutoNavigation" : "Deaktiver automatisk navigasjon",
    "disableAutoNavigationTooltipMessage" : "Hvis den er slått på, går ikke spørreundersøkelsen automatisk videre til neste lysbilde",
    "autoNavigation" : "Auto navigasjon",
    "autoNavigationTooltipMessage" : "Hvis slått deaktivert, vil ikke Spørreundersøkelsen automatisk gå til Neste Skyve",
    "progressBar" : "Fremdriftslinje",
    "progressBarTooltipMessage" : "Hvis du slår denne av, fjernes fremdriftslinjen fra undersøkelsen",
    "disableAnimation" : "Deaktiver animasjon",
    "disableAnimationTooltipMessage" : "Hvis du slår denne på, deaktiveres lysbildeovergangsanimasjonen",
    "scrollToTop" : "Bla til toppen",
    "scrollToTopTooltipMessage" : "Hvis den er slått på, vil undersøkelsen gå til toppen på neste lysbilde",
    "footerHTML" : "HTML for bunntekst",
    "footerHTMLMessage" : "Du kan skrive tilpasset CSS (ved hjelp av stilkode) og HTML i samme boks",
    "editFooter" : "Rediger bunntekst",
    "disqualifyImmediately" : "Diskvalifiser umiddelbart",
    "disqualifyAfterSubmit" : "Diskvalifiser etter innsending",
    "slideConflictAlertMessage" : "Dette lysbildet inneholder flere spørsmål som har logikk ved dem. Dette kan føre til utilsiktet atferd",
    "addSlide" : "Legg til lysbilde",
    "settings" : "Innstillinger",
    "delete" : "Slett",
    "slideDeleteAlertMessage" : "Er du sikker på at du vil slette denne lysbildet?",
    "slideDeleteBetweenAlertMessage" : "Dette vil omorganisere de andre lysbildene, er du sikker på at du vil slette dette lysbildet?",
    "slideName" : "Lysbildenavn",
    "slidePosition" : "Skyveposisjon",
    "jumpTo" : "Hopp til",
    "enableTimezoneSurvey" : "Hvis du aktiverer dette alternativet, registreres tidssoneinformasjonen til personen som sender inn undersøkelsen",
    "footer" : {
      "footer" : "Bunntekst",
      "enableNewFooter" : "Aktiver ny bunntekst",
      "stickToSurvey" : " Hold deg til undersøkelsen",
      "backgroundFill" : "Bakgrunnsfyll",
      "footerHeight" : "Høyde på bunntekst",
      "enableProgressBar" : "Aktiver fremdriftslinje",
      "buttons" : "Knapper",
      "buttonType" : "Knapptype",
      "fillNext" : "Fyll (neste)",
      "fillPrevious" : "Fyll (tidligere)",
      "fillSubmit" : "Fyll ut (send inn)",
      "textColor" : "Knappetekst",
      "position" : "Posisjon",
      "previousText" : "Forrige (tekst)",
      "nextText" : "Neste (tekst)",
      "submitText" : "Send inn (tekst)",
      "fillComplete" : "Innfylling fullført",
      "fillInactive" : "Innfylling inaktiv",
      "footerType" : "Bunnteksttype",
      "stickToCard" : "Sett fast på kortet",
      "stickToPage" : "Sett fast på siden"
    },
    "surveyNotfication" : "Spørreundersøkelsesvarsel",
    "style" : {
      "surveyPadding" : "Undersøkelses-padding"
    },
    "autoResponderSubheader" : "Send en automatisk e-post til personen som sender inn undersøkelsen (e-posten inneholder en kopi av informasjonen de har lagt inn i undersøkelsen)"
  },
  "form" : {
    "typeOfBuilder" : "Skjema",
    "formStyle" : "Skjemastil",
    "formElement" : "Skjemaelement",
    "addFormElement" : "Legg til skjemaelement",
    "addConditionalLogic" : "Legg til betinget logikk",
    "inlineForm" : "Inline-skjema",
    "formName" : "Skjemanavn",
    "fullScreenMode" : "Fullskjermmodus",
    "fullScreenModetooltip" : "Å slå den på vil føre til at skjemaet utvides over hele bredden på beholderen, og strekker seg til kanten av innebyggingsområdet mens det holder seg til skjemaets bredde.",
    "enableTimezoneForm" : "Aktivering av dette alternativet vil fange opp tidssoneinformasjonen til personen som sender inn skjemaet",
    "formSettings" : "Innstillinger for skjema",
    "unsavedAlertMessage" : "Skjemaet har ulagret arbeid. Er du sikker på at du vil forlate og forkaste alt arbeid som ikke er lagret?",
    "thirdPartyScriptAlertMessage" : "Tredjepartsskript inne i HTML-blokken kan gi sterk funksjonalitet, men de medfører også risiko for personvern, sikkerhet, ytelse og sideoppførsel. Vi anbefaler derfor at du leser gjennom innholdet i dette skriptet før du lagrer det i skjemaet.",
    "onFormSubmission" : "Ved innsending av skjema",
    "integrateForm" : "Integrer skjema",
    "embedlayoutType" : "Bygg inn layouttype",
    "embedlayoutTypeSubMessage" : "Velg en layout som skal bygges inn i en trakt eller et eksternt nettsted",
    "triggerType" : "Triggertype",
    "showOnScrolling" : "Vis på scrolling",
    "showAfter" : "Vis etter",
    "seconds" : "sekunder",
    "alwaysShow" : "Vis alltid",
    "activationOptions" : "Aktiveringsalternativer",
    "activateOn" : "Aktiver på",
    "visit" : "besøk",
    "alwaysActivated" : "Alltid aktivert",
    "deactivationOptions" : "Alternativer for deaktivering",
    "deactivateAfterShowing" : "Deaktiver etter visning",
    "times" : "ganger",
    "deactiveOnceLeadCollected" : "Deaktiveres når leaden er hentet inn",
    "neverDeactivate" : "Aldri deaktivere",
    "copyEmbedCode" : "Kopier innbyggingskode",
    "copyFormLink" : "Kopier skjemalenke",
    "openFormLink" : "Åpne skjemalenke",
    "inline" : "Innebygd",
    "inlineDescription" : "Et innebygd skjema vises sammen med innholdet på siden som et innebygd element. Det legges ikke over og kan ikke lukkes eller minimeres.",
    "inlineHoverDescription" : "Et innebygd skjema vises sammen med innholdet på siden som et innebygd element. Det legges ikke over og kan ikke lukkes eller minimeres.",
    "popup" : "Popup",
    "popupDescription" : "En popup viser form som et overlegg inne i en lysboks. Den kan ikke minimeres, men lukkes.",
    "popupHoverDescription" : "En popup viser form som et overlegg inne i en lysboks. Den kan ikke minimeres, men lukkes.",
    "politeSlideIn" : "Høflig varsel",
    "politeSlideInDescription" : "Et høflig varsel vises på bunnen av skjermen og forsvinner når den lukkes",
    "politeSlideInHoverDescription" : "Vises nederst på skjermen og forsvinner når den lukkes",
    "stickySidebar" : "Klistret sidefelt",
    "stickySidebarDescription" : "Et klistret sidefelt vises på siden av skjermen og kan minimeres til en fane som fester seg til siden av vinduet",
    "stickySidebarHoverDescription" : "Vises på siden av skjermen og kan minimeres til en fane som fester seg til siden av vinduet",
    "allowMinimize" : "Tillat minimering",
    "conditonalLogic" : {
      "conditions" : "Betingelser",
      "noExistingCondition" : "Ingen eksisterende betingelser ennå",
      "freeRoamMessage" : "Du kan bevege deg fritt uten regler – i hvert fall inntil videre",
      "addNewCondition" : "Legg til ny betingelse",
      "redirectToUrl" : "Omdiriger til URL",
      "displayCustomMessage" : "Vis egendefinert melding",
      "disqualifyLead" : "Diskvalifiser lead",
      "showHideFields" : "Vis/skjul felt",
      "redirectToUrlSubText" : "Omdiriger Bruker etter innsending",
      "displayCustomMessageSubText" : "Vise-meldinger basert på På Bruker-innganger",
      "disqualifyLeadSubText" : "Filtrere ut oppføringer under spesifikke betingelser",
      "showHideFieldsSubText" : "Endre Følte Synlighet dynamisk",
      "openBelowUrl" : "Åpne URL-en nedenfor",
      "showCustomMessage" : "Vis egendefinert melding",
      "alertConditionDelete" : "Er du sikker på at du vil slette denne betingelsen?",
      "deleteCondition" : "Slett betingelse",
      "conditionExecutedMessage" : "Betingelsene vil bli utført i rekkefølgen ovenfra og ned.",
      "allOperationOptions" : {
        "isEqualTo" : "Er lik",
        "isNotEqualTo" : "Er ikke lik",
        "contains" : "Inneholder",
        "startsWith" : "Begynner med",
        "endsWith" : "Slutter med",
        "isEmpty" : "Er tom",
        "isFilled" : "Er fylt",
        "before" : "Før",
        "after" : "Etter",
        "greaterThan" : "Større enn",
        "lessThan" : "Mindre enn"
      },
      "logic" : {
        "if" : "Hvis",
        "then" : "Deretter",
        "and" : "Og",
        "or" : "Eller"
      },
      "phoneAlertMessage" : "For å søke etter telefonnumre med landskode, må du inkludere «+»-symbolet i søket ditt.",
      "deleteAlertMessage" : "Er du sikker på at du vil slette dette feltet? Betingelsen som er knyttet til dette feltet, vil også bli fjernet.",
      "deleteField" : "Slett felt"
    },
    "minimizedTitlePlaceholder" : "Minimert tittel",
    "dragYourElementFromLeft" : "Dra elementene dine hit fra venstre",
    "formNotification" : "Skjema for varsling",
    "style" : {
      "formPadding" : "Skjema-padding"
    }
  },
  "quiz" : {
    "quizElement" : "Quiz-element",
    "quizNotification" : "Quiz-varsling",
    "viewAndCustomise" : "Se og tilpass",
    "categoryAndScore" : "Kategori og poengsum",
    "provideFeedback" : "Gi tilbakemelding",
    "addQuizElement" : "Legg til quiz-element",
    "typeOfBuilder" : "Quiz",
    "catgory" : {
      "manageCategories" : "Administrer kategorier"
    },
    "quizSettings" : "Quiz-innstillinger",
    "onQuizSubmission" : "Ved innlevering av quiz",
    "settings" : "Innstillinger",
    "deletePage" : "Slett side",
    "quizResult" : {
      "resultTemplate" : "Resultatmal",
      "quizResultAlertMessage" : "Valgt resultatside vil vises når quizen er fullført"
    },
    "autoResponderSubheader" : "Send en automatisk e-post til personen som sender inn quizen (e-posten inneholder en kopi av informasjonen de har lagt inn i quizen)",
    "integrateHeader" : "Integrer quiz",
    "integrateSubHeader" : "Bruk følgende alternativer for å integrere din egendefinerte quiz på nettstedet ditt",
    "integrateCopyLinkMessage" : "Kopier lenken nedenfor og del den enkelt hvor du måtte ønske.",
    "pageName" : "Sidenavn",
    "pagePosition" : "Sideposisjon"
  }
}
