<template>
  <ColorInput
    :label="t('common.style.shortLabelColor')"
    :color="
      store.deviceMode === 'mobile'
        ? store.fieldStyle?.shortLabel?.mobileColor || store.fieldStyle?.shortLabel?.color
        : store.fieldStyle?.shortLabel?.color
    "
    @update:color="handleShortLabelColor"
  />
  <FontFamilyInput
    :label="t('common.fontFamily')"
    :value="
      store.deviceMode === 'mobile'
        ? store.fieldStyle?.shortLabel?.mobileFontFamily || store.fieldStyle?.shortLabel?.fontFamily
        : store.fieldStyle?.shortLabel?.fontFamily
    "
    class="w-full"
    :show-device-mode="true"
    @update:value="handleShortLabelFontFamily"
  />
  <div class="flex items-start gap-3">
    <PixelNumber
      :label="t('common.style.fontSize')"
      :value="
        parseFloat(
          store.deviceMode === 'mobile'
            ? store.fieldStyle?.shortLabel?.mobileFontSize || store.fieldStyle?.shortLabel?.fontSize
            : store.fieldStyle?.shortLabel?.fontSize
        )
      "
      :show-device-mode="true"
      @update:value="handleShortLabelFontSize"
    />
    <PixelNumber
      :label="t('common.style.fontWeight')"
      :value="
        parseFloat(
          store.deviceMode === 'mobile'
            ? store.fieldStyle?.shortLabel?.mobileFontWeight ||
                store.fieldStyle?.shortLabel?.fontWeight
            : store.fieldStyle?.shortLabel?.fontWeight
        )
      "
      :max="1800"
      :show-device-mode="true"
      @update:value="handleShortLabelFontWeight"
    />
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { useI18n } from "vue-i18n";
import ColorInput from "../Elements/ColorInput.vue";
import FontFamilyInput from "../Elements/FontFamilyInput.vue";
import PixelNumber from "../Elements/PixelNumber.vue";

const { t } = useI18n();
const store = useAppStore();

const handleShortLabelColor = (val: string) => {
  if (store.deviceMode === "mobile") {
    if (!store.fieldStyle.shortLabel.mobileColor) {
      store.fieldStyle.shortLabel.mobileColor = store.fieldStyle.shortLabel.color;
    }
    store.fieldStyle.shortLabel.mobileColor = val;
  } else {
    store.fieldStyle.shortLabel.color = val;
    if (!store.fieldStyle.shortLabel.mobileColor) {
      store.fieldStyle.shortLabel.mobileColor = val;
    }
  }
  store.anyUnsavedChanges = true;
};

const handleShortLabelFontFamily = (val: string) => {
  if (!store.fontCollection.includes(val)) {
    store.fontCollection.push(val);
  }

  if (store.deviceMode === "mobile") {
    if (!store.fieldStyle.shortLabel.mobileFontFamily) {
      store.fieldStyle.shortLabel.mobileFontFamily = store.fieldStyle.shortLabel.fontFamily;
    }
    store.fieldStyle.shortLabel.mobileFontFamily = val;
  } else {
    store.fieldStyle.shortLabel.fontFamily = val;
    if (!store.fieldStyle.shortLabel.mobileFontFamily) {
      store.fieldStyle.shortLabel.mobileFontFamily = val;
    }
  }
  store.anyUnsavedChanges = true;
};

const handleShortLabelFontSize = (val: number) => {
  if (store.deviceMode === "mobile") {
    if (!store.fieldStyle.shortLabel.mobileFontSize) {
      store.fieldStyle.shortLabel.mobileFontSize = store.fieldStyle.shortLabel.fontSize;
    }
    store.fieldStyle.shortLabel.mobileFontSize = val;
  } else {
    store.fieldStyle.shortLabel.fontSize = val;
  }
  store.anyUnsavedChanges = true;
};

const handleShortLabelFontWeight = (val: number) => {
  if (store.deviceMode === "mobile") {
    if (!store.fieldStyle.shortLabel.mobileFontWeight) {
      store.fieldStyle.shortLabel.mobileFontWeight = store.fieldStyle.shortLabel.fontWeight;
    }
    store.fieldStyle.shortLabel.mobileFontWeight = val;
  } else {
    store.fieldStyle.shortLabel.fontWeight = val;
  }
  store.anyUnsavedChanges = true;
};
</script>
