<template>
  <div class="flex flex-col">
    <div v-if="picklistOptions !== undefined" :style="styleObject">
      <VueDraggableNext v-model="localPicklistOptions" handle=".drag-handle" class="w-full">
        <div
          v-for="(pickerOption, index) in localPicklistOptions"
          :key="index"
          :style="minimumWidthRadio"
          class="option-radio"
        >
          <div
            v-if="typeof pickerOption === 'string' ? pickerOption !== '' : true"
            class="align-together flex"
          >
            <Menu05Icon
              v-if="isActive"
              class="drag-handle ml-2 mt-1 h-4 w-4 cursor-move text-gray-500"
            />
            <input
              v-if="type === 'input'"
              :id="field.id + '_' + index"
              type="radio"
              :name="field.id"
              :disabled="disabled"
              :value="typeof pickerOption === 'string' ? pickerOption : pickerOption.label"
              @keyup="(e) => $emit('keyup', e)"
              @change="(e) => $emit('change', e)"
            />
            <label
              :for="field.id + '_' + index"
              contenteditable="true"
              class="inline-block w-auto cursor-text border-0 shadow-none outline-none"
              @input="updateOptionLabel($event, index)"
              @focus="handleFocus"
              @keyup="handleEditing($event, index)"
            >
              {{ typeof pickerOption === "string" ? pickerOption : pickerOption.label }}
            </label>
            <Trash01Icon
              v-if="isActive"
              class="ml-2 mt-1 h-4 w-4 cursor-pointer text-gray-500"
              @click="prepareToRemoveOption(index)"
            />
          </div>
        </div>
      </VueDraggableNext>
    </div>
    <div
      v-if="picklistOptionsImage !== undefined"
      :class="[
        'row',
        picklistOptions === undefined || picklistOptionsImage.length > 0
          ? 'container-group-image'
          : '',
      ]"
      :style="styleObject"
      class="flex w-full flex-row flex-wrap items-center justify-start gap-4"
    >
      <div
        v-for="(pickerOption, index) in picklistOptionsImage"
        :key="index"
        :style="minimumWidthRadioImage"
        :class="[
          picklistOptions === undefined && disabled ? 'option-radio col-md-3' : 'option-radio',
        ]"
        class="h-[95px] w-[100px] flex-none"
      >
        <div
          v-if="pickerOption"
          :class="[
            disabled ? 'radio-image-group' : 'radio-field-group',
            pickerOption.src ? 'radio-image-option-set' : '',
          ]"
        >
          <input
            v-if="type === 'input'"
            :id="field.id + '_' + index"
            type="radio"
            :disabled="disabled"
          />

          <!-- For Image as radio button : If its disable mode (For Drag and Drop) -->
          <div v-if="disabled" class="opt-container-img-label">
            <label :for="field.id + '_' + index">
              <img
                v-if="pickerOption.src"
                class="radio-image-option-set-image"
                :src="pickerOption.src"
                alt="Option image"
              />
            </label>
            <div class="img-opt-title">{{ pickerOption.label || "Option" }}</div>
          </div>

          <!-- For Image as radio button : If its not disable mode (For contact section) -->
          <label v-else-if="pickerOption.src && !disabled" :for="field.id + '_' + index">
            <img class="radio-image-option-set-image" :src="pickerOption.src" alt="Option image" />
            <span>{{ pickerOption.label || "Option" }}</span>
          </label>
        </div>
      </div>
    </div>
    <div v-if="isActive && !picklistOptionsImage.length" class="mt-[-1rem] flex items-center">
      <UIButton id="add-option" text class="mt-4" :disabled="isLastOptionEmpty" @click="addOption">
        <PlusIcon class="mr-1.5 h-5 w-5" />
        {{ t("common.addOption") }}
      </UIButton>
    </div>
    <template v-if="isActive">
      <input
        v-model="shortLabel"
        :placeholder="t('common.shortLabelPlaceholder')"
        class="short-label border-0 p-0 focus:outline-none"
        @input="updateShortLabel"
      />
    </template>
    <template v-else>
      <span v-if="field.shortLabel" class="short-label">
        {{ field.shortLabel }}
      </span>
    </template>
  </div>
  <DeleteConfirmationModal
    :show="showDeleteModal"
    @confirm="removeOption"
    @cancel="cancelRemoveOption"
  />
</template>
<script setup lang="ts">
import { CONSTANTS } from "@/util/constants";
import { Menu05Icon, PlusIcon, Trash01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton } from "@gohighlevel/ghl-ui";
import { computed, CSSProperties, ref, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import { useI18n } from "vue-i18n";
import DeleteConfirmationModal from "./DeleteConfirmationModal.vue";

const { t } = useI18n();

interface PickerOption {
  src?: string;
  label?: string;
  [key: string]: any;
}

const props = defineProps({
  type: {
    type: String,
    default: "input",
  },
  name: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "",
  },
  currentLocationId: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  customClass: {
    type: String,
    default: "",
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  picklistOptions: {
    type: Array as () => (string | PickerOption)[],
    default: () => [],
  },
  picklistOptionsImage: {
    type: Array as () => PickerOption[],
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  field: {} as any,
  layout: {
    type: Number,
    default: 1,
  },
  styleObject: {
    type: Object as () => CSSProperties,
    default: () => ({}),
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "update:shortLabel",
  "update:modelValue",
  "update:picklistOptions",
  "keyup",
  "change",
]);

const shortLabel = ref(props.field.shortLabel || "");

// Add local state for the draggable component
const localPicklistOptions = ref([...props.picklistOptions]);

// Add bidirectional watchers with flag to prevent recursive updates
const isInternalUpdate = ref(false);

watch(
  () => props.picklistOptions,
  (newVal) => {
    if (!isInternalUpdate.value) {
      localPicklistOptions.value = JSON.parse(JSON.stringify(newVal));
    }
    isInternalUpdate.value = false;
  },
  { deep: true }
);

watch(
  () => localPicklistOptions.value,
  (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(props.picklistOptions)) {
      isInternalUpdate.value = true;
      emit("update:picklistOptions", JSON.parse(JSON.stringify(newVal)));
    }
  },
  { deep: true }
);

watch(
  () => props.field.shortLabel,
  (newVal) => {
    shortLabel.value = newVal || "";
  }
);

const leftPosition = computed(() => (props.isActive ? "32px" : "0"));
const getWidth = () => {
  let width = 100;
  if (props.layout === CONSTANTS.TWO_COLUMN) {
    width = Math.max(width / props.field.columnsNumber, 50);
  } else {
    width = Math.max(width / props.field.columnsNumber, 20);
  }
  if (props.field.spreadColumns) {
    return width + "%";
  }
  return "100%";
};

const minimumWidthRadio = computed((): CSSProperties => {
  const width = getWidth();
  return {
    position: "relative",
    display: "inline-block",
    width,
  };
});
const minimumWidthRadioImage = computed((): CSSProperties => {
  const width = getWidth();
  return {
    width: props.field.spreadColumns ? width : undefined,
  };
});

const updateOptionLabel = (event: Event, index: number) => {
  const target = event.target as HTMLElement;
  const text = target.innerText.replace(/\u200B/g, "");

  const newOptions = [...localPicklistOptions.value];
  if (typeof newOptions[index] === "string") {
    newOptions[index] = text;
  } else {
    (newOptions[index] as PickerOption).label = text;
  }

  localPicklistOptions.value = newOptions;
};

const indexToRemove = ref<number | null>(null);
const showDeleteModal = ref(false);

const prepareToRemoveOption = (index: number) => {
  if (props.picklistOptions.length > 1) {
    indexToRemove.value = index;
    showDeleteModal.value = true;
  }
};

const removeOption = () => {
  if (indexToRemove.value !== null) {
    const newOptions = [...localPicklistOptions.value];
    newOptions.splice(indexToRemove.value, 1);
    localPicklistOptions.value = newOptions;
  }
  showDeleteModal.value = false;
};

const cancelRemoveOption = () => {
  indexToRemove.value = null;
  showDeleteModal.value = false;
};

const isLastOptionEmpty = computed(() => {
  const lastOption = props.picklistOptions[props.picklistOptions.length - 1];
  if (typeof lastOption === "string") {
    return lastOption.trim() === "";
  }
  return lastOption?.label?.trim() === "";
});

const addOption = () => {
  const newOptions = [...localPicklistOptions.value];
  if (typeof newOptions[0] === "string") {
    newOptions.push("Type an Option");
  } else {
    newOptions.push({ id: `option-${Date.now()}`, label: "Type an Option" });
  }
  localPicklistOptions.value = newOptions;
};

const handleFocus = (event: FocusEvent) => {
  const target = event.target as HTMLElement;

  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
  }

  if (!target.textContent || target.textContent.trim() === "") {
    if (target.childNodes.length === 0) {
      const textNode = document.createTextNode("\u200B");
      target.appendChild(textNode);

      const range = document.createRange();
      range.setStart(textNode, 0);
      range.collapse(true);

      if (selection) {
        selection.addRange(range);
      }
    } else {
      const range = document.createRange();
      range.setStart(target, 0);
      range.collapse(true);

      if (selection) {
        selection.addRange(range);
      }
    }
  } else {
    const range = document.createRange();
    range.selectNodeContents(target);

    if (selection) {
      selection.addRange(range);
    }
  }
};

const updateShortLabel = (event?: Event) => {
  if (event) {
    const target = event.target as HTMLInputElement;
    shortLabel.value = target.value;
  }
  emit("update:shortLabel", shortLabel.value);
};

const handleEditing = (event: KeyboardEvent, index: number) => {
  const target = event.target as HTMLElement;

  if (target.textContent?.trim() === "") {
    if (target.textContent) {
      target.textContent = "";

      const range = document.createRange();
      range.setStart(target, 0);
      range.collapse(true);

      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }
};
</script>
<style>
[type="radio"]:checked + img {
  outline: 1px solid #f00;
  box-shadow: 0px 1px 8px 2px #000;
}

/* Radio image options styling */
.radio-image-option-set-image {
  max-width: 100%;
  max-height: 65px; /* Limit height to leave space for title */
  width: auto;
  height: auto;
  object-fit: contain; /* Maintain aspect ratio without stretching */
  border-radius: 4px 4px 0 0;
}

.container-group-image .radio-image-option-set label:before,
.radio-image-option-set label:before {
  opacity: 0;
}
.container-group-image .radio-image-option-set label,
.radio-image-option-set label {
  padding: 0;
  display: block;
  width: 100%;
  height: 65px; /* Fixed height for image container */
}
.container-group-image .radio-image-option-set,
.radio-image-option-set {
  margin: 0 0 8px 0; /* Add bottom margin for spacing between rows when wrapped */
  border: 0.15rem solid #787f8221;
  padding: 0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100px;
  height: 95px;
}
.container-group-image .radio-image-option-set .opt-container-img-label,
.radio-image-option-set .opt-container-img-label {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.container-group-image .radio-image-option-set .opt-container-img-label label,
.radio-image-option-set .opt-container-img-label label {
  flex: 0 0 65px; /* Fixed height for the label */
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-group-image .radio-image-option-set label img,
.radio-image-option-set label img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: block;
}
.container-group-image .img-opt-title,
.img-opt-title {
  background: #444444;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  font-weight: 800;
  border-radius: 0 0 4px 4px;
  padding: 5px;
  width: 100%;
  height: 30px; /* Fixed height for title */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px; /* Smaller font size to fit in the limited space */
}
.menu-field-wrap.col-sm-6 .option-radio.col-md-3 {
  max-width: 50%;
}

.tw-bottom-input,
.tw-bottom-input:focus {
  --tw-ring-color: none;
  --tw-ring-shadow: none;
  border: none;
  border-bottom: 1px solid rgba(209, 213, 219, var(--tw-border-opacity));
}

/*Radio Button*/
.option-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.option-radio label {
  line-height: 30px;
  display: block;
  padding-left: 25px;
  margin-bottom: 0;
  font-size: 0.875rem;
}

.option-radio label:before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  border: 2px solid #e6edf2;
  background: #fff;
  position: absolute;
  top: 5px;
  left: v-bind(leftPosition);
}

.option-radio label:after {
  font-family: "Magicons";
  color: #fff;
  content: "\e96c";
  font-size: 0.625rem;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #00d26d;
  background-color: #00d26d;
  background-size: 10px 7px;
  position: absolute;
  top: 10px;
  left: 5px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  opacity: 0;
}

.option-radio input[type="radio"] {
  margin: 0;
  display: none;
  visibility: hidden;
}

.option-radio input[type="radio"]:checked + label {
  color: #2a3135;
}

.option-radio input[type="radio"]:checked + label:after {
  width: 15px;
  height: 15px;
  opacity: 1;
  top: 5px;
  left: 0;
  border-radius: 10px;
}

.option-radio input[type="radio"] + label:before,
.option-radio input[type="radio"] + label:after {
  border-radius: 50%;
}

.option-radio label {
  width: 100%;
}
.option-radio label input.add-custom-opt {
  background: #fff;
  border: none;
  width: 50%;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 3px;
}

.short-label {
  background-color: transparent;
  margin-top: 4px;
  font-size: 14px;
  color: #4b5563;
}

/* Target all radio inputs to ensure cursor consistency */
input[type="radio"] {
  cursor: text !important;
  pointer-events: auto !important;
}
</style>
<style scoped>
.flex.flex-col .row > * {
  width: auto !important;
}
</style>
