<script setup lang="ts">
/* eslint-disable vue/no-mutating-props */
import { FormServices } from "@/service/FormServices";
import { SurveyServices } from "@/service/SurveyServices";
import { useAppStore } from "@/store/app";
import { applyStyles, showFieldLabel } from "@/util/methods";
import { XCircleIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton } from "@gohighlevel/ghl-ui";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { CONSTANTS } from "../../util/constants";
import AddressCardPreview from "./Address/AddressCardPreview2.vue";
import AlertModal from "./AlertModal.vue";
import TextElement from "./Fields/TextElement.vue";
import InlineRTE from "./InlineRTE.vue";
import Payment from "./Payments/Payment.vue";
import SelectField from "./SelectField.vue";

const { t } = useI18n();
const store = useAppStore();

const props = defineProps({
  element: {
    type: Object,
    default() {
      return {};
    },
  },
  fieldStyle: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  slideIndex: {
    type: Number,
    default: undefined,
  },
  currentElement: {
    type: Object,
    required: true,
  },
  formLabelVisible: {
    type: Boolean,
    default: true,
  },
  slide: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const emit = defineEmits(["update:label", "update:placeholder", "update:shortLabel"]);
const editableLabel = ref<HTMLElement | null>(null);

const localLabel = ref(props.element.label || "");

watch(
  () => props.element.label,
  (newLabel) => {
    localLabel.value = newLabel || "";
  }
);

const showDeleteModal = ref(false);
const localPlaceholder = ref(props.element.placeholder || "");
const localShortLabel = ref(props.element.shortLabel || "");

watch(
  () => props.element.placeholder,
  (newVal) => {
    localPlaceholder.value = newVal || "";
  }
);

watch(
  () => props.element.shortLabel,
  (newVal) => {
    localShortLabel.value = newVal || "";
  }
);

const updateLocalShortLabel = (event?: Event) => {
  if (event) {
    const target = event.target as HTMLInputElement;
    localShortLabel.value = target.value;
  }
  onUpdateShortLabel(localShortLabel.value);
};

const updateLocalPlaceholder = () => {
  onUpdatePlaceholder(localPlaceholder.value);
};

const inputClicked = ref(false);

const onLabelBlur = () => {
  store.addToHistory();
  inputClicked.value = false;
};

const onLabelInput = () => {
  emit("update:label", localLabel.value);
  resizeInput();
};

const onUpdatePlaceholder = (newPlaceholder: string) => {
  emit("update:placeholder", newPlaceholder);
  store.addToHistory();
};

const onUpdateShortLabel = (newShortLabel: string) => {
  emit("update:shortLabel", newShortLabel);
  store.addToHistory();
};

const handleFocus = (event: FocusEvent) => {
  const target = event.target as HTMLElement;
  const range = document.createRange();
  range.selectNodeContents(target);
  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

const deleteIndex = ref<number | null>(null);

const onFileChange = (e, index, slideIndex) => {
  const element = e.target;
  if (!element.files) {
    return;
  }
  for (let i = 0; i < element.files.length; i++) {
    imageUploadAndReszie(element.files[i], index, slideIndex);
  }
  element.files = null;
};

const imageUploadAndReszie = async (file: File, index, slideIndex) => {
  const bodyFormData = new FormData();
  let image;
  bodyFormData.append("locationId", store.locationId);
  bodyFormData.append("file", file);
  bodyFormData.append("type", "builder");
  if (store.formId) {
    bodyFormData.append("formId", store.formId);
    image = await FormServices.imageUpload(bodyFormData);
  } else {
    bodyFormData.append("surveyId", store.surveyId);
    image = await SurveyServices.imageUpload(bodyFormData);
  }

  setTimeout(() => {
    if (store.formId) {
      store.elements[
        index
      ].url = `https://images.leadconnectorhq.com/image/f_webp/q_85/r_1000/u_${image.data.imageUrl}`;
    } else {
      store.slides[slideIndex].slideData[
        index
      ].url = `https://images.leadconnectorhq.com/image/f_webp/q_85/r_1000/u_${image.data.imageUrl}`;
    }
  }, 1000);
};

const successCallbackDeleteUpdate = () => {
  store.builderConfig.conditionalLogic = store.builderConfig.conditionalLogic.filter((item) => {
    item.conditions = item?.conditions?.filter(
      (condition) =>
        condition.selectedField !== store.elements[deleteIndex.value].hiddenFieldQueryKey
    );
    return item.conditions?.length > 0;
  });
  store.elements.splice(deleteIndex.value, 1);
  showDeleteModal.value = false;
};

const cancelCallbackDeleteUpdate = () => {
  showDeleteModal.value = false;
};

const removeField = (tag: string, index: number, slideIndex?: number) => {
  store.anyUnsavedChanges = true;
  // For Survey or Quiz
  if (store.surveyId || store.quizId) {
    // Clear any references to the element being removed
    if (slideIndex !== undefined) {
      store.slides[slideIndex].slideData.splice(index, 1);
      // Reset active element state
      store.isAnyElementActive = false;
      store.currentActiveIndex = [undefined, undefined];
    }
  } else {
    // For Forms
    // Check if this field is used in conditional logic
    if (
      store.builderConfig.conditionalLogic?.some((item) =>
        item.conditions?.some(
          (condition) => condition.selectedField === store.elements[index].hiddenFieldQueryKey
        )
      )
    ) {
      // Show confirmation modal before deleting
      showDeleteModal.value = true;
      deleteIndex.value = index;
    } else {
      // Safe to delete immediately
      store.elements.splice(index, 1);
      // Reset active element reference if it was the deleted element
      if (store.currentCustomSidebarPreview === index) {
        store.currentCustomSidebarPreview = undefined;
      } else if (
        typeof store.currentCustomSidebarPreview === "number" &&
        store.currentCustomSidebarPreview > index
      ) {
        // Adjust the index for elements that come after the deleted one
        store.currentCustomSidebarPreview--;
      }
    }
    // Special handling for payment elements
    if (tag === "payment") {
      store.addedProductList = [];
      store.formAction.actionType = "2";
    }
  }
  // Record this change in history
  store.addToHistory();
};

const isActiveElement = (slideIndex, elementIndex, elementId, slideId) => {
  if (store.formId) {
    return store.currentCustomSidebarPreview === elementIndex;
  }
  if (store.quizId) {
    return store.currentActiveIndex[0] === slideId && store.currentActiveIndex[1] === elementId;
  }
  return store.currentActiveIndex[0] === slideIndex && store.currentActiveIndex[1] === elementIndex;
};

const headingStyleCSS = computed(() => {
  return {
    color: "#" + (props.element?.color || "000000"),
    fontFamily: props.element?.fontFamily ? `'${props.element?.fontFamily}'` : "Roboto",
    fontSize: (props.element?.fontSize || 40) + "px",
    fontWeight: props.element?.weight || 400,
    backgroundColor: "#" + (props.element?.bgColor || "FFFFFF"),
    boxShadow:
      props.element.shadow?.horizontal +
      "px " +
      props.element.shadow?.vertical +
      "px " +
      props.element.shadow?.blur +
      "px " +
      props.element.shadow?.spread +
      "px #" +
      props.element.shadow?.color,
    padding:
      props.element.padding?.top +
      "px " +
      props.element.padding?.right +
      "px " +
      props.element.padding?.bottom +
      "px " +
      props.element.padding?.left +
      "px",
    border:
      props.element.border?.border +
      "px " +
      props.element.border?.type +
      " #" +
      props.element.border?.color,
    borderRadius: props.element.border?.radius + "px",
    textAlign: "left",
    wordWrap: "break-word",
  };
});
const buttonStyleCSS = computed(() => {
  return {
    backgroundColor: "#" + props.element.bgColor,
    padding:
      props.element?.padding?.top +
      "px " +
      props.element?.padding?.right +
      "px " +
      props.element?.padding?.bottom +
      "px " +
      props.element?.padding?.left +
      "px",
    border:
      props.element?.border + "px " + props.element?.borderType + " #" + props.element?.borderColor,
    borderRadius: (props.element.radius || 0) + "px",
    boxShadow:
      props.element?.shadow?.horizontal +
      "px " +
      props.element?.shadow?.vertical +
      "px " +
      props.element?.shadow?.blur +
      "px " +
      props.element?.shadow?.spread +
      "px #" +
      props.element?.shadow?.color,
    marginTop:
      currentLayout.value !== CONSTANTS.SINGLE_LINE
        ? "10px"
        : currentLayout.value === CONSTANTS.SINGLE_LINE && props.formLabelVisible
        ? "12.5px"
        : "0px",
    width: props.element?.fullwidth
      ? "100%"
      : store.deviceMode === "mobile"
      ? "100%"
      : store.builderConfig.layout === CONSTANTS.SINGLE_LINE
      ? "100%"
      : props.element.fieldWidthPercentage + "%",
  };
});
const buttonStyleTextCSS = computed(() => {
  return {
    color: "#" + props.element?.color,
    fontSize: props.element?.fontSize + "px",
    fontWeight: props.element?.weight,
    fontFamily: `'${props.element?.fontFamily}'`,
    lineHeight: "1.575rem",
  };
});
const buttonSubTextStyleTextCSS = computed(() => {
  return {
    color: "#" + props.element?.subTextColor,
    fontSize: props.element?.subTextFontSize + "px",
    fontWeight: props.element?.subTextWeight,
    fontFamily: `'${props.element?.subTextFontFamily}'`,
    lineHeight: "1.575rem",
  };
});
const getInputCSSList = (): string[] => {
  return store.builderConfig.inputStyleType === "line" ? ["line-form-style"] : [];
};

const labelAlignment = () => {
  const layout =
    store.deviceMode === "mobile"
      ? store.builderConfig.mobileLayout || store.builderConfig.layout
      : store.builderConfig.layout;

  if (layout === CONSTANTS.ONE_COLUMN) {
    const validAlign = ["left", "right"].includes(store.fieldStyle.labelAlignment);
    return {
      width: validAlign ? store.fieldStyle.labelWidth + "px" : "",
      display: validAlign ? "inline-block" : "block",
      cssFloat: validAlign ? "left" : "none",
      textAlign: validAlign ? store.fieldStyle.labelAlignment : "",
      padding: validAlign ? "0 10px 0 0" : "",
    };
  }
  return {};
};
const getStyle = () => {
  return {
    fontSize: store.fieldStyle.placeholderFontSize + "px",
    width: "100%",
  };
};
const getEmailPhoneValidateClass = (fieldWidthPercentage: number) => {
  return [CONSTANTS.TWO_COLUMN, CONSTANTS.SINGLE_LINE].includes(currentLayout.value) &&
    fieldWidthPercentage <= 50
    ? "block space-y-3"
    : "flex";
};

const currentLayout = computed(() => {
  return store.deviceMode === "mobile"
    ? store.builderConfig.mobileLayout || store.builderConfig.layout
    : store.builderConfig.layout;
});

const resizeInput = () => {
  if (!inputElement.value) {
    return;
  }

  if (!localLabel.value.trim()) {
    inputElement.value.style.width = "5px";
    return;
  }

  inputElement.value.style.width = "5px";
  const scrollWidth = inputElement.value.scrollWidth;
  const width = scrollWidth + 7.5;
  inputElement.value.style.width = `${width}px`;
};

const inputElement = ref<HTMLInputElement | null>(null);

onMounted(() => {
  nextTick(resizeInput);
});

watch([() => localLabel.value, () => store.deviceMode], () => {
  nextTick(resizeInput);
});

const handleInputClick = (event: MouseEvent) => {
  const target = event.target as HTMLInputElement;
  if (!inputClicked.value) {
    target.select();
    inputClicked.value = true;
  }
  // For subsequent clicks, the default behavior will position the cursor
};

// Function to check if HTML content has actual text
const hasTextContent = (html: string | undefined): boolean => {
  if (!html) {
    return false;
  }

  // Create a temporary div to parse the HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  // Get the text content and remove whitespace
  const textContent = tempDiv.textContent || tempDiv.innerText || "";
  return textContent.trim().length > 0;
};
</script>

<template>
  <div class="menu-item">
    <div class="form-builder--item">
      <label
        v-if="element.description !== undefined"
        style="display: block; overflow-wrap: break-word"
        v-html="element.description"
      ></label>

      <div style="display: inline-flex" :style="labelAlignment()" class="label-wrapper">
        <div class="flex items-start">
          <div
            v-show="showFieldLabel(formLabelVisible, element)"
            class="label-container flex items-center"
          >
            <input
              ref="inputElement"
              v-model="localLabel"
              class="font-inter label-input cursor-text border-0 bg-transparent p-0 text-sm shadow-none focus:border-gray-300 focus:ring-0"
              placeholder=""
              @blur="onLabelBlur"
              @input="onLabelInput"
              @click="handleInputClick"
            />
          </div>
          <span
            v-if="element.required && showFieldLabel(formLabelVisible, element)"
            class="required-asterisk"
            >*</span
          >
          <div v-if="element.hidden">
            <small class="ml-[5px] rounded-sm bg-[#e1e1e1] px-[5px] py-[2px]">
              {{ t("common.hidden") }}
            </small>
          </div>
        </div>
      </div>

      <TextElement
        v-if="(element.type == 'text' && element.tag !== 'phone') || element.type == 'date'"
        :placeholder="element.placeholder"
        :class-list="[getInputCSSList()]"
        :style-obj="getStyle()"
        :element="element"
        :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
        @update:placeholder="onUpdatePlaceholder"
        @update:short-label="onUpdateShortLabel"
      ></TextElement>
      <div v-else-if="element.tag === 'email'" class="flex flex-col">
        <div
          class="gap-x-3 self-stretch"
          :class="[getEmailPhoneValidateClass(element.fieldWidthPercentage)]"
          :style="getStyle()"
        >
          <input
            v-model="localPlaceholder"
            :placeholder="localPlaceholder"
            class="form-placeholder form-control shadow-none focus:outline-transparent focus:ring-0"
            :class="getInputCSSList()"
            :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
            @input="updateLocalPlaceholder"
          />
          <UIButton
            v-if="element?.enableValidateEmail && !element.hidden"
            id="verify-email"
            class="pointer-events-none h-auto"
            :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
            >Verify Email</UIButton
          >
        </div>

        <template v-if="isActiveElement(slideIndex, index, element.uuid, slide.id)">
          <input
            v-model="localShortLabel"
            :placeholder="t('common.shortLabelPlaceholder')"
            class="short-label border-0 p-0 focus:outline-none"
            @input="updateLocalShortLabel"
          />
        </template>
        <template v-else>
          <span v-if="element.shortLabel" class="short-label">
            {{ element.shortLabel }}
          </span>
        </template>
      </div>
      <div v-else-if="element.tag === 'phone'" class="flex flex-col">
        <div
          class="gap-x-3"
          :class="[getEmailPhoneValidateClass(element.fieldWidthPercentage)]"
          :style="getStyle()"
        >
          <input
            v-model="localPlaceholder"
            :placeholder="localPlaceholder"
            class="form-placeholder form-control shadow-none focus:outline-transparent focus:ring-0"
            :class="getInputCSSList()"
            :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
            @input="updateLocalPlaceholder"
          />
          <UIButton
            v-if="element?.enableValidatePhone && !element.hidden"
            id="verify-phone"
            class="pointer-events-none h-auto"
            :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
            >Verify Phone</UIButton
          >
        </div>
        <template v-if="isActiveElement(slideIndex, index, element.uuid, slide.id)">
          <input
            v-model="localShortLabel"
            :placeholder="t('common.shortLabelPlaceholder')"
            class="short-label border-0 p-0 focus:outline-none"
            @input="updateLocalShortLabel"
          />
        </template>
        <template v-else>
          <span v-if="element.shortLabel" class="short-label">
            {{ element.shortLabel }}
          </span>
        </template>
      </div>
      <div v-else-if="element.tag === 'country'" class="flex flex-col">
        <SelectField
          :element="element"
          :placeholder="element.placeholder"
          :class="[getInputCSSList()]"
          :style="getStyle()"
          @update:placeholder="onUpdatePlaceholder"
        />
        <template v-if="isActiveElement(slideIndex, index, element.uuid, slide.id)">
          <input
            v-model="localShortLabel"
            :placeholder="t('common.shortLabelPlaceholder')"
            class="short-label border-0 p-0 focus:outline-none"
            @input="updateLocalShortLabel"
          />
        </template>
        <template v-else>
          <span v-if="element.shortLabel" class="short-label">
            {{ element.shortLabel }}
          </span>
        </template>
      </div>
      <div v-else-if="element.tag === 'header'">
        <div class="text-element">
          <InlineRTE
            v-model:model-value="element.label"
            :style-obj="headingStyleCSS"
            :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
          />
        </div>
      </div>
      <div v-else-if="element.tag === 'html'">
        <h5 v-if="element.html == ''">
          {{ element.placeholder }}
        </h5>
        <div v-else>
          <div>
            <small style="background: #e1e1e1; padding: 2px 5px; border-radius: 2px"
              >Script Added!</small
            >
          </div>
          <div v-html="element.html"></div>
        </div>
      </div>
      <div v-else-if="element.tag === 'image'">
        <input
          v-if="!('url' in element)"
          type="file"
          class="form-control"
          placeholder="Image"
          :name="element.tag"
          accept="image/*"
          @change="onFileChange($event, index, slideIndex)"
        />

        <div
          v-else-if="'url' in element"
          class="form-builder--item form-builder--image"
          :align="!element.align ? 'center' : element.align"
        >
          <img
            :src="element.url"
            :alt="element.alt"
            :style="{
              width: !element.width || element.width <= 0 ? '100%' : element.width + 'px',
              height: !element.height || element.height <= 0 ? '100%' : element.height + 'px',
            }"
          />
        </div>
      </div>
      <div v-else-if="element.tag === 'captcha'">
        <img
          class="captcha-wrap-img"
          src="../../assets/img/captcha.png"
          height="78"
          alt="Avatar Name"
        />
      </div>
      <div v-else-if="element.tag === 'source'">
        <div>
          <small style="background: #e1e1e1; padding: 2px 5px; border-radius: 2px"
            >Source Added!</small
          >
        </div>
        <div>
          {{ element.value }}
        </div>
      </div>
      <div v-else-if="element.tag === 'button'" :style="{ textAlign: element.align }">
        <button
          id="submit-button"
          type="button"
          :label="element.label"
          class="btn btn-dark button-element"
          :style="buttonStyleCSS"
        >
          <InlineRTE
            v-model:model-value="element.label"
            :style-obj="buttonStyleTextCSS"
            :is-button="true"
            :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
          />
          <InlineRTE
            v-if="element?.submitSubText && hasTextContent(element.submitSubText)"
            v-model:model-value="element.submitSubText"
            :style-obj="buttonSubTextStyleTextCSS"
            :is-button="true"
            :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
          />
        </button>
      </div>
      <div v-else-if="element.tag === 'terms_and_conditions'">
        <div id="tnc-element" class="flex flex-col">
          <div class="flex items-baseline">
            <input id="terms-and-conditions" class="w-auto" type="checkbox" />
            <InlineRTE
              v-if="isActiveElement(slideIndex, index, element.uuid, slide.id)"
              v-model:model-value="element.placeholder"
              class="tnc ml-2 text-sm text-gray-600"
              :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
            />
            <div
              v-else
              class="tnc ml-2 text-sm text-gray-600"
              v-html="applyStyles(element.placeholder, element?.linkColor, element?.textColor)"
            />
          </div>
          <div class="mt-2 flex w-full items-baseline">
            <input
              v-if="element?.placeholder2"
              id="terms-and-conditions-2"
              class="w-auto"
              type="checkbox"
            />
            <InlineRTE
              v-if="
                element?.placeholder2 && isActiveElement(slideIndex, index, element.uuid, slide.id)
              "
              v-model:model-value="element.placeholder2"
              class="tnc ml-2 flex-1 text-sm text-gray-600"
              :is-active="isActiveElement(slideIndex, index, element.uuid, slide.id)"
            />
            <div
              v-else-if="element?.placeholder2"
              class="tnc ml-2 flex-1 text-sm text-gray-600"
              v-html="applyStyles(element.placeholder2, element?.linkColor, element?.textColor)"
            />
          </div>
        </div>
      </div>
      <div v-else-if="element.tag === 'payment'">
        <Payment :payment="element" :input-class="getInputCSSList()"></Payment>
      </div>
      <div v-else-if="element.tag === 'group_address'">
        <AddressCardPreview
          :grouped-element="store.addressSettings"
          :form-label-visible="formLabelVisible"
          :selected-slide-index="slideIndex"
          :selected-index="index"
        />
      </div>
      <div v-else class="builder-item">
        {{ element }}
      </div>
    </div>
    <a
      v-if="isActiveElement(slideIndex, index, element.uuid, slide.id)"
      class="close-icon"
      @mousedown="removeField(element.tag, index, slideIndex)"
    >
      <XCircleIcon class="h-5 w-5"></XCircleIcon>
    </a>
  </div>
  <AlertModal
    v-if="showDeleteModal"
    :description="t('form.conditonalLogic.deleteAlertMessage')"
    :title="t('form.conditonalLogic.deleteField')"
    :positive-text="t('common.delete')"
    :negative-text="t('common.cancel')"
    :show-modal-popup="showDeleteModal"
    header-type="error"
    footer-type="error"
    @pop:change="cancelCallbackDeleteUpdate"
    @pop:positive="successCallbackDeleteUpdate"
    @pop:negative="cancelCallbackDeleteUpdate"
  />
</template>

<style>
.close-icon {
  position: absolute;
  right: -10px;
  top: -10px;
  opacity: 1;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  font-size: 10px;
  /* padding: 5px 7px; */
  background: #f5f5f5;
  color: #2a3135;
  border-radius: 50%;
  height: 20px;
  cursor: pointer;
}
.menu-item {
  position: relative;
}

.form-builder--item {
  border-radius: 4px;
  border: 1px solid transparent;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  position: relative;
}

.form-builder--item h1,
.form-builder--item h2,
.form-builder--item h3,
.form-builder--item h4,
.form-builder--item h5,
.form-builder--item h6 {
  margin-bottom: 0;
}
.form-builder--item label {
  font-size: 80%;
  color: #2a3135;
  overflow-wrap: anywhere;
  hyphens: auto;
}
.form-builder--item label span {
  padding-left: 5px;
}

.captcha-wrap-img {
  width: 304px;
  display: block;
}
.button-element {
  min-width: 85px;
}

#submit-button blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0 !important;
}

#tnc-element {
  word-break: break-word;
}

.label-container {
  display: flex;
  align-items: baseline;
  width: auto;
  overflow: visible;
  margin-bottom: 6px;
  position: relative;
}

.label-input {
  display: inline-block;
  min-width: 0;
  outline: none;
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  margin-bottom: 2px;
}

.label-input:focus {
  background-color: rgba(200, 200, 200, 0.1);
  border-radius: 2px;
  outline: none;
}

.required-asterisk {
  font-weight: bold;
  padding-left: 0;
  font-size: 14px;
  flex-shrink: 0;
  display: inline-flex;
  margin-left: -1px;
  margin-top: 0;
  align-self: flex-start;
  position: relative;
  top: 2px;
  margin-top: -2px;
}

.label-wrapper {
  display: flex;
}
</style>
