<template>
  <div class="flex flex-col">
    <div class="input-group" :style="styleObject">
      <div class="input-group-prepend">
        <span class="input-group-text"> $ </span>
      </div>
      <input
        v-if="isActive"
        v-model="inputPlaceholder"
        type="text"
        class="form-control"
        :style="styleInput"
        :class="classList"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <input
        v-else
        type="text"
        class="form-control"
        :style="styleInput"
        :class="classList"
        :placeholder="placeholder"
        :readonly="readonly"
      />
    </div>
    <template v-if="isActive">
      <input
        v-model="shortLabel"
        :placeholder="t('common.shortLabelPlaceholder')"
        class="short-label border-0 p-0 focus:outline-none"
        @input="updateShortLabel"
      />
    </template>
    <template v-else>
      <span v-if="element.shortLabel" class="short-label">{{ element.shortLabel }}</span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  element: {
    type: Object,
    default: () => {
      return {};
    },
  },
  placeholder: { type: String, default: "" },
  classList: {
    type: Array,
    default: () => {
      return [];
    },
  },
  styleObject: {
    type: Object,
    default: () => {
      return {};
    },
  },
  styleInput: {
    type: Object,
    default: () => {
      return {
        fontSize: "14px",
      };
    },
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:placeholder", "update:shortLabel"]);
const inputPlaceholder = ref(props.placeholder);
const shortLabel = ref(props.element.shortLabel || "");

watch(
  () => props.placeholder,
  (newVal) => {
    inputPlaceholder.value = newVal;
  }
);

watch(
  () => props.element.shortLabel,
  (newVal) => {
    shortLabel.value = newVal || "";
  }
);

const handleInput = () => {
  emit("update:placeholder", inputPlaceholder.value);
};

const handleFocus = () => {
  // Ensure the input has the placeholder value when focused
  if (!inputPlaceholder.value) {
    inputPlaceholder.value = props.placeholder;
  }
};

const handleBlur = () => {
  emit("update:placeholder", inputPlaceholder.value);
  const store = useAppStore();
  store.addToHistory();
};

const updateShortLabel = (event: Event) => {
  const target = event.target as HTMLInputElement;
  shortLabel.value = target.value;
  emit("update:shortLabel", shortLabel.value);
};
</script>

<style>
.short-label {
  background-color: transparent;
  margin-top: 4px;
  font-size: 14px;
  color: #4b5563;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend {
  display: flex;
  margin-right: 0px !important;
  border-color: #e5e7eb;
  border-right: 1px solid #dee5e8;
}

.input-group-text {
  font-family: Roboto, system, -apple-system, BlinkMacSystemFont, ".SFNSDisplay-Regular",
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-left: unset !important;
  border-bottom: unset !important;
  border-top: unset !important;
  border-right: 1px solid #dee5e8;
  background-color: #f3f8fb !important;
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057 !important;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #ced4da;
  border-radius: 0.3125rem;
}
</style>
