<template>
  <FormLayoutType v-if="product === PRODUCT_TYPE.FORM"></FormLayoutType>
  <InputStyleType
    :label="t('common.inputStyle')"
    :value="store?.builderConfig.inputStyleType"
    @update:value="handleInputStyleType"
  />
  <div class="grid grid-cols-2 gap-4">
    <PixelNumber
      :label="t('common.width')"
      :value="parseFloat(store?.formStyle?.width)"
      :max="1800"
      :icon="SpacingWidth01Icon"
      @update:value="handleWidth"
    />
    <PixelNumber
      :label="t('common.style.fieldSpacing')"
      :value="parseFloat(store?.formStyle.fieldSpacing)"
      :max="1800"
      @update:value="handleFieldSpacing"
    />
    <PixelNumber
      :label="t('common.style.labelAlignment.labelWidth')"
      :value="parseFloat(store?.fieldStyle?.labelWidth)"
      :max="1800"
      @update:value="handleLabelWidth"
    />
    <LabelAlignment></LabelAlignment>
  </div>
  <PaddingInput
    :label="t('common.padding')"
    :padding="currentPadding"
    :show-device-mode="true"
    @update-padding="handleFormPadding"
  ></PaddingInput>
  <Switch
    :label="t('common.showLabel')"
    :value="store?.builderConfig.formLabelVisible"
    @update:value="handleLabelVisible"
  />

  <Switch
    v-if="product === PRODUCT_TYPE.FORM && (store.builder.version <= 4 || !store.builder.version)"
    :value="store.builderConfig.fullScreenMode"
    :label="t('form.fullScreenMode')"
    :tooltip="true"
    :tooltip-message="tooltipMessage.fullScreenMode"
    @update:value="handleFullScreenMode"
  />
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { PRODUCT_TYPE, tooltipMessage } from "@/util/constants";
import { getBorderBasedOnCurrentSetup } from "@/util/default";
import { SpacingWidth01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import InputStyleType from "../Elements/InputStyleType.vue";
import LabelAlignment from "../Elements/LabelAlignment.vue";
import PaddingInput from "../Elements/PaddingInput.vue";
import PixelNumber from "../Elements/PixelNumber.vue";
import Switch from "../Elements/Switch.vue";
import FormLayoutType from "../StyleSettings/FormLayoutType.vue";

const { t } = useI18n();
const store = useAppStore();

defineProps({
  product: {
    type: String,
    default: PRODUCT_TYPE.FORM,
  },
});

const handleWidth = (val: number) => {
  store.formStyle.width = val;
  store.anyUnsavedChanges = true;
};

const handleInputStyleType = (val: string) => {
  if (val === "line") {
    //For the first time
    if (store.fieldStyle.border.type === "none") {
      store.fieldStyle.bgColor = "FFFFFF";
    }
    //Every time user change input style left padding will be zero
    store.fieldStyle.padding.left = 0;
    //PreStyle based on change to line type
    const { borderSize, borderType, borderColor } = getBorderBasedOnCurrentSetup(
      store.fieldStyle.border,
      store.formStyle.background
    );
    store.fieldStyle.border.border = borderSize;
    store.fieldStyle.border.type = borderType;
    store.fieldStyle.border.color = borderColor;
  }
  if (val === "box") {
    store.fieldStyle.padding.left = 20;
  }

  store.builderConfig.inputStyleType = val;
  store.anyUnsavedChanges = true;
};

const handleLabelVisible = (val: boolean) => {
  store.builderConfig.formLabelVisible = val;
  store.anyUnsavedChanges = true;
};

const handleFullScreenMode = (value: boolean) => {
  store.builderConfig.fullScreenMode = value;
  store.anyUnsavedChanges = true;
};

const handleFormPadding = (side: string, value: number) => {
  if (store.deviceMode === "mobile") {
    if (!store.formStyle.mobilePadding) {
      store.formStyle.mobilePadding = { ...store.formStyle.padding };
    }
    store.formStyle.mobilePadding[side] = value;
  } else {
    store.formStyle.padding[side] = value;

    if (!store.formStyle.mobilePadding) {
      store.formStyle.mobilePadding = { ...store.formStyle.padding };
    }
  }
  store.anyUnsavedChanges = true;
};

const handleLabelWidth = (val: number) => {
  store.fieldStyle.labelWidth = val;
  store.anyUnsavedChanges = true;
};

const handleFieldSpacing = (val: number) => {
  store.formStyle.fieldSpacing = val;
  store.anyUnsavedChanges = true;
};

const currentPadding = computed(() => {
  return store.deviceMode === "mobile"
    ? store.formStyle.mobilePadding || store.formStyle.padding
    : store.formStyle.padding;
});
</script>
