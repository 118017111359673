{
  "common" : {
    "save" : "Guardar",
    "saving" : "A guardar…",
    "integrate" : "Integrar",
    "preview" : "Pré-visualizar",
    "back" : "Voltar",
    "alert" : "Alerta",
    "proceed" : "Prosseguir",
    "cancel" : "Cancelar",
    "delete" : "Eliminar",
    "embed" : "Incorporar",
    "link" : "Ligar",
    "iframeEmbed" : "Incorporação de iframe",
    "styles" : "Estilos",
    "options" : "Opções",
    "quickAdd" : "Adição rápida",
    "customFields" : "Campos personalizados",
    "personalInfo" : "Informações pessoais",
    "contactInfo" : "Informações de contacto",
    "submit" : "Enviar",
    "address" : "Endereço",
    "customized" : "Personalizado(a)",
    "layout" : "Esquema",
    "showLabel" : "Mostrar etiqueta",
    "background" : "Fundo",
    "border" : "Limite",
    "borderType" : "Tipo de limite",
    "cornerRadius" : "Raio do canto",
    "width" : "Largura",
    "padding" : "Preenchimento",
    "top" : "Topo",
    "bottom" : "Parte inferior",
    "left" : "Esquerda",
    "right" : "Direita",
    "shadow" : "Sombra",
    "color" : "Cor",
    "horizontal" : "Horizontal",
    "vertical" : "Vertical",
    "blur" : "Desfocar",
    "spread" : "Distribuir",
    "fieldStyle" : "Estilo de campo",
    "fontColor" : "Cor da fonte",
    "label" : "Etiqueta",
    "fontFamily" : "Família do tipo de letra",
    "size" : "Tamanho",
    "weight" : "Peso",
    "placeholder" : "Marcador de posição",
    "customCSS" : "CSS personalizado",
    "backgroundImage" : "Imagem de fundo",
    "headerImage" : "Imagem do cabeçalho",
    "miscellaneous" : "Diversos",
    "agencyBranding" : "Marca da agência",
    "imageUploadMessage" : "Clique ou arraste um ficheiro de imagem para esta área para carregar",
    "backgroundImageTooltipMessage" : "A imagem de fundo destina-se a cobrir toda a página, fornecendo um pano de fundo visualmente apelativo para o formulário. Para ver a imagem de fundo, clique na opção Pré-visualizar.",
    "headerImageTooltipMessage" : "A imagem do cabeçalho é desenhada para cobrir toda a largura do formulário e permanece posicionada no topo do mesmo. Para ver a imagem do cabeçalho, clique na opção Pré-visualizar.",
    "onSubmit" : "Ao enviar",
    "facebookPixelID" : "ID do Facebook Pixel",
    "facebookPixelIDMessage" : "Ignore este campo se pretender utilizar este formulário/inquérito dentro de um funil",
    "facebookPixelEvents" : "Eventos Facebook Pixel",
    "onpageView" : "Ao visualizar a página",
    "stickyContact" : "Contacto fixo",
    "stickyContactMessage" : "O contacto fixo preenche automaticamente as informações de contacto introduzidas anteriormente para que não tenha de as introduzir duas vezes.",
    "fieldDescription" : "Descrição do Campo",
    "fieldTitle" : "Título do campo",
    "queryKey" : "Chave de consulta",
    "querykeyTooltipMessage" : "A chave de consulta que pode ser utilizada como um parâmetro de URL para preencher este campo",
    "required" : "Obrigatório(a)",
    "hidden" : "Oculto(a)",
    "hiddenValue" : "Valor oculto",
    "enableCountryPicker" : "Ativar o selecionador de país",
    "altText" : "Texto alternativo",
    "alignment" : "Alinhamento",
    "imageWidth" : "Largura da imagem",
    "imageHeight" : "Altura da imagem",
    "logic" : "Lógica",
    "pleaseInput" : "Introduza",
    "editHTML" : "Editar HTML",
    "textStyle" : "Estilo de texto",
    "subText" : "Subtexto",
    "text" : "Texto",
    "buttonStyle" : "Estilo do botão",
    "fullWidth" : "Largura total",
    "htmlMessage" : "O HTML personalizado vai aqui",
    "htmlEditorHeader" : "JAVASCRIPT PERSONALIZADO / HTML",
    "htmlEditorBodyMessage" : "Adicione o seu código aqui",
    "yesSave" : "Sim, guardar",
    "cancelKeep" : "Cancelar, Manter",
    "done" : "Concluído",
    "addElement" : "Adicionar elemento",
    "addUrlLike" : "Adicionar URLs válidos, como",
    "doNotAddRelativePath" : "Não adicionar caminhos relativos, como",
    "or" : "ou",
    "addCustomFields" : "Adicionar campos personalizados",
    "customFieldSearch" : "Pesquisar por nome",
    "enterSourceValue" : "Introduzir valor de origem",
    "shortLabel" : "Etiqueta curta",
    "shortLabelPlaceholder" : "Type a short label",
    "fileUploadPlaceholder" : "Upload a file",
    "customCSSTooltip" : "O CSS personalizado tem precedência sobre o estilo e os temas do formulário e pode ter um impacto no estilo do tema.",
    "fontFamilyTooltip" : "O Construtor suporta todos os tipos de letra se não conseguir ver isso na lista. Pesquise no menu pendente",
    "useGDPRCompliantFont" : "Utilizar o tipo de letra em conformidade com o RGPD",
    "enableTimezone" : "Ativar fuso horário",
    "inputStyle" : "Estilo de entrada",
    "conditionalLogicAlertOnSubmit" : "A lógica condicional tem precedência sobre a mensagem/redirecionamento aqui",
    "conditionalLogicAlertOnShowHide" : "A lógica condicional tem precedência sobre as definições ocultas aqui",
    "placeholderFacebookPixelIdInput" : "Introduza o ID do Facebook Pixel",
    "doNotShowMessage" : "Não mostrar novamente",
    "themes" : {
      "themes" : "Temas",
      "alertThemeMessage" : "Mudar de tema resultará na perda de todas as modificações de estilo atuais.",
      "useTheme" : "Utilizar tema",
      "removeTheme" : "Remover tema"
    },
    "box" : "Caixa",
    "line" : "Linha",
    "message" : "Mensagem",
    "openUrl" : "Abrir URL",
    "borderTypeList" : {
      "dotted" : "Pontilhado",
      "dashed" : "Tracejado",
      "solid" : "Sólido",
      "none" : "Nenhum"
    },
    "button" : "Botão",
    "emailNotification" : "Notificação por e-mail",
    "emailNotificationSubheader" : "Receba um e-mail quando alguém preencher o seu formulário",
    "subject" : "Assunto",
    "emailTo" : "E-mail (Para)",
    "senderName" : "Nome do remetente",
    "senderNameWarning" : "O seu (nome da subconta) será adicionado se for deixado em branco",
    "emailError" : "É obrigatório, pelo menos, um endereço de e-mail",
    "emailTooltip" : "O primeiro endereço de e-mail é designado para o campo \"Para\". Todos os endereços de e-mail subsequentes serão incluídos no campo \"Cc\".",
    "clear" : "Limpar",
    "showNotificationsSettings" : "Mostrar definições de notificações",
    "emailServicePaid" : "Esta funcionalidade é um serviço pago e será cobrada com base na sua utilização.",
    "integration" : "Integração",
    "payment" : {
      "payments" : "Pagamentos",
      "connected" : "Conectado(a)",
      "connect" : "Conectar",
      "liveModeOn" : "Modo publicado",
      "status" : "Estado",
      "test" : "Teste",
      "live" : "Publicado",
      "paymentFields" : "Campos de pagamento",
      "suggestAnAmount" : "Sugerir um montante",
      "selectCurrency" : "Selecionar moeda",
      "tagActiveBGColor" : "Cor da etiqueta ativa",
      "includeOtherAmount" : "Incluir outro montante",
      "footerInfo" : "Informações do rodapé",
      "paymentType" : "Tipo de pagamento",
      "sellProducts" : "Vender produtos",
      "customAmount" : "Montante personalizado",
      "collectPayment" : "Cobrar pagamento",
      "generalSettings" : "Campos gerais",
      "product" : {
        "addProduct" : "Adicionar produto",
        "productSettings" : "Definições do produto",
        "selectProduct" : "Selecionar produto",
        "editThisProduct" : "Para editar este produto",
        "clickHere" : "Clique aqui",
        "show" : "Mostrar",
        "description" : "Descrição",
        "image" : "Imagem",
        "layoutSettings" : "Definições de layout",
        "singleColumn" : "Coluna única",
        "twoColumn" : "Duas colunas",
        "threeColumn" : "Três colunas",
        "maxProductError" : "São permitidos, no máximo, 20 produtos num formulário",
        "productSelectPlaceholder" : "Selecione um produto",
        "orderConfirmation" : "Confirmação da encomenda",
        "orderConfirmationWarning" : "Será apresentada uma página de confirmação da encomenda assim que o pagamento for efetuado com êxito",
        "quantity" : "Quantidade",
        "createProduct" : "Para criar um produto"
      },
      "coupons" : {
        "coupons" : "Cupões",
        "enableCoupon" : "Ativar cupão",
        "couponAlignment" : "Alinhamento do cupão",
        "left" : "Esquerda",
        "right" : "Direita",
        "fullWidth" : "Largura total",
        "tooltipMessage" : "Definir a posição da introdução do cupão a partir dos seguintes tipos"
      }
    },
    "addressSettings" : {
      "autoComplete" : "Endereço de preenchimento automático",
      "addressfields" : "Campos de endereço",
      "placeholderSearch" : "Pesquisar um endereço",
      "autoCompleteTooltip" : "Fornece a capacidade de pesquisar e preencher automaticamente o endereço. Esta funcionalidade é cobrada.",
      "mandataorySearch" : {
        "title" : "Barra de pesquisa do Necessário",
        "primaryText" : "O que é uma barra Necessário Pesquisar?",
        "secondaryText" : "Quando Ativado, Quando o Utilizador interage com o Campo de Endereço, Depois deve selecionar um Endereço válido para Prosseguir.",
        "secondaryText1" : "Quando Desativado Depois, podem Prosseguir sem selecionar um Endereço in barra Pesquisar."
      }
    },
    "autoResponder" : "Resposta automática",
    "autoResponderSubheader" : "Enviar um e-mail automático à pessoa que envia o formulário (o e-mail inclui uma cópia das informações introduzidas no formulário)",
    "replyToEmail" : "Responder ao e-mail",
    "open" : "Abertas",
    "won" : "Ganhas",
    "lost" : "Perdidas",
    "abandon" : "Abandonar",
    "selectAPipeline" : "Selecionar uma pipeline",
    "stage" : "Fase",
    "status" : "Estado",
    "opportunityValue" : "Valor da oportunidade",
    "createNewPipeline" : "Criar novo pipeline",
    "editOpportunitySettings" : "Editar definições da oportunidade",
    "contact" : "Contacto",
    "opportunity" : "Oportunidade",
    "add" : "Adicionar",
    "more" : "Mais",
    "opportunityHeading" : "Onde pretende que a sua oportunidade seja atualizada?",
    "date" : {
      "dateSettings" : "Definições de data",
      "dateFormat" : "Formato da data",
      "dateSeparator" : "Separador de data",
      "dateFormatTooltipMessage" : "Selecione um formato de data. D significa dia, M significa meses e A significa ano",
      "dateSeparatorTooltipMessage" : "Selecione um separador que irá separar o dia, mês e ano no formato selecionado em Formato de data",
      "disablePicker" : "Desativar Selecionador"
    },
    "restoreVersion" : "Restaurar versão",
    "versionHistory" : "Histórico da versão",
    "errorMessage" : "Erro, tente novamente",
    "version" : "Versão:",
    "currentVersion" : "Versão atual",
    "messageStyling" : "Estilos de mensagens",
    "validateEmailHeader" : "Validar e-mail",
    "validateEmailDescription" : "O botão fica visível assim que o utilizador introduzir o respetivo e-mail. Esta funcionalidade foi criada para ajudar a gerar oportunidades potenciais de qualidade.",
    "validatePhoneHeader" : "Validar Telefone",
    "validatePhoneDescription" : "O botão fica visível quando o utilizador introduzir o respetivo telefone. Esta funcionalidade foi criada para ajudar a gerar oportunidades potenciais de qualidade.",
    "validatePhoneWarning" : "Para ativar a verificação OTP por SMS, certifique-se de que o seu número de telefone configurado nas suas definições de localização",
    "addAnotherTnc" : "Add another T & C",
    "tnc1" : "T & C 1",
    "tnc2" : "T & C 2",
    "deleteCaps" : "DELETE",
    "deleteConfirmation" : "Type DELETE to confirm",
    "style" : {
      "formLayoutType" : "Tipo de formulário",
      "oneColumn" : "Uma coluna",
      "twoColumn" : "Duas colunas",
      "singleLine" : "Linha única",
      "fieldWidth" : "Largura do campo",
      "labelAlignment" : {
        "labelAlignment" : "Alinhamento da etiqueta",
        "labelWidth" : "Largura da etiqueta"
      },
      "fieldSpacing" : "Espaçamento entre campos",
      "color" : "cor",
      "fontColor" : "Cor do tipo de letra",
      "inputBackground" : "Introduzir fundo",
      "layout" : "Esquema",
      "colorsAndBackground" : "Cores & Fundo",
      "advanceSettings" : "Advanced Settings",
      "inputField" : "Campo de entrada",
      "borderWidth" : "Largura do limite",
      "borderColor" : "Cor do limite",
      "borderStyle" : "Estilo do limite",
      "fontSize" : "Tamanho do tipo de letra",
      "fontWeight" : "Espessura do tipo de letra",
      "labelColor" : "Cor da etiqueta",
      "placeholderColor" : "Cor do marcador de posição",
      "shortLabelColor" : "Cor da etiqueta curta"
    },
    "replyToTooltip" : "Personalize o endereço de e-mail predefinido do campo Responder a utilizando {'{'}{'{'}contact.email{'}'}{'}'} para permitir respostas ao contacto que envia o formulário.",
    "content" : "Conteúdo",
    "calculations" : "Cálculos",
    "uploadOptions" : "Opções de Carregamento",
    "opportunitySettingsWarning" : "Configure as definições da oportunidade clicando no botão Editar definições da oportunidade.",
    "question" : "Pergunta",
    "createContactOnPartialSubmission" : "Criar contacto no envio parcial",
    "howItWorks" : "Como é que funciona?",
    "createContactOnPartialSubmissionDescription" : "Termine o diapositivo e clique em \"Seguinte\". Será criado e guardado automaticamente um novo contacto.",
    "swapWarningMessageSurvey" : "A edição ou troca de opções terá impacto neste campo personalizado em todos os locais onde o campo é utilizado. Também pode repor a lógica condicional. ",
    "swapWarningMessageForm" : "A edição ou troca de opções terá impacto neste campo personalizado em todos os locais onde o campo é utilizado. ",
    "optionLabel" : "Etiqueta da opção",
    "calculationValue" : "Valor de cálculo",
    "addOption" : "Adicionar Opção",
    "enableCalculations" : "Ativar cálculos",
    "allowOtherOption" : "Allow Other Option",
    "productSelectionSubtext" : "Ativar Individual Ou múltipla Seleção de produto",
    "multi" : "Multi",
    "single" : "Individual",
    "productSelectionHeading" : "Seleção de Produto",
    "spreadToColumns" : "Distribuir por colunas",
    "columns" : "Colunas",
    "desktopMode" : "Modo de ambiente de trabalho",
    "mobileMode" : "Modo móvel",
    "versions" : "Variantes"
  },
  "survey" : {
    "typeOfBuilder" : "Inquérito",
    "survyeStyle" : "Estilo do inquérito",
    "surveyName" : "Nome do inquérito",
    "surveyElement" : "Elemento do inquérito",
    "addSurveyElement" : "Adicionar elemento do inquérito",
    "unsavedAlertMessage" : "O inquérito tem trabalho não guardado. Tem a certeza de que pretende sair e descartar todo o trabalho não guardado?",
    "thirdPartyScriptAlertMessage" : "Os scripts de terceiros dentro do bloco HTML podem fornecer funcionalidades poderosas, mas também trazem riscos à privacidade, segurança, desempenho e comportamento da página. Por conseguinte, recomendamos que reveja o conteúdo deste script antes de o guardar no inquérito.",
    "integrateHeader" : "Integrar Inquérito",
    "integrateSubHeader" : "Utilize as seguintes opções para integrar o inquérito personalizado no seu Website",
    "integrateCopyLinkMessage" : "Copie a ligação abaixo e partilhe-a facilmente onde quiser.",
    "onSurveySubmission" : "Ao enviar o inquérito",
    "surveySettings" : "Definições do inquérito",
    "oneQuestionAtTime" : "Uma pergunta de cada vez",
    "backButton" : "Botão Voltar",
    "backButtonTooltipMessage" : "O botão Voltar ajuda o utilizador a deslocar-se para o diapositivo anterior",
    "disableAutoNavigation" : "Desativar navegação automática",
    "disableAutoNavigationTooltipMessage" : "Se ativado, o inquérito não passa automaticamente para o diapositivo seguinte",
    "autoNavigation" : "Navegação Automático",
    "autoNavigationTooltipMessage" : "Se for Desativado, o Inquérito não irá automaticamente para o Seguinte Deslizar",
    "progressBar" : "Barra de progresso",
    "progressBarTooltipMessage" : "Se desativar esta opção, remove a Barra de progresso do inquérito",
    "disableAnimation" : "Desativar animação",
    "disableAnimationTooltipMessage" : "Se desativar esta opção, desativa a animação de transição de diapositivos",
    "scrollToTop" : "Deslocar para o topo",
    "scrollToTopTooltipMessage" : "Se ativado, o inquérito é deslocado para o topo no diapositivo seguinte",
    "footerHTML" : "HTML do rodapé",
    "footerHTMLMessage" : "Pode escrever CSS personalizado (utilizando a etiqueta de estilo) e HTML dentro da mesma caixa",
    "editFooter" : "Editar rodapé",
    "disqualifyImmediately" : "Desqualificar imediatamente",
    "disqualifyAfterSubmit" : "Desqualificar depois de enviar",
    "slideConflictAlertMessage" : "Este diapositivo contém várias perguntas que têm uma lógica definida. Isto pode causar alguns comportamentos não intencionais",
    "addSlide" : "Adicionar diapositivo",
    "settings" : "Definições",
    "delete" : "Eliminar",
    "slideDeleteAlertMessage" : "Tem a certeza de que pretende eliminar este diapositivo?",
    "slideDeleteBetweenAlertMessage" : "Isto irá reorganizar os outros diapositivos, tem certeza de que pretende eliminar este diapositivo?",
    "slideName" : "Nome do diapositivo",
    "slidePosition" : "Posição do diapositivo",
    "jumpTo" : "Saltar para",
    "enableTimezoneSurvey" : "Ativar essa opção irá captar as informações sobre o fuso horário do indivíduo que vai enviar o inquérito",
    "footer" : {
      "footer" : "Rodapé",
      "enableNewFooter" : "Ativar novo rodapé",
      "stickToSurvey" : " Manter informações do inquérito",
      "backgroundFill" : "Preenchimento de fundo",
      "footerHeight" : "Altura do rodapé",
      "enableProgressBar" : "Ativar barra de progresso",
      "buttons" : "Botões",
      "buttonType" : "Tipo de botão",
      "fillNext" : "Preencher (seguinte)",
      "fillPrevious" : "Preencher (anterior)",
      "fillSubmit" : "Preencher (Enviar)",
      "textColor" : "Texto do botão",
      "position" : "Posição",
      "previousText" : "Anterior (texto)",
      "nextText" : "Seguinte (texto)",
      "submitText" : "Enviar (texto)",
      "fillComplete" : "Preenchimento concluído",
      "fillInactive" : "Preenchimento inativo",
      "footerType" : "Tipo de rodapé",
      "stickToCard" : "Manter informações do cartão",
      "stickToPage" : "Manter informações da página"
    },
    "surveyNotfication" : "Notificação de inquérito",
    "style" : {
      "surveyPadding" : "Preenchimento no inquérito"
    },
    "autoResponderSubheader" : "Enviar um e-mail automático para a pessoa que envia o inquérito (o e-mail inclui uma cópia das informações introduzidas)"
  },
  "form" : {
    "typeOfBuilder" : "Formulário",
    "formStyle" : "Estilo de formulário",
    "formElement" : "Elemento do formulário",
    "addFormElement" : "Adicionar elemento do formulário",
    "addConditionalLogic" : "Adicionar lógica condicional",
    "inlineForm" : "Formulário em linha",
    "formName" : "Nome do formulário",
    "fullScreenMode" : "Modo de ecrã completo",
    "fullScreenModetooltip" : "Ativar esta opção fará com que o formulário se expanda por toda a largura do contentor, estendendo-se até ao limite da área de incorporação enquanto adere à largura do formulário.",
    "enableTimezoneForm" : "Ativar essa opção irá captar as informações sobre o fuso horário da pessoa que vai enviar o inquérito",
    "formSettings" : "Definições do formulário",
    "unsavedAlertMessage" : "O formulário tem trabalho não guardado. Tem a certeza de que pretende sair e descartar todo o trabalho não guardado?",
    "thirdPartyScriptAlertMessage" : "Os scripts de terceiros dentro do bloco HTML podem fornecer funcionalidades poderosas, mas também trazem riscos à privacidade, segurança, desempenho e comportamento da página. Por conseguinte, recomendamos que reveja o conteúdo deste script antes de o guardar no formulário.",
    "onFormSubmission" : "Ao enviar o formulário",
    "integrateForm" : "Integrar Formulário",
    "embedlayoutType" : "Incorporar tipo de esquema",
    "embedlayoutTypeSubMessage" : "Selecione um esquema a incorporar num funil ou Website externo",
    "triggerType" : "Tipo de acionador",
    "showOnScrolling" : "Mostrar ao rolar",
    "showAfter" : "Mostrar depois",
    "seconds" : "segundos",
    "alwaysShow" : "Mostrar sempre",
    "activationOptions" : "Opções de ativação",
    "activateOn" : "Ativar em",
    "visit" : "visita",
    "alwaysActivated" : "Sempre ativado",
    "deactivationOptions" : "Opções de desativação",
    "deactivateAfterShowing" : "Desativar após mostrar",
    "times" : "vezes",
    "deactiveOnceLeadCollected" : "Desativar assim que a oportunidade potencial for recolhida",
    "neverDeactivate" : "Nunca desativar",
    "copyEmbedCode" : "Copiar código de incorporação",
    "copyFormLink" : "Copiar ligação do formulário",
    "openFormLink" : "Abrir ligação do formulário",
    "inline" : "Em linha",
    "inlineDescription" : "Um formulário em linha é apresentado juntamente com o conteúdo da página como um elemento nativo. Não se sobrepõe e não pode ser fechado ou minimizado.",
    "inlineHoverDescription" : "Um formulário em linha é apresentado juntamente com o conteúdo da página como um elemento nativo. Não se sobrepõe e não pode ser fechado ou minimizado.",
    "popup" : "Pop-up",
    "popupDescription" : "Um pop-up mostra o formulário como uma sobreposição dentro de uma caixa de luz. Não pode ser minimizado, mas pode ser fechado.",
    "popupHoverDescription" : "Um pop-up mostra o formulário como uma sobreposição dentro de uma caixa de luz. Não pode ser minimizado, mas pode ser fechado.",
    "politeSlideIn" : "Mensagem subtil",
    "politeSlideInDescription" : "Uma mensagem subtil é apresentada na parte inferior do ecrã e desaparece quando é fechada",
    "politeSlideInHoverDescription" : "É apresentada na parte inferior do ecrã e desaparece quando é fechada",
    "stickySidebar" : "Barra lateral fixa",
    "stickySidebarDescription" : "Uma barra lateral fixa será apresentada na parte lateral do ecrã e pode ser minimizada num separador fixado à parte lateral da janela",
    "stickySidebarHoverDescription" : "Apresentada na parte lateral do ecrã e pode ser minimizada num separador fixado à parte lateral da janela",
    "allowMinimize" : "Permitir minimizar",
    "conditonalLogic" : {
      "conditions" : "Condições",
      "noExistingCondition" : "Nenhuma condição existente ainda",
      "freeRoamMessage" : "É livre de andar à solta sem regras, pelo menos por enquanto",
      "addNewCondition" : "Adicionar nova condição",
      "redirectToUrl" : "Redirecionar para URL",
      "displayCustomMessage" : "Apresentar mensagem personalizada",
      "disqualifyLead" : "Desqualificar oportunidade potencial",
      "showHideFields" : "Mostrar/ocultar campos",
      "redirectToUrlSubText" : "Redirecionar o utilizador após a submissão",
      "displayCustomMessageSubText" : "Mostrar mensagens com base em entradas do Ativado Utilizador",
      "disqualifyLeadSubText" : "Filtrar entradas sob condições específicas",
      "showHideFieldsSubText" : "Modificar Campo Visibilidade de forma dinâmica",
      "openBelowUrl" : "Abrir URL abaixo",
      "showCustomMessage" : "Mostrar mensagem personalizada",
      "alertConditionDelete" : "Tem a certeza de que pretende eliminar esta condição?",
      "deleteCondition" : "Eliminar condição",
      "conditionExecutedMessage" : "As condições serão executadas de cima para baixo.",
      "allOperationOptions" : {
        "isEqualTo" : "É igual a",
        "isNotEqualTo" : "Não é igual a",
        "contains" : "Contém",
        "startsWith" : "Começa com",
        "endsWith" : "Termina com",
        "isEmpty" : "Está vazio",
        "isFilled" : "Está preenchido",
        "before" : "Antes de",
        "after" : "Depois de",
        "greaterThan" : "Maior que",
        "lessThan" : "Menor que"
      },
      "logic" : {
        "if" : "Se",
        "then" : "Depois",
        "and" : "E",
        "or" : "Ou"
      },
      "phoneAlertMessage" : "Para pesquisar números de telefone com um indicativo de país, tem de incluir explicitamente o símbolo \"+\" na sua consulta de pesquisa.",
      "deleteAlertMessage" : "Tem a certeza de que pretende eliminar este campo? A condição associada a este campo também será removida.",
      "deleteField" : "Eliminar Campo"
    },
    "minimizedTitlePlaceholder" : "Título minimizado",
    "dragYourElementFromLeft" : "Arraste os seus elementos para aqui a partir da esquerda",
    "formNotification" : "Notificação de formulário",
    "style" : {
      "formPadding" : "Preenchimento no formulário"
    }
  },
  "quiz" : {
    "quizElement" : "Elemento do questionário",
    "quizNotification" : "Notificação de questionário",
    "viewAndCustomise" : "Ver e personalizar",
    "categoryAndScore" : "Categoria e Pontuação",
    "provideFeedback" : "Fornecer comentários",
    "addQuizElement" : "Adicionar elemento do questionário",
    "typeOfBuilder" : "Questionário",
    "catgory" : {
      "manageCategories" : "Gerir categorias"
    },
    "quizSettings" : "Definições do questionário",
    "onQuizSubmission" : "Ao enviar o questionário",
    "settings" : "Definições",
    "deletePage" : "Eliminar página",
    "quizResult" : {
      "resultTemplate" : "Modelo de resultado",
      "quizResultAlertMessage" : "A página de resultados escolhida será mostrada assim que o questionário for concluído"
    },
    "autoResponderSubheader" : "Enviar um e-mail automático à pessoa que envia o questionário (o e-mail inclui uma cópia das informações introduzidas no questionário)",
    "integrateHeader" : "Integrar questionário",
    "integrateSubHeader" : "Utilize as seguintes opções para integrar o questionário personalizado no seu Website",
    "integrateCopyLinkMessage" : "Copie a ligação abaixo e partilhe-a facilmente onde quiser.",
    "pageName" : "Nome da página",
    "pagePosition" : "Posição da página"
  }
}
