<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UISwitch } from "@gohighlevel/ghl-ui";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const store = useAppStore();

const isRequired = computed(() => {
  if (props.selectedSlideIndex !== undefined) {
    return store.slides[props.selectedSlideIndex].slideData[props.selectedIndex].required;
  }
  return store.elements[props.selectedIndex].required;
});

const props = defineProps({
  selectedIndex: {
    type: Number,
    required: true,
  },
  selectedSlideIndex: {
    type: Number,
    required: false,
    default: undefined,
  },
});

const toggleAutoComplete = (isRequired: boolean) => {
  if (props.selectedSlideIndex !== undefined) {
    store.slides[props.selectedSlideIndex].slideData[props.selectedIndex].required = isRequired;
  } else {
    store.elements[props.selectedIndex].required = isRequired;
  }
  store.addToHistory();
  store.anyUnsavedChanges = true;
};
</script>

<template>
  <div class="flex justify-between py-2">
    <div class="flex items-center gap-1">
      <div class="switch-handle my-auto text-base">
        {{ t("common.addressSettings.mandataorySearch.title") }}
      </div>
    </div>
    <div class="switch">
      <UISwitch
        size="small"
        :value="isRequired"
        :disabled="false"
        @update:value="toggleAutoComplete"
      />
    </div>
  </div>
  <div class="flex w-full flex-col gap-3 rounded-lg border border-gray-300 p-4">
    <div class="flex flex-col gap-1">
      <div class="font-inter text-sm font-semibold leading-5 text-gray-700">
        {{ t("common.addressSettings.mandataorySearch.primaryText") }}
      </div>
      <ul class="font-inter text-sm font-normal leading-5 text-gray-600">
        <li>
          {{ t("common.addressSettings.mandataorySearch.secondaryText") }}
        </li>
        <li>{{ t("common.addressSettings.mandataorySearch.secondaryText1") }}</li>
      </ul>
    </div>
  </div>
</template>
