<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { ref, watch } from "vue";

const props = defineProps({
  placeholder: {
    type: String,
    default: "",
  },
  styleObject: {
    type: Object,
    default: () => {
      return {
        fontSize: "14px",
      };
    },
  },
});

const emit = defineEmits(["update:placeholder"]);
const inputPlaceholder = ref(props.placeholder);
const inputValue = ref("");
const store = useAppStore();

watch(
  () => props.placeholder,
  (newVal) => {
    inputPlaceholder.value = newVal;
  }
);

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  inputValue.value = target.value;
  inputPlaceholder.value = target.value;
  emit("update:placeholder", inputPlaceholder.value);
};

const handleFocus = () => {
  inputValue.value = inputPlaceholder.value;
};

const handleBlur = (event: Event) => {
  const target = event.target as HTMLInputElement;
  inputValue.value = "";
  emit("update:placeholder", inputPlaceholder.value);
  store.addToHistory();
};
</script>

<template>
  <div class="dropdown show form-dropdown" :style="styleObject">
    <div class="flex justify-between">
      <input
        :value="inputValue"
        :placeholder="inputPlaceholder"
        class="form-placeholder dropdown-toggle dropdown-input border-0 bg-transparent p-0 shadow-none outline-none ring-0 focus:outline-transparent focus:ring-0"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <div class="dropdown-icon">
        <div class="css-arrow"></div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.dropdown {
  position: relative;
}
.show {
  display: block;
}
.dropdown-toggle {
  cursor: pointer;
  width: 524px;
  height: 23px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #607179 !important;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
}
.dropdown-icon {
  display: inline-flex;
  align-items: center;
  margin-right: -5px;
  margin-top: 3px;
}
.css-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #8c8c8c;
}

.dropdown-icon:active .css-arrow,
.dropdown-icon:focus .css-arrow,
.dropdown-icon:hover .css-arrow,
.active .css-arrow {
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  border-top: 5px solid #8c8c8c !important;
}

.form-dropdown {
  padding: 15px 20px;
  border-radius: 5px;
}
.form-dropdown a {
  padding: 0;
}
</style>
