import CreditCardShield from "@/assets/icons/credit-card-shield.svg?url";
import AddressIcon from "@/assets/icons/custom-builder-address.svg?url";
import BirthdayIcon from "@/assets/icons/custom-builder-birthdate.svg?url";
import ButtonIcon from "@/assets/icons/custom-builder-button.svg?url";
import CaptchaIcon from "@/assets/icons/custom-builder-captcha.svg?url";
import CheckboxIcon from "@/assets/icons/custom-builder-checkbox.svg?url";
import CityIcon from "@/assets/icons/custom-builder-city.svg?url";
import CountryIcon from "@/assets/icons/custom-builder-country.svg?url";
import DatePickerIcon from "@/assets/icons/custom-builder-date-picker.svg?url";
import DropdownIcon from "@/assets/icons/custom-builder-dropdown.svg?url";
import EmailIcon from "@/assets/icons/custom-builder-email.svg?url";
import FileUploadIcon from "@/assets/icons/custom-builder-file-upload.svg?url";
import FirstNameIcon from "@/assets/icons/custom-builder-first-name.svg?url";
import FullNameIcon from "@/assets/icons/custom-builder-full-name.svg?url";
import HtmlIcon from "@/assets/icons/custom-builder-html.svg?url";
import ImageIcon from "@/assets/icons/custom-builder-image.svg?url";
import LastNameIcon from "@/assets/icons/custom-builder-last-name.svg?url";
import MonetaryIcon from "@/assets/icons/custom-builder-monetary.svg?url";
import MultiLineIcon from "@/assets/icons/custom-builder-multi-line.svg?url";
import OrganizationIcon from "@/assets/icons/custom-builder-organization.svg?url";
import PhoneIcon from "@/assets/icons/custom-builder-phone.svg?url";
import PostalCodeIcon from "@/assets/icons/custom-builder-postal-code.svg?url";
import RadioIcon from "@/assets/icons/custom-builder-radio.svg?url";
import SignatureIcon from "@/assets/icons/custom-builder-signature.svg?url";
import SingleLineIcon from "@/assets/icons/custom-builder-single-line.svg?url";
import SourceIcon from "@/assets/icons/custom-builder-source.svg?url";
import StateIcon from "@/assets/icons/custom-builder-state.svg?url";
import TermsAndConditionsIcon from "@/assets/icons/custom-builder-terms.svg?url";
import TextBoxIcon from "@/assets/icons/custom-builder-text-box.svg?url";
import TextIcon from "@/assets/icons/custom-builder-text.svg?url";
import WebsiteIcon from "@/assets/icons/custom-builder-website.svg?url";
import SellProductIcon from "@/assets/icons/sell-product.svg?url";
import { h } from "vue";
import { i18n } from "../plugin/vue-i18n";
import {
  DEFAULT_FOOTER_SHADOW,
  DEFAULT_LABEL_COLOR,
  DEFAULT_TEXT_COLOR,
  FULL_WIDTH_FIELDS,
  LEFT,
  LEFT_AND_RIGHT,
  ONLY_ARROW,
  ONLY_TEXT,
  RIGHT,
  TEXT_AND_ARROW,
} from "./constants";
import { defaultBorder, defaultBtnPadding, defaultPadding, defaultShadow } from "./default";

const { t } = i18n.global;

export interface GroupField {
  label: string;
  tag: string;
  hiddenFieldQueryKey?: string;
  type: string;
  placeholder?: string;
  required?: boolean;
  standard: boolean;
  custom?: boolean;
  icon: any;
  category: string;
  dataType?: string;
  typeLabel?: string;
  subType?: string;
  format?: string;
  separator?: string;
  enableCountryPicker?: boolean;
  picklistOptions?: Array<{ label: string; prefillValue?: string }> | string[];
  title?: string;
  children?: GroupField[];
  color?: string;
  fontFamily?: string;
  fontSize?: number;
  weight?: number;
  html?: string;
  value?: string;
  bgColor?: string;
  align?: string;
  shadow?: any;
  textColor?: string;
  linkColor?: string;
  padding?: any;
  preview?: string;
  border?: any;
  hidden?: boolean;
  shortLabel?: string;
  fieldKey?: string;
  hiddenFieldValue?: string;
  submitSubText?: string;
  hideInLeftSideBar?: boolean;
  borderType?: string;
  borderColor?: string;
  borderRadius?: number;
  fullwidth?: boolean;
  subTextColor?: string;
  subTextFontSize?: number;
  subTextWeight?: number;
  subTextFontFamily?: string;
  radius?: number;
  webkitBoxShadow?: string;
  boxShadow?: string;
}

interface FieldGroup {
  label: string;
  group: GroupField[];
}

interface StandardFields {
  quizQuestions: FieldGroup;
  personal: FieldGroup;
  submit: FieldGroup;
  integration: FieldGroup;
  address: FieldGroup;
  text: FieldGroup;
  choiceElements: FieldGroup;
  customized: FieldGroup;
  other: FieldGroup;
}

// Example usage

export const standardFields: StandardFields = {
  quizQuestions: {
    label: "Quiz Questions",
    group: [
      {
        label: "Single Choice",
        tag: "radio",
        hiddenFieldQueryKey: "radio",
        type: "radio",
        dataType: "RADIO",
        typeLabel: "Single Choice",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: RadioIcon }),
        category: "quizQuestions",
        picklistOptions: ["Option 1", "Option 2", "Option 3"],
      },
      {
        label: "Multiple Choice",
        tag: "checkbox",
        hiddenFieldQueryKey: "checkbox",
        type: "checkbox",
        dataType: "CHECKBOX",
        typeLabel: "Multiple Choice",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: CheckboxIcon }),
        category: "quizQuestions",
        picklistOptions: ["Option 1", "Option 2", "Option 3"],
      },
      {
        label: "Yes / No",
        tag: "radio",
        hiddenFieldQueryKey: "radio",
        type: "radio",
        dataType: "RADIO",
        typeLabel: "Yes / No",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: RadioIcon }),
        category: "quizQuestions",
        picklistOptions: ["Yes", "No"],
      },
      {
        label: "Short Answer",
        tag: "single_line",
        hiddenFieldQueryKey: "single_line",
        type: "text",
        dataType: "TEXT",
        typeLabel: "Short Answer",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: SingleLineIcon }),
        category: "text",
      },
      {
        label: "Long Answer",
        tag: "multi_line",
        hiddenFieldQueryKey: "multi_line",
        type: "large_text",
        dataType: "LARGE_TEXT",
        typeLabel: "Long Answer",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: MultiLineIcon }),
        category: "text",
      },
      {
        label: "Number",
        tag: "text",
        hiddenFieldQueryKey: "number",
        type: "numerical",
        dataType: "NUMERICAL",
        typeLabel: "Number",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: MonetaryIcon }),
        category: "other",
      },
      {
        label: "Single Dropdown",
        tag: "dropdown",
        hiddenFieldQueryKey: "dropdown",
        type: "single_options",
        dataType: "SINGLE_OPTIONS",
        typeLabel: "Single Dropdown",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: DropdownIcon }),
        category: "choiceElements",
        picklistOptions: ["Option 1", "Option 2", "Option 3"],
      },
      {
        label: "Multi Dropdown",
        tag: "dropdown_multi",
        hiddenFieldQueryKey: "dropdown_multi",
        type: "multiple_options",
        dataType: "MULTIPLE_OPTIONS",
        typeLabel: "Multi Dropdown",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: DropdownIcon }),
        category: "choiceElements",
        picklistOptions: ["Option 1", "Option 2", "Option 3"],
      },
    ],
  },
  personal: {
    label: `${t("common.personalInfo")}`,
    group: [
      {
        label: "Full Name",
        tag: "full_name",
        hiddenFieldQueryKey: "full_name",
        type: "text",
        typeLabel: "Text",
        placeholder: "Full Name",
        required: false,
        standard: true,
        icon: () => h("img", { src: FullNameIcon }),
        category: "personal",
      },
      {
        label: "First Name",
        tag: "first_name",
        hiddenFieldQueryKey: "first_name",
        type: "text",
        typeLabel: "Text",
        placeholder: "First Name",
        required: false,
        standard: true,
        icon: () => h("img", { src: FirstNameIcon }),
        category: "personal",
      },
      {
        label: "Last Name",
        tag: "last_name",
        hiddenFieldQueryKey: "last_name",
        type: "text",
        typeLabel: "Text",
        placeholder: "Last Name",
        required: false,
        standard: true,
        icon: () => h("img", { src: LastNameIcon }),
        category: "personal",
      },
      {
        label: "Date of birth",
        tag: "date_of_birth",
        type: "date",
        typeLabel: "Date",
        format: "YYYY-MM-DD",
        separator: "-",
        placeholder: "Date of birth",
        standard: true,
        icon: () => h("img", { src: BirthdayIcon }),
        category: "personal",
      },
      {
        label: "Phone",
        tag: "phone",
        hiddenFieldQueryKey: "phone",
        type: "text",
        typeLabel: "Phone",
        placeholder: "Phone",
        enableCountryPicker: false,
        required: true,
        standard: true,
        icon: () => h("img", { src: PhoneIcon }),
        category: "personal",
      },
      {
        label: "Email",
        tag: "email",
        hiddenFieldQueryKey: "email",
        type: "email",
        typeLabel: "Email",
        placeholder: "Email",
        required: true,
        standard: true,
        icon: () => h("img", { src: EmailIcon }),
        category: "personal",
      },
    ],
  },
  submit: {
    label: `${t("common.submit")}`,
    group: [
      {
        label: "Button",
        tag: "button",
        hiddenFieldQueryKey: "button",
        type: "submit",
        placeholder: "Button",
        submitSubText: "",
        bgColor: "18BD5BFF",
        padding: defaultBtnPadding,
        border: 0,
        borderType: "none",
        borderColor: "FFFFFF",
        borderRadius: 6,
        shadow: defaultShadow,
        fullwidth: true,
        color: "FFFFFF",
        fontSize: 14,
        weight: 500,
        radius: 4,
        fontFamily: "Roboto",
        subTextColor: "000000",
        subTextFontSize: 14,
        subTextWeight: 200,
        subTextFontFamily: "Roboto",
        align: "left",
        standard: true,
        icon: () => h("img", { src: ButtonIcon }),
        category: "submit",
      },
    ],
  },
  integration: {
    label: `${t("common.payment.payments")}`,
    group: [
      {
        label: "Sell Products",
        tag: "payment",
        type: "payment",
        subType: "product",
        icon: () => h("img", { src: SellProductIcon }),
        category: "payment",
        required: true,
        standard: true,
      },
      {
        label: "Collect Payment",
        tag: "payment",
        type: "payment",
        subType: "donation",
        icon: () => h("img", { src: CreditCardShield }),
        category: "payment",
        required: true,
        standard: true,
      },
    ],
  },
  address: {
    label: `${t("common.address")}`,
    group: [
      {
        label: "Address",
        tag: "group_address",
        hiddenFieldQueryKey: "addressId",
        type: "group",
        placeholder: "Address",
        required: false,
        standard: true,
        icon: () =>
          h(
            "div",
            {
              style: { display: "inline-block", padding: "0px !important" },
              class: "w-[60px] h-[60px] p-[0px]",
            },
            [
              h(
                "span",
                {
                  style: {
                    position: "absolute",
                    top: "-10px",
                    right: "20px",
                    padding: "2px 6px",
                    fontSize: "10px",
                    fontFamily: "Inter",
                    fonSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#004EEB",
                    lineHeight: "18px" /* 150% */,
                    borderRadius: "16px",
                    background: " #EFF4FF",
                  },
                },
                "Updated"
              ),
              h("img", { src: AddressIcon, class: "p-2" }),
            ]
          ),
        category: "address",
        title: "Street Address",
      },
      {
        label: "Street Address",
        tag: "address",
        hiddenFieldQueryKey: "address",
        type: "text",
        typeLabel: "Text",
        placeholder: "Address",
        required: false,
        standard: true,
        icon: () => h("img", { src: AddressIcon }),
        category: "address",
        title: "Address",
        hideInLeftSideBar: true,
      },
      {
        label: "City",
        tag: "city",
        hiddenFieldQueryKey: "city",
        type: "text",
        typeLabel: "Text",
        placeholder: "City",
        required: false,
        standard: true,
        icon: () => h("img", { src: CityIcon }),
        category: "address",
        title: "City",
      },
      {
        label: "State",
        tag: "state",
        hiddenFieldQueryKey: "state",
        type: "text",
        typeLabel: "Text",
        placeholder: "State",
        required: false,
        standard: true,
        icon: () => h("img", { src: StateIcon }),
        category: "address",
        title: "State",
      },
      {
        label: "Country",
        tag: "country",
        hiddenFieldQueryKey: "country",
        type: "select",
        typeLabel: "Select",
        placeholder: "Country",
        required: false,
        standard: true,
        icon: () => h("img", { src: CountryIcon }),
        category: "address",
        title: "Country",
      },
      {
        label: "Postal code",
        tag: "postal_code",
        hiddenFieldQueryKey: "postal_code",
        type: "text",
        typeLabel: "Text",
        placeholder: "Postal Code",
        required: false,
        standard: true,
        icon: () => h("img", { src: PostalCodeIcon }),
        category: "address",
        title: "Postal Code",
      },
      {
        label: "Organization",
        tag: "organization",
        hiddenFieldQueryKey: "organization",
        type: "text",
        typeLabel: "Text",
        placeholder: "Organization",
        required: false,
        standard: true,
        icon: () => h("img", { src: OrganizationIcon }),
        category: "address",
        title: "Organization",
      },
      {
        label: "Website",
        tag: "website",
        hiddenFieldQueryKey: "website",
        type: "text",
        typeLabel: "Text",
        placeholder: "Web URL goes here",
        standard: true,
        icon: () => h("img", { src: WebsiteIcon }),
        category: "address",
        title: "Website",
      },
    ],
  },
  text: {
    label: `${t("common.text")}`,
    group: [
      {
        label: "Single Line",
        tag: "single_line",
        hiddenFieldQueryKey: "single_line",
        type: "text",
        dataType: "TEXT",
        typeLabel: "Single Line",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: SingleLineIcon }),
        category: "text",
      },
      {
        label: "Multi Line",
        tag: "multi_line",
        hiddenFieldQueryKey: "multi_line",
        type: "large_text",
        dataType: "LARGE_TEXT",
        typeLabel: "Multi Line",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: MultiLineIcon }),
        category: "text",
      },
      {
        label: "Text Box List",
        tag: "textbox_list",
        hiddenFieldQueryKey: "textbox_list",
        type: "textbox_list",
        dataType: "TEXTBOX_LIST",
        typeLabel: "Text Box List",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: TextBoxIcon }),
        category: "text",
        picklistOptions: [
          {
            label: "Option 1",
            prefillValue: "",
          },
          {
            label: "Option 2",
            prefillValue: "",
          },
          {
            label: "Option 3",
            prefillValue: "",
          },
        ],
      },
    ],
  },
  choiceElements: {
    label: "Choice Elements",
    group: [
      {
        label: "Single Dropdown",
        tag: "dropdown",
        hiddenFieldQueryKey: "dropdown",
        type: "single_options",
        dataType: "SINGLE_OPTIONS",
        typeLabel: "Single Dropdown",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: DropdownIcon }),
        category: "choiceElements",
        picklistOptions: ["Option 1", "Option 2", "Option 3"],
      },
      {
        label: "Multi Dropdown",
        tag: "dropdown_multi",
        hiddenFieldQueryKey: "dropdown_multi",
        type: "multiple_options",
        dataType: "MULTIPLE_OPTIONS",
        typeLabel: "Multi Dropdown",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: DropdownIcon }),
        category: "choiceElements",
        picklistOptions: ["Option 1", "Option 2", "Option 3"],
      },
      {
        label: "Checkbox",
        tag: "checkbox",
        hiddenFieldQueryKey: "checkbox",
        type: "checkbox",
        dataType: "CHECKBOX",
        typeLabel: "Checkbox",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: CheckboxIcon }),
        category: "choiceElements",
        picklistOptions: ["Option 1", "Option 2", "Option 3"],
      },
      {
        label: "Radio",
        tag: "radio",
        hiddenFieldQueryKey: "radio",
        type: "radio",
        dataType: "RADIO",
        typeLabel: "Radio",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: RadioIcon }),
        category: "choiceElements",
        picklistOptions: ["Option 1", "Option 2", "Option 3"],
      },
    ],
  },
  customized: {
    label: `${t("common.customized")}`,
    group: [
      {
        label: "Text",
        tag: "header",
        hiddenFieldQueryKey: "header",
        type: "h1",
        placeholder: "header",
        color: "000000",
        fontFamily: "Roboto",
        typeLabel: "Text",
        fontSize: 40,
        weight: 400,
        bgColor: "FFFFFF",
        align: "left",
        shadow: defaultShadow,
        padding: defaultPadding,
        border: defaultBorder,
        standard: true,
        icon: () => h("img", { src: TextIcon }),
        category: "customized",
      },
      {
        label: "Html",
        tag: "html",
        hiddenFieldQueryKey: "html",
        type: "html",
        placeholder: "The Custom HTML goes here",
        html: "",
        standard: true,
        icon: () => h("img", { src: HtmlIcon }),
        category: "customized",
      },
      {
        label: "Captcha",
        tag: "captcha",
        hiddenFieldQueryKey: "captcha",
        type: "captcha",
        placeholder: "Captcha",
        standard: true,
        icon: () => h("img", { src: CaptchaIcon }),
        category: "customized",
      },
      {
        label: "Source",
        tag: "source",
        hiddenFieldQueryKey: "source",
        type: "source",
        value: "",
        standard: true,
        icon: () => h("img", { src: SourceIcon }),
        category: "customized",
      },
      {
        label: "T & C",
        tag: "terms_and_conditions",
        type: "terms_and_conditions",
        required: true,
        hiddenFieldQueryKey: "terms_and_conditions",
        textColor: "000000",
        linkColor: "188bf6",
        placeholder: `I agree to terms & conditions provided by the company. By providing my phone number, I agree to receive text messages from the business.`,
        preview: `<span style="color: #000000;">I agree to terms &amp; conditions provided by the company. By providing my phone number, I agree to receive text messages from the business.</span>`,
        standard: true,
        icon: h("img", { src: TermsAndConditionsIcon }),
        category: "customized",
      },
      {
        label: "Score",
        tag: "score",
        hiddenFieldQueryKey: "score",
        dataType: "SCORE",
        typeLabel: "Score",
        type: "score",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: FileUploadIcon }),
        category: "other",
      },
    ],
  },
  other: {
    label: "Other Elements",
    group: [
      {
        label: "Image",
        tag: "image",
        hiddenFieldQueryKey: "image",
        type: "img",
        placeholder: "",
        standard: true,
        icon: () => h("img", { src: ImageIcon }),
        category: "other",
      },
      {
        label: "File Upload",
        tag: "file_upload",
        hiddenFieldQueryKey: "file_upload",
        dataType: "FILE_UPLOAD",
        typeLabel: "File Upload",
        type: "file_upload",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: FileUploadIcon }),
        category: "other",
      },
      {
        label: "Monetary",
        tag: "monetory",
        hiddenFieldQueryKey: "monetory",
        type: "monetory",
        dataType: "MONETORY",
        typeLabel: "Monetary",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: MonetaryIcon }),
        category: "other",
      },
      {
        label: "Number",
        tag: "text",
        hiddenFieldQueryKey: "number",
        type: "numerical",
        dataType: "NUMERICAL",
        typeLabel: "Number",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: MonetaryIcon }),
        category: "other",
      },
      {
        label: "Date Picker",
        tag: "date",
        hiddenFieldQueryKey: "date",
        type: "date",
        dataType: "DATE",
        typeLabel: "Date Picker",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: DatePickerIcon }),
        category: "other",
      },
      {
        label: "Signature",
        tag: "signature",
        hiddenFieldQueryKey: "signature",
        type: "signature",
        dataType: "SIGNATURE",
        typeLabel: "Signature",
        placeholder: "",
        required: false,
        standard: false,
        custom: true,
        icon: () => h("img", { src: SignatureIcon }),
        category: "other",
      },
    ],
  },
};

export const addressFields = standardFields.address.group.filter(
  (field) =>
    field.tag !== "group_address" && field.tag !== "website" && field.tag !== "organization"
);

export function getConstructedQueryKey(name) {
  return name.toLowerCase().trim().split(" ").join("_");
}

export function getAllFontsImport(fonts, isGDPRCompliant) {
  let fontParams = isGDPRCompliant
    ? "@import url('https://fonts.bunny.net/css?family="
    : "@import url('https://fonts.googleapis.com/css?family=";
  const fontLength = fonts.length;
  fonts.forEach((font: string, index) => {
    fontParams +=
      font +
      ":100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" +
      (fontLength !== index + 1 ? "|" : "");
  });
  fontParams = fontParams + "');\n";
  return fontParams;
}

export function getAllUniqueFonts(fieldStyle, submitMessageStyle, elements, isForm, footerFont) {
  const finalFontCollection = [];
  if (fieldStyle.labelFontFamily && !finalFontCollection.includes(fieldStyle.labelFontFamily)) {
    finalFontCollection.push(fieldStyle.labelFontFamily);
  }
  if (
    fieldStyle.mobileLabelFontFamily &&
    !finalFontCollection.includes(fieldStyle.mobileLabelFontFamily)
  ) {
    finalFontCollection.push(fieldStyle.mobileLabelFontFamily);
  }
  if (
    fieldStyle.placeholderFontFamily &&
    !finalFontCollection.includes(fieldStyle.placeholderFontFamily)
  ) {
    finalFontCollection.push(fieldStyle.placeholderFontFamily);
  }
  if (
    fieldStyle.mobilePlaceholderFontFamily &&
    !finalFontCollection.includes(fieldStyle.mobilePlaceholderFontFamily)
  ) {
    finalFontCollection.push(fieldStyle.mobilePlaceholderFontFamily);
  }
  if (
    fieldStyle.shortLabel?.fontFamily &&
    !finalFontCollection.includes(fieldStyle.shortLabel.fontFamil)
  ) {
    finalFontCollection.push(fieldStyle.shortLabel.fontFamily);
  }
  if (
    fieldStyle.shortLabel?.mobileFontFamily &&
    !finalFontCollection.includes(fieldStyle.shortLabel.mobileFontFamily)
  ) {
    finalFontCollection.push(fieldStyle.shortLabel.mobileFontFamily);
  }
  if (
    submitMessageStyle.fontFamily &&
    !finalFontCollection.includes(submitMessageStyle.fontFamily)
  ) {
    finalFontCollection.push(submitMessageStyle.fontFamily);
  }

  if (isForm) {
    elements.forEach((field) => {
      if (field.tag === "header" && !finalFontCollection.includes(field.fontFamily)) {
        finalFontCollection.push(field.fontFamily);
      }
      if (field.tag === "button") {
        if (!finalFontCollection.includes(field.fontFamily)) {
          finalFontCollection.push(field.fontFamily);
        }
        if (!finalFontCollection.includes(field.subTextFontFamily)) {
          finalFontCollection.push(field.subTextFontFamily);
        }
      }
    });
  } else {
    elements.forEach((slide) => {
      slide.slideData.forEach((field) => {
        if (field.tag === "header" && !finalFontCollection.includes(field.fontFamily)) {
          finalFontCollection.push(field.fontFamily);
        }
      });
    });
    if (footerFont && !finalFontCollection.includes(footerFont)) {
      finalFontCollection.push(footerFont);
    }
  }
  return finalFontCollection;
}
export const applyStyles = (
  html,
  linkColor = DEFAULT_LABEL_COLOR,
  textColor = DEFAULT_TEXT_COLOR
) => {
  const styledHTML = html
    ? html
        .replace(/<a /g, `<a style="color: #${linkColor}; text-decoration: none;" `)
        .replace(/\|/g, `<br><input type="checkbox" style="position: absolute; left: 0;">`)
    : html;
  return `<span style="color: #${textColor};">${styledHTML}</span>`;
};

export const generateFooterStyle = (store) => {
  const footerBorderRadius = `${store.formStyle?.border?.radius}px`;
  const footerStyle = store?.footerStyle;
  const style = store?.formStyle;
  const stickyShadow =
    style?.shadow?.horizontal +
    "px " +
    style?.shadow?.vertical +
    "px " +
    style?.shadow?.blur +
    "px " +
    style?.shadow?.spread +
    "px #" +
    style?.shadow?.color;

  const progressBarStyle = store?.footerStyle.progressBarStyle;

  const buttonStyle = store?.footerStyle?.buttonStyle;

  const containerStyle = {
    color: `#${buttonStyle.fontColor}`,
    justifyContent: "flex-end", // Default value
    position: store?.footerStyle?.stickyFooter ? "static" : "absolute",
    height: `${store?.footerStyle?.height}px`,
  };

  switch (buttonStyle.position) {
    case RIGHT:
      containerStyle.justifyContent = "flex-end";
      break;
    case LEFT:
      containerStyle.justifyContent = "flex-start";
      break;
    case LEFT_AND_RIGHT:
      containerStyle.justifyContent = "space-between";
      break;
    default:
      containerStyle.justifyContent = "flex-end";
  }

  const loaderStyle: any = {
    transform: "translate(-50%, -50%)",
  };
  if (footerStyle.stickyFooter) {
    loaderStyle.transform = "translate(-50%, 20%)";
    loaderStyle.position = "relative";
    if (buttonStyle.position === LEFT) {
      loaderStyle.left = "38%";
    } else {
      loaderStyle.right = "30px";
    }
  } else {
    loaderStyle.position = "absolute";
    loaderStyle.top = "50%";
    if (buttonStyle.position === LEFT) {
      loaderStyle.left = "15%";
    } else {
      loaderStyle.right = "35px";
    }
  }
  const formStyle = store?.formStyle;
  const boxShadow = `${formStyle?.shadow?.horizontal}px ${formStyle?.shadow?.vertical}px ${formStyle?.shadow?.blur}px ${formStyle?.shadow?.spread}px #${formStyle?.shadow?.color}`;
  const borderRadius = `${formStyle?.border?.radius}px`;
  const styles = {
    footer: {
      position: footerStyle.stickyFooter ? "static" : "fixed",
      backgroundColor: `#${footerStyle.backgroundFill}`,
      height: `${footerStyle.height}px`,
      fontSize: `${footerStyle.fontSize}px`,
      fontWeight: footerStyle.fontWeight,
      fontFamily: footerStyle.fontFamily,
      boxShadow: footerStyle.stickyFooter ? stickyShadow : DEFAULT_FOOTER_SHADOW,
      webkitBoxShadow: footerStyle.stickyFooter ? stickyShadow : DEFAULT_FOOTER_SHADOW,
      borderRadius: footerStyle.stickyFooter
        ? `0 0 ${footerBorderRadius} ${footerBorderRadius}`
        : 0,
    },
    progressBar: {
      barStyle: {
        border: `${progressBarStyle.borderWidth}px solid #${progressBarStyle.borderColor}`,
        borderRadius: `${progressBarStyle.borderRadius}px`,
        backgroundColor: `#${progressBarStyle.inactiveFillColor}`,
      },
      fillStyle: {
        backgroundColor: `#${progressBarStyle.completeFillColor}`,
        color: `#${progressBarStyle.textColor}`,
        borderRadius:
          progressBarStyle.borderRadius < 16
            ? `${progressBarStyle.borderRadius - 4}px 0 0 ${progressBarStyle.borderRadius - 4}px`
            : `${progressBarStyle.borderRadius}px 0 0 ${progressBarStyle.borderRadius}px`,
      },
      textStyle: { color: `#${progressBarStyle.textColor}` },
    },
    container: containerStyle,
    nextButton: {
      backgroundColor: `#${buttonStyle.nextButtonBgColor}`,
      borderRadius:
        store?.footerStyle?.stickyFooter && [RIGHT, LEFT_AND_RIGHT].includes(buttonStyle.position)
          ? `0 0 ${store.formStyle?.border?.radius}px 0`
          : 0,
    },
    submitButton: {
      backgroundColor: `#${buttonStyle.submitButtonBgColor}`,
      borderRadius:
        store?.footerStyle?.stickyFooter && [RIGHT, LEFT_AND_RIGHT].includes(buttonStyle.position)
          ? `0 0 ${store.formStyle?.border?.radius}px 0`
          : 0,
    },
    backButton: {
      backgroundColor: `#${buttonStyle.backButtonBgColor}`,
      opacity: 0.8,
      borderRadius:
        store?.footerStyle?.stickyFooter && [LEFT, LEFT_AND_RIGHT].includes(buttonStyle.position)
          ? `0 0 0 ${store.formStyle?.border?.radius}px`
          : 0,
    },
    icon: {
      verticalAlign: "text-bottom",
      width: `${buttonStyle.fontSize * 1.2}px`,
      height: `${buttonStyle.fontSize * 1.2}px`,
    },
    loader: loaderStyle,
    surveyWrapper: {
      boxShadow: store?.footerStyle?.stickyFooter ? boxShadow : 0,
      borderRadius: store?.footerStyle?.stickyFooter ? borderRadius : 0,
      marginBottom: `${footerStyle?.height || 60}px`,
    },
  };

  const showArrow = [ONLY_ARROW, TEXT_AND_ARROW].includes(buttonStyle.buttonType);
  const showText = [ONLY_TEXT, TEXT_AND_ARROW].includes(buttonStyle.buttonType);

  return {
    styles,
    showArrow,
    showText,
  };
};

export const allOperationOptions = [
  {
    label: t("form.conditonalLogic.allOperationOptions.isEqualTo"),
    value: "isEqualTo",
    types: [
      "text",
      "large_text",
      "phone",
      "email",
      "single_options",
      "select",
      "radio",
      "monetory",
      "date",
      "numerical",
      "score",
    ],
  },
  {
    label: t("form.conditonalLogic.allOperationOptions.isNotEqualTo"),
    value: "isNotEqualTo",
    types: [
      "text",
      "large_text",
      "phone",
      "email",
      "single_options",
      "select",
      "radio",
      "monetory",
      "date",
      "numerical",
      "score",
    ],
  },
  {
    label: t("form.conditonalLogic.allOperationOptions.contains"),
    value: "contains",
    types: ["text", "large_text", "phone", "email"],
  },
  {
    label: t("form.conditonalLogic.allOperationOptions.startsWith"),
    value: "startsWith",
    types: ["text", "large_text", "phone", "email"],
  },
  {
    label: t("form.conditonalLogic.allOperationOptions.endsWith"),
    value: "endsWith",
    types: ["text", "large_text", "phone", "email"],
  },
  {
    label: t("form.conditonalLogic.allOperationOptions.isEmpty"),
    value: "isEmpty",
    types: [
      "text",
      "large_text",
      "phone",
      "email",
      "date",
      "single_options",
      "select",
      "radio",
      "numerical",
      "monetory",
      "score",
    ],
  },
  {
    label: t("form.conditonalLogic.allOperationOptions.isFilled"),
    value: "isFilled",
    types: [
      "text",
      "large_text",
      "phone",
      "email",
      "date",
      "single_options",
      "select",
      "radio",
      "numerical",
      "monetory",
      "score",
    ],
  },
  { label: t("form.conditonalLogic.allOperationOptions.before"), value: "before", types: ["date"] },
  { label: t("form.conditonalLogic.allOperationOptions.after"), value: "after", types: ["date"] },
  {
    label: t("form.conditonalLogic.allOperationOptions.greaterThan"),
    value: "greaterThan",
    types: ["numerical", "monetory", "score"],
  },
  {
    label: t("form.conditonalLogic.allOperationOptions.lessThan"),
    value: "lessThan",
    types: ["numerical", "monetory", "score"],
  },
];

export const camelToPascal = (str) => {
  return str?.split(/(?=[A-Z])/)?.join(" ");
};

export const isPaymentGatewayConnected = (paymentIntegration, isLive) => {
  let isConnected = false;
  console.log(paymentIntegration, "paymentIntegration");
  Object.entries(paymentIntegration).forEach(([key, value]: [string, any]) => {
    if (validateAllPaymentIntegration(key, value, isLive)) {
      isConnected = true;
      return true;
    }
  });
  return isConnected;
};

const validateAllPaymentIntegration = (gateway, paymentObj, isLive) => {
  if (gateway === "stripe" && isLive && paymentObj.live.publishableKey) {
    return true;
  }
  if (gateway === "stripe" && !isLive && paymentObj.test.publishableKey) {
    return true;
  }
  if (gateway === "nmi" && isLive && paymentObj.live.merchantPublicKey) {
    return true;
  }
  if (gateway === "nmi" && !isLive && paymentObj.test.merchantPublicKey) {
    return true;
  }
  if (gateway === "authorizeNet" && isLive && paymentObj.live.merchantPublicClientKey) {
    return true;
  }
  if (gateway === "authorizeNet" && !isLive && paymentObj.test.merchantPublicClientKey) {
    return true;
  }
  if (gateway === "square" && isLive && paymentObj.live.merchantGatewayId) {
    return true;
  }
  if (gateway === "square" && !isLive && paymentObj.test.merchantGatewayId) {
    return true;
  }
  if (gateway === "paypal" && isLive && paymentObj.live.paypalPublishableKey) {
    return true;
  }
  if (gateway === "paypal" && !isLive && paymentObj.test.paypalPublishableKey) {
    return true;
  }
};

export const convertToNumbers = (arrayOfNumberLikeStrings) => {
  const numbers = [];
  arrayOfNumberLikeStrings.forEach((number) => {
    const parsedNumber = parseFloat(parseFloat(number).toFixed(2));
    if (parsedNumber && parsedNumber > 0) {
      return numbers.push(parsedNumber);
    }
  });
  return numbers;
};

export const convertToStrings = (arrayOfNumbers) => {
  return arrayOfNumbers.map((number) => {
    return number.toString();
  });
};

export const showFieldLabel = (formLabelVisible, item: any) => {
  if (
    formLabelVisible &&
    (item.type === "text" ||
      item.type === "email" ||
      item.type === "captcha" ||
      item.type === "date" ||
      item.type === "select" ||
      item.type === "radio" ||
      item.type === "payment")
  ) {
    return true;
  }
  return false;
};

export const dateFormatForUI = (format = "YYYY-MM-DD", separator = "-") => {
  return (format.replace(/-/g, separator) || "YYYY-MM-DD").toLowerCase().replace(/mm/g, "MM");
};

export const dateFormatForDayjs = (format = "YYYY-MM-DD", separator = "-") => {
  return format.replace(/-/g, separator) || "YYYY-MM-DD";
};

export const fieldFullWidth = (type: string, isLayoutSingleLine = false) => {
  if (isLayoutSingleLine && type === "submit") {
    return false;
  }
  if (FULL_WIDTH_FIELDS.indexOf(type) >= 0) {
    return true;
  }
  return false;
};
