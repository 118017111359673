<script setup lang="ts">
import { useEmitter } from "@/composition/useEmitter";
import { LocationService } from "@/service/LocationServices";
import { useAppStore } from "@/store/app";
import { JUMP_TO_FIELDS } from "@/util/constants";
import { getAllFontsImport, getConstructedQueryKey } from "@/util/methods";
import {
  DotsHorizontalIcon,
  PlusCircleIcon,
  Settings02Icon,
  Trash04Icon,
} from "@gohighlevel/ghl-icons/24/outline";
import {
  renderIcon,
  UICard,
  UIDropdown,
  UITextSmMedium,
  UITextXsMedium,
} from "@gohighlevel/ghl-ui";
import { cloneDeep } from "lodash";
import { storeToRefs } from "pinia";
import uuid4 from "uuid4";
import { computed, h, onMounted, ref, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import { useI18n } from "vue-i18n";
import AlertModal from "../common/AlertModal.vue";
import BuilderBranding from "../common/BuilderBranding.vue";
import CustomFieldsPreview from "../common/CustomFieldsPreview.vue";
import Footer from "../common/Footer.vue";
import HLImage from "../common/HLImage.vue";
import StandardFieldPreview from "../common/StandardFieldPreview.vue";

const { t } = useI18n();
const store = useAppStore();
const emitter = useEmitter();
const { elements } = storeToRefs(store);
const currentElement = ref({ label: "" });
const globalScriptCSSStyle = ref({ textContent: null });
const globalScriptCSSLabelHolder = ref({ textContent: null });
const globalScriptCSSPlaceHolder = ref({ textContent: null });
const globalScriptCSSFieldBGColor = ref({ textContent: null });
const globalScriptCSSFieldBorder = ref({ textContent: null });
const globalScriptCSSFieldShadow = ref({ textContent: null });
const globalScriptCSSFieldWidth = ref({ textContent: null });
const globalScriptCSSFieldPadding = ref({ textContent: null });
const globalScriptCSSFontHolder = ref({ textContent: null });

const globalScriptCSSShortLabelHolder = ref({ textContent: null });

const openAlertModal = ref(false);
const slideDeleteMessage = ref("Are you sure you want to delete store slide?");
const slideToBeRemoved = ref(undefined);

const formWidth = computed(() => {
  return store.deviceMode === "mobile" ? 400 : store.formStyle.width;
});

const formStyle = computed(() => {
  const style = store.formStyle;
  const padding = store.deviceMode === "mobile" ? style?.mobilePadding : style?.padding;

  return {
    padding: `${padding?.top}px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
    backgroundColor: `#${style?.background}`,
    border: `${style?.border?.border}px ${style?.border?.style} #${style?.border?.color}`,
    borderRadius: `${style?.border?.radius}px`,
    boxShadow: `${style?.shadow?.horizontal}px ${style?.shadow?.vertical}px ${style?.shadow?.blur}px ${style?.shadow?.spread}px #${style?.shadow?.color}`,
    width: `${formWidth.value}px`,
  };
});

const clickedOnElement = (e, element, elementId, slideId) => {
  e.stopPropagation();
  store.sidebarOverlaySettings = { elementId, slideId, formIndex: undefined };
  currentElement.value = element;
  store.isAnyElementActive = true;
  store.currentActiveIndex = [slideId, elementId];
  store.currentSlideIndex = slideId;
};

const fieldStyle = computed(() => {
  const fieldStyleStore = store.fieldStyle;
  const style = {
    padding: `${fieldStyleStore?.padding?.top}px ${fieldStyleStore?.padding?.right}px ${fieldStyleStore?.padding?.bottom}px ${fieldStyleStore?.padding?.left}px`,
    backgroundColor: "#" + fieldStyleStore?.bgColor,
    border: `${fieldStyleStore?.border?.border}px ${
      fieldStyleStore?.border?.type === "none" ? "" : fieldStyleStore?.border?.type
    } #${fieldStyleStore?.border?.color}`,
    borderRadius: fieldStyleStore?.border?.radius + "px",
    boxShadow: `${fieldStyleStore?.shadow?.horizontal}px ${fieldStyleStore?.shadow?.vertical}px ${fieldStyleStore?.shadow?.blur}px ${fieldStyleStore?.shadow?.spread}px #${fieldStyleStore?.shadow?.color}`,
    width: fieldStyleStore?.width + "px",
    color: "#" + fieldStyleStore?.labelColor,
    font: `${fieldStyleStore.labelFontSize}px  ${fieldStyleStore.labelFontWeight} ${fieldStyleStore.labelFontFamily}`,
  };
  return style;
});

onMounted(() => {
  const globalScriptCSS = document.createElement("style");
  globalScriptCSS.type = "text/css";

  // Google and bunny font css script
  const globalScriptFontFamily = document.createElement("style");
  globalScriptFontFamily.type = "text/css";
  globalScriptFontFamily.appendChild(document.createTextNode(""));
  globalScriptCSSFontHolder.value = globalScriptFontFamily.childNodes[0];
  document.head.appendChild(globalScriptFontFamily);

  for (let i = 0; i < 9; i++) {
    globalScriptCSS.appendChild(document.createTextNode(""));
  }
  globalScriptCSSStyle.value = globalScriptCSS.childNodes[0];
  //For Label CSS Change
  globalScriptCSSLabelHolder.value = globalScriptCSS.childNodes[1];
  //For Placeholder CSS Change
  globalScriptCSSPlaceHolder.value = globalScriptCSS.childNodes[2];
  globalScriptCSSFieldBGColor.value = globalScriptCSS.childNodes[3];
  globalScriptCSSFieldBorder.value = globalScriptCSS.childNodes[4];
  globalScriptCSSFieldShadow.value = globalScriptCSS.childNodes[5];
  globalScriptCSSFieldWidth.value = globalScriptCSS.childNodes[6];
  globalScriptCSSFieldPadding.value = globalScriptCSS.childNodes[7];
  globalScriptCSSShortLabelHolder.value = globalScriptCSS.childNodes[8];

  document.head.appendChild(globalScriptCSS);
  globalScriptCSSFontHolder.value.textContent = getAllFontsImport(
    store.fontCollection,
    store.builderConfig.isGDPRCompliant
  );
  setGlobalStyleInHTMl();
});

const setGlobalStyleInHTMl = () => {
  const fieldStyle = store.fieldStyle;
  const formStyle = store.formStyle;
  const isMobile = store.deviceMode === "mobile";

  const getMobileOrDesktop = (mobileVal, desktopVal) =>
    isMobile ? mobileVal || desktopVal : desktopVal;

  const labelColor = getMobileOrDesktop(fieldStyle.mobileLabelColor, fieldStyle.labelColor);
  const labelFontSize = getMobileOrDesktop(
    fieldStyle.mobileLabelFontSize,
    fieldStyle.labelFontSize
  );
  const labelFontWeight = getMobileOrDesktop(
    fieldStyle.mobileLabelFontWeight,
    fieldStyle.labelFontWeight
  );
  const labelFontFamily = getMobileOrDesktop(
    fieldStyle.mobileLabelFontFamily,
    fieldStyle.labelFontFamily
  );

  const placeholderColor = getMobileOrDesktop(
    fieldStyle.mobilePlaceholderColor,
    fieldStyle.placeholderColor
  );
  const placeholderFontFamily = getMobileOrDesktop(
    fieldStyle.mobilePlaceholderFontFamily,
    fieldStyle.placeholderFontFamily
  );
  const placeholderFontSize = getMobileOrDesktop(
    fieldStyle.mobilePlaceholderFontSize,
    fieldStyle.placeholderFontSize
  );
  const placeholderFontWeight = getMobileOrDesktop(
    fieldStyle.mobilePlaceholderFontWeight,
    fieldStyle.placeholderFontWeight
  );

  const shortLabelColor = getMobileOrDesktop(
    fieldStyle.shortLabel?.mobileColor,
    fieldStyle.shortLabel?.color
  );
  const shortLabelFontSize = getMobileOrDesktop(
    fieldStyle.shortLabel?.mobileFontSize,
    fieldStyle.shortLabel?.fontSize
  );
  const shortLabelFontWeight = getMobileOrDesktop(
    fieldStyle.shortLabel?.mobileFontWeight,
    fieldStyle.shortLabel?.fontWeight
  );
  const shortLabelFontFamily = getMobileOrDesktop(
    fieldStyle.shortLabel?.mobileFontFamily,
    fieldStyle.shortLabel?.fontFamily
  );

  globalScriptCSSLabelHolder.value.textContent = `#_builder-form label {
      color: #${labelColor};
      font-size: ${labelFontSize}px;
      font-weight: ${labelFontWeight};
      font-family: '${labelFontFamily}';
      line-height: 1.8;
    }
    #_builder-form .tnc {
      font-family: '${labelFontFamily}';
    }
    `;

  globalScriptCSSPlaceHolder.value.textContent = `#_builder-form ::placeholder, #_builder-form .form-dropdown a,
      #_builder-form .form-dropdown .dropdown-toggle, #_builder-form .form-placeholder , #_builder-form .currency-code { 
        color : #${placeholderColor}; 
        font-family:'${placeholderFontFamily}'; 
        font-size:${placeholderFontSize}px; 
        font-weight: ${placeholderFontWeight};
      }`;
  globalScriptCSSFieldBGColor.value.textContent = `#_builder-form .form-control, .form-control:disabled, .form-control[readonly], #_builder-form .form-dropdown{ background-color : #${fieldStyle.bgColor};}`;
  globalScriptCSSFieldBorder.value.textContent =
    "  #_builder-form .form-control, .form-control:disabled, .form-control[readonly], #_builder-form .form-dropdown { border:" +
    fieldStyle.border.border +
    "px " +
    fieldStyle.border.type +
    " #" +
    fieldStyle.border.color +
    ";border-radius:" +
    fieldStyle.border.radius +
    "px ; }" +
    ` .line-form-style { border-bottom: ${fieldStyle.border.border}px ${fieldStyle.border.type} #${fieldStyle.border.color} !important; background-color: #${fieldStyle.bgColor} !important;}`;
  globalScriptCSSFieldPadding.value.textContent =
    "  #_builder-form .form-control, .form-control:disabled, .form-control[readonly], #_builder-form .form-dropdown { padding:" +
    fieldStyle.padding.top +
    "px " +
    fieldStyle.padding.right +
    "px " +
    fieldStyle.padding.bottom +
    "px " +
    fieldStyle.padding.left +
    "px}";
  globalScriptCSSFieldShadow.value.textContent =
    "  #_builder-form .form-control, .form-control:disabled, .form-control[readonly], #_builder-form .form-dropdown { box-shadow:" +
    fieldStyle.shadow.horizontal +
    "px " +
    fieldStyle.shadow.vertical +
    "px " +
    fieldStyle.shadow.blur +
    "px " +
    fieldStyle.shadow.spread +
    "px #" +
    fieldStyle.shadow.color +
    "}";
  globalScriptCSSFieldWidth.value.textContent =
    "  #_builder-form .form-control, .form-control:disabled, .form-control[readonly], #_builder-form .form-dropdown {max-width:" +
    fieldStyle.width +
    "px}";
  globalScriptCSSShortLabelHolder.value.textContent = `#_builder-form .short-label, #_builder-form .short-label::placeholder {
      color: #${shortLabelColor} !important;
      font-size: ${shortLabelFontSize}px !important;
      font-weight: ${shortLabelFontWeight};
      font-family: '${shortLabelFontFamily}';
  }`;
  const styleStore = formStyle.customStyle.split("}");
  let styleString = "";
  styleStore.map(function (value: string) {
    if (value.includes("footer")) {
      styleString += `${value}}`;
    } else if (value !== "" && value.length > 0) {
      styleString += "#_builder-form " + value + "} ";
    }
  });
  globalScriptCSSStyle.value.textContent = styleString;
};

//Store Subscription to watch on store value change using patch
store.$subscribe(() => {
  setGlobalStyleInHTMl();
});

watch([store.fontCollection, () => store.builderConfig.isGDPRCompliant], () => {
  globalScriptCSSFontHolder.value.textContent = getAllFontsImport(
    store.fontCollection,
    store.builderConfig.isGDPRCompliant
  );
});

const updateElementProperty = (property: string, value: string, elementId: string) => {
  store.slides.forEach((slide) => {
    slide.slideData.forEach((element) => {
      if (element.hiddenFieldQueryKey === elementId) {
        element[property] = value;
      }
    });
  });
};

const updateLabel = (newLabel: string, elementId: string) => {
  updateElementProperty("label", newLabel, elementId);
  store.anyUnsavedChanges = true;
};

const updatePlaceholder = (newPlaceholder: string, elementId: string) => {
  updateElementProperty("placeholder", newPlaceholder, elementId);
  store.anyUnsavedChanges = true;
};

const updateShortLabel = (newShortLabel: string, elementId: string) => {
  updateElementProperty("shortLabel", newShortLabel, elementId);
  store.anyUnsavedChanges = true;
};

const isActiveElement = (slideId, elementId) => {
  return store.currentActiveIndex[0] === slideId && store.currentActiveIndex[1] === elementId;
};

const ChangeInSlideData = (e, slideIndex, slideId) => {
  const item = e.added?.element || e.moved?.element || e.removed?.element;
  const elementDrop = {
    ...cloneDeep(item),
    hiddenFieldQueryKey: item.id ? item.hiddenFieldQueryKey : item.tag,
    active: false,
  };
  if (e.added) {
    setTimeout(() => {
      if (
        store.slides[slideIndex].slideData.filter((element) => element.model === "opportunity")
          ?.length === 1
      ) {
        store.showOpportunitiesModal = true;
      }
    }, 1000);
    setTimeout(async () => {
      let isAlreadyPresent = false;
      let customElementCount = 0;
      store.slides.forEach((slide) => {
        slide.slideData.forEach((element) => {
          const isSameId = elementDrop?.id && element?.id === elementDrop?.id;
          const isSameTag = !elementDrop?.id && element.tag === elementDrop.tag;
          const isCustomOrDuplicateAllowed =
            elementDrop?.custom || store.duplicateAllowed.includes(elementDrop.tag);
          if ((isSameId || isSameTag) && !isCustomOrDuplicateAllowed) {
            isAlreadyPresent = true;
          }
          if (element.custom) {
            customElementCount += 1;
          }
        });
      });
      if (!isAlreadyPresent && e.added) {
        if (elementDrop?.custom) {
          if (!customElementCount && !store.parentFolderId) {
            store.parentFolderName = `Quiz | ${store.builder.name}`;
            const response = await LocationService.createCustomField(store.locationId, {
              name: store.parentFolderName,
              documentType: "folder",
              model: "contact",
            });
            store.parentFolderId = response.data?.customFieldFolder?.id;
          }
          const existingElementsCount = [
            ...store.customFields,
            ...store.slides[slideIndex].slideData,
          ].filter((element) => element.dataType === elementDrop.dataType)?.length;
          elementDrop.label = `${elementDrop.label} ${existingElementsCount + 1}${Math.random()
            .toString(36)
            .substr(2, 3)}`;
          elementDrop.hiddenFieldQueryKey = getConstructedQueryKey(elementDrop.label);
          elementDrop.parentId = store.parentFolderId;
        }
        delete elementDrop.icon;
        delete elementDrop.category;
        elementDrop.uuid = uuid4();
        const isJumpToFieldsExists = store.slides[slideIndex].slideData.filter((field) =>
          JUMP_TO_FIELDS.includes(field.dataType)
        );
        if (isJumpToFieldsExists.length >= 1 && JUMP_TO_FIELDS.includes(elementDrop.dataType)) {
          const nextSlideIndex = slideIndex;
          addSlideBelow(slideIndex);
          store.slides[nextSlideIndex + 1].slideData.splice(e.added.newIndex, 0, elementDrop);
        } else {
          store.slides[slideIndex].slideData.splice(e.added.newIndex, 0, elementDrop);
        }

        if (
          elementDrop.model === "opportunity" &&
          store.opportunitySettings.payload.customFields.length
        ) {
          store.opportunitySettings.payload.customFields.push({
            id: elementDrop.Id,
            key: elementDrop.fieldKey.replace("opportunity.", ""),
          });
        }
      }
      store.addToHistory();
    }, 200);
  }

  if (e.removed) {
    store.slides[slideIndex].slideData.splice(e.removed.oldIndex, 1);
    store.addToHistory();
  }
  if (e.moved) {
    const oldElement = store.slides[slideIndex].slideData[e.moved.newIndex];
    const draggedElement = store.slides[slideIndex].slideData[e.moved.oldIndex];
    store.slides[slideIndex].slideData[e.moved.newIndex] = draggedElement;
    store.slides[slideIndex].slideData[e.moved.oldIndex] = oldElement;
    store.addToHistory();
  }
  store.anyUnsavedChanges = true;
};

const addSlideBelow = (index) => {
  const newSlide = {
    slideName: getSuccessorIndex(),
    slideData: [],
    id: uuid4(),
  };
  store.slides.splice(index + 1, 0, newSlide);
  store.formAction.fieldPerPage = store.slides.length;
  store.anyUnsavedChanges = true;
  emitter.emit("open-left-side-bar");
};

const slideChange = (e) => {
  allSlideInactive();
  store.isAnyElementActive = false;
  store.anyUnsavedChanges = true;
};

const getSuccessorIndex = () => {
  return "Page " + (store.slides.length + 1);
};

const allSlideInactive = () => {
  store.currentSlideIndex = undefined;
  store.currentActiveIndex = [undefined, undefined];
};

const removeSlide = (slideIndex) => {
  openAlertModal.value = true;
  slideToBeRemoved.value = slideIndex;
  if (slideIndex < store.slides.length - 1) {
    slideDeleteMessage.value = `${t("survey.slideDeleteBetweenAlertMessage")}`;
  } else {
    slideDeleteMessage.value = `${t("survey.slideDeleteAlertMessage")}`;
  }
};

const openSlideSettings = (slideId) => {
  store.sidebarOverlaySettings = {
    elementId: undefined,
    slideId: slideId,
    formIndex: undefined,
  };
  store.isAnyElementActive = true;
};

const feedback = (modalName, result) => {
  if (result) {
    store.slides.splice(slideToBeRemoved.value, 1);
    store.anyUnsavedChanges = true;
  }
  slideToBeRemoved.value = undefined;
  openAlertModal.value = false;
};
const popModalChange = () => {
  openAlertModal.value = false;
};

const options = computed(() => {
  return [
    {
      label: `${t("quiz.settings")}`,
      key: "settings",
      icon() {
        return h(renderIcon(Settings02Icon), null);
      },
    },
    {
      label: `${t("quiz.deletePage")}`,
      key: "delete",
      disabled: store.slides.length === 1,
      icon() {
        return h(renderIcon(Trash04Icon), { style: { color: "red" } });
      },
    },
  ];
});

const actionON = (key, slideId, slideIndex) => {
  if (key === "settings") {
    openSlideSettings(slideId);
  }
  if (key === "delete") {
    removeSlide(slideIndex);
  }
};

const clickedOutSideSlide = (e) => {
  store.currentCustomSidebarPreview = undefined;
  store.sidebarOverlaySettings = undefined;
  store.isAnyElementActive = false;
  allSlideInactive();
};

const applyInlineStyle = () => {
  return {
    marginBottom: store.formStyle.fieldSpacing + "px",
  };
};
</script>

<template>
  <section class="hl_wrapper d-flex survey-wrap-container" @click.prevent="clickedOutSideSlide">
    <section
      id="form-builder"
      class="hl_wrapper--inner form-builder"
      :style="{
        backgroundImage: `url(${
          store.deviceMode === 'mobile'
            ? store.formStyle?.mobileBgImage || store.formStyle?.bgImage
            : store.formStyle?.bgImage
        })`,
        backgroundSize: 'cover',
      }"
    >
      <section class="hl_form-builder--main">
        <form id="_builder-form" name="builder-form" class="builder-preview">
          <div class="form-child-wrapper">
            <div>
              <VueDraggableNext
                id="form-builder-container"
                :list="store.slides"
                :sort="true"
                group="slide"
                @change="slideChange"
              >
                <div
                  v-for="(itemSlide, indexSlide) in store.slides"
                  :key="indexSlide"
                  class="cursor-pointer"
                >
                  <div
                    class="survey-form-container flex"
                    :style="{
                      paddingBottom: indexSlide === store.slides.length - 1 ? '200px' : 0,
                    }"
                  >
                    <div class="builder-form-name">
                      <div
                        class="mb-1 flex h-10 items-center justify-between justify-items-center text-center"
                      >
                        <div class="flex items-center text-sm font-semibold">
                          {{ itemSlide?.slideName }}
                        </div>
                        <div>
                          <UIDropdown
                            :id="'dropdown-' + indexSlide"
                            :options="options"
                            trigger="hover"
                            size="small"
                            @select="(key) => actionON(key, itemSlide.id, indexSlide)"
                          >
                            <DotsHorizontalIcon
                              class="focus_outline_none h-4 w-4 cursor-pointer"
                            ></DotsHorizontalIcon>
                          </UIDropdown>
                        </div>
                      </div>
                      <div
                        v-if="
                          store.formAction?.headerImageSrc?.trim().length > 0 ||
                          store.formAction?.mobileHeaderImageSrc?.trim().length > 0
                        "
                      >
                        <div
                          class="ghl-header-banner w-full max-w-full"
                          :style="{
                            maxWidth: formWidth + 'px',
                          }"
                        >
                          <HLImage
                            :url="
                              store.deviceMode === 'mobile'
                                ? store.formAction?.mobileHeaderImageSrc ||
                                  store.formAction?.headerImageSrc
                                : store.formAction?.headerImageSrc
                            "
                            :width="store.formStyle.width"
                            alt="banner"
                          ></HLImage>
                        </div>
                      </div>
                      <div
                        id="the-form-builder"
                        class="form-element form-builder--wrap"
                        :style="formStyle"
                      >
                        <div class="drop form-builder-drop builder-padding-remove">
                          <VueDraggableNext
                            :model-value="itemSlide.slideData"
                            class="builder_sub_container"
                            :class="store.builderConfig.inlineForm ? 'row' : ''"
                            group="elements"
                            :sort="true"
                            @change="(e) => ChangeInSlideData(e, indexSlide, itemSlide.id)"
                          >
                            <div
                              v-for="(element, index) in itemSlide.slideData"
                              :key="`quiz-element-${
                                element.hiddenFieldQueryKey || element.fieldKey || index
                              }-${itemSlide.id}`"
                              class="smooth-dnd-draggable-wrapper element-box menu-field-wrap col-12"
                              :style="applyInlineStyle()"
                              @click="
                                (e) => clickedOnElement(e, element, element.uuid, itemSlide.id)
                              "
                            >
                              <div :class="{ active: isActiveElement(itemSlide.id, element.uuid) }">
                                <StandardFieldPreview
                                  v-if="!(element.id !== undefined || element?.custom)"
                                  :key="`std-field-${
                                    element.hiddenFieldQueryKey || element.fieldKey || index
                                  }-${itemSlide.id}`"
                                  :element="element"
                                  :index="index"
                                  :slide-index="indexSlide"
                                  :slide="itemSlide"
                                  :current-element="currentElement"
                                  :field-style="fieldStyle"
                                  :form-label-visible="store.builderConfig.formLabelVisible"
                                  @update:label="
                                    (label) => updateLabel(label, element.hiddenFieldQueryKey)
                                  "
                                  @update:placeholder="
                                    (placeholder) =>
                                      updatePlaceholder(placeholder, element.hiddenFieldQueryKey)
                                  "
                                  @update:short-label="
                                    (shortLabel) =>
                                      updateShortLabel(shortLabel, element.hiddenFieldQueryKey)
                                  "
                                ></StandardFieldPreview>
                                <CustomFieldsPreview
                                  v-if="element.id !== undefined || element?.custom"
                                  :key="`custom-field-${
                                    element.hiddenFieldQueryKey || element.fieldKey || index
                                  }-${itemSlide.id}`"
                                  :element="element"
                                  :index="index"
                                  :slide-index="indexSlide"
                                  :slide="itemSlide"
                                  :current-element="currentElement"
                                  :field-style="fieldStyle"
                                  :form-label-visible="store.builderConfig.formLabelVisible"
                                  :is-form="false"
                                  @update:label="
                                    (label) => updateLabel(label, element.hiddenFieldQueryKey)
                                  "
                                  @update:placeholder="
                                    (placeholder) =>
                                      updatePlaceholder(placeholder, element.hiddenFieldQueryKey)
                                  "
                                  @update:short-label="
                                    (shortLabel) =>
                                      updateShortLabel(shortLabel, element.hiddenFieldQueryKey)
                                  "
                                ></CustomFieldsPreview>
                              </div>
                            </div>
                            <section
                              v-if="itemSlide.slideData.length < 1"
                              class="flex flex-col items-center justify-center gap-3 self-stretch px-6 py-14"
                              @click="emitter.emit('open-left-side-bar')"
                            >
                              <div
                                class="shadow-xs flex items-center justify-center gap-2 rounded-md border border-gray-200 bg-white px-3.5 py-2"
                              >
                                <PlusCircleIcon class="h-4 w-4 text-gray-300"></PlusCircleIcon>
                                <UITextSmMedium class="text-gray-300">Add Questions</UITextSmMedium>
                              </div>
                              <UITextSmRegular class="text-gray-500"
                                >Drag and drop a Question Element here.</UITextSmRegular
                              >
                            </section>
                          </VueDraggableNext>
                          <BuilderBranding
                            :is-branding-active="store.formStyle.acBranding"
                            :company="store.company"
                          />
                        </div>
                      </div>
                      <div>
                        <Footer
                          v-if="store.footerStyle.stickyFooter"
                          :current-slide-index="indexSlide"
                          :is-last-slide-index="indexSlide === store.slides.length - 1"
                          :style="{ maxWidth: formWidth + 'px' }"
                        />
                        <Footer
                          v-if="!store.footerStyle.stickyFooter && store.deviceMode === 'mobile'"
                          :current-slide-index="indexSlide"
                          :is-last-slide-index="indexSlide === store.slides.length - 1"
                          :style="{ maxWidth: formWidth + 'px' }"
                        />
                      </div>
                      <section class="mt-4 flex justify-center">
                        <section
                          class="flex cursor-pointer items-center justify-center gap-2 rounded-full border border-gray-300 bg-white px-3.5 py-2"
                          @click="addSlideBelow(indexSlide)"
                        >
                          <PlusCircleIcon class="focus_outline_none block h-4 w-4"></PlusCircleIcon>
                          <UITextSmMedium class="text-gray-700">Add New Page</UITextSmMedium>
                        </section>
                      </section>
                      <section v-if="indexSlide === store.slides.length - 1" class="mt-4 w-full">
                        <UICard>
                          <div class="flex gap-x-8">
                            <img src="@/assets/img/template.png" class="h-14 w-20" />
                            <div>
                              <UITextSmMedium class="mb-2 text-gray-700"
                                >Result Template</UITextSmMedium
                              >
                              <UITextXsMedium
                                class="text-primary-700"
                                @click="store.openQuizResultBuilderModal"
                                >Edit Template</UITextXsMedium
                              >
                            </div>
                          </div>
                        </UICard>
                        <!-- <section
                          class="flex cursor-pointer items-center justify-center gap-2 rounded-full border border-gray-300 bg-primary-700 px-3.5 py-2"
                          @click="store.openQuizResultBuilderModal"
                        >
                          <Edit01Icon class="focus_outline_none block h-4 w-4 text-white" />
                          <UITextSmMedium class="text-white">Edit Result Template</UITextSmMedium>
                        </section> -->
                      </section>
                    </div>
                  </div>
                </div>
              </VueDraggableNext>
            </div>
          </div>
        </form>
      </section>
    </section>
    <Footer v-if="!store.footerStyle.stickyFooter && store.deviceMode === 'desktop'" />
  </section>
  <AlertModal
    v-if="openAlertModal"
    title="Alert"
    :description="slideDeleteMessage"
    modal-name="slideDelete"
    :show-modal-popup="openAlertModal"
    positive-text="Ok"
    negative-text="Cancel"
    header-type="error"
    footer-type="primary"
    @pop:change="popModalChange"
    @pop:positive="feedback"
    @pop:negative="feedback"
  ></AlertModal>
</template>

<style scoped>
.settings {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 5px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
  z-index: 100;
  position: absolute;
  margin-bottom: 10px;
}

.form-control {
  border-radius: 0.3125rem;
  padding: 15px 20px;
  font-size: 0.875rem;
}
.form-control:disabled,
.form-control [readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

#settings {
  font-size: 10px;
  height: 30px;
}

.builder-form-name {
  margin: 0 auto;
}

.icon-settings {
  display: flex;
  align-items: center;
}

.icon-settings svg {
  padding-right: 5px;
}

.builder-preview {
  min-height: 450px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

#form-builder {
  background-color: #f3f4f6;
  margin-bottom: 0px;
  overflow: auto;
  margin: 0 auto;
  height: calc(100vh - 52px);
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  width: 100%;
  /* Firefox */
}

#form-builder::-webkit-scrollbar {
  display: none;
}

.add_new_slides {
  text-align: center;
  margin-bottom: 20px;
}

.builder-item {
  cursor: pointer;
  width: 100%;
  background: grey;
  margin: 0.5rem 0;
  min-height: 3rem;
}

.builder_sub_container {
  min-height: 140px;
}

#_builder-form .active {
  outline: 1px solid #86b7fe;
  cursor: move;
}

.smooth-dnd-draggable-wrapper {
  cursor: move;
}

#_builder-form .main_active {
  border: 1px solid #168bf5;
}

.form-builder--wrap {
  border-radius: 4px;
  border: 1px dashed #cde0ec;
  padding: 30px 15px;
  background-color: #fff;
}

.survey-wrap-container .survey-form-container {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}

.form-builder--item {
  margin-bottom: 15px;
  border-radius: 4px;
  border: 2px solid transparent;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  position: relative;
}

.builder-inline .smooth-dnd-draggable-wrapper.col-md-6 .f-even .form-builder--item {
  padding-left: 4px !important;
}

.builder-inline .smooth-dnd-draggable-wrapper.col-md-6 .f-odd .form-builder--item {
  padding-left: 10px;
  padding-right: 4px;
}

.builder-inline .smooth-dnd-draggable-wrapper.col-12 .form-builder--item {
  padding-left: 10px;
  padding-right: 4px;
}

.form-builder--item h1,
.form-builder--item h2,
.form-builder--item h3,
.form-builder--item h4,
.form-builder--item h5,
.form-builder--item h6 {
  margin-bottom: 0;
}

.form-builder--item label {
  font-size: 0.875rem;
  color: #2a3135;
}

.captcha-wrap-img {
  width: 335px;
  display: block;
}

.button-element {
  min-width: 85px;
}

.menu-item {
  position: relative;
}

.menu-field-wrap {
  position: relative;
}

#the-form-builder {
}

.form-builder-drop {
  padding: 30px 15px;
}

.builder-padding-remove {
  padding: 30px 0;
}

.add-dropzone-builder {
  background: transparent;
  width: 100%;
  height: 86px;
  border-width: 1;
  border-style: solid;
  border-color: #e5e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6c757d !important;
}

.add-dropzone-builder p {
  margin-left: 5px !important;
}

#form-builder-container {
  height: 100%;
  min-height: 340px;
}

.slide_settings {
  position: absolute;
  right: 130px;
  top: 10px;
}

.survey-form-container a.close-icon-survey {
  position: absolute;
  right: 0px;
  top: 0px;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  font-size: 10px;
  /* padding: 5px 7px; */
  background: #f5f5f5;
  color: #2a3135;
  border-radius: 50%;
  height: 20px;
  cursor: pointer;
}
.focus_outline_none:focus {
  outline: none;
}
.ghl-header-banner {
  margin: 0 auto;
}

.element-box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.slide-header {
  border-bottom: 1px solid #eaecf0;
}
</style>
