<template>
  <div class="flex flex-col items-start gap-1 self-stretch">
    <div class="mb-2 flex items-center justify-between">
      <UITextXsMedium class="mr-2">{{ t("common.style.formLayoutType") }}</UITextXsMedium>
      <UIDropdown id="desktop-mobile-toggle" :options="deviceOptions" @select="switchDeviceMode">
        <template #default>
          <div class="flex items-center gap-0.5">
            <Phone01Icon v-if="store.deviceMode === 'mobile'" class="h-4 w-4 cursor-pointer" />
            <Monitor04Icon v-else class="h-4 w-4 cursor-pointer" />
            <ChevronDownIcon class="h-3 w-3" />
          </div>
        </template>
      </UIDropdown>
    </div>
    <div class="flex items-start gap-3 self-stretch">
      <div
        class="layout-container"
        :class="{ 'layout-container-desktop': store.deviceMode !== 'mobile' }"
        @click="layoutChange(1)"
      >
        <div class="layout-card" :class="{ 'layout-card-active': currentLayout === 1 }">
          <div class="layout-card-icon">
            <OneColumnIcon></OneColumnIcon>
          </div>
        </div>
        <UITextXsRegular>{{ t("common.payment.product.singleColumn") }}</UITextXsRegular>
      </div>
      <div v-if="store.deviceMode !== 'mobile'" class="layout-container" @click="layoutChange(2)">
        <div class="layout-card" :class="{ 'layout-card-active': currentLayout === 2 }">
          <div class="layout-card-icon">
            <TwoColumnIcon></TwoColumnIcon>
          </div>
        </div>
        <UITextXsRegular>{{ t("common.style.twoColumn") }}</UITextXsRegular>
      </div>
      <div v-if="store.deviceMode !== 'mobile'" class="layout-container" @click="layoutChange(3)">
        <div class="layout-card" :class="{ 'layout-card-active': currentLayout === 3 }">
          <div class="layout-card-icon">
            <SingleLineIcon></SingleLineIcon>
          </div>
        </div>
        <UITextXsRegular>{{ t("common.style.singleLine") }}</UITextXsRegular>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import OneColumnIcon from "@/assets/icons/one-column.svg";
import SingleLineIcon from "@/assets/icons/single-line.svg";
import TwoColumnIcon from "@/assets/icons/two-group.svg";
import { useAppStore } from "@/store/app";
import { fieldFullWidth } from "@/util/methods";
import { ChevronDownIcon, Monitor04Icon, Phone01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIDropdown, UITextXsMedium, UITextXsRegular } from "@gohighlevel/ghl-ui";
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { CONSTANTS, deviceOptions } from "../../../util/constants";
const { t } = useI18n();
const store = useAppStore();

const switchDeviceMode = (option) => {
  store.deviceMode = option;
};

const currentLayout = computed(() => {
  if (store.deviceMode === "mobile") {
    // Always use single column layout for mobile
    return 1;
  }

  if (
    [CONSTANTS.ONE_COLUMN, CONSTANTS.TWO_COLUMN, CONSTANTS.SINGLE_LINE].includes(
      store.builderConfig.layout
    )
  ) {
    return store.builderConfig.layout;
  }
  return 1;
});

const layoutChange = (layoutIndex: number) => {
  if (store.deviceMode === "mobile") {
    store.builderConfig.mobileLayout = layoutIndex;
  } else {
    store.builderConfig.layout = layoutIndex;

    if (!store.builderConfig.mobileLayout) {
      store.builderConfig.mobileLayout = layoutIndex;
    }
  }

  const validFieldsForWidth = store.elements.filter(
    (field) => !fieldFullWidth(field.type, true)
  ).length;
  store.elements.forEach((field) => {
    if (!fieldFullWidth(field.type, true)) {
      if (layoutIndex === CONSTANTS.ONE_COLUMN) {
        field.fieldWidthPercentage = 100;
      } else if (layoutIndex === CONSTANTS.TWO_COLUMN) {
        field.fieldWidthPercentage = 50;
      } else {
        field.fieldWidthPercentage = Math.max(
          parseFloat((100 / validFieldsForWidth).toFixed(2)),
          20
        );
        if (field.type === "submit") {
          field.fullwidth = false;
          field.padding.left = 10;
          field.padding.right = 10;
        }
      }
    }
  });
  store.addToHistory();
  store.anyUnsavedChanges = true;
};

watch(
  () => store.deviceMode,
  () => {
    const currentLayout = store.deviceMode === "mobile" ? 1 : store.builderConfig.layout;
    layoutChange(currentLayout);
  }
);
</script>

<style scoped>
.layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.layout-container-desktop {
  flex: 1 0 0;
}

.layout-card {
  display: flex;
  height: 100px;
  width: 96px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #ffffff;
  border: 2px solid #eaecf0;
}
.layout-card-active {
  border: 2px solid #155eef;
  background: #eff4ff;
}
.layout-card-icon {
  padding: 0px 16px;
}
</style>
