<template>
  <div class="flex flex-col">
    <label :for="field.id" class="custom-file-upload">
      <FileUploadIcon></FileUploadIcon>
      <span
        v-if="!isActive"
        class="file-upload-content"
        :data-placeholder="t('common.fileUploadPlaceholder')"
      >
        {{
          placeholder
            ? `${placeholder} ${picklistOptions.length ? "-" : ""} ${picklistOptions.join(", ")}`
            : ""
        }}
      </span>
      <label
        v-else
        contenteditable="true"
        :data-placeholder="t('common.fileUploadPlaceholder')"
        class="form-placeholder cursor-text text-center shadow-none focus:outline-transparent focus:ring-0"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        v-text="inputPlaceholder"
      ></label>
    </label>
    <input :id="field.id" class="file-input" type="file" disabled />
    <template v-if="isActive">
      <input
        v-model="shortLabel"
        :placeholder="t('common.shortLabelPlaceholder')"
        class="short-label border-0 p-0 focus:outline-none"
        @input="updateShortLabel"
      />
    </template>
    <template v-else>
      <span v-if="field.shortLabel" class="short-label">{{ field.shortLabel }}</span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import FileUploadIcon from "./../../assets/icons/file-upload.svg";

const { t } = useI18n();

const props = defineProps({
  field: {
    type: Object,
    default() {
      return {};
    },
  },
  placeholder: { type: String, default: "" },
  picklistOptions: {
    type: Array,
    default() {
      return [];
    },
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:placeholder", "update:shortLabel"]);
const inputPlaceholder = ref(props.placeholder);
const shortLabel = ref(props.field.shortLabel || "");

watch(
  () => props.placeholder,
  (newVal) => {
    inputPlaceholder.value = newVal;
  }
);

watch(
  () => props.field.shortLabel,
  (newVal) => {
    shortLabel.value = newVal || "";
  }
);

const handleInput = (event: Event) => {
  const target = event.target as HTMLLabelElement;
  inputPlaceholder.value = target.innerText;
  emit("update:placeholder", inputPlaceholder.value);
};

const handleFocus = () => {
  // Ensure the label has the placeholder value when focused
  if (!inputPlaceholder.value) {
    inputPlaceholder.value = props.placeholder;
  }
};

const handleBlur = () => {
  emit("update:placeholder", inputPlaceholder.value);
  const store = useAppStore();
  store.addToHistory();
};

const updateShortLabel = (event: Event) => {
  const target = event.target as HTMLInputElement;
  shortLabel.value = target.value;
  emit("update:shortLabel", shortLabel.value);
};
</script>

<style scoped>
.file-input {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block !important;
  width: 100%;
  min-height: 5rem;
  text-align: center;
}

.custom-file-upload svg {
  width: 30px;
  display: block;
  margin: 5px auto;
}

#_builder-form .custom-file-upload {
  color: #6e7d84 !important;
}

.bi-upload {
  font-size: 20px;
}

.short-label {
  background-color: transparent;
  margin-top: 4px;
  font-size: 14px;
  color: #4b5563;
}

[contenteditable="true"] {
  min-height: 24px;
  display: inline-block;
  width: 100%;
  outline: none;
  background-color: transparent;
  border: none;
}

.file-upload-content:empty:before {
  content: attr(data-placeholder);
  color: #aaa;
  pointer-events: none;
}

[contenteditable="true"]:empty:before {
  content: attr(data-placeholder);
  color: #aaa;
  pointer-events: none;
}
</style>
