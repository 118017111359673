<template>
  <div>
    <div v-if="isActive">
      <div
        v-if="editor"
        id="inlineRichTextEditor"
        class="toolbar editor-style flex flex-col flex-wrap rounded-lg border-gray-400"
      >
        <editor-content
          ref="editorEl"
          class="cursor-text"
          :class="{ 'text-center': isButton }"
          :editor="editor"
          :style="styleObj"
        />
        <div v-if="!isButton" class="top-border flex items-center justify-between p-1">
          <div class="flex gap-2">
            <div
              class="flex items-center text-gray-600"
              :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            >
              <Heading01Icon class="icon h-5 w-5" />
            </div>
            <div
              class="flex items-center text-gray-600"
              :class="{ 'is-active': editor.isActive('paragraph') }"
              @click="editor.chain().focus().setParagraph().run()"
            >
              <Pilcrow01Icon class="icon h-5 w-5" />
            </div>
            <div
              class="flex items-center text-gray-600"
              :class="{ 'is-active': editor.isActive('bold') }"
              @click="editor.chain().focus().toggleBold().run()"
            >
              <Bold01Icon class="icon h-5 w-5 stroke-[3.5px]" />
            </div>
            <div
              class="flex items-center text-gray-600"
              :class="{ 'is-active': editor.isActive('italic') }"
              @click="editor.chain().focus().toggleItalic().run()"
            >
              <Italic01Icon class="icon h-5 w-5" />
            </div>
            <div
              class="flex items-center text-gray-600"
              :class="{ 'is-active': editor.isActive('strike') }"
              @click="editor.chain().focus().toggleStrike().run()"
            >
              <Strikethrough01Icon class="icon h-5 w-5" />
            </div>
            <UIDivider class="divider" :vertical="true" />
            <UIDropdown
              id="dropdown-alignment"
              :options="alignmentOptions"
              @select="dropDownActionChange"
            >
              <AlignLeftIcon class="h-5 w-5 cursor-pointer outline-0" />
            </UIDropdown>
          </div>
          <UIDropdown
            v-if="isSubmitMessage"
            id="dropdown-extra-options"
            :options="extraOptions"
            @select="dropDownActionChange"
          >
            <DotsVerticalIcon class="h-5 w-5 cursor-pointer outline-0" />
          </UIDropdown>
        </div>
      </div>
      <LinkModal
        :show="showLinkModal"
        :url="linkValue"
        @update:success="setLink"
        @update:cancel="showLinkModal = false"
      />
    </div>
    <div v-else>
      <div :style="styleObj" v-html="modelValue"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import {
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignLeftIcon,
  AlignRightIcon,
  Bold01Icon,
  DotsVerticalIcon,
  Heading01Icon,
  Italic01Icon,
  Link01Icon,
  LinkBroken01Icon,
  Pilcrow01Icon,
  Strikethrough01Icon,
} from "@gohighlevel/ghl-icons/24/outline";
import { renderIcon, UIDivider, UIDropdown } from "@gohighlevel/ghl-ui";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-3";
import { nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import LinkModal from "./LinkModal.vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  isSubmitMessage: {
    type: Boolean,
    default: true,
  },
  styleObj: {
    type: Object,
    default: () => ({}),
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  isButton: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);

const store = useAppStore();

const editor = ref<any>(null);
const linkValue = ref("");
const showLinkModal = ref(false);
const editorEl = ref<HTMLElement | null>(null);

const alignmentOptions = [
  {
    key: "alignLeft",
    icon: renderIcon(AlignLeftIcon),
  },
  {
    key: "alignCenter",
    icon: renderIcon(AlignCenterIcon),
  },
  {
    key: "alignRight",
    icon: renderIcon(AlignRightIcon),
  },
  {
    key: "alignJustify",
    icon: renderIcon(AlignJustifyIcon),
  },
];
const extraOptions = [
  {
    key: "link",
    icon: renderIcon(Link01Icon),
  },
  {
    key: "unlink",
    icon: renderIcon(LinkBroken01Icon),
  },
];

const dropDownActionChange = (action: string) => {
  switch (action) {
    case "alignLeft":
      editor.value.chain().focus().setTextAlign("left").run();
      break;
    case "alignCenter":
      editor.value.chain().focus().setTextAlign("center").run();
      break;
    case "alignRight":
      editor.value.chain().focus().setTextAlign("right").run();
      break;
    case "alignJustify":
      editor.value.chain().focus().setTextAlign("justify").run();
      break;
    case "link":
      showModal();
      break;
    case "unlink":
      editor.value.chain().focus().unsetLink().run();
      break;
    default:
  }
};

// Function to adjust editor height based on content
const adjustEditorHeight = () => {
  if (!editor.value || !editorEl.value) {
    return;
  }

  const proseMirrorEl = editorEl.value.querySelector(".ProseMirror") as HTMLElement;
  if (!proseMirrorEl) {
    return;
  }

  // Reset height to auto for proper content measurement
  proseMirrorEl.style.height = "auto";
  proseMirrorEl.style.overflow = "hidden";

  // Set the height to match the content (using scrollHeight)
  nextTick(() => {
    const contentHeight = proseMirrorEl.scrollHeight;
    if (contentHeight > 0) {
      // Add extra 15px to the bottom for better spacing
      proseMirrorEl.style.height = `${contentHeight + 15}px !important`;
    }
  });
};

// Watch for model value changes
watch(
  () => props.modelValue,
  (newValue) => {
    if (editor.value && editor.value.getHTML() !== newValue) {
      editor.value.commands.setContent(newValue, false);
      nextTick(() => adjustEditorHeight());
    }
  }
);

// Create editor instance on component mount
onMounted(() => {
  editor.value = new Editor({
    content: props.modelValue,
    extensions: [
      StarterKit,
      TextAlign.configure({
        types: ["heading", "paragraph"],
        defaultAlignment: "left",
      }),
      Link.configure({
        autolink: false,
        openOnClick: false,
      }),
      Highlight,
    ],
    onUpdate: ({ editor }) => {
      emit("update:modelValue", editor.getHTML());
      store.anyUnsavedChanges = true;
      store.addToHistory();
      nextTick(() => adjustEditorHeight());
    },
    onFocus: () => {
      nextTick(() => adjustEditorHeight());
    },
    onBlur: () => {
      nextTick(() => adjustEditorHeight());
    },
  });

  // Initial height adjustment after editor is mounted
  nextTick(() => {
    // Use setTimeout to ensure the DOM has fully rendered
    setTimeout(() => adjustEditorHeight(), 0);
  });
});

// Create a MutationObserver to watch for content changes
const observer = ref<MutationObserver | null>(null);

// Set up MutationObserver when editor is ready
watch(editorEl, (el) => {
  if (el) {
    nextTick(() => {
      const proseMirrorEl = el.querySelector(".ProseMirror");
      if (proseMirrorEl && !observer.value) {
        observer.value = new MutationObserver(() => {
          adjustEditorHeight();
        });

        observer.value.observe(proseMirrorEl, {
          childList: true,
          subtree: true,
          characterData: true,
          attributes: true,
        });
      }
    });
  }
});

// Clean up observer when component unmounts
onUnmounted(() => {
  if (observer.value) {
    observer.value.disconnect();
    observer.value = null;
  }
});

const showModal = () => {
  linkValue.value = editor.value.getAttributes("link").href;
  showLinkModal.value = true;
};

const setLink = (url: string) => {
  const checkHttp = new RegExp("^(http|https):|{{");
  if (url === "") {
    editor.value.chain().focus().extendMarkRange("link").unsetLink().run();
  }
  if (url && !checkHttp.test(url)) {
    url = "http://" + url;
  }
  editor.value.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  linkValue.value = url;
  showLinkModal.value = false;

  // Adjust height after link is set
  nextTick(() => adjustEditorHeight());
};
</script>

<style>
#inlineRichTextEditor .ProseMirror {
  height: auto;
  transition: height 0.1s ease;
}

#inlineRichTextEditor .ProseMirror:focus-visible {
  outline: none !important;
  border: none !important;
}

.n-dropdown-option-body__suffix {
  display: none !important;
}

#inlineRichTextEditor .ProseMirror p {
  margin: 0;
  word-break: break-word;
}
</style>

<style scoped>
.editor-style {
  hyphens: auto;
  word-wrap: break-word;
  border: 1px solid #dee2e6;
}

#inlineRichTextEditor .ProseMirror {
  overflow-wrap: anywhere;
  hyphens: auto;
  border-radius: 6px;
  min-height: 30px;
  word-break: break-word;
}

#inlineRichTextEditor .ProseMirror:focus-visible {
  outline: none !important;
  border: none !important;
}

.toolbar {
  background-color: transparent;
}
.icon {
  cursor: pointer;
  color: #101828;
}
.n-divider.n-divider--vertical {
  height: auto !important;
  background-color: #dee2e6;
}
.top-border {
  border-top: 1px solid #dee2e6;
}
.editor-style div:last-child div {
  word-break: break-word;
}
a {
  color: #68cef8;
}
.toolbar div > button {
  border: none;
}
.toolbar div > button > div > svg {
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: 0 0.2rem;
}
.toolbar .is-active {
  --tw-text-opacity: 1;
  color: #000;
}

:deep(#inlineRichTextEditor .ProseMirror) {
  padding: v-bind(isButton ? "0": "15px");
}
</style>
