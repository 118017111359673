{
  "common" : {
    "save" : "Speichern",
    "saving" : "Speichern",
    "integrate" : "Integrieren",
    "preview" : "Vorschau",
    "back" : "Zurück",
    "alert" : "Benachrichtigung",
    "proceed" : "Fortfahren",
    "cancel" : "Stornieren",
    "delete" : "Löschen",
    "embed" : "Einbinden",
    "link" : "Link",
    "iframeEmbed" : "Iframe Einbetten",
    "styles" : "Stile",
    "options" : "Optionen",
    "quickAdd" : "Schnelles Hinzufügen",
    "customFields" : "Benutzerdefinierte Felder",
    "personalInfo" : "Persönliche Informationen",
    "contactInfo" : "Kontaktdaten",
    "submit" : "Absenden",
    "address" : "Adresse",
    "customized" : "Kundenspezifisch",
    "layout" : "Layout",
    "showLabel" : "Label anzeigen",
    "background" : "Hintergrund",
    "border" : "Abgrenzung",
    "borderType" : "Rahmen-Typ",
    "cornerRadius" : "Eckenradius",
    "width" : "Breite",
    "padding" : "Innenabstand",
    "top" : "Top",
    "bottom" : "Unten",
    "left" : "Links",
    "right" : "Rechts",
    "shadow" : "Schatten",
    "color" : "Farbe",
    "horizontal" : "Horizontal",
    "vertical" : "Vertikal",
    "blur" : "Verwischen",
    "spread" : "Verbreiten",
    "fieldStyle" : "Feldstil",
    "fontColor" : "Schriftfarbe",
    "label" : "Label",
    "fontFamily" : "Schriftfamilie",
    "size" : "Größe",
    "weight" : "Gewicht",
    "placeholder" : "Platzhalter",
    "customCSS" : "Benutzerdefiniertes CSS",
    "backgroundImage" : "Hintergrundbild",
    "headerImage" : "Header-Bild",
    "miscellaneous" : "Verschiedenes",
    "agencyBranding" : "Branding der Agentur",
    "imageUploadMessage" : "Klicke oder ziehe eine Bilddatei in diesen Bereich, um sie hochzuladen",
    "backgroundImageTooltipMessage" : "Das Hintergrundbild soll die gesamte Seite bedecken und einen optisch ansprechenden Hintergrund für das Formular bilden. Um das Hintergrundbild anzuzeigen, klicken Sie auf die Option Vorschau.",
    "headerImageTooltipMessage" : "Das Kopfbild ist so gestaltet, dass es die gesamte Breite des Formulars abdeckt und oben im Formular positioniert bleibt. Um das Header-Bild anzuzeigen, klicken Sie auf die Option Vorschau.",
    "onSubmit" : "Beim Absenden",
    "facebookPixelID" : "Facebook-Pixel-ID",
    "facebookPixelIDMessage" : "Ignoriere dieses Feld, wenn du dieses Formular/diese Umfrage innerhalb eines Trichters verwenden möchtest",
    "facebookPixelEvents" : "Facebook Pixel Events",
    "onpageView" : "Seitenansicht",
    "stickyContact" : "Angehefteter Kontakt",
    "stickyContactMessage" : "Sticky Contact füllt zuvor eingegebene Kontaktinformationen automatisch aus, sodass Sie sie nicht zweimal eingeben müssen.",
    "fieldDescription" : "Beschreibung des Feldes",
    "fieldTitle" : "Feldtitel",
    "queryKey" : "Abfrageschlüssel",
    "querykeyTooltipMessage" : "Der Abfrageschlüssel, der als URL-Parameter zum Auffüllen dieses Felds verwendet werden kann",
    "required" : "Erforderlich",
    "hidden" : "Versteckt",
    "hiddenValue" : "Versteckter Wert",
    "enableCountryPicker" : "Länderauswahl aktivieren",
    "altText" : "Alt-Text",
    "alignment" : "Alignment",
    "imageWidth" : "Bildbreite",
    "imageHeight" : "Bildhöhe",
    "logic" : "Logik",
    "pleaseInput" : "Bitte eingeben",
    "editHTML" : "HTML bearbeiten",
    "textStyle" : "Textstil",
    "subText" : "Subtext",
    "text" : "Text",
    "buttonStyle" : "Button-Stil",
    "fullWidth" : "Volle Breite",
    "htmlMessage" : "Das benutzerdefinierte HTML wird hier eingefügt",
    "htmlEditorHeader" : "BENUTZERDEFINIERTES JAVASCRIPT / HTML",
    "htmlEditorBodyMessage" : "Füge deinen Code hier ein",
    "yesSave" : "Ja, speichern",
    "cancelKeep" : "Abbrechen, Behalten",
    "done" : "Erledigt",
    "addElement" : "Element hinzufügen",
    "addUrlLike" : "Fügen Sie gültige Urls wie",
    "doNotAddRelativePath" : "Fügen Sie keine relativen Pfade wie",
    "or" : "oder",
    "addCustomFields" : "Benutzerdefinierte Felder hinzufügen",
    "customFieldSearch" : "Suche nach Namen",
    "enterSourceValue" : "Quellwert eingeben",
    "shortLabel" : "Kurzbezeichnung",
    "shortLabelPlaceholder" : "Type a short label",
    "fileUploadPlaceholder" : "Upload a file",
    "customCSSTooltip" : "Benutzerdefiniertes CSS hat Vorrang vor dem Formularstyling und den Themes und kann sich auf das Styling des Themes auswirken.",
    "fontFamilyTooltip" : "Builder unterstützt alle Arten von Schriftarten, falls Sie diese nicht in der Liste sehen können. Suchen Sie im Dropdown-Menü danach.",
    "useGDPRCompliantFont" : "GDPR-konforme Schriftart verwenden",
    "enableTimezone" : "Zeitzone aktivieren",
    "inputStyle" : "Eingabestil",
    "conditionalLogicAlertOnSubmit" : "Die bedingte Logik hat hier Vorrang vor der Nachricht / Weiterleitung",
    "conditionalLogicAlertOnShowHide" : "Die bedingte Logik hat hier Vorrang vor den versteckten Einstellungen",
    "placeholderFacebookPixelIdInput" : "Geben Sie die Facebook-Pixel-ID ein",
    "doNotShowMessage" : "Nicht mehr anzeigen",
    "themes" : {
      "themes" : "Themen",
      "alertThemeMessage" : "Ein Wechsel des Themas führt zum Verlust aller aktuellen Stiländerungen.",
      "useTheme" : "Thema verwenden",
      "removeTheme" : "Thema entfernen"
    },
    "box" : "Kasten",
    "line" : "Linie",
    "message" : "Nachricht",
    "openUrl" : "URL öffnen",
    "borderTypeList" : {
      "dotted" : "Gepunktet",
      "dashed" : "Gestrichelt",
      "solid" : "Einfarbig",
      "none" : "Keine"
    },
    "button" : "Schaltfläche",
    "emailNotification" : "E-Mail-Benachrichtigung",
    "emailNotificationSubheader" : "Erhalte eine E-Mail, wenn jemand dein Formular ausfüllt",
    "subject" : "Betreff",
    "emailTo" : "E-Mail (An)",
    "senderName" : "Absendername",
    "senderNameWarning" : "Ihr (Name des Unterkontos) wird hinzugefügt, wenn Sie das Feld leer lassen",
    "emailError" : "Mindestens eine E-Mail-Adresse ist erforderlich.",
    "emailTooltip" : "Die erste E-Mail-Adresse ist für das Feld „An“ bestimmt. Alle nachfolgenden E-Mail-Adressen werden in das Feld \"Cc\" aufgenommen.",
    "clear" : "Klären",
    "showNotificationsSettings" : "Benachrichtigungseinstellungen anzeigen",
    "emailServicePaid" : "Diese Funktion ist ein kostenpflichtiger Dienst, für den Gebühren auf der Grundlage Ihrer Nutzung anfallen.",
    "integration" : "Integration",
    "payment" : {
      "payments" : "Zahlungen",
      "connected" : "Verbunden",
      "connect" : "Verbinden",
      "liveModeOn" : "Live-Modus",
      "status" : "Status",
      "test" : "Test",
      "live" : "Live",
      "paymentFields" : "Zahlungsfelder",
      "suggestAnAmount" : "Einen Betrag vorschlagen",
      "selectCurrency" : "Währung auswählen",
      "tagActiveBGColor" : "Aktive Tag-Farbe",
      "includeOtherAmount" : "Anderen Betrag einschließen",
      "footerInfo" : "Informationen zur Fußzeile",
      "paymentType" : "Zahlungsart",
      "sellProducts" : "Produkte verkaufen",
      "customAmount" : "Benutzerdefinierter Betrag",
      "collectPayment" : "Zahlung einziehen",
      "generalSettings" : "Allgemeine Felder",
      "product" : {
        "addProduct" : "Produkt hinzufügen",
        "productSettings" : "Produkt-Einstellungen",
        "selectProduct" : "Produkt auswählen",
        "editThisProduct" : "So bearbeiten Sie dieses Produkt",
        "clickHere" : "Hier klicken",
        "show" : "Anzeigen",
        "description" : "Beschreibung",
        "image" : "Bild",
        "layoutSettings" : "Layouteinstellungen",
        "singleColumn" : "Einzelne Säule",
        "twoColumn" : "Zwei Spalten",
        "threeColumn" : "Drei Spalten",
        "maxProductError" : "Maximal 20 Produkte sind in einem Formular erlaubt",
        "productSelectPlaceholder" : "Bitte wählen Sie ein Produkt aus",
        "orderConfirmation" : "Bestätigung der Bestellung",
        "orderConfirmationWarning" : "Sobald die Zahlung erfolgreich war, wird eine Bestellbestätigungsseite angezeigt",
        "quantity" : "Menge",
        "createProduct" : "Um ein Produkt zu erstellen"
      },
      "coupons" : {
        "coupons" : "Coupons",
        "enableCoupon" : "Coupon aktivieren",
        "couponAlignment" : "Coupon-Ausrichtung",
        "left" : "Links",
        "right" : "Rechts",
        "fullWidth" : "Volle Breite",
        "tooltipMessage" : "Legen Sie die Position der Couponeingabe aus den folgenden Typen fest"
      }
    },
    "addressSettings" : {
      "autoComplete" : "Adresse automatisch ausfüllen",
      "addressfields" : "Adressfelder",
      "placeholderSearch" : "Suche nach einer Adresse",
      "autoCompleteTooltip" : "Bietet die Möglichkeit zum Suchen und automatischen Ausfüllen von Adressen. Diese Funktion ist kostenpflichtig.",
      "mandataorySearch" : {
        "title" : "Erforderlich Suchen Sie die Leerleiste",
        "primaryText" : "Was ist eine erforderliche Suchenleiste?",
        "secondaryText" : "Wenn aktiviert, wenn ein Benutzer mit dem Anschrift-Feld interagieren, Then muss er eine gültige Anschrift auswählen, um Fortfahren.",
        "secondaryText1" : "Wenn deaktiviert , können sie fortfahren, ohne eine Adresse in der Suchenleiste auszuwählen."
      }
    },
    "autoResponder" : "Automatische Antwort",
    "autoResponderSubheader" : "Sende eine automatische E-Mail an die Person, die das Formular absendet (die E-Mail enthält eine Kopie der Informationen, die sie in das Formular eingegeben hat)",
    "replyToEmail" : "Antwort auf E-Mail",
    "open" : "Offen",
    "won" : "Gewonnen",
    "lost" : "Verpasst",
    "abandon" : "Verlassen",
    "selectAPipeline" : "Pipeline auswählen",
    "stage" : "Schritt",
    "status" : "Status",
    "opportunityValue" : "Opportunitätswert",
    "createNewPipeline" : "Neue Pipeline erstellen",
    "editOpportunitySettings" : "Opportunity-Einstellungen bearbeiten",
    "contact" : "Kontakt",
    "opportunity" : "Gelegenheit",
    "add" : "Hinzufügen",
    "more" : "Mehr",
    "opportunityHeading" : "Wo möchten Sie, dass Ihre Chance aktualisiert wird?",
    "date" : {
      "dateSettings" : "Datumseinstellungen",
      "dateFormat" : "Datumsformat",
      "dateSeparator" : "Datumstrennzeichen",
      "dateFormatTooltipMessage" : "Wählen Sie ein Datumsformat aus. D steht für Tag, M für Monate und Y für Jahr",
      "dateSeparatorTooltipMessage" : "Wähle ein Trennzeichen, das Tag, Monat und Jahr im ausgewählten Datumsformat trennt",
      "disablePicker" : "Picker deaktivieren"
    },
    "restoreVersion" : "Version wiederherstellen",
    "versionHistory" : "Versionsverlauf",
    "errorMessage" : "Fehler, erneuter Versuch",
    "version" : "Version:",
    "currentVersion" : "Aktuelle Version",
    "messageStyling" : "Nachrichten-Styling",
    "validateEmailHeader" : "E-Mail validieren",
    "validateEmailDescription" : "Die Schaltfläche wird sichtbar, sobald der Benutzer seine E-Mail-Adresse eingibt. Diese Funktion soll Ihnen helfen, hochwertige Leads zu generieren.",
    "validatePhoneHeader" : "Telefon validieren",
    "validatePhoneDescription" : "Die Schaltfläche wird sichtbar, sobald der Benutzer sein Telefon betritt. Diese Funktion soll Ihnen helfen, hochwertige Leads zu generieren.",
    "validatePhoneWarning" : "Um die SMS-OTP-Verifizierung zu aktivieren, stellen Sie bitte sicher, dass Ihre Telefonnummer in Ihren Standorteinstellungen eingerichtet ist.",
    "addAnotherTnc" : "Add another T & C",
    "tnc1" : "T & C 1",
    "tnc2" : "T & C 2",
    "deleteCaps" : "DELETE",
    "deleteConfirmation" : "Type DELETE to confirm",
    "style" : {
      "formLayoutType" : "Formulartyp",
      "oneColumn" : "Eine Säule",
      "twoColumn" : "Zwei Spalten",
      "singleLine" : "Einzelzeile",
      "fieldWidth" : "Feldbreite",
      "labelAlignment" : {
        "labelAlignment" : "Etikettenausrichtung",
        "labelWidth" : "Etikettenbreite"
      },
      "fieldSpacing" : "Feldabstand",
      "color" : "Farbe",
      "fontColor" : "Schriftfarbe",
      "inputBackground" : "Eingabe-Hintergrund",
      "layout" : "Layout",
      "colorsAndBackground" : "Farben & Hintergrund",
      "advanceSettings" : "Advanced Settings",
      "inputField" : "Eingabefeld",
      "borderWidth" : "Rahmenbreite",
      "borderColor" : "Rahmenfarbe",
      "borderStyle" : "Rahmenstil",
      "fontSize" : "Schriftgröße",
      "fontWeight" : "Schriftstärke",
      "labelColor" : "Etikettenfarbe",
      "placeholderColor" : "Farbe des Platzhalters",
      "shortLabelColor" : "Kurze Etikettenfarbe"
    },
    "replyToTooltip" : "Passen Sie die standardmäßige Antwort-E-Mail-Adresse an, indem Sie {'{'}{'{'}contact.email{'}'}{'}'} verwenden, um Antworten an den Kontakt zu ermöglichen, der das Formular sendet.",
    "content" : "Inhalt",
    "calculations" : "Berechnungen",
    "uploadOptions" : "Upload-Optionen",
    "opportunitySettingsWarning" : "Bitte richten Sie die Opportunity-Einstellungen ein, indem Sie auf die Schaltfläche „Opportunity-Einstellungen bearbeiten“ klicken.",
    "question" : "Frage",
    "createContactOnPartialSubmission" : "Kontakt bei teilweiser Übermittlung erstellen",
    "howItWorks" : "Wie funktioniert das?",
    "createContactOnPartialSubmissionDescription" : "Beenden Sie die Folie und klicken Sie auf \" Weiter \". Ein neuer Kontakt wird automatisch erstellt und gespeichert.",
    "swapWarningMessageSurvey" : "Das Bearbeiten oder Austauschen von Optionen wirkt sich auf dieses benutzerdefinierte Feld an allen Stellen aus, an denen das Feld verwendet wird. Sie kann auch die bedingte Logik zurücksetzen. ",
    "swapWarningMessageForm" : "Das Bearbeiten oder Austauschen von Optionen wirkt sich auf dieses benutzerdefinierte Feld an allen Stellen aus, an denen das Feld verwendet wird. ",
    "optionLabel" : "Label der Option",
    "calculationValue" : "Berechnungswert",
    "addOption" : "Option hinzufügen",
    "enableCalculations" : "Berechnungen aktivieren",
    "allowOtherOption" : "Allow Other Option",
    "productSelectionSubtext" : "Aktivieren Sie die Einzelperson oder mehrere Produktauswahl",
    "multi" : "Multi",
    "single" : "Einzelperson",
    "productSelectionHeading" : "Produkt",
    "spreadToColumns" : "Auf Spalten Verbreiten",
    "columns" : "Spalte",
    "desktopMode" : "Desktop-Modus",
    "mobileMode" : "Mobiler Modus",
    "versions" : "Varianten"
  },
  "survey" : {
    "typeOfBuilder" : "Umfrage",
    "survyeStyle" : "Umfrage-Stil",
    "surveyName" : "Umfragename",
    "surveyElement" : "Umfrageelement",
    "addSurveyElement" : "Umfrageelement hinzufügen",
    "unsavedAlertMessage" : "Die Umfrage enthält ungespeicherte Arbeit. Möchten Sie wirklich gehen und alle nicht gespeicherten Arbeiten verwerfen?",
    "thirdPartyScriptAlertMessage" : "Drittanbieter-Skripte innerhalb des HTML-Blocks können zwar leistungsstarke Funktionen bieten, bringen aber auch Risiken für Datenschutz, Sicherheit, Leistung und Seitenverhalten mit sich. Daher empfehlen wir, den Inhalt dieses Skripts zu überprüfen, bevor du es in der Umfrage speicherst.",
    "integrateHeader" : "Umfrage integrieren",
    "integrateSubHeader" : "Verwenden Sie die folgenden Optionen, um Ihre benutzerdefinierte Umfrage in Ihre Website zu integrieren",
    "integrateCopyLinkMessage" : "Kopieren Sie den unten stehenden Link und teilen Sie ihn ganz einfach, wo immer Sie möchten.",
    "onSurveySubmission" : "Bei Umfrageeinreichung",
    "surveySettings" : "Umfrage-Einstellungen",
    "oneQuestionAtTime" : "Eine Frage nach der anderen",
    "backButton" : "Schaltfläche „Zurück“",
    "backButtonTooltipMessage" : "Die Zurück-Schaltfläche hilft dem Benutzer, die vorherige Folie zu verschieben",
    "disableAutoNavigation" : "Automatische Navigation deaktivieren",
    "disableAutoNavigationTooltipMessage" : "Wenn diese Option aktiviert ist, wird die Umfrage nicht automatisch zur nächsten Folie weitergeleitet.",
    "autoNavigation" : "Auto Navigation",
    "autoNavigationTooltipMessage" : "Wenn deaktiviert, wird die Umfrage nicht automatisch zur nächsten Folie weitergeleitet",
    "progressBar" : "Fortschrittsbalken",
    "progressBarTooltipMessage" : "Wenn Sie diese Option deaktivieren, wird der Fortschrittsbalken aus der Umfrage entfernt",
    "disableAnimation" : "Animation ausschalten",
    "disableAnimationTooltipMessage" : "Wenn Sie diese Option aktivieren, wird die Animation des Folienübergangs deaktiviert.",
    "scrollToTop" : "Nach oben scrollen",
    "scrollToTopTooltipMessage" : "Wenn diese Option aktiviert ist, wird die Umfrage auf der nächsten Folie nach oben gescrollt",
    "footerHTML" : "Fußzeile HTML",
    "footerHTMLMessage" : "Sie können benutzerdefiniertes CSS (unter Verwendung des style-Tags) und HTML innerhalb desselben Kastens schreiben",
    "editFooter" : "Fußzeile bearbeiten",
    "disqualifyImmediately" : "Sofort disqualifizieren",
    "disqualifyAfterSubmit" : "Nach dem Absenden disqualifizieren",
    "slideConflictAlertMessage" : "Diese Folie enthält mehrere Fragen, für die eine Logik festgelegt ist. Dies kann zu unbeabsichtigtem Verhalten führen",
    "addSlide" : "Dia hinzufügen",
    "settings" : "Einstellungen",
    "delete" : "Löschen",
    "slideDeleteAlertMessage" : "Sind Sie sicher, dass Sie diese Folie löschen möchten?",
    "slideDeleteBetweenAlertMessage" : "Dadurch werden die anderen Folien neu angeordnet. Möchten Sie diese Folie wirklich löschen?",
    "slideName" : "Folienname",
    "slidePosition" : "Folienposition",
    "jumpTo" : "Springen zu",
    "enableTimezoneSurvey" : "Wenn Sie diese Option aktivieren, werden die Zeitzoneninformationen der Person erfasst, die die Umfrage einreicht",
    "footer" : {
      "footer" : "Fußzeile",
      "enableNewFooter" : "Neuen Footer aktivieren",
      "stickToSurvey" : " Bei der Umfrage bleiben",
      "backgroundFill" : "Hintergrundfüllung",
      "footerHeight" : "Höhe der Fußzeile",
      "enableProgressBar" : "Fortschrittsbalken einschalten",
      "buttons" : "Buttons",
      "buttonType" : "Button-Typ",
      "fillNext" : "Füllen (nächste)",
      "fillPrevious" : "Ausfüllen (vorherige)",
      "fillSubmit" : "Ausfüllen (Absenden)",
      "textColor" : "Schaltflächentext",
      "position" : "Position",
      "previousText" : "Vorherige (Text)",
      "nextText" : "Weiter (Text)",
      "submitText" : "Einreichen (Text)",
      "fillComplete" : "Ausfüllen abgeschlossen",
      "fillInactive" : "Inaktive ausfüllen",
      "footerType" : "Art der Fußzeile",
      "stickToCard" : "An Karte halten",
      "stickToPage" : "Seite beibehalten"
    },
    "surveyNotfication" : "Umfrage-Benachrichtigung",
    "style" : {
      "surveyPadding" : "Padding für Umfragen"
    },
    "autoResponderSubheader" : "Senden Sie eine automatisierte E-Mail an die Person, die die Umfrage einreicht (die E-Mail enthält eine Kopie der Informationen, die sie in der Umfrage eingegeben hat)"
  },
  "form" : {
    "typeOfBuilder" : "Formular",
    "formStyle" : "Formularstil",
    "formElement" : "Formularelement",
    "addFormElement" : "Formularelement hinzufügen",
    "addConditionalLogic" : "Bedingte Logik hinzufügen",
    "inlineForm" : "Inline-Formular",
    "formName" : "Formularname",
    "fullScreenMode" : "Vollbildmodus",
    "fullScreenModetooltip" : "Wenn Sie die Funktion einschalten, wird das Formular über die gesamte Breite seines Containers erweitert und erstreckt sich bis zum Rand des Einbettungsbereichs, während es die Breite des Formulars beibehält.",
    "enableTimezoneForm" : "Durch Aktivieren dieser Option werden die Zeitzoneninformationen der Person erfasst, die das Formular einreicht.",
    "formSettings" : "Formulareinstellungen",
    "unsavedAlertMessage" : "Das Formular enthält ungespeicherte Arbeit. Möchten Sie wirklich gehen und alle nicht gespeicherten Arbeiten verwerfen?",
    "thirdPartyScriptAlertMessage" : "Drittanbieter-Skripte innerhalb des HTML-Blocks können zwar leistungsstarke Funktionen bieten, bringen aber auch Risiken für Datenschutz, Sicherheit, Leistung und Seitenverhalten mit sich. Daher empfehlen wir Ihnen, den Inhalt dieses Skripts zu überprüfen, bevor Sie es im Formular speichern.",
    "onFormSubmission" : "Bei der Einreichung des Formulars",
    "integrateForm" : "Formular integrieren",
    "embedlayoutType" : "Layouttyp einbetten",
    "embedlayoutTypeSubMessage" : "Wähle ein Layout aus, das in einen Trichter oder eine externe Website eingebettet werden soll",
    "triggerType" : "Auslöser-Typ",
    "showOnScrolling" : "Beim Scrollen anzeigen",
    "showAfter" : "Anzeigen nach",
    "seconds" : "Sekunden",
    "alwaysShow" : "Immer anzeigen",
    "activationOptions" : "Aktivierungsoptionen",
    "activateOn" : "Aktivieren am",
    "visit" : "Besuchen",
    "alwaysActivated" : "Immer aktiviert",
    "deactivationOptions" : "Deaktivierungsoptionen",
    "deactivateAfterShowing" : "Nach dem Anzeigen deaktivieren",
    "times" : "Mal",
    "deactiveOnceLeadCollected" : "Deaktivieren, sobald der Lead erfasst wurde",
    "neverDeactivate" : "Niemals deaktivieren",
    "copyEmbedCode" : "Einbettungscode kopieren",
    "copyFormLink" : "Formularlink kopieren",
    "openFormLink" : "Link zum Formular öffnen",
    "inline" : "Inline",
    "inlineDescription" : "Ein Inline-Formular wird zusammen mit dem Inhalt der Seite als natives Element angezeigt. Es überlagert sich nicht und kann nicht geschlossen oder minimiert werden.",
    "inlineHoverDescription" : "Ein Inline-Formular wird zusammen mit dem Inhalt der Seite als natives Element angezeigt. Es überlagert sich nicht und kann nicht geschlossen oder minimiert werden.",
    "popup" : "Pop-up",
    "popupDescription" : "Ein Pop-up zeigt ein Formular als Overlay in einem Leuchtkasten an. Sie kann nicht minimiert, sondern nur geschlossen werden.",
    "popupHoverDescription" : "Ein Pop-up zeigt ein Formular als Overlay in einem Leuchtkasten an. Sie kann nicht minimiert, sondern nur geschlossen werden.",
    "politeSlideIn" : "Höfliches Einschieben",
    "politeSlideInDescription" : "Ein höflicher Einschub erscheint an der Unterseite des Bildschirms und verschwindet nach dem Schließen",
    "politeSlideInHoverDescription" : "Erscheint auf der Unterseite des Bildschirms und verschwindet nach dem Schließen",
    "stickySidebar" : "Klebrige Seitenleiste",
    "stickySidebarDescription" : "Eine Sticky-Seitenleiste wird an der Seite des Bildschirms angezeigt und kann auf einen Tab minimiert werden, der an der Seite des Fensters haftet",
    "stickySidebarHoverDescription" : "Erscheint an der Seite des Bildschirms und kann auf eine Registerkarte minimiert werden, die an der Seite des Fensters klebt",
    "allowMinimize" : "Minimieren zulassen",
    "conditonalLogic" : {
      "conditions" : "Bedingungen",
      "noExistingCondition" : "Noch keine bestehenden Bedingungen",
      "freeRoamMessage" : "Du kannst dich frei bewegen, ohne Regeln – zumindest im Moment",
      "addNewCondition" : "Neue Bedingung hinzufügen",
      "redirectToUrl" : "Weiterleitung auf URL",
      "displayCustomMessage" : "Benutzerdefinierte Nachricht anzeigen",
      "disqualifyLead" : "Lead disqualifizieren",
      "showHideFields" : "Felder ein-/ausblenden",
      "redirectToUrlSubText" : "Leiten Sie Benutzer nach der Einreichung um",
      "displayCustomMessageSubText" : "Nachrichten basierend auf den Eingaben eines Benutzers anzeigen",
      "disqualifyLeadSubText" : "Filter Einträge unter bestimmten Bedingungen heraus",
      "showHideFieldsSubText" : "Feld Sichtbarkeit synchron ändern",
      "openBelowUrl" : "Öffnen Sie die folgende URL",
      "showCustomMessage" : "Benutzerdefinierte Nachricht anzeigen",
      "alertConditionDelete" : "Bist du sicher, dass du diese Bedingung löschen möchtest?",
      "deleteCondition" : "Bedingung löschen",
      "conditionExecutedMessage" : "Bedingungen werden in Top-Down-Reihenfolge ausgeführt.",
      "allOperationOptions" : {
        "isEqualTo" : "Ist gleich",
        "isNotEqualTo" : "Ist nicht gleich",
        "contains" : "Enthält",
        "startsWith" : "Beginnt mit",
        "endsWith" : "Endet mit",
        "isEmpty" : "Ist leer",
        "isFilled" : "Ist gefüllt",
        "before" : "Vorher",
        "after" : "Nach",
        "greaterThan" : "Größer als",
        "lessThan" : "Weniger als"
      },
      "logic" : {
        "if" : "Wenn",
        "then" : "Dann",
        "and" : "Und",
        "or" : "Oder"
      },
      "phoneAlertMessage" : "Um nach Telefonnummern mit einer Landesvorwahl zu suchen, musst du das \"+\"-Symbol ausdrücklich in deine Suchanfrage aufnehmen.",
      "deleteAlertMessage" : "Bist du sicher, dass du dieses Feld löschen möchtest? Die mit diesem Feld verknüpfte Bedingung wird ebenfalls entfernt.",
      "deleteField" : "Feld löschen"
    },
    "minimizedTitlePlaceholder" : "Minimierter Titel",
    "dragYourElementFromLeft" : "Ziehen Sie Ihre Elemente von links hierher",
    "formNotification" : "Benachrichtigung per Formular",
    "style" : {
      "formPadding" : "Formular-Padding"
    }
  },
  "quiz" : {
    "quizElement" : "Quiz-Element",
    "quizNotification" : "Quiz-Benachrichtigung",
    "viewAndCustomise" : "Anzeigen und Anpassen",
    "categoryAndScore" : "Kategorie und Punktzahl",
    "provideFeedback" : "Feedback geben",
    "addQuizElement" : "Quiz-Element hinzufügen",
    "typeOfBuilder" : "Quiz",
    "catgory" : {
      "manageCategories" : "Kategorien verwalten"
    },
    "quizSettings" : "Quiz-Einstellungen",
    "onQuizSubmission" : "Bei der Quiz-Einreichung",
    "settings" : "Einstellungen",
    "deletePage" : "Seite löschen",
    "quizResult" : {
      "resultTemplate" : "Ergebnisvorlage",
      "quizResultAlertMessage" : "Die ausgewählte Ergebnisseite wird angezeigt, sobald das Quiz abgeschlossen ist"
    },
    "autoResponderSubheader" : "Senden Sie eine automatische E-Mail an die Person, die das Quiz eingereicht hat (E-Mail enthält eine Kopie der Informationen, die sie in das Quiz eingegeben hat)",
    "integrateHeader" : "Quiz integrieren",
    "integrateSubHeader" : "Verwenden Sie die folgenden Optionen, um Ihr individuelles Quiz in Ihre Website zu integrieren",
    "integrateCopyLinkMessage" : "Kopieren Sie den unten stehenden Link und teilen Sie ihn einfach, wo immer Sie möchten.",
    "pageName" : "Seitenname",
    "pagePosition" : "Seitenposition"
  }
}
