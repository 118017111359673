<script lang="ts" setup>
import { useAppStore } from "@/store/app";
import {
  ArrowNarrowLeftIcon,
  Contrast01Icon,
  Dataflow03Icon,
  Edit05Icon,
  Globe06Icon,
  InfoCircleIcon,
  UserX01Icon,
  XCloseIcon,
  Menu05Icon,
} from "@gohighlevel/ghl-icons/24/outline";
import { UIModal, UIModalContent, UIModalHeader, UISpace } from "@gohighlevel/ghl-ui";
import { computed, ref } from "vue";
import ActionButton from "./ActionButton.vue";
import ConditionCard from "./ConditionCard.vue";
import AlertModal from "./AlertModal.vue";
import DisplayCustomMessage from "./DisplayCustomMessage.vue";
import ConditionIcons from "./ConditionIcons.vue";
import { VueDraggableNext } from "vue-draggable-next";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const store = useAppStore();
const editIndex = ref(0);
const showHomePage = ref(true);
const showDisplayCustomMessage = ref(false);
const showRedirectToUrl = ref(false);
const showDisqualifyLead = ref(false);
const showHideFields = ref(false);
const enableDeleteModal = ref(false);

const emit = defineEmits(["update:closeModal"]);
const closeModal = () => emit("update:closeModal");

const conditionButtons = [
  {
    color: "success",
    buttonText: t("form.conditonalLogic.redirectToUrl"),
    subButtonText: t("form.conditonalLogic.redirectToUrlSubText"),
    type: "redirectToUrl",
    icon: "Globe06Icon",
    iconClass: "text-success-600",
  },
  {
    color: "primary",
    buttonText: t("form.conditonalLogic.displayCustomMessage"),
    subButtonText: t("form.conditonalLogic.displayCustomMessageSubText"),
    type: "displayCustomMessage",
    icon: "Edit05Icon",
    iconClass: "text-primary-600",
  },
  {
    color: "error",
    buttonText: t("form.conditonalLogic.disqualifyLead"),
    subButtonText: t("form.conditonalLogic.disqualifyLeadSubText"),
    type: "disqualifyLead",
    icon: "UserX01Icon",
    iconClass: "text-error-600",
  },
  {
    color: "warning",
    buttonText: t("form.conditonalLogic.showHideFields"),
    subButtonText: t("form.conditonalLogic.showHideFieldsSubText"),
    type: "showHideFields",
    icon: "Contrast01Icon",
    iconClass: "text-warning-600",
  },
];

const getIconComponent = (iconName) => {
  switch (iconName) {
    case "Globe06Icon":
      return Globe06Icon;
    case "Edit05Icon":
      return Edit05Icon;
    case "UserX01Icon":
      return UserX01Icon;
    case "Contrast01Icon":
      return Contrast01Icon;
    default:
      return null;
  }
};

const goToHomePage = () => {
  store.builderConfig.conditionalLogic.forEach((item) => {
    item.conditions = item.conditions?.filter(
      (condition) => condition.selectedField !== null && condition.selectedOperation !== null
    );
  });
  if (!store.builderConfig.conditionalLogic[editIndex.value]?.outcome?.value) {
    deleteStep(editIndex.value, false);
  }
  showHomePage.value = true;
  showDisplayCustomMessage.value = false;
  showRedirectToUrl.value = false;
  showHideFields.value = false;
  showDisqualifyLead.value = false;
};

const goToDisplayCustomMessage = (condition, type) => {
  const stepIndex = store.builderConfig.conditionalLogic?.findIndex((c) => c === condition);
  if (condition !== null) {
    editIndex.value = stepIndex;
  } else {
    if (!store.builderConfig.conditionalLogic) {
      store.builderConfig.conditionalLogic = [];
    }
    editIndex.value =
      store.builderConfig.conditionalLogic.push({
        conditionalOperation: null,
        conditions: null,
        outcome: null,
      }) - 1;
  }
  showHomePage.value = false;
  if (type === "displayCustomMessage") {
    showDisplayCustomMessage.value = true;
  } else if (type === "redirectToUrl") {
    showRedirectToUrl.value = true;
  } else if (type === "disqualifyLead") {
    showDisqualifyLead.value = true;
  } else if (type === "showHideFields") {
    showHideFields.value = true;
  }
};

const deleteStep = (stepIndex, showUnsavedChanges = true) => {
  store.builderConfig.conditionalLogic.splice(stepIndex, 1);
  store.builderConfig.conditionalLogic = store.builderConfig.conditionalLogic.filter(
    (item: any) => item.outcome !== null
  );
  if (showUnsavedChanges) {
    store.anyUnsavedChanges = true;
  }
};

const onSave = () => {
  showHomePage.value = true;
  showDisplayCustomMessage.value = false;
  showRedirectToUrl.value = false;
  showDisqualifyLead.value = false;
  showHideFields.value = false;
};

const cancelCallbackDeleteUpdate = () => {
  enableDeleteModal.value = false;
};

const successCallbackDeleteUpdate = () => {
  deleteStep(editIndex.value);
  enableDeleteModal.value = false;
};

const showDeleteModal = (condition) => {
  const stepIndex = store.builderConfig.conditionalLogic?.findIndex((c) => c === condition);
  editIndex.value = stepIndex;
  enableDeleteModal.value = true;
};

const mapConditionNames = {
  displayCustomMessage: { name: t("form.conditonalLogic.displayCustomMessage"), color: "primary" },
  redirectToUrl: { name: t("form.conditonalLogic.redirectToUrl"), color: "success" },
  disqualifyLead: { name: t("form.conditonalLogic.disqualifyLead"), color: "error" },
  showHideFields: { name: t("form.conditonalLogic.showHideFields"), color: "warning" },
};

const showHideConditionsFlag = (condition) => condition.outcome?.type === "showHideFields";
const otherConditionsFlag = (condition) => condition.outcome?.type !== "showHideFields";

const showHideConditions = computed({
  get: () => store.builderConfig.conditionalLogic?.filter(showHideConditionsFlag),
  set: (newShowHideConditions) => {
    store.builderConfig.conditionalLogic = [
      ...newShowHideConditions,
      ...otherConditions.value.filter(otherConditionsFlag),
    ];
  },
});

const otherConditions = computed({
  get: () => store.builderConfig.conditionalLogic?.filter(otherConditionsFlag),
  set: (newOtherConditions) => {
    store.builderConfig.conditionalLogic = [
      ...showHideConditions.value.filter(showHideConditionsFlag),
      ...newOtherConditions,
    ];
  },
});

const displayCustomMessageType = computed(() => {
  if (showDisplayCustomMessage.value) {
    return "displayCustomMessage";
  }
  if (showRedirectToUrl.value) {
    return "redirectToUrl";
  }
  if (showDisqualifyLead.value) {
    return "disqualifyLead";
  }
  if (showHideFields.value) {
    return "showHideFields";
  }
  return "";
});
</script>

<template>
  <UIModal :show="show" :width="800" @update:show="closeModal">
    <template #header>
      <UIModalHeader id="modal-custom-header" type="custom">
        <template #customHeader>
          <UISpace class="py-2" justify="space-between" align="center">
            <Dataflow03Icon v-if="showHomePage" class="h-6 w-6 text-gray-700" />
            <ArrowNarrowLeftIcon
              v-else
              class="h-6 w-6 cursor-pointer text-gray-700"
              @click="goToHomePage"
            />
            <XCloseIcon class="h-5 w-5 cursor-pointer text-gray-700" @click="closeModal" />
          </UISpace>
          <div v-if="showHomePage">
            <div class="pb-2">{{ t("form.conditonalLogic.conditions") }}</div>
            <span class="text-base font-medium">{{
              t("form.conditonalLogic.addNewCondition")
            }}</span>
            <div class="mt-2 flex flex-wrap gap-x-1.5 gap-y-1.5">
              <ActionButton
                v-for="(button, index) in conditionButtons"
                :key="index"
                :color="button.color"
                :button-text="button.buttonText"
                :sub-button-text="button.subButtonText"
                :disabled="button.disabled"
                class="w-[calc(50%-0.25rem)]"
                @on-action-button-click="() => goToDisplayCustomMessage(null, button.type)"
              >
                <component
                  :is="getIconComponent(button.icon)"
                  class="h-6 w-6"
                  :class="button.iconClass"
                />
              </ActionButton>
            </div>
          </div>
          <div v-else-if="showDisplayCustomMessage" class="pb-2">
            {{ t("form.conditonalLogic.displayCustomMessage") }}
          </div>
          <div v-else-if="showRedirectToUrl" class="pb-2">
            {{ t("form.conditonalLogic.redirectToUrl") }}
          </div>
          <div v-else-if="showDisqualifyLead" class="pb-2">
            {{ t("form.conditonalLogic.disqualifyLead") }}
          </div>
          <div v-else-if="showHideFields" class="pb-2">
            {{ t("form.conditonalLogic.showHideFields") }}
          </div>
        </template>
      </UIModalHeader>
    </template>
    <UIModalContent>
      <div class="divider" />
      <div
        v-if="showHomePage && !store.builderConfig.conditionalLogic?.length"
        class="flex flex-col items-center justify-center p-6"
      >
        <span class="text-base font-semibold">{{
          t("form.conditonalLogic.noExistingCondition")
        }}</span>
        <span class="text-gray-500">{{ t("form.conditonalLogic.freeRoamMessage") }}</span>
        <img src="@/assets/img/no-conditions.png" class="p-6" alt="No Conditions Yet" />
      </div>
      <DisplayCustomMessage
        v-if="showDisplayCustomMessage || showRedirectToUrl || showDisqualifyLead || showHideFields"
        :type="displayCustomMessageType"
        :edit-index="editIndex"
        @on-save="onSave" />
      <div v-if="showHomePage" class="max-h-[460px] overflow-auto rounded-lg border">
        <div v-if="showHideConditions?.length">
          <VueDraggableNext
            v-model="showHideConditions"
            handle=".drag-handle"
            @change="store.anyUnsavedChanges = true"
          >
            <div v-for="(condition, index) in showHideConditions" :key="index">
              <div :class="index !== showHideConditions?.length - 1 ? 'divider' : 'divider2'">
                <ConditionCard
                  :color="mapConditionNames[condition.outcome?.type]?.color"
                  :button-text="mapConditionNames[condition.outcome?.type]?.name"
                  :condition="condition"
                  @on-edit-button-click="
                    () => goToDisplayCustomMessage(condition, condition.outcome?.type)
                  "
                  @on-delete-button-click="() => showDeleteModal(condition)"
                >
                  <ConditionIcons :type="condition.outcome.type" />
                  <template #handle>
                    <div class="drag-handle cursor-pointer">
                      <Menu05Icon class="h-4 w-4" />
                    </div>
                  </template>
                </ConditionCard>
              </div>
            </div>
          </VueDraggableNext>
        </div>
        <div v-if="otherConditions?.length">
          <VueDraggableNext
            v-model="otherConditions"
            handle=".drag-handle"
            @change="store.anyUnsavedChanges = true"
          >
            <div v-for="(condition, index) in otherConditions" :key="index">
              <div :class="index !== otherConditions?.length - 1 ? 'divider' : ''">
                <ConditionCard
                  :color="mapConditionNames[condition.outcome?.type]?.color"
                  :button-text="mapConditionNames[condition.outcome?.type]?.name"
                  :condition="condition"
                  @on-edit-button-click="
                    () => goToDisplayCustomMessage(condition, condition.outcome?.type)
                  "
                  @on-delete-button-click="() => showDeleteModal(condition)"
                >
                  <ConditionIcons :type="condition.outcome.type" />
                  <template #handle>
                    <div class="drag-handle cursor-pointer">
                      <Menu05Icon class="h-4 w-4" />
                    </div>
                  </template>
                </ConditionCard>
              </div>
            </div>
          </VueDraggableNext>
        </div>
      </div>
      <div
        v-if="showHomePage"
        class="my-1.5 flex items-center gap-x-1.5 text-xs leading-5 text-gray-600"
      >
        <InfoCircleIcon class="h-4 w-4" />
        <div>{{ t("form.conditonalLogic.conditionExecutedMessage") }}</div>
      </div>
      <div v-if="showHomePage" class="divider mb-6"
    /></UIModalContent>
  </UIModal>
  <AlertModal
    v-if="enableDeleteModal"
    :description="t('form.conditonalLogic.alertConditionDelete')"
    :title="t('form.conditonalLogic.deleteCondition')"
    :positive-text="t('common.delete')"
    :negative-text="t('common.cancel')"
    :show-modal-popup="enableDeleteModal"
    header-type="error"
    footer-type="error"
    @pop:change="cancelCallbackDeleteUpdate"
    @pop:positive="successCallbackDeleteUpdate"
    @pop:negative="cancelCallbackDeleteUpdate"
  />
</template>

<style scoped>
.divider {
  border-bottom: 1px solid #eaecf0;
  width: 100%;
}
.divider2 {
  border-bottom: 3px solid #eaecf0;
  width: 100%;
}
</style>
