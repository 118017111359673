<template>
  <div
    class="flex w-1/2 cursor-default select-none items-center gap-x-4 rounded-lg border p-3"
    :class="disabled ? 'cursor-not-allowed opacity-50' : `${hoverClass} opacity-100`"
    @click="handleClick"
  >
    <div class="flex h-12 w-12 items-center justify-center rounded-full" :class="iconBgClass">
      <slot></slot>
    </div>
    <div class="flex-1">
      <div class="text-base font-medium">{{ buttonText }}</div>
      <div class="text-xs font-normal text-gray-500">{{ subButtonText }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { colorBgVariants, colorBgHoverVariants } from "@/util/constants";

const props = defineProps({
  color: {
    type: String,
    default: "gray",
  },
  buttonText: {
    type: String,
    default: "Button Text",
  },
  subButtonText: {
    type: String,
    default: "Sub Button Text",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["onActionButtonClick"]);
const iconBgClass = colorBgVariants[props.color];
const hoverClass = colorBgHoverVariants[props.color];

const handleClick = () => {
  if (!props.disabled) {
    emit("onActionButtonClick");
  }
};
</script>
