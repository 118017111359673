<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { generateFooterStyle } from "@/util/methods";
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from "@gohighlevel/ghl-icons/24/outline";
import { computed } from "vue";
const props = defineProps({
  isLastSlideIndex: { type: Boolean, default: false },
  currentSlideIndex: { type: Number, default: 0 },
});

const store = useAppStore();

const showSubmitButton = computed(
  () =>
    props.isLastSlideIndex || (store.slides.length === 1 && !store.formAction.fieldSettingEnable)
);

const showPreviousButton = computed(() => props.currentSlideIndex > 0);

const computedFooterStyles: any = computed(() => generateFooterStyle(store));
store.footerStyle.computedStyles = computedFooterStyles.value;
</script>

<template>
  <div
    v-if="store.builderConfig?.newFooter"
    :style="computedFooterStyles.styles.footer"
    class="ghl-footer-preview ghl-footer footer-divider bottom-0 w-full"
  >
    <div
      v-if="
        store.footerStyle?.enableProgressBar &&
        store?.footerStyle?.theme === 'steps' &&
        store.deviceMode !== 'mobile'
      "
      class="flex h-full items-center justify-center"
    >
      <span
        class="ghl-footer-progress-text mr-4 text-sm opacity-80"
        :style="computedFooterStyles.styles.progressBar.textStyle"
        >Showing 1 of {{ store.slides.length }}</span
      >
      <div
        class="ghl-footer-progress-bar flex h-[10px] w-[320px] items-center rounded-lg"
        :style="computedFooterStyles.styles.progressBar.barStyle"
      >
        <div
          class="h-full rounded-l-lg bg-blue-400"
          :style="{
            width: `${100 / store.slides.length}%`,
            ...computedFooterStyles.styles.progressBar.fillStyle,
          }"
        ></div>
      </div>
    </div>
    <div
      v-if="
        store.footerStyle?.enableProgressBar &&
        store?.footerStyle?.theme === 'percentageBar' &&
        store.deviceMode !== 'mobile'
      "
      class="flex h-full items-center justify-center"
    >
      <div
        class="ghl-footer-progress-bar flex h-[24px] w-[200px] items-center rounded-lg"
        :style="computedFooterStyles.styles.progressBar.barStyle"
      >
        <div class="ghl-footer-progress-text absolute ml-px mt-px text-sm">0%</div>
        <div
          class="h-full rounded-none bg-blue-400"
          :style="{ width: '10%', ...computedFooterStyles.styles.progressBar.fillStyle }"
        ></div>
      </div>
    </div>
    <div
      class="ghl-footer-buttons z-2 absolute bottom-0 left-0 right-0 flex h-[18px] items-center"
      :style="computedFooterStyles.styles.container"
    >
      <div
        v-if="showPreviousButton"
        class="ghl-footer-back flex h-full select-none items-center p-4"
        :style="computedFooterStyles.styles.backButton"
      >
        <ArrowNarrowLeftIcon
          v-if="computedFooterStyles.showArrow"
          direction="left"
          :stroke-color="store?.footerStyle?.buttonStyle?.fontColor"
          class="ghl-footer-back-arrow mr-0.5 h-5 w-5"
          :style="computedFooterStyles.styles.icon"
        />
        <span v-if="computedFooterStyles.showText">{{
          store?.footerStyle?.buttonStyle?.prevBtnText
        }}</span>
      </div>
      <div v-else></div>
      <div
        v-if="!showSubmitButton"
        class="ghl-footer-next flex h-full select-none items-center p-4"
        :style="computedFooterStyles.styles.nextButton"
      >
        <span v-if="computedFooterStyles.showText">{{
          store?.footerStyle?.buttonStyle?.nextBtnText
        }}</span>
        <ArrowNarrowRightIcon
          v-if="computedFooterStyles.showArrow"
          direction="right"
          :stroke-color="store?.footerStyle?.buttonStyle?.fontColor"
          class="ghl-footer-next-arrow ml-0.5 h-5 w-5"
          :style="computedFooterStyles.styles.icon"
        />
      </div>
      <div v-if="showSubmitButton"></div>
      <div
        v-if="showSubmitButton"
        class="ghl-footer-submit flex h-full select-none items-center p-4"
        :style="computedFooterStyles.styles.submitButton"
      >
        <span>{{ store?.footerStyle?.buttonStyle?.submitBtnText }}</span>
      </div>
    </div>
  </div>
</template>

<style>
.footer-divider {
  border-top: 1px solid #eaecf0;
}
</style>
