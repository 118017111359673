<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { showFieldLabel } from "@/util/methods";
import { nextTick, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  element: {
    type: Object,
    default: () => ({}),
  },
  formLabelVisible: Boolean,
});

const emit = defineEmits(["update:label", "update:placeholder", "addToHistory"]);
const store = useAppStore();

const getInputCSSList = (): Array<String> => {
  return store.builderConfig.inputStyleType === "line" ? ["line-form-style"] : [];
};

const editableLabel = ref<HTMLElement | null>(null);
const inputElement = ref<HTMLInputElement | null>(null);
const localLabel = ref(props.element.label || "");
const initialLabel = ref(props.element.label || "");
const inputClicked = ref(false);

watch(
  () => props.element.label,
  (newLabel) => {
    localLabel.value = newLabel || "";
    initialLabel.value = newLabel || "";
  }
);

const resizeInput = () => {
  if (!inputElement.value) {
    return;
  }

  if (!localLabel.value.trim()) {
    inputElement.value.style.width = "5px";
    return;
  }

  inputElement.value.style.width = "5px";
  setTimeout(() => {
    if (!inputElement.value) {
      return;
    }
    const scrollWidth = inputElement.value.scrollWidth;
    const width = scrollWidth + 7;
    inputElement.value.style.width = `${width}px`;
  }, 0);
};

const initializeInputWidth = () => {
  nextTick(() => {
    resizeInput();
  });
};

onMounted(() => {
  initializeInputWidth();
});

watch([() => localLabel.value, () => store.deviceMode], () => {
  nextTick(resizeInput);
});

watch(
  () => props.element,
  () => {
    initializeInputWidth();
  },
  { deep: true }
);

const handleInputClick = (event: MouseEvent) => {
  const target = event.target as HTMLInputElement;
  if (!inputClicked.value) {
    target.select();
    inputClicked.value = true;
  }
  // For subsequent clicks, the default behavior will position the cursor
};

const handleInput = () => {
  emit("update:label", localLabel.value);
};

const onLabelBlur = () => {
  if (initialLabel.value !== localLabel.value) {
    emit("addToHistory");
    initialLabel.value = localLabel.value;
  }
  inputClicked.value = false;
};

const inputPlaceholder = ref(props.element.placeholder || "");
watch(
  () => props.element.placeholder,
  (newVal) => {
    inputPlaceholder.value = newVal || "";
  }
);

const onPlaceholderFocus = () => {
  placeholderInputValue.value = inputPlaceholder.value;
};

const onPlaceholderInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  placeholderInputValue.value = target.value;
  inputPlaceholder.value = target.value;
};

const onPlaceholderBlur = (event: Event) => {
  placeholderInputValue.value = "";
  emit("update:placeholder", inputPlaceholder.value);
  emit("addToHistory");
};

const placeholderInputValue = ref("");

const handleFocus = (event: FocusEvent) => {
  const target = event.target as HTMLElement;
  if (target.isContentEditable) {
    const range = document.createRange();
    range.selectNodeContents(target);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
};
</script>

<template>
  <div
    v-show="showFieldLabel(formLabelVisible, element)"
    style="display: inline-flex"
    class="label-wrapper"
  >
    <div class="flex items-start">
      <div v-show="formLabelVisible" class="label-container flex items-center">
        <input
          ref="inputElement"
          v-model="localLabel"
          class="label-input font-inter cursor-text border-0 bg-transparent p-0 text-sm text-black shadow-none focus:border-gray-300 focus:ring-0"
          placeholder=""
          @input="handleInput"
          @blur="onLabelBlur"
          @click="handleInputClick"
        />
      </div>
      <span v-if="element.required && formLabelVisible" class="required-asterisk">*</span>
      <div v-if="element.hidden">
        <small class="ml-[5px] rounded-sm bg-[#e1e1e1] px-[5px] py-[2px]">
          {{ t("common.hidden") }}
        </small>
      </div>
    </div>
  </div>

  <input
    :value="placeholderInputValue"
    :placeholder="inputPlaceholder"
    class="form-placeholder form-control placeholder-input"
    :class="getInputCSSList()"
    :style="{
      fontSize: store.fieldStyle.placeholderFontSize + 'px',
    }"
    @focus="onPlaceholderFocus"
    @input="onPlaceholderInput"
    @blur="onPlaceholderBlur"
  />
  <span v-if="element.shortLabel" class="short-label">{{ element.shortLabel }}</span>
</template>

<style scoped>
.label-wrapper {
  display: flex;
}

.required-flag {
  display: flex;
  margin-bottom: 6px;
}

.label-container {
  display: flex;
  align-items: baseline;
  width: auto;
  overflow: visible;
  position: relative;
}

.label-input {
  display: inline-block;
  min-width: 0;
  outline: none;
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  margin-bottom: 2px;
}

.label-input:focus {
  background-color: rgba(200, 200, 200, 0.1);
  border-radius: 2px;
  outline: none;
}

.required-asterisk {
  font-weight: bold;
  padding-left: 0;
  font-size: 14px;
  flex-shrink: 0;
  display: inline-flex;
  margin-left: -1px;
  margin-top: 0;
  align-self: flex-start;
  position: relative;
  top: 2px;
  margin-top: -2px;
}

.placeholder-input {
  padding: 8px 20px;
  border: 1px solid #acacacff;
  color: #8c8c8c;
}
</style>
