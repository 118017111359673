<script setup lang="ts">
// import { useAppStore } from "@/store/app";
import { useAppStore } from "@/store/app";
import { deviceOptions } from "@/util/constants";
import { ChevronDownIcon, Monitor04Icon, Phone01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIBuilderSpace, UIDropdown, UITextSmMedium } from "@gohighlevel/ghl-ui";
import { PropType, computed } from "vue";
import { useI18n } from "vue-i18n";
// const store = useAppStore();

interface Padding {
  top: number;
  bottom: number;
  right: number;
  left: number;
}
interface Props {
  label: string;
  padding: Padding;
  showDeviceMode: boolean;
}
const { t } = useI18n();
const props = defineProps({
  label: {
    type: String,
    default: "Form Padding",
  },
  padding: {
    type: Object as PropType<Padding>,
    required: true,
  },
  showDeviceMode: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["updatePadding"]);

const updatedValue = (value) => {
  if (value.changed.padding.right) {
    return emits("updatePadding", "right", parseFloat(value.numericValue));
  }
  if (value.changed.padding.left) {
    return emits("updatePadding", "left", parseFloat(value.numericValue));
  }
  if (value.changed.padding.top) {
    return emits("updatePadding", "top", parseFloat(value.numericValue));
  }
  if (value.changed.padding.bottom) {
    return emits("updatePadding", "bottom", parseFloat(value.numericValue));
  }
};
const paaddingPixel = computed(() => {
  return {
    top: (props.padding.top || 0) + "px",
    right: (props.padding.right || 0) + "px",
    bottom: (props.padding.bottom || 0) + "px",
    left: (props.padding.left || 0) + "px",
  };
});

const store = useAppStore();

const switchDeviceMode = (value: string) => {
  store.deviceMode = value;
};
</script>

<template>
  <div class="flex flex-col items-start gap-2 self-stretch">
    <div class="flex items-center justify-between">
      <UITextSmMedium class="mr-2">{{ label }} </UITextSmMedium>
      <UIDropdown
        v-if="showDeviceMode"
        id="desktop-mobile-toggle"
        :options="deviceOptions"
        @select="switchDeviceMode"
      >
        <template #default>
          <div class="flex items-center gap-0.5">
            <Phone01Icon v-if="store.deviceMode === 'mobile'" class="h-4 w-4 cursor-pointer" />
            <Monitor04Icon v-else class="h-4 w-4 cursor-pointer" />
            <ChevronDownIcon class="h-3 w-3" />
          </div>
        </template>
      </UIDropdown>
    </div>
    <div class="flex justify-center self-stretch">
      <UIBuilderSpace
        :margin-readonly="true"
        :margin="{ top: 'auto', bottom: 'auto', right: 'auto', left: 'auto' }"
        :padding="paaddingPixel"
        :padding-units-config="['px']"
        @update:value="updatedValue"
      />
    </div>
  </div>
</template>

<style scoped></style>
