<script setup lang="ts">
import { reactive, watch } from "vue";
import {
  Bold02Icon,
  Heading02Icon,
  Pilcrow02Icon,
  Italic02Icon,
  Strikethrough02Icon,
  AlignLeftIcon,
  AlignCenterIcon,
  AlignRightIcon,
  AlignJustifyIcon,
  Attachment02Icon,
} from "@gohighlevel/ghl-icons/24/outline";
import RichTextEditor from "./RichTextEditor.vue";
import Card from "./Card.vue";
import FontFamilyInput from "./FontFamilyInput.vue";
import ColorInputField from "./ColorInputField.vue";
import CountInput from "./CountInput.vue";
import BorderInput from "./BorderInput.vue";
import BorderType from "./BorderType.vue";
import Switch from "./Switch.vue";
import { defaultShadow, defaultBtnPadding } from "./../../util/default";
import { useAppStore } from "@/store/app";
import { useI18n } from "vue-i18n";
import { UIInputGroup, UIInputNumber, UIInputGroupLabel } from "@gohighlevel/ghl-ui";
import { CONSTANTS } from "../../util/constants";
const { t } = useI18n();
const store = useAppStore();

const props = defineProps({
  property: {
    type: Object,
    default() {
      return {
        label: "Button",
        tag: "button",
        type: "submit",
        placeholder: "Button",
        submitSubText: "",
        bgColor: "18BD5BFF",
        padding: defaultBtnPadding,
        border: 0,
        borderType: "none",
        borderColor: "FFFFFF",
        borderRadius: 6,
        shadow: defaultShadow,
        fullwidth: true,
        color: "FFFFFF",
        fontSize: 14,
        weight: 500,
        radius: 0,
        fontFamily: "Roboto",
        subTextColor: "000000",
        subTextFontSize: 14,
        subTextWeight: 200,
        subTextFontFamily: "Roboto",
        align: "left",
        fieldWidthPercentage: 100,
      };
    },
  },
});
const output = reactive({ ...props.property });

watch(
  () => props.property,
  (newVal) => {
    const keys = Object.keys(newVal);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      output[key] = newVal[key];
    }
  },
  {
    deep: true,
  }
);

const emits = defineEmits(["update:button"]);

const handleTextFieldStyling = (value, type) => {
  switch (type) {
    case "align":
      output.align = value;
      break;
    case "background":
      output.bgColor = value;
      break;
    case "border":
      output.border = parseFloat(value);
      break;
    case "borderColor":
      output.borderColor = value;
      break;
    case "borderType":
      output.borderType = value;
      break;
    case "cornerRadius":
      output.radius = parseFloat(value);
      break;
    case "fullWidth":
      output.fullwidth = value;
      if (
        CONSTANTS.SINGLE_LINE ===
          (store.deviceMode === "mobile"
            ? store.builderConfig.mobileLayout || store.builderConfig.layout
            : store.builderConfig.layout) &&
        value
      ) {
        output.fieldWidthPercentage = 100;
      }
      break;
    case "paddingTop":
      output.padding.top = value;
      break;
    case "paddingBottom":
      output.padding.bottom = value;
      break;
    case "paddingLeft":
      output.padding.left = value;
      break;
    case "paddingRight":
      output.padding.right = value;
      break;
    case "shadowColor":
      output.shadow.color = value;
      break;
    case "shadowHorizontal":
      output.shadow.horizontal = value;
      break;
    case "shadowVertical":
      output.shadow.vertical = value;
      break;
    case "shadowBlur":
      output.shadow.blur = value;
      break;
    case "shadowSpread":
      output.shadow.spread = value;
      break;
    case "textLabel":
      output.label = value;
      break;
    case "labelFontFamily":
      output.fontFamily = value;
      store.fontCollection.push(value);
      break;
    case "labelFontColor":
      output.color = value;
      break;
    case "labelFontSize":
      output.fontSize = value;
      break;
    case "labelFontWeight":
      output.weight = value;
      break;
    case "subTextLabel":
      output.submitSubText = value;
      break;
    case "subLabelFontFamily":
      output.subTextFontFamily = value;
      store.fontCollection.push(value);
      break;
    case "subLabelFontColor":
      output.subTextColor = value;
      break;
    case "subLabelFontSize":
      output.subTextFontSize = value;
      break;
    case "subLabelFontWeight":
      output.subTextWeight = value;
      break;
    case "fieldWidthPercentage":
      output.fieldWidthPercentage = value;
    default:
      break;
  }
  store.anyUnsavedChanges = true;
  store.addToHistory();
  emits("update:button", output);
};
</script>

<template>
  <h6 class="field-name-sidebar">{{ t("common.button") }}</h6>
  <div class="alignment">
    <div class="alignment-div">{{ t("common.alignment") }}</div>
    <div class="icons-collection">
      <AlignLeftIcon
        class="custom-icons h-5 w-5"
        @click="handleTextFieldStyling('left', 'align')"
      ></AlignLeftIcon>
      <AlignCenterIcon
        class="custom-icons h-5 w-5"
        @click="handleTextFieldStyling('center', 'align')"
      ></AlignCenterIcon>
      <AlignRightIcon
        class="custom-icons h-5 w-5"
        @click="handleTextFieldStyling('right', 'align')"
      ></AlignRightIcon>
    </div>
  </div>
  <UITextMdNormal>{{ t("common.text") }}</UITextMdNormal>
  <RichTextEditor
    :model-value="output.label"
    @update:model-value="(value) => handleTextFieldStyling(value, 'textLabel')"
  ></RichTextEditor>
  <UITextMdNormal>{{ t("common.subText") }}</UITextMdNormal>
  <RichTextEditor
    :model-value="output.submitSubText"
    @update:model-value="(value) => handleTextFieldStyling(value, 'subTextLabel')"
  ></RichTextEditor>
  <Card :header="t('common.buttonStyle')">
    <ColorInputField
      :label="t('common.background')"
      :color="output.bgColor"
      @update:color="(value) => handleTextFieldStyling(value, 'background')"
    />
    <BorderInput
      :border="output?.border"
      :border-color="output?.borderColor"
      @update:border="(value) => handleTextFieldStyling(value, 'border')"
      @update:color="(value) => handleTextFieldStyling(value, 'borderColor')"
    />
    <BorderType
      :border-type="output?.borderType"
      @update:border-type="(value) => handleTextFieldStyling(value, 'borderType')"
    />
    <CountInput
      :label="t('common.cornerRadius')"
      :value="output?.radius"
      @update:value="(value) => handleTextFieldStyling(value, 'cornerRadius')"
    />
    <Switch
      :label="t('common.fullWidth')"
      :value="output?.fullwidth"
      @update:value="(value) => handleTextFieldStyling(value, 'fullWidth')"
    />
    <div v-if="!output.fullwidth" class="grid grid-cols-2">
      <label class="my-auto text-sm font-normal text-gray-700">
        {{ t("common.style.fieldWidth") }}</label
      >
      <div id="input-color-wrapper" class="input-color-picker">
        <UIInputGroup>
          <UIInputNumber
            id="fieldQueryKey"
            size="medium"
            class="w-100"
            :min="1"
            :max="100"
            :default-value="100"
            :show-button="false"
            :model-value="output.fieldWidthPercentage"
            @update:model-value="(value) => handleTextFieldStyling(value, 'fieldWidthPercentage')"
            @blur="store.addToHistory()"
          ></UIInputNumber
          ><UIInputGroupLabel size="medium">%</UIInputGroupLabel>
        </UIInputGroup>
      </div>
    </div>
    <div class="m-2"></div>
    <UITextMdNormal>{{ t("common.padding") }}</UITextMdNormal>
    <div class="my-2 grid grid-cols-2 gap-4">
      <CountInput
        :is-label-small="true"
        :label="t('common.top')"
        :value="output?.padding?.top"
        @update:value="(value) => handleTextFieldStyling(value, 'paddingTop')"
      />
      <CountInput
        :is-label-small="true"
        :label="t('common.bottom')"
        :value="output?.padding?.bottom"
        @update:value="(value) => handleTextFieldStyling(value, 'paddingBottom')"
      />
    </div>
    <div class="my-2 grid grid-cols-2 gap-4">
      <CountInput
        :is-label-small="true"
        :label="t('common.left')"
        :value="output?.padding?.left"
        @update:value="(value) => handleTextFieldStyling(value, 'paddingLeft')"
      />
      <CountInput
        :is-label-small="true"
        :label="t('common.right')"
        :value="output?.padding?.right"
        @update:value="(value) => handleTextFieldStyling(value, 'paddingRight')"
      />
    </div>
    <div class="m-2"></div>
    <UITextMdNormal>{{ t("common.shadow") }}</UITextMdNormal>
    <ColorInputField
      :label="t('common.color')"
      :color="output?.shadow?.color"
      @update:color="(value) => handleTextFieldStyling(value, 'shadowColor')"
    />
    <div class="my-2 grid grid-cols-2 gap-4">
      <CountInput
        :is-label-small="true"
        :label="t('common.horizontal')"
        :value="output?.shadow?.horizontal"
        @update:value="(value) => handleTextFieldStyling(value, 'shadowHorizontal')"
      />
      <CountInput
        :is-label-small="true"
        :label="t('common.vertical')"
        :value="output?.shadow?.vertical"
        @update:value="(value) => handleTextFieldStyling(value, 'shadowVertical')"
      />
    </div>
    <div class="my-2 grid grid-cols-2 gap-4">
      <CountInput
        :is-label-small="true"
        :label="t('common.blur')"
        :value="output?.shadow?.blur"
        @update:value="(value) => handleTextFieldStyling(value, 'shadowBlur')"
      />
      <CountInput
        :is-label-small="true"
        :label="t('common.spread')"
        :value="output?.shadow?.spread"
        @update:value="(value) => handleTextFieldStyling(value, 'shadowSpread')"
      />
    </div>
  </Card>
  <Card :header="t('common.text')">
    <ColorInputField
      :label="t('common.color')"
      :color="props.property.color"
      @update:color="(value) => handleTextFieldStyling(value, 'labelFontColor')"
    />
    <FontFamilyInput
      :label="t('common.fontFamily')"
      :value="props.property.fontFamily"
      @update:value="(value) => handleTextFieldStyling(value, 'labelFontFamily')"
    />
    <CountInput
      :label="t('common.size')"
      :value="props.property.fontSize"
      @update:value="(value) => handleTextFieldStyling(value, 'labelFontSize')"
    />
    <CountInput
      :label="t('common.weight')"
      :value="props.property.weight"
      :max="1800"
      @update:value="(value) => handleTextFieldStyling(value, 'labelFontWeight')"
    />
  </Card>
  <Card :header="t('common.subText')">
    <ColorInputField
      :label="t('common.color')"
      :color="props.property.subTextColor"
      @update:color="(value) => handleTextFieldStyling(value, 'subLabelFontColor')"
    />
    <FontFamilyInput
      :label="t('common.fontFamily')"
      :value="props.property.subTextFontFamily"
      @update:value="(value) => handleTextFieldStyling(value, 'subLabelFontFamily')"
    />
    <CountInput
      :label="t('common.size')"
      :value="props.property.subTextFontSize"
      @update:value="(value) => handleTextFieldStyling(value, 'subLabelFontSize')"
    />
    <CountInput
      :label="t('common.weight')"
      :value="props.property.subTextWeight"
      :max="1800"
      @update:value="(value) => handleTextFieldStyling(value, 'subLabelFontWeight')"
    />
  </Card>
</template>

<style scoped>
.custom-icons {
  color: #38a0db;
  margin: 0 10px;
}
.icons-collection {
  display: flex;
  align-items: center;
}
.icons-collection svg {
  cursor: pointer;
}
.alignment {
  display: flex;
  margin-bottom: 10px;
}
.alignment-div {
  margin-right: 10px;
}

.field-name-sidebar {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center !important;
  margin-top: 1rem;
}
</style>
