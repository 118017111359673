<template>
  <div class="flex flex-col">
    <div v-if="picklistOptions !== undefined" :style="styleObject">
      <VueDraggableNext v-model="localPicklistOptions" handle=".drag-handle" class="w-full">
        <div
          v-for="(pickerOption, index) in localPicklistOptions"
          :key="index"
          :style="minimumWidth"
          class="option"
        >
          <div
            v-if="typeof pickerOption === 'string' ? pickerOption !== '' : true"
            class="align-together flex"
          >
            <Menu05Icon
              v-if="isActive"
              class="drag-handle ml-4 mt-1 h-4 w-4 cursor-move text-gray-500"
            />
            <input
              v-if="type === 'input'"
              class="ml-2 mt-1"
              type="checkbox"
              :readonly="readonly"
              :value="typeof pickerOption === 'string' ? pickerOption : pickerOption.label"
              disabled
            />
            <label
              :for="field.id + '_' + index"
              contenteditable="true"
              class="inline-block w-auto cursor-text border-0 shadow-none outline-none"
              @input="updateOptionLabel($event, index)"
              @focus="handleFocus"
              @keyup="handleEditing($event, index)"
            >
              {{ typeof pickerOption === "string" ? pickerOption : pickerOption.label }}
            </label>
            <Trash01Icon
              v-if="isActive"
              class="ml-2 mt-1 h-4 w-4 cursor-pointer text-gray-500"
              @click="prepareToRemoveOption(index)"
            />
          </div>
        </div>
      </VueDraggableNext>
    </div>
    <div v-if="isActive" class="mt-[-1rem] flex items-center">
      <UIButton id="add-option" text class="mt-4" :disabled="isLastOptionEmpty" @click="addOption">
        <PlusIcon class="mr-1.5 h-5 w-5" />
        {{ t("common.addOption") }}
      </UIButton>
    </div>
    <template v-if="isActive">
      <input
        v-model="shortLabel"
        :placeholder="t('common.shortLabelPlaceholder')"
        class="short-label border-0 p-0 focus:outline-none"
        @input="updateShortLabel"
      />
    </template>
    <template v-else>
      <span v-if="field.shortLabel" class="short-label">
        {{ field.shortLabel }}
      </span>
    </template>
  </div>
  <DeleteConfirmationModal
    :show="showDeleteModal"
    @confirm="removeOption"
    @cancel="cancelRemoveOption"
  />
</template>

<script setup lang="ts">
import { CONSTANTS } from "@/util/constants";
import { Menu05Icon, PlusIcon, Trash01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton } from "@gohighlevel/ghl-ui";
import { cloneDeep, isEqual } from "lodash";
import { computed, CSSProperties, ref, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import { useI18n } from "vue-i18n";
import DeleteConfirmationModal from "./DeleteConfirmationModal.vue";

const { t } = useI18n();

interface PickerOption {
  src?: string;
  label?: string;
  [key: string]: any;
}

const props = defineProps({
  type: {
    type: String,
    default: "input",
  },
  placeholder: {
    type: String,
    default: "",
  },
  picklistOptions: {
    type: Array as () => (string | PickerOption)[],
    default: () => {
      return [];
    },
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  layout: {
    type: Number,
    default: 1,
  },
  field: {
    type: Object,
    default() {
      return {};
    },
  },
  styleObject: {
    type: Object as () => CSSProperties,
    default: () => ({}),
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:shortLabel", "update:picklistOptions"]);

const shortLabel = ref(props.field.shortLabel || "");

// Add local state for the draggable component
const localPicklistOptions = ref([...props.picklistOptions]);

// Add bidirectional watchers with flag to prevent recursive updates
const isInternalUpdate = ref(false);

watch(
  () => props.picklistOptions,
  (newVal) => {
    if (!isInternalUpdate.value) {
      localPicklistOptions.value = cloneDeep(newVal);
    }
    isInternalUpdate.value = false;
  },
  { deep: true }
);

watch(
  () => localPicklistOptions.value,
  (newVal) => {
    if (!isEqual(newVal, props.picklistOptions)) {
      isInternalUpdate.value = true;
      emit("update:picklistOptions", cloneDeep(newVal));
    }
  },
  { deep: true }
);

watch(
  () => props.field.shortLabel,
  (newVal) => {
    shortLabel.value = newVal || "";
  }
);

const indexToRemove = ref<number | null>(null);
const showDeleteModal = ref(false);

const getWidth = () => {
  let width = 100;
  if (props.layout === CONSTANTS.TWO_COLUMN) {
    width = Math.max(width / props.field.columnsNumber, 50);
  } else {
    width = Math.max(width / props.field.columnsNumber, 20);
  }
  if (props.field.spreadColumns) {
    return width + "%";
  }
  return "100%";
};
const minimumWidth = computed((): CSSProperties => {
  const width = getWidth();
  return {
    width,
  };
});

const updateOptionLabel = (event: Event, index: number) => {
  const target = event.target as HTMLElement;
  const text = target.innerText.replace(/\u200B/g, "");

  const newOptions = [...localPicklistOptions.value];
  if (typeof newOptions[index] === "string") {
    newOptions[index] = text;
  } else {
    (newOptions[index] as PickerOption).label = text;
  }

  localPicklistOptions.value = newOptions;
};

const prepareToRemoveOption = (index: number) => {
  if (props.picklistOptions.length > 1) {
    indexToRemove.value = index;
    showDeleteModal.value = true;
  }
};

const removeOption = () => {
  if (indexToRemove.value !== null) {
    const newOptions = [...localPicklistOptions.value];
    newOptions.splice(indexToRemove.value, 1);
    localPicklistOptions.value = newOptions;
  }
  showDeleteModal.value = false;
};

const cancelRemoveOption = () => {
  indexToRemove.value = null;
  showDeleteModal.value = false;
};

const isLastOptionEmpty = computed(() => {
  const lastOption = props.picklistOptions[props.picklistOptions.length - 1];
  if (typeof lastOption === "string") {
    return lastOption.trim() === "";
  }
  return lastOption?.label?.trim() === "";
});

const addOption = () => {
  const newOptions = [...localPicklistOptions.value];
  if (typeof newOptions[0] === "string") {
    newOptions.push("Type an Option");
  } else {
    newOptions.push({ id: `option-${Date.now()}`, label: "Type an Option" });
  }
  localPicklistOptions.value = newOptions;
};

const handleFocus = (event: FocusEvent) => {
  const target = event.target as HTMLElement;

  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
  }

  if (!target.textContent || target.textContent.trim() === "") {
    if (target.childNodes.length === 0) {
      const textNode = document.createTextNode("\u200B");
      target.appendChild(textNode);

      const range = document.createRange();
      range.setStart(textNode, 0);
      range.collapse(true);

      if (selection) {
        selection.addRange(range);
      }
    } else {
      const range = document.createRange();
      range.setStart(target, 0);
      range.collapse(true);

      if (selection) {
        selection.addRange(range);
      }
    }
  } else {
    const range = document.createRange();
    range.selectNodeContents(target);

    if (selection) {
      selection.addRange(range);
    }
  }
};

const updateShortLabel = (event?: Event) => {
  if (event) {
    const target = event.target as HTMLInputElement;
    shortLabel.value = target.value;
  }
  emit("update:shortLabel", shortLabel.value);
};

const handleEditing = (event: KeyboardEvent, index: number) => {
  const target = event.target as HTMLElement;

  if (target.textContent?.trim() === "") {
    if (target.textContent) {
      target.textContent = "";

      const range = document.createRange();
      range.setStart(target, 0);
      range.collapse(true);

      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }
};
</script>

<style scoped>
.option {
  position: relative;
  display: inline-block;
  widows: 100%;
}
.option label {
  line-height: 30px;
  display: block;
  margin-left: 10px;
}
.short-label {
  background-color: transparent;
  margin-top: 4px;
  font-size: 14px;
  color: #4b5563;
}
</style>
