<template>
  <UIModal :show="show">
    <template #header>
      <UIModalHeader
        id="modal-header"
        type="error"
        title="Delete Option"
        :description="description"
        @close="cancel"
      >
      </UIModalHeader>
    </template>
    <UIModalContent>
      <UIForm id="modal-form" ref="root" :model="formValue" :rules="rules">
        <UIFormItem :label="t('common.deleteConfirmation')" path="deleteConfirm">
          <UIInput
            id="deleteConfirm"
            v-model="formValue.deleteConfirm"
            class="mb-3"
            :placeholder="t('common.deleteCaps')"
          />
        </UIFormItem>
      </UIForm>
    </UIModalContent>
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        positive-text="Delete"
        negative-text="Cancel"
        type="error"
        @positive-click="confirm"
        @negative-click="cancel"
      >
      </UIModalFooter>
    </template>
  </UIModal>
</template>

<script setup lang="ts">
import {
  UIForm,
  UIFormItem,
  UIInput,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
} from "@gohighlevel/ghl-ui";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  description: {
    type: String,
    default:
      "Deleting an option will impact this custom field across all places where the field is used. Are you sure you wish to delete it?",
  },
});

watch(
  () => props.show,
  () => {
    if (props.show) {
      formValue.value.deleteConfirm = "";
    }
  }
);

const emit = defineEmits(["confirm", "cancel"]);

const root = ref();

const rules = {
  deleteConfirm: {
    required: true,
    message: "Please enter DELETE to confirm.",
    trigger: "blur",
    validator(_, value) {
      if (value.trim().toLowerCase() !== "delete") {
        return new Error("Please enter DELETE");
      }
    },
  },
};

const formValue = ref({
  deleteConfirm: "",
});

const confirm = async () => {
  await root.value.getForm().validate();
  emit("confirm");
};

const cancel = () => {
  emit("cancel");
};
</script>
